<template>
  <div>
    <div v-for="(element, index) in moduleList" :key="index">
      <div>
        <modlueSearch :module="element" v-if="element.type == 101" />
        <modlueBanner :module="element" v-if="element.type == 102" />
        <moduleNav :module="element" v-if="element.type == 103" />
        <modlueGoods :module="element" v-if="element.type == 104" />
        <modlueRecommend :module="element" v-if="element.type == 105" />
        <modlueNotice :module="element" v-if="element.type == 106" />
        <modlueGuangGao :module="element" v-if="element.type == 107" />
        <modlueMenu :module="element" v-if="element.type == 108" />
        <modluePriceLog :module="element" v-if="element.type == 109" />
      </div>
    </div>
  </div>
</template>

<script>
import HIMAGE from "@/components/IMAGE/index.vue";

// 移动端
import moduleNav from "./module/nav/common.vue";
import modlueGoods from "./module/goods/common.vue";
import modlueSearch from "./module/search/common.vue";
import modlueBanner from "./module/banner/common.vue";
import modlueRecommend from "./module/recommend/common.vue";
import modlueNotice from "./module/Notice/common.vue";
import modlueGuangGao from "./module/guanggao/common.vue";
import modlueMenu from "./module/menu/common.vue";
import modluePriceLog from "./module/priceLog/common.vue";

import MLink from "@/components/Link/mindex.vue";

export default {
  name: "Module",

  props: ["moduleList", "footerData"],

  components: {
    HIMAGE,
    MLink,

    //组件
    moduleNav,
    modlueGoods,
    modlueSearch,
    modlueBanner,
    modlueRecommend,
    modlueNotice,
    modlueGuangGao,
    modlueMenu,
    modluePriceLog,
  },
  data() {
    return {
      selectedLinkIndex: undefined,

      footerStatus: false,
      footerItem: {
        name: undefined,
        icon: undefined,
        link: undefined,
      },

      dialogFooterVisible: false,

      placeholder: false,
      selectIndex: undefined, //选中
      activeIndex: undefined, //当前活动active
    };
  },

  methods: {
    //模块点击
    moduleClick(index) {
      this.footerStatus = false;

      this.selectIndex = index;

      this.activeIndex = index;

      this.$emit("moduleClick", index);
    },

    //底部模块点击
    footerClick() {
      this.footerStatus = true;

      this.dialogFooterVisible = true;
    },

    //鼠标移入模块
    mouseovers(index) {
      let top = event.target.getBoundingClientRect().top;
      this.$emit("moduleMouseovers", { top: top, index: index });
    },

    /**
     * 底部栏添加一行
     */
    handleFooter() {
      if (!this.footerData.list) {
        this.footerData.list = [];
      }

      let row = { name: "标题", link: "", icon: "" };

      this.footerData.list.push(row);

      this.$forceUpdate();
    },

    /**
     * 删除
     */
    deleteFooter(item) {
      // 确保至少保留一个元素
      // if (this.footerData[1].list.length <= 1) {
      //   this.$message({
      //     showClose: true,
      //     message: "至少保留一个！"
      //   });
      //   return;
      // }

      var index = this.footerData.list.indexOf(item);
      if (index !== -1) {
        this.footerData.list.splice(index, 1);
      }

      this.$forceUpdate();
    },

    //鼠标离开模块
    mouseout() {
      this.$emit("moduleMouseout");
    },

    delectModule() {
      this.selectIndex = undefined;
      this.moduleList.splice(this.activeIndex, 1);
      this.$emit("deleteMouseout");
    },

    //上移
    up() {
      //移动
      this.moduleList.splice(
        this.activeIndex - 1,
        0,
        this.moduleList[this.activeIndex]
      );

      //删除原来的
      this.moduleList.splice(this.activeIndex + 1, 1);

      this.selectIndex = this.activeIndex - 1;

      this.activeIndex = this.activeIndex - 1;

      this.$forceUpdate();
    },

    //下移
    down() {
      this.moduleList.splice(
        this.activeIndex + 2,
        0,
        this.moduleList[this.activeIndex]
      );
      this.moduleList.splice(this.activeIndex, 1);

      this.selectIndex = this.activeIndex + 1;

      this.activeIndex = this.activeIndex + 1;

      this.$forceUpdate();
    },

    /**
     * 上传图片
     */
    openImageSelector(index) {
      this.selectedIndex = index;

      this.$refs.HImage.handleAdd();
    },

    //图片选择返回
    imageCallback(row) {
      // 获取要更新的行
      const updatedRow = {
        ...this.footerData.list[this.selectedIndex],
        icon: row.imgUrl,
      };

      // 使用 Vue 的 $set 方法更新数组中的对象
      this.$set(this.footerData.list, this.selectedIndex, updatedRow);

      this.$forceUpdate();
    },

    /**
     * 打开链接组件
     */
    openLink(index) {
      this.selectedLinkIndex = index;

      this.$refs.mlink.handleAdd();
    },

    /**
     * 链接选择
     */
    linkCallback(link) {
      // 获取要更新的行
      const updatedRow = {
        ...this.footerData.list[this.selectedLinkIndex],
        link: link,
      };

      // 使用 Vue 的 $set 方法更新数组中的对象
      this.$set(this.footerData.list, this.selectedLinkIndex, updatedRow);

      this.$forceUpdate();
    },
  },
};
</script>
<style>
.putItHere {
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px dashed #1890ff;
  color: #1890ff;
  background-color: #edf4fb;
}

.page-footer {
  display: flex;
  background: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  background: rgb(255, 255, 255);
}

.font-item_icon {
  width: 24px;
  height: 24px;
}

.page-footer .foot-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
}

/* ===================================新的=================================== */
.footer_bottom .item {
  position: relative;
  display: flex;
  background: #f9f9f9;
  align-items: center;
  padding: 16px 20px 16px 0;
  margin-bottom: 16px;
  border-radius: 3px;
}

.footer_bottom .item .delect-btn {
  position: absolute;
  right: -13px;
  top: -16px;
}

.footer_bottom .item .delect-btn .iconfont-diy {
  font-size: 25px;
  color: #ccc;
}

.footer_bottom .item .move-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  cursor: move;
}

.iconfont-diy {
  color: #ddd;
  font-size: 16px;
}

.footer_bottom .item .img-box {
  position: relative;
  width: 64px;
  height: 64px;
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #ccc;
}

.footer_bottom .item .info {
  flex: 1;
  margin-left: 22px;
}

.footer_bottom .item .info .info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer_bottom .item .info .info-item .span {
  width: 40px;
  font-size: 12px;
  color: #999;
}

.footer_bottom .item .info .info-item .input-box {
  flex: 1;
}

.footer_on {
  border: 2px solid #1890ff;
  box-shadow: 0 0 10px 0 rgba(24, 144, 255, 0.3);
}
</style>
