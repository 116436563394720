<!-- 用户升级 -->
<template>
  <div style="width: 100%; height: 100vh; background-color: #222">
    <van-nav-bar
      title="自助升级"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="cont infocont">
      <van-notice-bar
        left-icon="volume-o"
        :text="'您当前等级为『' + levelName + '』，等级越高购买价格更低~'"
      />
      <div class="addbank"><!----></div>
      <div class="u-select"><!----></div>
      <div>
        <div class="pad cont-top">
          <div class="cont-upgrade radius16">
            <div class="flex align-center">
              <div class="flex-sub padding-left">
                <div class="text-lg">{{ username }}</div>
                <div class="text-sm padding-top-xs">
                  当前等级：{{ levelName }}
                </div>
              </div>
              <div>
                <uni-button
                  class="cu-btn sm round"
                  type="primary"
                  @click="levelSj()"
                  >立即升级</uni-button
                >
              </div>
            </div>
          </div>
          <div
            class="top-title text-bold margin-top flex align-center justify-center"
          >
            <img class="round" src="@/assets/image/up_title_left.png" />
            <span>会员可享权益</span>
            <img class="round" src="@/assets/image/up_title_left.png" />
          </div>
          <div class="cont-grid flex flex-wrap align-center">
            <div class="item">
              <div>
                <img class="round" src="@/assets/image/hlyh.png" />
              </div>
              <div class="item-bottom" style="color: rgb(237, 199, 148)">
                海量优惠
              </div>
            </div>
            <div class="item">
              <div>
                <img class="round" src="@/assets/image/syzj.png" />
              </div>
              <div class="item-bottom" style="color: rgb(237, 199, 148)">
                收益增加
              </div>
            </div>
            <div class="item">
              <div>
                <img class="round" src="@/assets/image/tdsy.png" />
              </div>
              <div class="item-bottom" style="color: rgb(237, 199, 148)">
                团队收益
              </div>
            </div>
            <div class="item">
              <div>
                <img class="round" src="@/assets/image/zsfl.png" />
              </div>
              <div class="item-bottom" style="color: rgb(237, 199, 148)">
                专属福利
              </div>
            </div>
            <div class="item">
              <div>
                <img class="round" src="@/assets/image/zskf.png" />
              </div>
              <div class="item-bottom" style="color: rgb(237, 199, 148)">
                专属客服
              </div>
            </div>
            <div class="item">
              <div>
                <img class="round" src="@/assets/image/zsds.png" />
              </div>
              <div class="item-bottom" style="color: rgb(237, 199, 148)">
                专属导师
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 20px; background-color: rgb(34, 34, 34)">
        <van-button round type="success" block @click="levelSj()"
          >立即升级</van-button
        >
      </div>
    </div>

    <!-- 升级弹框 -->
    <van-dialog
      v-model="dialogVisible"
      title="自助升级"
      show-cancel-button
      @confirm="upgradation"
    >
      <div style="margin: 20px 10px">
        <van-form>
          <van-field
            readonly
            clickable
            name="等级"
            v-model="modelData.levelName"
            label="等级"
            placeholder="请选择等级"
            @click="showPicker = true"
          />

          <van-field
            readonly
            v-model="modelData.levelPrice"
            name="价格"
            label="价格"
            placeholder="升级价格"
            :rules="[{ required: true, message: '请输入价格' }]"
          />
        </van-form>
      </div>
    </van-dialog>

    <!-- 选择器 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="请选择提升等级"
        show-toolbar
        :columns="levelList"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      >
        <template #option="option">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div>{{ option.levelName }}L{{ option.levelNum }}</div>
          </div>
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getMemberLevelList, userUpgradation } from "@/api/user";
import { Toast } from "vant";

export default {
  data() {
    return {
      dialogVisible: false,
      showPicker: false,
      levelList: [],
      modelData: {
        levelPrice: undefined,
        levelName: undefined,
        levelNum: undefined,
      },
      queryParam: {},
    };
  },
  computed: {
    ...mapState({
      username: (state) => state.user.username,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      statusText: (state) => state.user.statusText,
      levelName: (state) => state.user.levelName,
    }),
  },
  created() {
    //查询我的信息
    this.$store.dispatch("user/info").then();
    this.queryMemberLevelList();
  },
  methods: {
    /**
     * 查询等级列表
     */
    queryMemberLevelList() {
      new Promise((resolve, rejust) => {
        getMemberLevelList(this.queryParam)
          .then((res) => {
            this.levelList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 升级弹框
     */
    levelSj() {
      if (this.levelList.length <= 0) {
        Toast.fail({
          message: "当前等级已是最高等级",
        });
        return;
      }
      this.dialogVisible = true;
    },

    /**
     * 确认选择
     */
    onConfirm(v) {
      this.modelData.levelPrice = v.levelPrice;
      this.modelData.levelName = v.levelName;
      this.modelData.levelNum = v.levelNum;
      this.showPicker = false;
    },

    /**
     * 自主升级
     */
    upgradation() {
      new Promise((resolve, rejust) => {
        userUpgradation(this.modelData)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "恭喜您,升级成功",
              });
            }
            this.queryMemberLevelList();
            this.$store.dispatch("user/info").then();
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //跳转到我的页面
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.infocont {
  background-color: #222;
  /* height: 100%; */
}

.cu-btn {
  position: relative;
  border: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16px;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  overflow: visible;
  margin-left: 0;
  -webkit-transform: translate(0);
  transform: translate(0);
  margin-right: 0;
}
.infocont {
  background: #fff;
}
.cont-top {
  background: #222;
}
.pad {
  padding: 9px;
}

.text-bold {
  font-weight: 700;
}
.align-end {
  align-items: flex-end;
}
.flex {
  display: flex;
}
.cont-upgrade {
  color: #987238;
  background: -webkit-linear-gradient(left, #f9dcab, #e3be79);
  background: linear-gradient(90deg, #f9dcab, #e3be79);
  padding: 21px 17px;
}
.cont-upgrade .avatar {
  width: 48px;
  height: 48px;
  border: 1px solid #987238;
}
.round {
  border-radius: 5000px;
}
.padding-left {
  padding-left: 13px;
}
.cont .cu-btn.sm {
  font-size: 14px;
}
.text-lg {
  font-size: 17px;
}
.text-sm {
  font-size: 13px;
}
.padding-top-xs {
  padding-top: 4px;
}
.flex-sub {
  flex: 1;
}
.align-center {
  align-items: center;
}
.radius16 {
  border-radius: 8px;
}
.cont .top-title {
  color: #fddea8;
  font-size: 19px;
  padding: 11px 0 16px;
}
.margin-top {
  margin-top: 16px;
}
.justify-center {
  justify-content: center;
}
.cont .icon-top {
  width: 30px;
  height: 12px;
  margin: 0 11px;
}
.flex-wrap {
  flex-wrap: wrap;
}

.bg-white {
  background-color: #fff;
  color: #333;
}

.cont-upgrade .cu-btn {
  background: -webkit-linear-gradient(left, #d0a060, #bb8b51);
  background: linear-gradient(90deg, #d0a060, #bb8b51);
  color: #ffffff;
}

.cu-btn::after {
  display: none;
}

.cont-grid .item {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 11px;
}
.cont-grid .item .img {
  width: 44px;
  height: 44px;
}

.addbank .u-form-item {
  padding: 11px;
}
</style>
