var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
    { 'border-radius': _vm.module.base.round + 'px' },
  ])},[_c('div',{style:([{ 'border-radius': _vm.module.base.round + 'px' }])},[_c('div',{staticClass:"classify_view"},[_c('van-swipe',{attrs:{"loop":false,"indicator-color":"#2196F3"}},_vm._l((_vm.groupedNavLists),function(group,index){return _c('van-swipe-item',{key:index},[_c('van-grid',{staticClass:"no-border-grid",attrs:{"column-num":4,"border":false}},_vm._l((group),function(item){return _c('van-grid-item',{key:item.id,on:{"click":function($event){return _vm.toProductList(item)}}},[_c('van-image',{staticStyle:{"width":"40px","height":"40px","border-radius":"5px"},attrs:{"round":"","src":item.prcUrl,"lazy-load":""}}),_c('div',{staticStyle:{"padding-top":"5px"}},[_vm._v(" "+_vm._s(item.categoryName)+" ")])],1)}),1)],1)}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }