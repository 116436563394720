var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    }
  ])},[_c('div',{staticClass:"slide_view"},_vm._l((_vm.catesList),function(value,key,index){return _c('div',{key:index,staticClass:"recommend_view"},[_c('div',[_c('div',{staticClass:"recommend_title"},[_vm._v(" "+_vm._s(value.categoryName)+" ")]),_c('div',{staticClass:"recommend_con rows"},_vm._l((value.children),function(goods,key,index){return _c('div',{key:index,staticClass:"recommend_item column"},[_c('van-image',{attrs:{"width":"40","height":"40","src":goods.prcUrl}}),_c('div',{staticStyle:{"margin-top":"5px"}},[_vm._v(_vm._s(goods.categoryName))])],1)}),0)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }