<template>
  <div class="module-common pc_style">
    <div class="product_1_style pc_style">
      <!-- 商品分类 -->
      <div>
        <div class="filter clear">
          <div class="items">
            <span style="font-size: 14px;color: rgb(0 0 0);">
              所有商品
              <a-icon type="caret-right" />
            </span>
            <dl>
              <dd class="active"><a href="#">分类一区</a></dd>
              <dd><a href="#">分类二区</a></dd>
              <dd><a href="#">分类三区</a></dd>
              <dd><a href="#">分类四区</a></dd>
              <dd><a href="#">分类五区</a></dd>
              <dd><a href="#">分类六区</a></dd>
            </dl>
          </div>

          <div class="items more">
            <span style="font-size: 14px;color: rgb(0 0 0);">
              商品目录
              <a-icon type="caret-right" />
            </span>
            <dl>
              <dd class="active"><a href="#">目录一</a></dd>
              <dd><a href="#">目录二</a></dd>
              <dd><a href="#">目录三</a></dd>
              <dd><a href="#">目录四</a></dd>
              <dd><a href="#">目录五</a></dd>
              <dd><a href="#">目录六</a></dd>
            </dl>
          </div>
          <!-- <div class="items line"></div> -->
          <!--排序-->
          <!-- <div class="items orderby">
            <span>排序：</span>
            <dl>
              <dd class="new active"><a href="javascript:;" onclick="self.location.href='/category/4'">最新</a></dd>
              <dd class=""><a href="javascript:;" onclick="self.location.href='/category/d-4'">最多</a></dd>
            </dl>
          </div> -->
        </div>
      </div>

      <div>
        <el-row :gutter="10">
          <el-col :span="4" v-for="(v, k, i) in 12" :key="i">
            <div class="px-goods-card">
              <!-- 商品图片 -->
              <el-image style="width: 100%;height: 170px;"> </el-image>

              <!-- 商品内容 -->
              <div class="goods2_content">
                <div style="height: 45px; overflow: hidden">
                  商品名称
                </div>
                <div style="margin: 10px 0px"></div>
                <div class="product_1_cent">
                  <div class="index2_price" style="font-size: 18px;line-height: 14px;">
                    <span style="font-size: 12px;">￥</span><span>1.00</span>
                  </div>

                  <a-tag color="#2db7f5">
                    立即购买
                  </a-tag>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  computed: {
    moduleBgStyle() {
      return {
        background: `linear-gradient(122235deg, ${this.module.moduleBg.bgColor
          }, ${this.module.moduleBg.fzColor})`,
        color: this.module.moduleBg.fontColor
      };
    }
  },
  components: {},
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      goodsName: "",
      activeName: "影视会员"
    };
  },

  methods: {
    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    }
  }
};
</script>

<style>
.product_index2 {
  /* transition: 0.6s; */
  background-color: #fff;
  /* border: 1px solid #f2f2f2; */
  line-height: 42px;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.product_index2 .label {
  transition: 0.2s;
  cursor: pointer;
  font-size: 16px;
}

.product_index2 .label:hover {
  color: #14d3b7;
}

.px-goods-card {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  margin-top: 10px;
}

.px-goods-card .goods2_content {
  line-height: 24px;
  font-size: 14px;
}

.px-goods-card .goods2_content .index2_price {
  font-family: DINAlternate, DINAlternate;
  font-weight: bold;
  font-size: 24px;
  color: #d02f24;
  line-height: 76px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  /* font-size: 14px;
  display: flex;
  color: #f44336;
  align-items: center;
  margin-bottom: 20px; */
}

.px-goods-card:hover {
  border: 1px solid #14d3b7;
}

/deep/.el-dialog {
  border-radius: 5px;
}

/* 美化tabs */
.goods-index2-tabs {
  padding: 0px 10px;
}

.goods-index2-tabs .el-tabs__header {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0px;
  /* margin-bottom: 20px; */
}

.goods-index2-tabs .el-tabs__item {
  color: #606266;
  border: none;
  height: 50px;
  line-height: 50px;
  /* margin-right: 10px; */
  font-size: 16px;
}

.goods-index2-tabs .el-tabs__item.is-active {
  background-color: rgb(246, 246, 246);
  color: #ff5f00;
  border-radius: 12px 12px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  /* padding: 12px 16px; */
}

.goods-index2-tabs .el-tabs__pane {
  padding: 10px;
}

.goods-index2-tabs .el-tabs__nav {
  padding: 0px;
}

/* .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 15px !important;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 15px !important;
} */

.goods-index2-tabs .el-tabs__active-bar {
  background-color: transparent !important;
}

.goods-index2-tabs .el-col-5 {
  width: 20%;
}

/*筛选*/
.filter {
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
  padding: 25px 20px 15px 20px;
  position: relative;
  overflow: hidden;
}

.filter .items {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.filter .items.more {
  padding-right: 110px;
  overflow: hidden;
}

.filter .items span {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 28px;
}

.filter .items dl {
  height: auto;
  overflow: hidden;
  width: 100%;
  padding-bottom: 15px;
  padding-left: 100px;
}

.filter .items dt {
  display: inline-block;
  position: absolute;
  left: 68px;
  top: 0;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 26px;
}

.filter .items dt,
.filter .items dd {
  display: inline-block;
  float: left;
  line-height: 28px;
  color: #666666;
  margin-right: 10px;
  border-radius: 4px;
  margin-bottom: 4px;
}

.filter .items dt {
  color: #000;
  letter-spacing: 2px;
}

.filter .items dd {
  white-space: nowrap;
}

.filter .items dt a,
.filter .items dd a {
  display: block;
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: inherit;
  padding: 0px 15px;
}

.filter .items dt:hover a,
.filter .items dd:hover a,
.filter .items dt.active,
.filter .items dd.active {
  color: #fff;
  background-color: #17A1FF;
  border-radius: 5px;
}

.filter .items.more dd {
  margin-bottom: 10px;
}


.filter .items h2 {
  font-weight: normal;
  font-size: 14px;
}

.filter .items.line {
  height: 15px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  overflow: visible;
}

.filter .items.line a {
  position: absolute;
  display: block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: #f2f2f2;
  right: 0;
  top: -30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}

.filter .items.line a i {
  font-size: 8px;
}

.filter .items.line a i.up {
  transform: rotate(180deg);
  display: inline-block;
}

.filter .items.orderby {
  display: inline-block;
  width: auto;
  float: left;
  margin-right: 120px;
  height: 38px;
  overflow: visible;
}

.filter .items.orderby:last-child {
  margin-right: 0;
}

.filter .items.orderby dl {
  overflow: visible;
  padding-left: 68px;
}

.filter .items.orderby dd.new {
  position: relative;
}

.filter .items.orderby dd.new:after {
  content: "";
  width: 30px;
  height: 20px;
  position: absolute;
  top: -8px;
  right: -19px;
}

.product_1_cent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
</style>
