<template>
  <div class="search-page">
    <van-search
      show-action
      v-model="goodsName"
      @search="toSearchDetails"
      @keyup.enter="toSearchDetails"
      shape="round"
      background="#E9DFDE"
      placeholder="请输入搜索关键词"
    >
      <template #left>
        <div @click="onClickLeft()">
          <van-icon name="arrow-left" size="22" style="margin-right: 5px" />
        </div>
      </template>

      <template #action>
        <div @click="dialogMenuVisible = true">
          <van-icon name="apps-o" size="22" style="margin-left: 5px" />
        </div>
      </template>
    </van-search>

    <!-- <div class="search-history">
      <div class="history-title">
        <uni-text class="text"><span>历史搜索</span></uni-text>
      </div>
      <div class="history-content">
        <div
          class="history-item"
          v-for="(key, index) in searchHistory"
          :key="index"
          @click="toHistory(key)"
        >
          <van-button plain round size="small" hairline type="info">
            {{ key }}
          </van-button>
        </div>
      </div>
    </div> -->

    <div class="search-history">
      <div class="history-title">
        <span class="text">热门搜索</span>
      </div>
      <div class="history-content">
        <div
          class="history-item"
          v-for="(key, index) in hotKeywordsList"
          :key="index"
          @click="toHistory(key)"
        >
          {{ key }}
        </div>
      </div>
    </div>

    <!-- 搜索辅助菜单 -->
    <van-popup
      v-model="dialogMenuVisible"
      position="bottom"
      round
      :style="{ height: '30%' }"
    >
      <div style="margin-top: 10px">
        <van-grid :column-num="4" :border="false">
          <van-grid-item icon="home-o" text="商城首页" to="/mindex" />

          <van-grid-item icon="apps-o" text="商品分类" to="/cate" />

          <van-grid-item icon="user-o" text="我的账户" to="/my" />

          <van-grid-item icon="orders-o" text="我的订单" to="/order" />

          <van-grid-item icon="bullhorn-o" text="商城公告" to="/sysNotice" />

          <van-grid-item icon="service-o" text="联系客服" to="/contact" />
        </van-grid>

        <div style="padding: 15px">
          <van-button type="default" size="large" round>关闭</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      dialogMenuVisible: false,
      searchHistory: [],
      goodsName: undefined,
      hotKeywordsList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      setting: (state) => state.index.setting,
    }),
  },
  created() {
    // 从localStorage中加载搜索记录
    // if (localStorage.getItem("searchHistory")) {
    //   this.searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    // }
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.setting.keywords) {
        this.hotKeywordsList = this.setting.keywords.split(",");
      }

      // let data = {};
      // this.$store.dispatch("index/queryHotKeywords", data).then(() => {
      //   this.hotKeywordsList = this.hotKeywords.result;
      //   // this.$forceUpdate();
      // });
    },

    toHistory(v) {
      this.goodsName = v;
      this.toSearchDetails();
    },

    // 跳转到详情页面
    toSearchDetails() {
      if (this.goodsName == "" || this.goodsName == undefined) {
        // showToast('请输入搜索关键词')
      } else {
        // if (this.goodsName) {
        //   this.searchHistory.unshift(this.goodsName);
        //   // 可选：限制搜索记录的长度，例如只保留最近的10个记录
        //   if (this.searchHistory.length > 10) {
        //     this.searchHistory.pop();
        //   }
        //   // 保存搜索记录到localStorage中
        //   localStorage.setItem(
        //     "searchHistory",
        //     JSON.stringify(this.searchHistory)
        //   );
        //   // 清空搜索框
        //   this.goodsName = undefined;
        // }

        this.$router.push({
          path: "/product_list",
          query: { goodsName: this.goodsName },
        });
      }
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.search-page {
  width: 100%;
  height: 100vh;
  background-color: #e9dfde;
  position: fixed;

  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  /* flex-direction: column; */
}

.search-history {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  /* margin-top: 1.28125rem; */
  height: 100vh;
  border-radius: 10px;
  background-color: #ffffff;
}

.search-page .search-history .history-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.53125rem;
  margin-top: 15px;
  margin-left: 10px;
}

.search-history .history-title .text {
  font-size: 16px;
  font-weight: 700;
  color: #1e2331;
}

.search-history .history-content {
  padding: 15px 10px;
  margin-top: 1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}

.search-history .history-content .history-item {
  color: #71747c;
  font-weight: 400;
  font-size: 0.8125rem;
  background: #f4f7fa;
  border-radius: 0.9375rem;
  padding: 8px 10px;
}
</style>
