<template>
  <div class="module-common pc_style">
      <!-- 商品分类 -->
      <div>
        <div class="filter clear">
          <div class="items">
            <span style="font-size: 14px; color: rgb(0 0 0)">
              <a-icon type="appstore" style="margin-right: 10px" />全部分类
              <a-icon type="caret-right" />
            </span>
            <dl>
              <dd
                v-for="(value, index) in catesList"
                :key="index"
                :class="{ active: index == selectedIndex }"
                @click="handleClick(value, index)"
              >
                <div class="filter_title">{{ value.categoryName }}</div>
              </dd>
            </dl>
          </div>

          <div class="items more">
            <span style="font-size: 14px; color: rgb(0 0 0)">
              <a-icon type="filter" style="margin-right: 10px" />商品目录
              <a-icon type="caret-right" />
            </span>
            <dl>
              <dd
                v-for="(item, index) in catesList[selectedIndex].children"
                :key="index"
                :class="{ active: index == selectedSubIndex }"
                @click="queryCateSub(item, index)"
              >
                <div class="filter_title">
                  {{ item.categoryName }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>


      <a-spin :spinning="loadingState">
        <div class="grid_c1 more2_inner" style="min-height: 400px">
          <div class="feedContentWrapper">
            <ul
              id="feedContent0"
              class="more2_list clearfix"
              role="tabpanel"
              aria-labelledby="feedTab0"
            >
              <li
                class="more2_item more2_item_good hover-on"
                v-for="(item, k, i) in goodsList"
                :key="i"
              >
                <span class="more2_item_gdot"></span>
                <a class="more2_lk" @click="toProductDetails(item)">
                  <div class="more2_img">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.picUrl"
                      lazy
                    ></el-image>
                  </div>
                  <div class="more2_info">
                    <p class="more2_info_name">{{ item.productName }}</p>

                    <!-- 标签 -->
                    <!-- <div class="more2_tail_tags">22222222</div> -->
                    <div
                      class="more2_info_price more2_info_price_plus more2_info_price_newcomer"
                    >
                      <div class="mod_price">
                        <!-- <span>¥</span> -->
                        <span
                          class="more2_info_price_txt"
                          v-html="$decimalPrice(item.price)"
                        ></span>
                      </div>

                      <div style="float: right">
                        <div class="lijigoumai_buy">立即购买</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <div style="text-align: center; padding: 20px">
              <template v-if="finished">
                <a-divider>
                  <span style="color: #777; font-size: 14px">没有更多了</span>
                </a-divider>
              </template>

              <template v-else>
                <a-button type="primary" @click="loadMore()">
                  <a-icon type="reload" />加载更多
                </a-button>
              </template>
            </div>
          </div>
        </div>
      </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      spinningTable: false,
      tabName: "1",
      selectSubIndex: undefined,
      activeName: "",
      selectedIndex: 0, // 默认选中第一个li元素
      selectedSubIndex: undefined,
      catesList: [],
      goodsList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryId: undefined,
        categoryPid: undefined,
      },
      finished: false,
      loadingState: true,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      productList: (state) => state.index.productList,
    }),
  },

  created() {
    //查收商品分类
    this.getCateList();
    //查询商品列表
    this.getProductList();
  },
  methods: {
    numToStr(num) {
      num = num.toString();
      return num;
    },

    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
        this.activeName = this.catesList[0].categoryName; // 获取第一个选项卡的名称
      });
    },

    /**
     * 加载更多
     */
    loadMore() {
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.getProductList();
    },

    //查询商品列表
    getProductList() {
      this.$store
        .dispatch("index/queryProductList", this.queryParam)
        .then(() => {
          this.goodsList = this.goodsList.concat(this.productList.productList);
          this.total = this.productList.total;

          if (this.productList.productList.length == 0) {
            this.finished = true;
          }
          this.loadingState = false;
        });
    },

    handleClick(value, index) {
      this.selectedIndex = index;
      this.selectedSubIndex = 0;

      //赋值二级目录
      this.queryParam.categoryId = this.catesList[index].children[0].id;

      //初始化参数
      this.queryParam.categoryPid = value.id;
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;

      this.loadingState = true;
      this.finished = false;
      this.goodsList = [];
      this.getProductList();
    },

    /**
     * 根据 */
    queryCateSub(value, index) {
      this.selectedSubIndex = index;
      //初始化参数
      this.queryParam.categoryId = value.id;
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryPid = null;

      this.loadingState = true;
      this.finished = false;
      this.goodsList = [];

      this.getProductList();
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/pgoodsDetail/${v.id}`);
    },
  },
};
</script>


<style>
.product_index2 {
  /* transition: 0.6s; */
  background-color: #fff;
  /* border: 1px solid #f2f2f2; */
  line-height: 42px;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.product_index2 .label {
  transition: 0.2s;
  cursor: pointer;
  font-size: 16px;
}

.product_index2 .label:hover {
  color: #14d3b7;
}

.px-goods-card {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  margin-top: 10px;
}

/* .px-goods-card .goods2_content {
  line-height: 24px;
  font-size: 14px;
} */

.px-goods-card .goods2_content .index2_price {
  font-family: DINAlternate, DINAlternate;
  font-weight: bold;
  font-size: 24px;
  color: #d02f24;
  line-height: 76px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  /* font-size: 14px;
  display: flex;
  color: #f44336;
  align-items: center;
  margin-bottom: 20px; */
}


/* 美化tabs */
.goods-index2-tabs {
  padding: 0px 10px;
}

.goods-index2-tabs .el-tabs__header {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0px;
  /* margin-bottom: 20px; */
}

.goods-index2-tabs .el-tabs__item {
  color: #606266;
  border: none;
  height: 50px;
  line-height: 50px;
  /* margin-right: 10px; */
  font-size: 16px;
}

.goods-index2-tabs .el-tabs__item.is-active {
  background-color: rgb(246, 246, 246);
  color: #ff5f00;
  border-radius: 12px 12px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  /* padding: 12px 16px; */
}

.goods-index2-tabs .el-tabs__pane {
  padding: 10px;
}

.goods-index2-tabs .el-tabs__nav {
  padding: 0px;
}

.goods-index2-tabs .el-tabs__active-bar {
  background-color: transparent !important;
}

.goods-index2-tabs .el-col-5 {
  width: 20%;
}

/*筛选*/
.filter {
  width: 1300px;
  margin: 0 auto;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
  padding: 25px 20px 15px 20px;
  position: relative;
  overflow: hidden;
  /* background: linear-gradient(to top, #FFFFFF 50%, #F2F9FF 100%); */
  border-radius: 10px;
}

.filter .items {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.filter .items.more {
  padding-right: 110px;
  overflow: hidden;
}

.filter .items span {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 28px;
}

.filter .items dl {
  height: auto;
  overflow: hidden;
  width: 100%;
  padding-bottom: 15px;
  padding-left: 120px;
}

.filter .items dt {
  display: inline-block;
  position: absolute;
  left: 68px;
  top: 0;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 26px;
}

.filter .items dt,
.filter .items dd {
  display: inline-block;
  float: left;
  line-height: 28px;
  color: #666666;
  margin-right: 10px;
  border-radius: 4px;
  margin-bottom: 4px;
}

.filter .items dt {
  color: #000;
  letter-spacing: 2px;
}

.filter .items dd {
  white-space: nowrap;
}

.filter .items dt .filter_title,
.filter .items dd .filter_title {
  display: block;
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: inherit;
  padding: 0px 15px;
}

.filter .items dt:hover .filter_title,
.filter .items dd:hover .filter_title,
.filter .items dt.active,
.filter .items dd.active {
  color: #fff;
  background-color: #538df8;
  border-radius: 5px;
}

.filter .items.more dd {
  margin-bottom: 10px;
}



.filter .items h2 {
  font-weight: normal;
  font-size: 14px;
}

.filter .items.line {
  height: 15px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  overflow: visible;
}

.filter .items.line a {
  position: absolute;
  display: block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: #f2f2f2;
  right: 0;
  top: -30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}

.filter .items.line a i {
  font-size: 8px;
}

.filter .items.line a i.up {
  transform: rotate(180deg);
  display: inline-block;
}

.filter .items.orderby {
  display: inline-block;
  width: auto;
  float: left;
  margin-right: 120px;
  height: 38px;
  overflow: visible;
}

.filter .items.orderby:last-child {
  margin-right: 0;
}

.filter .items.orderby dl {
  overflow: visible;
  padding-left: 68px;
}

.filter .items.orderby dd.new {
  position: relative;
}

.filter .items.orderby dd.new:after {
  content: "";
  width: 30px;
  height: 20px;
  position: absolute;
  top: -8px;
  right: -19px;
}

.product_1_cent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

/* 新的 */
.feedContentWrapper {
  background-color: #fff;
  box-sizing: border-box;
  padding-left: 12px;
}

.more2_list {
  margin: 0 -5px;
}

.more2_item {
  position: relative;
  float: left;
  width: 240px;
  height: 342px;
  margin: 0 8px 20px;
  background: #fff;
  overflow: hidden;
  padding: 10px;
}

.more2_item_gdot {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: inline-block;
  width: 1px;
  height: 1px;
  background-color: #b1b1b1;
}

.more2_lk {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.more2_img {
  display: block;
  /* -webkit-transition: opacity .2s ease; */
  transition: opacity 0.2s ease;
}

.more2_img {
  width: 220px;
  height: 220px;
  margin: 0 auto 5px;
}

.more2_img > img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  border-radius: 8px;
}

.more2_info {
  /* padding: 0 10px; */
  height: 65px;
  clear: both;
  line-height: 24px;
}

.more2_info_name {
  height: 48px;
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  color: #333;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.more2_tail_tags {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.more2_info_price {
  margin-top: 15px;
}

.more2_info_price_plus .mod_price {
  float: left;
  text-align: left;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  height: 18px;
  color: #e1251b;
}

.more2 .mod_price {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  height: 18px;
  color: #e1251b;
}

.more2 .mod_price i {
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  font-family: MicrosoftYahei-regular, Arial, Helvetica, sans-serif;
}

.more2_info_price_txt {
  font-size: 20px;
  font-weight: 700;
  font-family: arial, sans-serif;
}

.more2_item.hover-on .more2_item_delete {
  display: block;
}

.more2_item_delete {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  display: block;
  opacity: 1;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGTSURBVHgBrVPNSsNAEN5NakKbg4i1iYIFU5BSvJQe7FP03rtv4d2n8N57n6Ke2oM/FNoE2oOJwdJLGs0PcUZ367JEUegHw8LsfN9+O7NLiYQ8zy9g6UJcQpgs7UC4EANK6YtYTwWiAUsfokd+x5AJhVsBRr6BOCN/Azq6RhGFJfr/ICNsxiEKnF4TbQdBsL9YLI4gT0VGGIb6fD4/ieO4xFI97JfClTiiKNKm0+m567oWz202G20ymTR935eFuyVmZ4t6vR6kaVqazWafecuyVuPxuJllmdrpdB51XU9EAQqKQ1IAx3GOwbKtqmqmadp7u91+MgzjTa5TyA8wTXMFXc7W6/VBtVp9LSJzAV9O8jvjya1W62G5XJ6iowK+gz24I8IUsNtI5nfGk0EowZ7AdYlt2x44y1m5iw5GoqTneYdQQDgZc0B6bjQaLuyZMKU9oXzAX+IVd4FzxlFJ3cYaiuRyuZwwB0NYb3kTB+TreRKwm8pkBJIqlUrMyA7j7OgzSVZr5Ptv8EeGk8Jmj4B4L9Z/AKY3wz5yNp2fAAAAAElFTkSuQmCC)
    no-repeat;
  background-size: 16px;
  background-position: 50%;
  cursor: pointer;
  z-index: 8;
}

.more2_info_name {
  height: 48px;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #666;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lijigoumai_buy {
  width: 70px;
  line-height: 22px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  color: #999;
  background-color: #fff;
  border: 1px solid #999;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #0080ff;
  border: 1px solid #0080ff;
}
</style>
