<template>
  <div  id="app">

    <a-layout id="components-layout-demo-top-side-2">

      <div :class="classObj" class="app-wrapper">

        <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />


        <a-layout-header
          style="position: fixed; top: 0; left: 0; right: 0; z-index: 10;">
          <navbar />
        </a-layout-header>

        <!-- 手机端 -->
        <template v-if="device === 'mobile'">
          <a-layout style="margin-top: 64px;">

            <a-layout-sider :trigger="null" collapsible style="background: #fff">
              <sidebar class="sidebar-container" />
            </a-layout-sider>

            <a-layout>
              <a-layout-content :style="{background: '#F5F7F9'}">
                <!-- 内容 -->
                <router-view> </router-view>
              </a-layout-content>
            </a-layout>
          </a-layout>
        </template>

        <!-- PC端 -->
        <template v-else>
          <a-layout style="margin-top: 64px;">
            <a-layout-sider v-model="sidebar.opened" :trigger="null" collapsible style="scrollbar-width: none;"
              :style="{ background: '#fff', overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
              <sidebar class="sidebar-container" />
            </a-layout-sider>

            <a-layout :style="{ marginLeft: sidebar.opened ? '90px' : '200px' }">

              <a-layout-content :style="{background: '#F5F7F9',padding: '12px 16px'}">
                <!-- 内容 -->
                <router-view> </router-view>
              </a-layout-content>
            </a-layout>
          </a-layout>
        </template>
      </div>
    </a-layout>

    <!-- 首页弹框 -->
    <el-dialog
      :destroy-on-colse="true"
      title="温馨提示"
      :visible.sync="vipNoticeVisible"
      append-to-body
    >
      <div class="ql-editor" v-html="vipNoticeContent" />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";
import { getVipNotice } from "@/api/index";
import ResizeMixin from "@/layout/mixin/ResizeHandler";

import sidebar from "./sidebar/menu";
import navbar from "./sidebar/navbar";

import '@/assets/css/sidebar.scss'

export default {
  components: { sidebar,navbar },
  data() {
    return {
      collapsed: false,
      isCollapse: false,
      vipNoticeVisible: false,
      vipNoticeContent: undefined,
    };
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      branchId: (state) => state.user.branchId,
      webSetting: (state) => state.index.webSetting,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },

  created() {
    this.verifyIsLogin();

    this.queryVipNotice();
  },
  methods: {

    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },

    toggleSideBar() {
      console.log("=-============================");
      this.$store.dispatch("app/toggleSideBar");
    },



    /**
     * 查询首页弹框公告
     */
    queryVipNotice() {
      getVipNotice().then((response) => {
        if (response.result) {
          this.vipNoticeVisible = true;
          this.vipNoticeContent = response.result.content;
        }
      });
    },

    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.$store.dispatch("user/info").then(() => {
          this.$nextTick(() => {
            this.verifyAuthority();
          });
        });
      }
    },

    /**
     * 验证权限
     */
    verifyAuthority() {
      if (!this.branchId) {
        this.$message({
          message: "权限不足",
          type: "warning",
        });
        this.$router.push("/");
      }
    },

    toReturn() {
      this.$router.push("/");
    },

    /**
     * 跳转路由
     */
    toLink(link) {
      this.$router.push(link).catch((err) => {
        // 在这里处理错误
        console.error("路由跳转出错：", err);
      });
    },
  },
};
</script>
<style scoped>
.fz-container {
  width: 100%;
  min-height: 100vh;
}



.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.el-header {
  font-size: 20px;
  background-color: #ffffff;
  border-color: rgba(25, 39, 142, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-nav-header {
  color: black;
  height: 58px;
  line-height: 58px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.icon-box {
  float: left;
  width: 30px;
  text-align: center;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
}

.sidebar-title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-weight: 600;
  line-height: 50px;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
  margin-left: 5px;
}

.sidebar-logo {
  width: 30px;
  height: 30px;
  border-radius: 2px;
}

/* x */
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}


/* .app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
} */
.app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.ant-layout-header {
  padding: 0px 20px 0px 0px !important;
}
</style>
