<template>
  <div class="my">
    <div class="header-my-top">
      <!-- 未登录 -->
      <div class="top-head" v-if="!this.loginStatus" @click="login">
        <a-avatar :size="64" icon="user" />
        <div class="detail">
          <div class="info">登录 / 注册</div>
          <div style="color: #b2b2b2; font-size: 13px">
            登录后可享受更多服务
          </div>
        </div>
        <div>
          <a-icon type="setting" style="font-size: 18px" />
        </div>
      </div>

      <!-- 已登录 -->
      <div class="top-head" v-if="this.loginStatus">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="/api/user/uploadimage"
          :http-request="handleUploadHttpRequest"
          :show-file-list="false"
          :auto-upload="true"
        >
          <div class="avatar-item">
            <img :src="avatarPicUrl" alt="" style="width: 100%" />
            <p class="avatar-title">修改头像</p>
          </div>
        </el-upload>

        <div class="detail">
          <div class="info">{{ username }}</div>
          <div
            style="
              display: flex;
              align-items: center;
              font-size: 12px;
              color: hsla(0, 0%, 100%, 0.6);
            "
          >
            <div class="num-txt">{{ userid }}</div>
            <div class="num-txt">{{ levelName }}</div>
          </div>
        </div>

        <div @click="goToMyPage('/setup')">
          <a-icon type="setting" style="font-size: 18px" />
        </div>
      </div>
    </div>

    <!-- 已经登录 -->
    <div class="cash-boy" style="background-color: #ffffff">
      <div
        class="cash-info flex-between px-30 flex-vertical-center"
        @click="goToMyPage('/userlevel')"
      >
        <div class="cash-num">
          <div>
            <img
              src="@/assets/image/zhuanshi.png"
              style="width: 22px; margin-right: 5px"
            />提升等级 享受更多折扣
          </div>
        </div>
        <div class="flex" style="color: white">
          <div>立即升级 <i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
    </div>

    <div>
      <div class="my-card">
        <div>
          <van-grid :border="false" :column-num="4">
            <van-grid-item @click="goToMyPage('/mbalance')">
              <span class="amunot_title">
                {{ balanceTotal }}
              </span>
              <span class="amunot_text">账户余额</span>
            </van-grid-item>

            <van-grid-item @click="goToMyPage('/commission')">
              <span class="amunot_title">
                {{ balanceAllowTransfer }}
              </span>
              <span class="amunot_text">佣金</span>
            </van-grid-item>

            <van-grid-item @click="goToMyPage('/collectlist')">
              <span class="amunot_title">
                {{ collectNum ? collectNum : 0 }}
              </span>
              <span class="amunot_text">收藏</span>
            </van-grid-item>

            <van-grid-item @click="goToMyPage('/browselist')">
              <span class="amunot_title">
                {{ browseNum ? browseNum : 0 }}
              </span>
              <span class="amunot_text">历史浏览</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <div class="d-open-vip" style="padding: 10px 15px 0px">
        <div
          class="d-open-vip-inner"
          style="color: rgb(106, 71, 20)"
          @click="goToMyPage('/openVip')"
        >
          <div class="inner-icon">
            <img src="@/assets/image/vip.png" style="width: 25px" />
          </div>
          <div class="inner-text">
            <div style="font-size: 14px">开通分站 分享赚钱 自购省钱</div>
          </div>
          <div class="inner-button">
            <div class="inner-button-text">立即开通</div>
          </div>
        </div>
      </div>

      <div class="aui-boy" style="margin-top: 10px">
        <div class="order-box">
          <div class="title-box">
            <div class="title">我的订单</div>
          </div>

          <div class="line"></div>

          <div class="aui-grids order-box-content">
            <div class="aui-grids-well" @click="toMyOrder(0)">
              <div class="user-order-img">
                <img
                  class="order_img"
                  src="@/assets/image/icon_dcl.png"
                  alt=""
                />
              </div>
              <p class="aui-grids-well-text">等待处理</p>
            </div>
            <div class="aui-grids-well" @click="toMyOrder(1)">
              <div class="user-order-img">
                <img
                  class="order_img"
                  src="@/assets/image/icon_clz.png"
                  alt=""
                />
              </div>
              <p class="aui-grids-well-text">正在处理</p>
            </div>
            <div class="aui-grids-well" @click="toMyOrder(2)">
              <div class="user-order-img">
                <img
                  class="order_img"
                  src="@/assets/image/icon_ywc.png"
                  alt=""
                />
              </div>
              <p class="aui-grids-well-text">交易成功</p>
            </div>
            <div class="aui-grids-well" @click="toMyOrder(4)">
              <div class="user-order-img">
                <img
                  class="order_img"
                  src="@/assets/image/icon_tk.png"
                  alt=""
                />
              </div>
              <p class="aui-grids-well-text">退款订单</p>
            </div>
            <div class="aui-grids-well" @click="toMyOrder(undefined)">
              <div class="user-order-img">
                <img
                  class="order_img"
                  src="@/assets/image/icon_qbdd.png"
                  alt=""
                />
              </div>
              <p class="aui-grids-well-text">全部订单</p>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 10px">
        <div class="order-box">
          <div class="title-box">
            <div class="title">常用功能</div>
          </div>

          <div class="line"></div>

          <div class="order-box-content">
            <div class="cash-detal-btn" @click="goToMyPage('/afterSales')">
              <img
                class="order_box_icon"
                src="@/assets/user/tousu.png"
                draggable="false"
              />
              售后订单
            </div>

            <div class="cash-detal-btn" @click="goToMyPage('/amountDetail')">
              <img
                class="order_box_icon"
                src="@/assets/user/zijin.png"
                draggable="false"
              />
              资金明细
            </div>

            <div class="cash-detal-btn" @click="goToMyPage('/sysNotice')">
              <img
                class="order_box_icon"
                src="@/assets/user/gonggao.png"
                draggable="false"
              />
              系统公告
            </div>

            <div class="cash-detal-btn" @click="goToMyPage('/minvited')">
              <img
                class="order_box_icon"
                src="@/assets/user/fenxiang.png"
                draggable="false"
              />
              分享赚钱
            </div>

            <div
              class="cash-detal-btn"
              @click="goToMyPage('/receivingAddress')"
            >
              <img
                class="order_box_icon"
                src="@/assets/user/shouhuo.png"
                draggable="false"
              />
              收货地址
            </div>

            <div class="cash-detal-btn" @click="toVipAdmin()">
              <img
                class="order_box_icon"
                src="@/assets/icon/vip_icon.png"
                draggable="false"
              />
              我的分站
            </div>

            <div class="cash-detal-btn" @click="goToMyPage('/apiPage')">
              <img
                class="order_box_icon"
                src="@/assets/icon/api.png"
                draggable="false"
              />
              开放接口
            </div>

            <div class="cash-detal-btn" @click="goToMyPage('/mwithdrawal')">
              <img
                class="order_box_icon"
                src="@/assets/icon/tixian.png"
                draggable="false"
              />
              提现
            </div>
          </div>
        </div>
        <div style="height: 70px"></div>
      </div>
    </div>

    <NavModule />
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { mapState } from "vuex";

import { avatar } from "@/api/user";
import NavModule from "@/pages/mobile/common/navModule.vue";
import { getVipAddress } from "@/api/vip";

export default {
  components: {
    NavModule,
  },

  data() {
    return {
      loginStatus: false,
    };
  },
  computed: {
    ...mapState({
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      balanceAllowTransfer: (state) => state.balance.balanceAllowTransfer,
      branchId: (state) => state.user.branchId,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      userid: (state) => state.user.userid,
      levelName: (state) => state.user.levelName,
      collectNum: (state) => state.user.collectNum,
      browseNum: (state) => state.user.browseNum,
    }),
  },

  created() {
    //用户是否登录
    this.queryUserIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    queryUserIsLogin() {
      const hasToken = getToken();
      if (hasToken) {
        this.loginStatus = true;

        //查询我的信息
        this.$store.dispatch("user/info").then();
        //查询我的余额
        this.$store.dispatch("balance/detail");
      }
    },

    // 修改头像
    async handleUploadHttpRequest(param) {
      if (!this.loginStatus) {
        this.$router.push("/login");
        return;
      }

      const fileObj = param.file;
      const response = await avatar(fileObj);
      if (response.status == 0) {
        this.$store.commit("user/SET_AVATAR", response.result);
      } else {
        this.$message({
          message: response.message,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },

    /**
     * 跳转到分站后台
     */
    toVipAdmin() {
      if (!this.loginStatus) {
        this.$router.push("/login");
        return;
      }
      if (this.branchId) {
        new Promise((resolve, rejust) => {
          getVipAddress()
            .then((res) => {
              let url = res.result.url + "/viplogin";
              setTimeout(() => window.open(url, "_blank"));
              resolve();
            })
            .catch((error) => {
              rejust(error);
            });
        });
      } else {
        this.$router.push("/openVip");
      }
    },

    /**
     * 登录
     */
    login() {
      this.$router.push("/login");
    },

    goToMyPage(pash) {
      if (!this.loginStatus) {
        this.$router.push("/login");
        return;
      } else {
        this.$router.push(pash);
      }
    },

    // 我的订单
    toMyOrder(v) {
      if (!this.loginStatus) {
        this.$router.push("/login");
        return;
      }
      this.$router.push({
        path: "/order",
        query: {
          statusDelivery: v,
        },
      });
    },
  },
};
</script>
<style>
/* 头像 */

.header-my-top {
  background-color: #ffffff;
  padding: 10px 20px 0px 20px;
}

.header-my-top .top-head {
  position: relative;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
}

.header-my-top .top-head .avatarUrl {
  width: 49px;
  height: 49px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 7px;
}

.header-my-top .top-head .detail {
  flex: 1;
  padding-left: 8px;
}

.header-my-top .top-head .detail .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}

.header-my-top .top-head .detail .info .nick-name {
  font-size: 17px;
  font-weight: 600;
  color: #292b35;
  line-height: 27px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-my-top .top-head .detail .user-id {
  font-size: 13px;
}

.non-owner-header {
  height: 43px;
}

.non-owner-orders {
  margin-top: -47px;
}

/* 我的服务 */

.order-box {
  position: relative;
  z-index: 1;
  margin: 0px 15px;
  border-radius: 7px;
  background: #fff;
  padding-bottom: 9px;
}

.order-box .title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
}

.order-box .title {
  /* font-size: 0.33rem; */
  font-weight: 600;
  color: #333;
  line-height: 16px;
}

.order-box .title-box .image {
  display: block;
  width: 15px;
  height: 15px;
}

.order-box .line {
  background: #f5f5f5;
  height: 1px;
  margin: 0 15px;
}

.order-box .order-box-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 4px;
}

.order-box .cash-detal-btn {
  position: relative;
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #666;
  padding: 11px 0;
  font-size: 12px;
  line-height: 16px;
}

.order-box .order_box_icon {
  margin-bottom: 9px;
  width: 32px;
  height: 32px;
}

.my {
  width: 100vw;
  min-height: 100vh;
  background-color: #f7f7f9;
}

.my .user {
  width: 100%;
  padding: 0 0.35rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.my .user .title {
  height: 1.8rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.my .user .title,
.my .user .title .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .user .userImg {
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 1rem;
}

.my .user .userName {
  margin-left: 0.3rem;
  font-size: 0.4rem;
  font-weight: 600;
  color: #36343a;
}

.my .user .progress {
  padding: 0.35rem 0.35rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .user .progresTtip {
  margin-top: 0.15rem;
  padding-left: 0.35rem;
}

.my .amount {
  margin: 15px 15px;
  /* margin-top: -40px; */
}

.my .amount .amountIn {
  background: #fff;
  border-radius: 7px;
  /* -webkit-box-shadow: 5px 5px 7px 5px rgba(96, 83, 75, .05); */
  /* box-shadow: 0 5px 7px 0 rgba(96, 83, 75, .05); */
  padding: 15px 15px;
  color: #333;
}

.my .amount .myProfit .myProfitTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 0.8rem;
}

.my .amount .myProfit .money {
  /* font-size: .47rem; */
  line-height: 0.7rem;
  margin-bottom: 0.1rem;
}

.my .amount .moneyItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
}

.my .amount .moneyItem div {
  width: 15%;
}

.my .amount .moneyItem div span {
  display: block;
  color: #666;
  /* font-size: .28rem */
}

.my .amount .moneyItem div b {
  display: block;
  margin-top: 0.06rem;
  /* font-size: .38rem */
}

.my .amount .moneyItem div b i {
  font-style: normal;
  /* font-size: .32rem; */
  color: #666;
}

.my .amount .moneyItem div .popoverTip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my .amount .moneyItem div .popoverTip .popoverTipIcon {
  margin-left: 0.1rem;
}

.my .amount .goExtract {
  background: #f5dabc;
  height: 0.55rem;
  width: 1.4rem;
  line-height: 0.55rem;
  border-radius: 0.55rem;
  text-align: center;
  color: #2e333a;
  margin-top: 0.3rem;
  padding-left: 0.1rem;
  /* font-size: .32rem; */
}

.my .banner {
  /* height: 2rem; */
  padding: 0.15rem 0.36rem 0;
  /* margin: 0 0 .3rem; */
  position: relative;
}

.my .banner .advertisingSpace {
  width: 100%;
  height: 1.5rem;
  background-color: #fff;
  border-radius: 0.24rem;
}

/* 我的余额 */
.cash-boy {
  padding: 0 15px;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.cash-info .cash-num {
  color: #fadfdd;
  text-align: center;
}

.px-30 {
  padding: 0 12px;
}

.cash-info {
  height: 48px;
  background: url(../../../assets/image/h5bg.png) no-repeat center center;
  border-radius: 7px 7px 0px 0px;
}

.aui-arrow {
  position: relative;
  /* padding-right: 0.8rem; */
}

.aui-arrow a {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.aui-grids {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin-top: 15px; */
}

.aui-grids-well {
  position: relative;
  float: left;
  padding: 10px 0 0px 0;
  width: 20%;
  box-sizing: border-box;
}

.user-order-img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.aui-grids-well-text {
  text-align: center;
  font-size: 12px;
  padding-top: 4px;
  margin-top: 5px;
  color: #9a9a9e;
}

.order_img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

/* 新的 */

.d-account-inner {
  padding: 12px;
  background: #ffffff;
}

.d-account-inner .d-inner-left {
  display: flex;
  align-items: center;
}

.d-account-inner .d-inner-left-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.d-account-inner .d-inner-left-title {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--nut-title-color);
}

.d-account-inner .d-inner-right {
  text-align: right;
  font-size: 12px;
}

.d-account-inner .d-inner-right-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--nut-title-color2);
}

.d-account-inner .d-inner-right-title-link {
  display: flex;
  margin-left: 4px;
  flex-direction: row;
  align-items: center;
}

.d-account-inner .d-inner-right-desc {
  color: var(--nut-primary-color);
}

.d-account-inner .d-inner-account {
  color: var(--nut-title-color);
  font-weight: 700;
  font-size: 17px;
}

.d-account-inner .d-inner-text {
  color: var(--nut-title-color2);
}

/* 新的 */

.my-card {
  padding: 0px 15px 0px 15px;
}

.amunot_title {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 5px;
  display: block;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #000000;
}

.amunot_text {
  font-size: 14px;
  color: #555;
}

/* 分站的 */

.d-open-vip-inner {
  background-image: url(../../../assets/image/fenzhan.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  font-size: 16x;
  color: #6a4714;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-open-vip-inner .inner-icon {
  margin-right: 5px;
}

.d-open-vip-inner .inner-text {
  flex: 1;
  font-weight: 600;
}

.d-open-vip-inner .inner-button {
  margin-left: 12px;
}

.d-open-vip-inner .inner-button-text {
  background: rgba(255, 255, 255, 0.5);
  padding: 3px 8px;
  border-radius: 50px;
}

.d-order .nut-skeleton.d-order-skeleton {
  padding: 8px 0;
  width: 90%;
}

.avatar-item {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 65px;
  height: 65px;
}

.avatar-title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0px;
  height: 25px;
  line-height: 21px;
  background: rgba(0, 0, 0, 0.384);
  font-size: 9px;
  font-weight: 600;
  color: #fff;
}

.num-txt {
  color: #6c2a09;
  padding: 0 11px;
  border-radius: 10px;
  background: linear-gradient(90deg, #ffebc0, #ffc479);
  margin-right: 10px;
  font-weight: 600;
}
</style>
