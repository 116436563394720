var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
  !_vm.module.base.m ? { 'margin-top': '0' } : {},
  {
    padding:
      '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
  }
])},[_c('van-row',[_c('van-col',{attrs:{"span":"24"}},[_c('div',[_c('div',[_c('div',{staticClass:"aui-flex"},[_c('span',{staticClass:"aui-flex-box"},[_vm._v(" "+_vm._s(_vm.module.style.text)+" ")]),_c('div',{staticStyle:{"font-size":"0.8rem","font-weight":"400","color":"#999"},on:{"click":function($event){return _vm.tohotProduct()}}},[_c('a',[_vm._v("查看更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)])]),_c('div',{staticClass:"recommend-grids"},[_c('div',{staticClass:"choice_list"},[_c('van-swipe',{staticClass:"recommend",attrs:{"autoplay":2000,"indicator-color":"white","width":90}},_vm._l((_vm.listData),function(value,key,index){return _c('van-swipe-item',{key:index},[_c('div',{staticClass:"choice_item",on:{"click":function($event){return _vm.toProductDetails(value)}}},[_c('div',{staticClass:"choice_img_view"},[_c('van-image',{staticClass:"choice_img",attrs:{"fit":"contain","src":value.picUrl}})],1),_c('div',{staticClass:"choice_name"},[_vm._v(_vm._s(value.productName))]),_c('div',{staticClass:"choice_price"},[_c('span',{staticClass:"choice_symbol"},[_c('span',[_vm._v("￥")])]),_vm._v(_vm._s(value.price)+" ")])])])}),1)],1)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }