<template>
  <div class="callback-container">
    <div class="bind-box">
      <div class="main-wrap" style="line-height: 30px">
        <div class="logo"><h4>首次登录绑定</h4></div>
        <div class="btns">
          <el-form>
            <el-form-item>
              <el-input
                prefix-icon="el-icon-search"
                v-model="loginForm.username"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-input
                prefix-icon="el-icon-search"
                v-model="loginForm.password"
                placeholder="请输入密码"
                show-password
              ></el-input>
            </el-form-item>

            <el-form-item>
              <a-button
                type="primary"
                block
                @click="BangDingAccount()"
                v-preventClick
              >
                绑定已有会员账号
              </a-button>
            </el-form-item>
          </el-form>

          <a-divider />
          <a-button
            type="danger"
            block
            @click="ChuangJianAccount()"
            v-preventClick
          >
            新用户，自动创建一个账号
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bindAccount, createAccount, scanLogin } from "@/api/user";
import { setToken, setUserId } from "@/utils/auth";

export default {
  data() {
    return {
      uid: undefined,
      loginForm: {
        username: "",
        password: "",
        uid: undefined,
        type: undefined,
      },
    };
  },
  components: {},
  created() {
    this.queryScanLogin();
  },
  methods: {
    /**
     * queryScanLogin
     */
    queryScanLogin() {
      //获取扫码登录
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const type = searchParams.get("type");

      const data = {
        code: code,
        type: type,
      };
      new Promise((resolve, rejust) => {
        scanLogin(data)
          .then((res) => {
            if (res.result.token) {
              const { token, userId } = res.result;
              setToken(token);
              setUserId(userId);
              this.$router.push("/");
            } else {
              this.uid = res.result;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 创建新账号
     */
    ChuangJianAccount() {
      let data = {
        uid: this.uid,
        type: this.$route.query.type,
      };
      new Promise((resolve, rejust) => {
        createAccount(data)
          .then((res) => {
            if (res.status === 0) {
              const { token, userId } = res.result;
              setToken(token);
              setUserId(userId);
              this.$router.push("/");
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 绑定账号
     */
    BangDingAccount() {
      this.loginForm.uid = this.uid;
      this.loginForm.type = this.$route.query.type;

      new Promise((resolve, rejust) => {
        bindAccount(this.loginForm)
          .then((res) => {
            if (res.status === 0) {
              const { token, userId } = res.result;
              setToken(token);
              setUserId(userId);
              this.$router.push("/");
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style scoped>
.callback-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #0083ff;
  background-image: url(../../assets/image/login_bg.png);
  background-size: cover;
  background-position: 50%;
}
.callback-container .bind-box {
  position: absolute;
  padding: 38px 30px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* PC端样式 */
@media (min-width: 992px) {
  .callback-container .bind-box {
    width: 550px;
    /* height: 350px; */
  }
}

/* 移动端样式 */
@media (max-width: 991px) {
  .callback-container .bind-box {
    width: 90%;
    height: auto;
    max-width: 550px;
    min-height: 250px;
  }
}
.callback-container .bind-box .main-wrap .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
}
.callback-container .bind-box .main-wrap .logo h4 {
  font-size: 16px;
  color: #ea0808;
  font-weight: 700;
}

.callback-container .bind-box .user-wrap .user-form {
  padding-top: 50px;
}
</style>
