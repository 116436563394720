<!-- pc登录页 -->
<template>
  <div class="login_p">
    <div class="page-account">
      <div class="page-account-container">
        <!-- 返回 -->
        <div class="d-forget-web-forget">
          <router-link to="/plogin">
            <a-icon type="arrow-left" />
          </router-link>
        </div>

        <div style="text-align: center;margin-bottom: 20px;">
          <div class="page-account-top-desc">找回密码</div>
        </div>

        <el-form
          :model="loginForm"
          status-icon
          :rules="rules"
          ref="registerForm"
        >
          <el-form-item prop="username">
            <el-input
              v-model="resetPassEmail.phoneOrEmail"
              placeholder="请输入绑定的手机或邮箱"
            ></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              placeholder="请输入验证码"
              v-model="resetPassEmail.verifyCode"
            >
              <template
                slot="append"
                style="background-color: #ffffff; color: #ef8466"
              >
                <el-button
                  type="text"
                  style="width: 90px; margin: 0"
                  @click="resetPassSendEmail()"
                  v-preventClick
                  >获取验证码
                </el-button>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="checkPass">
            <el-input
              placeholder="请确认新密码"
              type="password"
              v-model="resetPassEmail.password"
              autocomplete="off"
              show-password
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="checkPass">
            <el-input
              placeholder="请再次输入新密码"
              type="password"
              v-model="resetPassEmail.password"
              autocomplete="off"
              show-password
            >
            </el-input>
          </el-form-item>
        </el-form>

        <el-button style="width: 100%" type="primary" @click="resetPassword()" v-preventClick>
          确定
        </el-button>

        <div class="dx2-global-footer"></div>
      </div>
    </div>

    <el-dialog
      title="用户协议"
      :visible.sync="dialogUseAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.useAgreement"></div>
    </el-dialog>

    <el-dialog
      title="隐私协议"
      :visible.sync="dialogPrivacyAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.privacyAgreement"></div>
    </el-dialog>

    <!-- 首页弹框 -->
    <el-dialog
      destroy-on-close
      append-to-body
      :visible.sync="loginNoticeVisible"
      class="success-dialog el_dialog_radius"
      width="40%"
    >
      <div class="success-dialog-container">
        <div class="title">系统公告</div>

        <div class="dilalog_img">
          <img class="fail" src="@/assets/image/notice_icon.png" />
        </div>

        <div class="ql-editor" v-html="loginNoticeContent" />
        <div class="footer">
          <div class="but" @click="loginNoticeVisible = false">关闭</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getUserId } from "@/utils/auth";
import { getSysXieYi, getLoginNotice, getQuickLogin } from "@/api/index";
import {
  getScanLogin,
  sendPhoneCode,
  resetPwdVerifyCode,
  pwdReset,
} from "@/api/user";
import { mapState } from "vuex";

// import Captcha from "@/components/captcha/CaptchaB.vue";
import Verify from "@/components/verifition/Verify";

export default {
  components: { Verify },

  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("请输入不少于6位的密码"));
      } else {
        if (this.loginForm.checkPass !== "") {
          this.$refs.registerForm.validateField("checkPass");
        }
        callback();
      }
    };

    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateCheckPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };

    var validateCheckVerificationCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      loginRules: {
        username: [
          { required: true, message: "请输入帐户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      //重置密码发送邮件
      resetPassEmail: {
        phoneOrEmail: undefined,
      },

      //1登录 2注册 3忘记密码
      loginStatus: 1,

      dialogPrivacyAgreement: false,
      dialogUseAgreement: false,
      getUserId: getUserId(),
      rules: {
        username: [{ validator: validateUserName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validateCheckPass, trigger: "blur" }],
        phoneNumber: [{ validator: validateCheckPhone, trigger: "blur" }],
        smsVerificationCode: [
          { validator: validateCheckVerificationCode, trigger: "blur" },
        ],
      },
      dialogLoginVisible: true,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
        platformType: "1",
        validateCaptcha: undefined,
      },

      xieyiObj: {},
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
      inviter_code: "",
      //true是登录 false是注册
      LoginRegister: true,
      loginNoticeVisible: false,
      loginNoticeContent: undefined,
      quickLoginList: [],
      verifyStatus: false,
    };
  },
  computed: {
    ...mapState({
      //查询网站系统配置
      setting: (state) => state.index.setting,
      webSetting: (state) => state.index.webSetting,
    }),
  },

  mounted() {
    // // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = "accountLogin";
    } else if (this.setting.phoneLogin) {
      this.activeName = "phoneLogin";
    } else {
      this.activeName = "accountLogin";
    }
  },
  created() {
    //有推广码的话授权
    if (localStorage.getItem("inviterCode")) {
      this.loginForm.inviterCode = localStorage.getItem("inviterCode");
    }

    //加载协议
    this.loadXieYi();

    //加载登录公告
    this.queryLoginNotice();

    //加载快捷登录配置
    this.loadQuickLogin();
  },
  methods: {
    /**
     * 验证成功
     */
    success(params) {
      //验证成功
      this.verifyStatus = true;

      this.loginForm.validateCaptcha = params.captchaVerification;
    },

    /**
     * 点击验证
     */
    useVerify() {
      this.$refs.verify.show();
    },

    /**
     * 加载快捷登录配置
     */
    loadQuickLogin() {
      new Promise((resolve, rejust) => {
        getQuickLogin()
          .then((res) => {
            this.quickLoginList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询首页弹框公告
     */
    queryLoginNotice() {
      getLoginNotice().then((response) => {
        if (response.result) {
          this.loginNoticeVisible = true;
          this.loginNoticeContent = response.result.content;
        }
      });
    },

    /**
     * 重置密码 发送邮件
     */
    resetPassSendEmail() {
      new Promise((resolve, rejust) => {
        resetPwdVerifyCode(this.resetPassEmail)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "发送成功",
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 重置密码
     */
    resetPassword() {
      new Promise((resolve, rejust) => {
        pwdReset(this.resetPassEmail)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 账号密码登录
     */
    handleLogin(formName) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/login", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });

              this.$router.push("/index");
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 手机号登录
     */
    phoneLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/phoneLogin", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });

              this.$router.push("/index");
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 注册
     *
     * */
    handleRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/register", this.loginForm)
            .then(() => {
              this.$store.dispatch("user/login", this.loginForm).then(() => {
                Toast.success({
                  message: "注册成功",
                });
                this.dialogLoginVisible = false;

                this.$router.push("/index");
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 切换登录
     */
    handleClick() {
      this.$refs.loginForm.resetFields();
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    closeLogin() {
      this.dialogLoginVisible = false;
      this.$emit("close"); // 触发关闭事件，通知父组件
    },

    /**
     * 聚合登录
     */
    jhLoginForm(type) {
      var data = {
        loginType: type,
      };
      new Promise((resolve, rejust) => {
        getScanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.result.code === 0) {
              // 获取第三方URL
              var url = res.result.url;

              // 在当前窗口中打开第三方URL
              window.location.href = url;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style>

.d-forget-web-forget {
  padding: 10px 0px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  font-weight: 600;
}
</style>
