<template>
  <!-- 兑换卡密管理 -->
  <div>
    <el-card shadow="never">
      <!-- 兑换管理 -->
      <span class="member-user-title">兑换管理</span>

      <div style="margin-bottom: 10px">
        <el-alert
          title="温馨提示:"
          type="success"
          :description="
            '添加兑换商品可生成兑换码给用户使用，用户兑换以后才扣费;统一兑换地址：' +
            this.currentDomain
          "
          show-icon
        >
        </el-alert>
      </div>

      <div class="table-operations">
        <el-button type="primary" @click="dialogVisible = true" size="medium">
          添加兑换商品
        </el-button>
      </div>

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          prop="cardName"
          label="兑换卡名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="productName"
          label="关联商品"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="totalNum" label="卡密总数" align="center">
        </el-table-column>
        <el-table-column prop="usedNum" label="已兑换数量" align="center">
        </el-table-column>
        <el-table-column prop="unusedNum" label="未兑换数量" align="center">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="150"
          align="center"
        >
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.row)"
              v-preventClick
            >
              删除
            </el-button>

            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              兑换码
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="float: right; margin: 16px 0">
        <pagination
          :total="total"
          :current-page="queryParam.pageNum"
          :page-size="queryParam.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>
    </el-card>

    <!-- 添加兑换卡弹框 -->
    <el-dialog
      title="添加兑换卡"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
    >
      <el-card shadow="never">
        <el-form
          ref="cardForm"
          :rules="rules"
          :model="cardForm"
          label-position="top"
        >
          <el-form-item label="卡名称" prop="cardName">
            <el-input
              v-model="cardForm.cardName"
              placeholder="请输入卡名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="关联商品" prop="productId">
            <el-select
              v-model="cardForm.productId"
              placeholder="请输入关联商品"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.productName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('cardForm')"
              v-preventClick
            >
              确认
            </el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-dialog>

    <!-- 管理兑换码弹框 -->
    <el-dialog
      title="兑换码管理"
      :visible.sync="dialogCardVisible"
      :modal-append-to-body="false"
    >
      <div class="table-operations">
        <el-button
          type="primary"
          @click="dialogAddCardVisible = true"
          size="medium"
        >
          添加兑换码
        </el-button>
      </div>
      <el-table :data="cardData" style="width: 100%">
        <el-table-column label="序号" width="50px" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column prop="cdkey" label="兑换码" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="expiresTime"
          label="过期时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" effect="dark" v-if="scope.row.status == 1">
              已使用
            </el-tag>

            <el-tag type="success" effect="dark" v-if="scope.row.status == 0">
              未使用
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <!-- 分页 -->
        <div style="margin: 16px 0">
          <pagination
            :total="cardTotal"
            :current-page="queryCardParam.pageNum"
            :page-size="queryCardParam.pageSize"
            @currentChange="handleCurrentChangeCard"
            @editPagesizes="editPageSizesCard"
          />
        </div>
      </span>
    </el-dialog>

    <!-- 添加兑换码弹框 -->
    <el-dialog
      title="管理兑换码"
      :visible.sync="dialogAddCardVisible"
      :modal-append-to-body="false"
    >
      <el-form
        ref="cardForm"
        :rules="rules"
        :model="cardForm"
        label-width="100px"
      >
        <el-form-item label="卡名称" prop="cardName">
          <el-input
            v-model="cardForm.cardName"
            placeholder="请输入卡名称"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item label="生成数量" prop="number">
          <el-input
            v-model="cardForm.number"
            placeholder="请输入生成数量"
          ></el-input>
        </el-form-item>

        <el-form-item label="过期时间" prop="expiresTime">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="cardForm.expiresTime"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="前缀" prop="prefix">
          <el-input
            v-model="cardForm.prefix"
            placeholder="请输入前缀"
          ></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="cardForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="generateCard('cardForm')"
            v-preventClick
          >
            确认生成
          </el-button>
          <el-button @click="dialogAddCardVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  insertConvert,
  queryConvert,
  insertCard,
  queryCardList,
  delCard,
} from "@/api/convert";
import { queryProductList } from "@/api/product";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      dialogVisible: false,
      dialogCardVisible: false,
      dialogAddCardVisible: false,
      tableData: [],
      // 表单参数
      cardForm: {
        cardName: undefined,
        productId: undefined,
        productName: undefined,
        cardId: undefined,
        number: undefined,
        remark: undefined,
        expiresTime: undefined,
        prefix: undefined,
      },
      rules: {
        cardName: [
          { required: true, message: "请输入卡名称", trigger: "change" },
        ],
        productId: [
          { required: true, message: "请选择关联商品", trigger: "change" },
        ],
        number: [
          { required: true, message: "请输入生成数量", trigger: "change" },
        ],
      },
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        cardId: "",
      },
      total: 0,
      productList: [],
      queryCardParam: {
        pageNum: 1,
        pageSize: 10,
        cardId: "",
      },
      cardData: [],
      cardTotal: 0,
      currentDomain: "",
    };
  },

  created() {
    this.currentDomain =
      window.location.protocol + "//" + window.location.host + "/cdk";

    this.init();
    this.queryProduct();
  },

  methods: {
    /**
     * 查询卡组信息
     */
    init() {
      var then = this;

      new Promise((resolve, rejust) => {
        queryConvert(this.queryParam)
          .then((res) => {
            then.tableData = res.result.list;
            then.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //删除卡组信息
    handleDelete(row) {
      new Promise((resolve, rejust) => {
        delCard(row.id)
          .then((res) => {
            console.log(res);
            this.init();
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询商品信息
     */
    queryProduct() {
      var then = this;

      new Promise((resolve, rejust) => {
        let data = {
          isConvert: 1,
        };
        queryProductList(data)
          .then((res) => {
            then.productList = res.result.list;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 创建兑换卡密
     */
    submitForm(formName) {
      var then = this;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          new Promise((resolve, rejust) => {
            insertConvert(this.cardForm)
              .then((res) => {
                if (res.status == 0) {
                  then.dialogVisible = false;
                  then.init();
                }

                resolve();
              })
              .catch((error) => {
                rejust(error);
              });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 管理卡密
     */
    handleEdit(index, row) {
      //查询用
      this.queryCardParam.cardId = row.id;
      //新增用
      this.cardForm.cardName = row.cardName;
      this.cardForm.cardId = row.id;

      this.dialogCardVisible = true;

      //查询先清空
      this.cardData = [];
      this.queryCard();
    },

    /**
     * 根据卡组查询兑换码信息
     */
    queryCard() {
      var then = this;
      new Promise((resolve, rejust) => {
        queryCardList(this.queryCardParam)
          .then((res) => {
            then.cardData = res.result.list;
            then.cardTotal = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 确认生成兑换码
     */
    generateCard(formName) {
      var then = this;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          new Promise((resolve, rejust) => {
            insertCard(this.cardForm)
              .then((res) => {
                if (res.status == 0) {
                  then.dialogAddCardVisible = false;
                  then.queryCard();
                }

                resolve();
              })
              .catch((error) => {
                rejust(error);
              });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.init();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.init();
    },

    // 修改当前页事件
    handleCurrentChangeCard(pageNum) {
      this.queryCardParam.pageNum = pageNum;
      this.queryCard();
    },
    // 修改分页的每页的条数
    editPageSizesCard(val) {
      this.queryCardParam.pageSize = val;
      this.queryCard();
    },
  },
};
</script>
<style scoped>
.table-operations {
  margin-bottom: 20px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>
