<template>
  <div class="module-common">
    <div class="plane">
      <div class="banner">
        <template v-if="module.list.length > 0">
          <el-carousel>
            <el-carousel-item v-for="(item, index) in module.list" :key="index">
              <el-image
                style="border-radius: 10px; width: 100%; height: 100%"
                :src="item.imageUrl"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </template>

        <template v-else>
          <el-image style="height: 100%;width: 100%;border-radius: 10px;">
          </el-image>
        </template>
      </div>

      <div class="right">
        <div class="user-info">
          <div class="el-image avatar">
            <div class="el-image">
              <a-avatar :size="64" icon="user" />

            </div>
          </div>
          <div class="user-name">
            <span>您好，欢迎使用</span>
          </div>
          <div class="login-box">
            <el-button type="primary" size="small">个人中心</el-button>
            <el-button size="small">退出登录</el-button>
          </div>
        </div>
        <div class="login-enjoy">
          <div class="title">
            <div class="befor"></div>
            登录即享
            <div class="after"></div>
          </div>
          <div class="icon-show">
            <div class="item">
              <i class="el-icon-goods index-icon"></i>
              <div class="name">专属折扣</div>
            </div>
            <div class="item">
              <i class="el-icon-goods index-icon"></i>
              <div class="name">专属优惠</div>
            </div>
            <div class="item">
              <i class="el-icon-goods index-icon"></i>
              <div class="name">优惠特权</div>
            </div>
            <div class="item">
              <i class="el-icon-goods index-icon"></i>
              <div class="name">低价预定</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      goodsName: "",
      products: [],
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        }
      ]
    };
  },
  computed: {},
  methods: {
    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    },

    showProducts(category) {
      this.products = category;
      console.log(this.products);
    },

    hideProducts() {
      this.products = [];
    }
  }
};
</script>

<style>
.plane {
  height: 407px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
}

.plane .left {
  height: 100%;
  width: 250px;
  border-radius: 10px;
  background: #3b8cfe;
  display: flex;
  flex-direction: column;
  position: relative;
}

.plane .left .item {
  height: 82px;
  padding: 17px 17px 9px 17px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.14);
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.plane .left .item .item-title {
  font-size: 14px;
  height: 19px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}

.plane .left .item .item-title .icon {
  width: auto;
  /* height: 15px; */
  margin-right: 6px;
}

.plane .left .item .item-list {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  /* color: #fff; */
}

.plane .left .item .item-list .link {
  cursor: pointer;
}

.plane .left .item .item-list .link:after {
  content: "/";
}

.plane .left .item .item-list .link {
  cursor: pointer;
}

.plane .item-child-box {
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: start;
  justify-items: center;
  align-content: start;
  height: 407px;
  background: #fff;
  /* box-shadow: 0 2px 7px 2px rgba(220, 224, 227, 0.8); */
  border-radius: 9px;
  position: absolute;
  left: 245px;
  top: 0;
  z-index: 5;
  transition: all 0.5s;
  min-width: 800px;
}

.plane .item-child-box .goods-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 170px;
  gap: 5px;
  height: 135px;
  cursor: pointer;
}

.plane .item-child-box .goods-box .img-box {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.plane .item-child-box .goods-box .img-box .goods-img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.plane .item-child-box .goods-box .goods-name {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #252525;
}

.plane .banner {
  height: 100%;
  flex: 1;
  border-radius: 10px;
  /* background-color: #fff; */
  position: relative;
}

.plane .right {
  height: 100%;
  width: 22%;
  border-radius: 10px;
  background-color: #fff;
  padding: 0 13px;
}

.plane .right .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 186px;
  border-bottom: 1px dashed #e4e4e4;
  gap: 12px;
}

.plane .right .user-info .avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.plane .right .user-info .user-name {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #252525;
}

.plane .right .login-enjoy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.plane .right .login-enjoy .title {
  margin-top: 13px;
  font-size: 17px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 500;
  color: #252525;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 17px;
  gap: 7px;
}

.plane .right .login-enjoy .title .befor {
  position: relative;
  width: 47px;
  height: 7px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
}

.plane .right .login-enjoy .title .after {
  width: 47px;
  height: 7px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
}

.plane .right .login-enjoy .icon-show {
  margin-top: 17px;
  width: 158px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 28px;
}

.plane .right .login-enjoy .icon-show .item {
  width: 50px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
}

.plane .right .login-enjoy .icon-show .item .icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.plane .right .login-enjoy .icon-show .item .name {
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  /* color: #252525; */
}

.el-carousel__container {
  height: 407px;
}

.plane .left .more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.plane .left .more .left-btn {
  width: 170px;
  height: 40px;
  background: hsla(0, 0%, 100%, 0.18);
  border-radius: 4px 4px 4px 4px;
  border: none;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.index-icon {
  background-color: #e2eeff;
  border-radius: 50%;
  padding: 10px;
  /* width: 100px; */
  font-size: 22px;
  color: #3175d4;
}
</style>
