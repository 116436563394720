import { getWebTemplate } from '@/api/index'
const state = {
    templateList: [],
}

const mutations = {
    SET_TEMPLATE: (state, data) => {
        state.templateList = data
    },

}

const actions = {
    getTemplate({ state, commit }) {
        return new Promise((resolve, reject) => {
            getWebTemplate({}).then(response => {
                commit('SET_TEMPLATE', response.data)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}