<template>
  <div>
    <template v-if="device !== 'mobile'">
      <div class="layout-demo-top-side-logo" style="float: left;">
        分站管理控制台
      </div>
    </template>

    <a-button type="link" @click="toggleSideBar">
      <a-icon type="menu-unfold" style="color:#FFFFFF" />
    </a-button>

    <div style=" float: right;margin-right: 10px;">
      <a-space :size="24">

        <a-tooltip placement="bottom">
          <template slot="title">
            首页
          </template>
          <div style="cursor: pointer;" @click="toDomain()">
            <a-icon type="desktop" :style="{ fontSize: '18px', color: '#FFFFFF' }" />
          </div>
        </a-tooltip>

       

        <div style="color: #FFFFFF;    line-height: 20px;">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-avatar shape="square" size="large" :style="{ backgroundColor: '#7265e6', verticalAlign: 'middle' }">
               分站
              </a-avatar>
            </a>
            <a-menu slot="overlay" style="width: 100px;text-align: center;">
              <a-menu-item @click.native="handleLogout()" >
                <a-icon type="poweroff" style="margin-right: 5px;" />退出登录
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-space>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
      },
    };
  },
  computed: {
    ...mapState({
      device: state => state.app.device,
    }),
    classObj() {
      return {
        mobile: this.device === "mobile"
      };
    }
  },

  created() {

  },
  methods: {

    /**
     * 退出登录
     */
     handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          location.reload();
          this.$message({
            message: "注销成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },


    /**
     * 跳转到商城地址
     */
    toDomain() {
      window.open(window.location.origin, "_blank");
    },

    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },


    handleClose(done) {
      done();
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 64px;
  overflow: hidden;
  position: relative;
  background: #fff;

  .hamburger-container {
    line-height: 64px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 64px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 22px;
          height: 22px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.navbar-item {
  padding: 0 12px;
  cursor: pointer;
}

.layout-demo-top-side-logo {
  width: 200px;
  text-align: center;
  height: 64px;
  line-height: 64px;
  color: #fff;
  font-size: 20px;
}



.ant-layout-header {
  padding: 0px 10px !important;
}
</style>
