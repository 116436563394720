<template>
  <!-- 订单收货 -->

  <van-popup
    v-model="orderPopupStatus"
    closeable
    position="bottom"
    :style="{ height: '60%' }"
  >
    <div style="padding: 10px">
      <van-form>
        <van-field
          readonly
          clickable
          name="picker"
          :value="value"
          label="申请原因"
          placeholder="点击选择"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            value-key="label"
            show-toolbar
            :columns="options"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          v-model="reasionDescription"
          rows="3"
          autosize
          label="问题描述"
          type="textarea"
          placeholder="请输入"
          maxlength="50"
          show-word-limit
        />

        <div style="margin: 10px">
          <van-button round block type="info" @click="handleCreate()"
            >提交申请</van-button
          >
        </div>
      </van-form>
    </div>
  </van-popup>
</template>
<script>
import { Toast } from "vant";

export default {
  props: {
    orderShouHouId: {
      type: Number,
      default: 0,
    },
    orderShouHouProductId: {
      type: Number,
      default: 0,
    },
    orderShouHouQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showPicker: false,
      orderPopupStatus: false,

      reasionDescription: "",
      value: "",
      reasonType: "",
      options: [
        { value: 1, label: "充值没到账" },
        { value: 2, label: "其他问题" },
      ],
    };
  },

  created() {},
  methods: {
    /**
     * 申请订单售后
     */
    handleOpen() {
      this.orderPopupStatus = true;
    },

    onConfirm(value) {
      this.value = value.label;
      this.reasonType = value.value;
      this.showPicker = false;
    },

    /**
     * 提交申请售后
     */
    handleCreate() {
      console.log(this.reasonType);
      if (this.reasonType == "") {
        Toast.fail({
          message: "请选择申请原因",
        });
        return;
      }

      if (this.reasionDescription == "") {
        Toast.fail({
          message: "请输入问题描述",
        });
        return;
      }

      this.$store
        .dispatch("refund/create", {
          id: this.orderShouHouId,
          orderFormProductId: this.orderShouHouProductId,
          quantity: this.orderShouHouQuantity,
          reasonType: this.reasonType,
          reasionDescription: this.reasionDescription,
        })
        .then(() => {
          Toast.success({
            message: "提交成功",
          });
          this.orderPopupStatus = false;
        });
    },
  },
};
</script>
