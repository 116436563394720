<template>
  <div>
    <van-nav-bar
      title="开放接口"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div style="padding: 10px">
      <van-form @submit="onSubmit">
        <van-field
          v-model="userid"
          name="用户编号"
          label="用户编号"
          placeholder="用户编号"
          readonly
        />

        <van-field
          readonly
          v-model="secretKey"
          rows="2"
          autosize
          label="接口密钥"
          type="textarea"
          maxlength="50"
          placeholder="请生成接口密钥"
        >
          <template #button>
            <van-button size="small" type="primary" @click="createKey()"
              >生成</van-button
            >
          </template>
        </van-field>

        <!-- <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >

          <van-button plain block round type="info">接口文档</van-button>
        </div> -->
      </van-form>
      <div style="margin: 20px 0px">
        <a href="https://www.showdoc.com.cn/2285565147915645" target="_blank">
          <van-button plain block round type="info">接口文档</van-button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.commission.balanceAllowTransfer,
      balanceIsRejected: (state) => state.commission.balanceIsRejected,
      balanceIsTransfering: (state) => state.commission.balanceIsTransfering,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      secretKey: (state) => state.user.secretKey,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
      branchId: (state) => state.user.branchId,
      levelName: (state) => state.user.levelName,
      levelNum: (state) => state.user.levelNum,
      userid: (state) => state.user.userid,
      isCardNo: (state) => state.user.isCardNo,
    }),
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {
    this.$store.dispatch("user/info").then();
  },
  methods: {
    /**
     * 生成密钥
     */
    createKey() {
      let data = {};

      this.$store.dispatch("user/generateKey", data).then(() => {
        console.log(this.secretKey);
      });
    },

    onSubmit(values) {
      console.log("submit", values);
    },

    //跳转到我的页面
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
