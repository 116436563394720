<template>
  <div>

    <van-nav-bar left-text="返回" title="重置密码" left-arrow @click-left="onClickLeft" />


    <div class="reset_pwd">
      <div class="reset_pwd_title">
        重置密码
        <div class="change-type-box">
          <span>
            <span>邮箱找回</span>
          </span>
        </div>
      </div>

      <div class="reset_pwd_form">
        <el-form ref="form" :model="form">

          <el-form-item>
            <el-input v-model="resetPassEmail.phoneOrEmail" placeholder="请输入绑定的手机或邮箱"></el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="resetPassEmail.verifyCode" placeholder="请输入验证码">
              <template slot="append" style="background-color: #FFFFFF;color: #ef8466;">
                <el-button type="text" style="width: 90px;margin: 0;" @click="resetPassSendEmail()" v-preventClick>获取验证码</el-button>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="resetPassEmail.password" placeholder="请确认新密码"></el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="resetPassEmail.password" placeholder="请再次输入新密码"></el-input>
          </el-form-item>


        </el-form>

        <div>
          <van-button round block type="warning" @click="resetPassword()" >重置密码</van-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { Toast } from "vant";


import { resetPwdVerifyCode, pwdReset } from "@/api/user";

export default {
  data() {
    return {
      resetPassEmail: {
        phoneOrEmail: undefined,
      },
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {



    /**
       * 重置密码 发送邮件
       */
    resetPassSendEmail() {
      new Promise((resolve, rejust) => {
        resetPwdVerifyCode(this.resetPassEmail)
          .then((res) => {
            console.log(res);


            Toast.success({
              message: "发送成功",
            });
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },


    /**
    * 重置密码
    */
    resetPassword() {
      new Promise((resolve, rejust) => {
        pwdReset(this.resetPassEmail)
          .then((res) => {
            console.log(res);
            Toast.success({
              message: "重置成功",
            });
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },






    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.reset_pwd {
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 30px;
  left: 0;
}

.reset_pwd .logo {
  margin-top: 52px;
  margin-left: 43px;
  width: 35px;
  height: 35px;
}


.reset_pwd .reset_pwd_title {
  width: 100%;
  padding-left: 35px;
  color: #1e2331;
  font-weight: 600;
  font-size: 22px;
  margin-top: 60px;
}


.reset_pwd .reset_pwd_title .change-type-box {
  float: right;
  font-size: 13px;
  margin-right: 20px;
  color: red;
  font-weight: 400;
  cursor: pointer;
}


.reset_pwd .reset_pwd_form {
  padding: 20px 30px;
  width: 100%;
  margin-top: 10px;
}
</style>