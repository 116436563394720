<template>
  <div class="module-page-background" :style="style"></div>
</template>

<script>
export default {
  name: "pagebg",
  props: ["base"],
  computed: {
    style() {
      let bg = "";
      //  1 默认背景  2 自定义背景颜色 3自定义背景图片
      if (this.base.pageBg.bgType == 1) {
        bg = "background:none;";
      } else if (this.base.pageBg.bgType == 2) {
        bg = `background:${this.base.pageBg.bgColor};`;
      } else if (this.base.pageBg.bgType == 3) {
        bg = `background:url(${this.base.pageBg.bgImage}) no-repeat center;background-size:cover;`;
      }
      console.log(bg);
      return bg;
    },
  },
};
</script>
<style scoped>
.module-page-background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
