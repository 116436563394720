<template>
  <div style="background-color: #ffffff">
    <a-drawer title="公共设置" :visible="visibleStatus" width="35%">
      <div class="right-box" style="min-width: 100%">
        <div style="padding: 20px 10px">
          <el-form label-width="80px">
            <el-form-item label="首页背景">
              <a-radio-group v-model="attr.base.pageBg.bgType">
                <a-radio value="1">默认背景</a-radio>
                <a-radio value="2">自定义颜色</a-radio>
                <a-radio value="3">自定义图片背景</a-radio>
              </a-radio-group>
            </el-form-item>

            <el-form-item label="首页设置">
              <!-- 自定义颜色 -->
              <template v-if="attr.base.pageBg.bgType == 2">
                <el-color-picker v-model="attr.base.pageBg.bgColor" />
              </template>

              <!-- 自定义图片 -->
              <template v-if="attr.base.pageBg.bgType == 3">
                <a-button type="primary" @click="$refs.HImage.handleAdd()">
                  上传图片
                </a-button>
              </template>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 公共图片 -->
      <HIMAGE ref="HImage" @imageCallback="imageCallback"></HIMAGE>
    </a-drawer>
  </div>
</template>
<script>
import HIMAGE from "@/components/IMAGE/index.vue";

export default {
  components: {
    HIMAGE,
  },

  data() {
    return {
      attr: {},
      visibleStatus: false,
    };
  },

  mounted() {},

  methods: {
    /**
     * 点击控件
     */
    childKongJian(attr) {
      this.visibleStatus = true;
      this.attr = attr;
    },

    /**
     * 图片选择
     */
    imageCallback(row) {
      this.attr.base.pageBg.bgImage = row.imgUrl;

      this.$forceUpdate();
    },
  },
};
</script>
