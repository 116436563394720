var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
    { 'border-radius': _vm.module.base.round + 'px' },
  ])},[_c('div',[_c('div',[_c('div',{staticClass:"group-wrapper"},[_c('div',{staticClass:"hd"},[_vm._m(0),_c('router-link',{attrs:{"to":"/mpriceLog"}},[_c('span',{staticStyle:{"font-size":"0.8rem","font-weight":"400","color":"#999"}},[_vm._v("查看更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)])],1),_c('div',{staticClass:"group-scroll"},[_c('vue-seamless-scroll',{staticClass:"seamless-warp",attrs:{"data":_vm.tableData,"class-option":_vm.optionLeft}},[_c('div',{staticClass:"scroll-view_H"},[_c('div',[_c('div',{staticStyle:{"overflow":"auto hidden"}},[_c('div',_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index,staticClass:"group-item",staticStyle:{"margin":"5px"},on:{"click":function($event){return _vm.toProductDetails(item.productId)}}},[_c('div',{staticClass:"info",staticStyle:{"align-items":"center","text-align":"center"}},[_c('div',{staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.08)","height":"80px","width":"80px"}},[_c('van-image',{staticStyle:{"width":"78px","height":"78px"},attrs:{"radius":"5","src":item.productImg,"lazy-load":""}})],1),_c('div',{staticClass:"name line1",staticStyle:{"color":"#9b9fa8"}},[_vm._v(" "+_vm._s(item.productName)+" ")])]),_c('div',{staticClass:"bom-btn"},[_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("@/assets/image/jiang.png")}}),_vm._v(" "+_vm._s(item.priceAfter)+" ")])])])}),0)])])])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',{staticClass:"iconfont icon-hot3"},[_c('span')]),_c('div',{staticClass:"name"},[_vm._v("近期降价")])])
}]

export { render, staticRenderFns }