<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
      }
    ]"
  >
    <div class="choice_view">
      <div class="gds-list">
        <div style="background-color: #ffffff">
          <van-tabs @click="toPCate" :ellipsis="false">
            <van-tab
              :title="item.categoryName"
              v-for="item in catesList"
              :key="item.id"
              :name="item.id"
            >
              <van-swipe :loop="true" indicator-color="#2196F3">
                <van-swipe-item>
                  <van-grid icon-size="36px" :border="false" :column-num="4">
                    <van-grid-item text="爱奇艺" icon="apps-o" />
                    <van-grid-item text="爱奇艺" icon="apps-o" />
                    <van-grid-item text="爱奇艺" icon="apps-o" />
                    <van-grid-item text="爱奇艺" icon="apps-o" />
                  </van-grid>
                </van-swipe-item>
              </van-swipe>
            </van-tab>
          </van-tabs>
        </div>

        <div>
          <van-list
            finished-text="没有更多了"
            style="background-color: #f5f7fa;"
          >
            <van-row gutter="10">
              <van-col
                span="12"
                v-for="(value, key, index) in listData"
                :key="index"
              >
                <div
                  style="
                    background: #fff;
                    padding-bottom: 10px;
                    padding: 8px 8px 10px 8px;
                    border-radius: 5px;
                    margin-top: 10px;
                  "
                  @click="toProductDetails(value)"
                >
                  <span class="pic-wrap">
                    <div class="nut-image">
                      <a-icon
                        type="appstore"
                        theme="twoTone"
                        style="font-size:64px"
                      />
                    </div>
                  </span>

                  <div>
                    <span class="tit">
                      {{ value.productName }}
                    </span>
                  </div>

                  <!-- 标签 -->
                  <div style="margin: 10px 0px"></div>

                  <div class="dx2-goods-price">
                    <div>
                      <span>￥1.00</span>
                    </div>

                    <span
                      style="
                        border: 1px solid #e1c1c7;
                        background-color: #fef4f3;
                        display: inline-block;
                        height: 26px;
                        padding: 0 10px;
                        line-height: 24px;
                        font-size: 12px;
                        color: #d57081;
                        border-radius: 20px;
                      "
                    >
                      立即购买
                    </span>
                  </div>
                </div>
              </van-col>
            </van-row>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        }
      ],
      listData: [
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        }
      ]
    };
  },
  computed: {}
};
</script>

<style>
.between {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* 加载更多数据 */
.loading {
  font-size: 15px;
  color: #a6a6a6;
  text-align: center;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 商品列表 */
.gds-list ul {
  /* background: #fff; */
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* margin-top: 10px; */
}

.gds-list li {
  /* margin: 10px 0px 0px 0px; */
  /* margin: 10px 2.5px 0px 2.5px; */
  width: 48%;
  /* padding-bottom: 10px; */
  margin-bottom: 9px;
  border-radius: 5px;
  background: #fff;
}

.gds-list li a {
  display: block;
}

.gds-list .pic-wrap {
  display: block;
  /* height: 180px; */
  overflow: hidden;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dx2-good-img {
  background: #fff;
}

.nut-image {
  display: block;
  position: relative;
  padding: 1px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gds-list .tit {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;

  /* font-weight: 550; */
}

.dx2-goods-price {
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nut-price {
  font-size: 0;
  display: inline;
  color: var(--nut-primary-color, #fa2c19);
}

.nut-price--symbol-normal {
  display: inline-block;
  font-size: var(--nut-price-symbol-medium-size, 14px);
}

.nut-price--normal {
  display: inline-block;
  color: rgb(253, 77, 32);
  font-weight: 500;
  font-size: 19px;
  padding-left: 3px;
}

.nut-price--decimal-normal {
  display: inline-block;
  font-size: var(--nut-price-decimal-medium-size, 14px);
}

.nut-price--decimal-normal {
  display: inline-block;
  font-size: var(--nut-price-decimal-medium-size, 14px);
}

.nut-tag {
  padding: 0 4px;
  display: inline-flex;
  align-items: center;
  font-size: var(--nut-tag-font-size, 12px);
  border-radius: var(--nut-tag-default-border-radius, 4px);
  height: var(--nut-tag-height, auto);
}

.nut-tag--default {
  border: var(--nut-tag-border-width, 1px) solid transparent;
}

/* 弹框 */
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}

/deep/ .van-dropdown-menu__bar {
  box-shadow: unset !important;
  /* 解决van-dropdownMenu下拉菜单有白边问题 */
}
</style>
