<template>
  <div>
    <div style="padding: 0px 10px">
      <div class="hot_imgs">
        <div class="hot_imgs">
          <!---->
          <div class="list-box">
            <!-- 图1 -->
            <div class="item">
              <div>
                <div class="delect-btn">
                  <a-button type="link">
                    <a-icon type="close" />
                  </a-button>
                </div>

                <div class="info">
                  <div class="info-item">
                    <span class="span">图一</span>
                    <div class="img-box" @click="openImageSelector(1)">
                      <div class="upload-box" v-if="!attr.data.path1">
                        <a-icon type="plus" />
                      </div>

                      <img :src="attr.data.path1" v-else />
                    </div>
                  </div>
                </div>
                <div class="info">
                  <div class="info-item">
                    <span class="span">标题</span>
                    <a-input
                      placeholder="请输入标题"
                      v-model="attr.data.title1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- 图2 -->
            <div class="item">
              <div>
                <div class="delect-btn">
                  <a-button type="link">
                    <a-icon type="close" />
                  </a-button>
                </div>

                <div class="info">
                  <div class="info-item">
                    <span class="span">图二</span>
                    <div class="img-box" @click="openImageSelector(2)">
                      <div class="upload-box" v-if="!attr.data.path1">
                        <a-icon type="plus" />
                      </div>

                      <img :src="attr.data.path2" v-else />
                    </div>
                  </div>
                </div>
                <div class="info">
                  <div class="info-item">
                    <span class="span">标题</span>
                    <a-input
                      placeholder="请输入标题"
                      v-model="attr.data.title2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 公共图片 -->
    <HIMAGE ref="HImage" @imageCallback="imageCallback"></HIMAGE>
  </div>
</template>

<script>
import HIMAGE from "@/components/IMAGE/index.vue";

export default {
  components: {
    HIMAGE
  },
  props: ["attr"],
  data() {
    return {
      selectedIndex: undefined
    };
  },

  methods: {
    /**
     * 添加连接
     */
    insterLink() {
      this.attr.list.push({
        icon: "",
        link: ""
      });
    },

    /**
     * 删除
     */
    deleteLink(item) {
      // 确保至少保留一个元素
      if (this.attr.list.length <= 1) {
        this.$message({
          showClose: true,
          message: "至少保留一个！"
        });
        return;
      }

      var index = this.attr.list.indexOf(item);
      if (index !== -1) {
        this.attr.list.splice(index, 1);
      }
    },

    openImageSelector(index) {
      this.selectedIndex = index;

      this.$refs.HImage.handleAdd();
    },

    /**
     * 图片选择
     */
    imageCallback(row) {
      if (this.selectedIndex == 1) {
        this.attr.data.path1 = row.imgUrl;
      }

      if (this.selectedIndex == 2) {
        this.attr.data.path2 = row.imgUrl;

        // this.$set(this.attr.list, this.selectedIndex, { icon: row.imgUrl });
      }
      this.$forceUpdate();
    }
  }
};
</script>

<style></style>
