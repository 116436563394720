<template>
  <div class="container" style="margin-top: 20px">
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="18">
        <div
          style="
            background-color: #ffffff;
            height: 130px;
            display: flex;
            align-items: center;
            padding: 20px;
            border-radius: 10px;
            position: relative;
          "
        >
          <a-avatar
            :size="64"
            icon="user"
            :src="avatarPicUrl"
            style="margin-left: 5px"
          />
          <div class="centerTxt" style="margin-left: 20px">
            <div style="margin-bottom: 8px">
              <span
                style="
                  font-weight: bold;
                  font-size: 20px;
                  color: #333333;
                  margin-right: 10px;
                "
                >{{ username }}</span
              >
              <span>
                <a-tag color="#2db7f5"> {{ levelName }} </a-tag>

                <a-button type="link" @click="dialogVisibleLevel = true">
                  <a-icon type="arrow-up" />提升等级享受更多折扣
                </a-button>
              </span>
            </div>
            <div style="color: #888">用户编号：{{ userid }}</div>
          </div>

          <div style="position: absolute; right: 30px; align-items: center">
            <div class="menu-button">
              <div>
                <div v-if="branchId">
                  <span class="name" @click="toVipAdmin()" v-preventClick>
                    <a-button shape="round" type="primary"> 我的分站 </a-button>
                  </span>
                </div>

                <div v-else>
                  <router-link to="/openFz">
                    <a-button shape="round" type="danger"> 开通分站 </a-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div
          class="gutter-box"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px;
            background-color: #ffffff;
            height: 130px;
            border-radius: 10px;
          "
        >
          <div
            style="
              font-size: 27px;
              font-weight: 700;
              color: #333333;
              margin-bottom: 10px;
            "
          >
            {{ balanceTotal }}
          </div>
          <div style="margin-bottom: 10px; color: #666666; font-size: 14px">
            用户余额
          </div>
          <div style="display: flex; justify-content: center">
            <a-button type="primary" @click="goCredit()" v-preventClick>
              充值
            </a-button>

            <a-button
              type="primary"
              style="margin-left: 10px"
              @click="goCash()"
              v-preventClick
            >
              提现
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>

    <!-- 用户中心 -->

    <div class="top-head" style="margin-top: 10px">
      <div class="glmall-new-header-group user-header-group">
        <div>
          <div class="ucenter-home-menu">
            <ul class="menu-list">
              <li>
                <router-link
                  to="/user_info"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  用户中心
                </router-link>
              </li>

              <li>
                <router-link
                  to="/ucenter"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  账号管理
                </router-link>
              </li>

              <li>
                <router-link
                  to="/porder"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  订单列表
                </router-link>
              </li>

              <li>
                <router-link
                  to="/service"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  售后订单
                </router-link>
              </li>

              <li>
                <router-link
                  to="/balance"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  资金明细
                </router-link>
              </li>

              <li>
                <router-link
                  to="/invited"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  推广分销
                </router-link>
              </li>
              <li>
                <router-link
                  to="/address"
                  class="name"
                  active-class="router-link-exact-active router-link-activ act"
                >
                  收货地址
                </router-link>
              </li>

              <li>
                <router-link
                  to="/convertManage"
                  class="name"
                  active-class="router-link-exact-active router-link-active act"
                >
                  兑换管理
                </router-link>
              </li>

              <!-- <li>
                <router-link
                  to="/messageSubscribe"
                  class="name"
                  active-class="router-link-exact-active router-link-active act"
                >
                  消息订阅
                </router-link>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="ucenter-container">
        <router-view />
      </div>
    </div>

    <!-- 提示等级弹框 -->
    <a-modal
      title="提升等级"
      v-model:dialogVisibleLevel="dialogVisibleLevel"
      width="40%"
      :footer="null"
    >
      <USERLEVEL></USERLEVEL>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";
import USERLEVEL from "@/pages/pc/userlevel.vue";

import { getVipAddress } from "@/api/vip";

export default {
  data() {
    return {
      dialogVisibleLevel: false,
    };
  },

  components: { USERLEVEL },
  computed: {
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.commission.balanceAllowTransfer,
      balanceIsRejected: (state) => state.commission.balanceIsRejected,
      balanceIsTransfering: (state) => state.commission.balanceIsTransfering,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      userid: (state) => state.user.userid,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
      balanceTotal: (state) => state.balance.balanceTotal,
      branchId: (state) => state.user.branchId,
      levelName: (state) => state.user.levelName,
      levelNum: (state) => state.user.levelNum,
    }),
  },
  created() {
    this.$store.dispatch("user/info");
    this.$store.dispatch("balance/detail").then(() => {
      this.itemData[0][0].num = this.balanceTotal;
      this.itemData[0][1].num = this.totalRechargeSuccess;
      this.itemData[1][0].num = this.totalWithdrawRejected;
      this.itemData[1][1].num = this.totalWithdrawSuccess;
      this.itemData[2][0].num = this.balanceCanWithdraw;
      this.itemData[2][1].num = this.totalWithdrawing;
    });

    //验证是否登录
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      }
    },

    /**
     * 跳转到分站后台
     */
    toVipAdmin() {
      new Promise((resolve, rejust) => {
        getVipAddress()
          .then((res) => {
            // let isMain = res.result.isMain;

            //主站访问 直接跳转到分站登录页
            // if (isMain) {
            let url = res.result.url + "/viplogin";
            setTimeout(() => window.open(url, "_blank"));
            // }

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //充值页面
    goCredit() {
      this.$router.push("/credit");
    },

    //提现页面
    goCash() {
      this.$router.push("/cash");
    },
    isActive(tag) {
      return tag === this.$route.path;
    },
  },
};
</script>

<style>
.app-wrapper {
  position: relative;
  background-color: #f2f3f5;
}

.app-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.svg-icon[data-v-7fa6676a] {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon[data-v-7fa6676a] {
  background-color: currentColor;
  -webkit-mask-size: cover !important;
  mask-size: cover !important;
  display: inline-block;
}

.html[data-v-614fb8c2] p {
  margin: 0;
  padding: 0;
}

.link-click {
  color: #0084ff;
  cursor: pointer;
}

.pr {
  position: relative;
}

.pr .code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 14px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}

.user-header-group .user-header-wrapper {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-image: url(../../../assets/image/ucenter_bg.png);
  background-size: cover;
  background-color: #2868f2;
  -webkit-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
}

.user-header-group .glmall-new-header {
  background: transparent !important;
}

.user-header-group .glmall-new-header-menu li a {
  color: hsla(0, 0%, 100%, 0.7019607843137254);
}

.user-header-group .glmall-new-header-menu li.glmall-curr a,
.user-header-group .glmall-new-header-menu li.glmall-curr a:hover,
.user-header-group .glmall-new-header-menu li a:hover {
  color: #fff;
}

.ucenter-home-info {
  width: 1300px;
  margin: 0 auto;
  position: relative;
  /* height: 170px; */
}

.ucenter-container {
  width: 1300px;
  margin: 0 auto;
  padding: 16px 0 60px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  margin-bottom: 26px;
  min-height: 400px;
}

.ucenter-home-info .home_bar {
  height: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  /* margin-top: 20px; */
  display: flex;
}

.ucenter-home-info .home_bar .bar_userInfo {
  width: 80%;
  color: #fff;
  padding-left: 110px;
  position: relative;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_avatar {
  position: absolute;
  left: 20px;
  top: 50px;
  width: 80px;
  height: 80px;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_avatar .avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_avatar .auth {
  position: absolute;
  bottom: 0;
  right: -4px;
  width: 24px;
  height: 24px;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_auth {
  padding-top: 54px;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_auth .title {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  max-width: 260px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_auth .auth_tip {
  display: inline-block;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.7);
  font-weight: 400;
  text-align: center;
  margin-left: 6px;
  background: #5c81d2;
  color: hsla(0, 0%, 100%, 0.7019607843137254);
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_auth .auth_tip.active {
  background: #4197ff;
  color: #fff;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_auth .auth_btn {
  display: inline-block;
  margin-left: 20px;
  background: #fff;
  width: 82px;
  height: 20px;
  border-radius: 10px;
  font-size: 12px;
  color: #417ff9;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_auth .auth_btn:hover {
  color: #608dfa;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_info {
  margin-top: 14px;
  line-height: 18px;
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.6);
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_info .hr {
  font-size: 16px;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_info .seal {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 0;
  background-image: none;
  padding-left: 4px;
  margin-left: 30px;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.ucenter-home-info .home_bar .bar_userInfo .userInfo_info .seal:hover {
  color: #fff;
}

.ucenter-home-info .home_bar .bar_signInfo {
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 70px;
}

.ucenter-home-info .home_bar .bar_signInfo .count {
  height: 26px;
  line-height: 40px;
  padding-left: 16px;
  position: relative;
  color: #d7d9de;
  font-size: 12px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left 36px center;
}

.ucenter-home-info .home_bar .bar_signInfo .count .number {
  font-size: 22px;
  color: #fff;
  position: absolute;
  right: 16px;
  display: inline-block;
  text-align: left;
}

.ucenter-home-info .home_bar .bar_signInfo .count.count1,
.ucenter-home-info .home_bar .bar_signInfo .count.count2,
.ucenter-home-info .home_bar .bar_signInfo .count.count3 {
  width: 200px;
}

.ucenter-home-info .home_bar .bar_signInfo .count:hover {
  color: #fff;
}

.ucenter-home-info .home_bar .bar_signInfo .count + .count {
  border-left: 1px solid hsla(0, 0%, 100%, 0.2);
}

.ucenter-home-menu {
  width: 1300px;
  background: #fff;
  height: 60px;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
}

.ucenter-home-menu .menu-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
  height: 60px;
  margin-top: -60px;
}

.ucenter-home-menu .menu-button li {
  line-height: 60px;
  margin-right: 14px;
  cursor: pointer;
}

.ucenter-home-menu .menu-button .name {
  padding: 8px 18px;
  background: #333;
  color: #fff;
  border-radius: 26px;
}

.ucenter-home-menu .menu-list {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ucenter-home-menu .menu-list li {
  line-height: 60px;
  margin-left: 14px;
  cursor: pointer;
}

.ucenter-home-menu .menu-list .name {
  position: relative;
  /* 需要为父元素设置相对定位 */
  padding: 5px 12px;
  background: #fff;
  color: #000;
  border-radius: 26px 26px 0 26px;
  /* 左上、右上和右下圆角 */
  font-size: 14px;
}

.ucenter-home-menu .menu-list .act,
.ucenter-home-menu .menu-list .name:hover {
  padding: 5px 12px;
  background: #62a7d9;
  color: #fff;
  border-radius: 26px 26px 0 26px;
  /* 同上边缘更新 */
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-transform-enter-active,
.fade-transform-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}

.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}

.breadcrumb-move {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-dialog__body {
  padding-top: 0 !important;
}

.full-wrapper {
  /* background: url(../../static/img/index_bg.ca754e33.jpg) repeat-x center 0; */
  min-width: 1300px;
}

.full-wrapper .container {
  padding: 16px 0 60px;
  margin-top: 120px;
}

.full-wrapper .full-container {
  width: 1300px;
  margin: 0 auto;
  padding: 16px 0 60px;
  margin-top: 117px;
}
</style>
