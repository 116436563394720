import request from '@/utils/request'


/**
 * 热门搜索关键词
 * @param {*} data 
 * @returns 
 */
export function queryPayDetails(data) {
    return request({
        url: '/api/payConfig/details',
        method: 'post',
        data
    })
}