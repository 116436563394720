<template>
  <div :style="!module.base.m ? 'margin-top:0' : ''">
    <div class="bannerComb on" configdata="[object Object]">
      <div class="bgImg">
        <img :src="currentBackground" />
      </div>
      <div class="searchBox">
        <van-search
          shape="round"
          placeholder="请输入搜索关键词"
          background="transparent"
        />
      </div>
      <div class="banner">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          @change="onSwipeChange"
          v-if="module.list.length > 0"
        >
          <van-swipe-item v-for="(item, index) in module.list" :key="index">
            <el-image
              :src="item.imageUrl"
              style="height: 160px;width: 100%;border-radius: 10px;"
            >
            </el-image>
          </van-swipe-item>
        </van-swipe>

        <template v-else>
          <el-image style="height: 160px;width: 100%;border-radius: 10px;">
          </el-image>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],

  data() {
    return {
      // 默认背景
      currentBackground: ""
    };
  },
  mounted() {
    // 初始化时设置第一张轮播图的背景
    if (this.module.list.length > 0) {
      this.currentBackground = this.module.list[0].imageUrl;
    }
  },
  methods: {
    onSwipeChange(index) {
      // 当轮播图切换时，更新背景
      this.currentBackground = this.module.list[index].imageUrl;
    }
  }
};
</script>

<style>
.bannerComb {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
}

.bannerComb .bgImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  filter: blur(0);
  overflow: hidden;
}

.bannerComb .searchBox {
  position: relative;
  z-index: 1;
}

.bannerComb .banner {
  padding: 0px 15px;
  /* height: 160px; */
  position: relative;
  z-index: 1;
  border-radius: 6px;
  margin: 0 auto;
}

.bannerComb .banner img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.bannerComb .bgImg img {
  width: 100%;
  height: 100%;
  filter: blur(15px);
  transform: scale(1.5);
}
</style>
