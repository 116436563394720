<template>
  <div class="module-common" :style="!module.base.m ? 'margin-top:0' : ''">

    <van-search disabled shape="round" :placeholder="module.style.text" :background="module.style.bgcolor" />

  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
<script>
export default {
  props: ["module"],
};
</script>