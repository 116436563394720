<template>
  <div>
    <div>
      <div class="hot_imgs">
        <div class="hot_imgs">
          <div class="list-box">
            <!-- <a-row style="padding:10px">
              <a-col :span="4">
                <span style="display: flex;align-items: center;height: 30px;font-size: 14px;color: #999;">
                  背景颜色
                </span>
              </a-col>
              <a-col :span="20">
                <el-color-picker v-model="attr.style.bgcolor"> </el-color-picker>
              </a-col>
            </a-row>


            <a-row style="padding:10px">
              <a-col :span="4">
                <span style="display: flex;align-items: center;height: 30px;font-size: 14px;color: #999;">
                  文字颜色
                </span>
              </a-col>
              <a-col :span="20">
                <el-color-picker v-model="attr.style.color"> </el-color-picker>
              </a-col>
            </a-row> -->

            <div class="item" v-for="(item, index) in attr.list" :key="index">
              <div>
                <div class="delect-btn">
                  <a-button type="link" @click="deleteLink(item)">
                    <a-icon type="close" />
                  </a-button>
                </div>

                <div style="padding:0px 15px">
                  <el-form label-width="60px" label-position="left">
                    <el-form-item :label="'图' + (index + 1)">
                      <div @click="openImageSelector(index)">
                        <div class="upload-box" v-if="!item.imageUrl">
                          <a-icon type="plus" />
                        </div>
                        <img v-else :src="item.imageUrl" style="width: 100%;" />
                      </div>
                    </el-form-item>

                    <el-form-item label="链接">
                      <a-input placeholder="请输入链接" v-model="item.link" />
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>

            <div>
              <a-button type="primary" @click="insterLink" block>
                添加
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 公共图片 -->
    <HIMAGE ref="HImage" @imageCallback="imageCallback"></HIMAGE>
  </div>
</template>

<script>
import HIMAGE from "@/components/IMAGE/index.vue";

export default {
  components: {
    HIMAGE
  },
  props: ["attr"],
  data() {
    return {
      selectedIndex: undefined
    };
  },

  methods: {
    /**
     * 添加连接
     */
    insterLink() {
      this.attr.list.push({
        imageUrl: undefined,
        link: undefined
      });
    },

    /**
     * 删除
     */
    deleteLink(item) {
      // 确保至少保留一个元素
      if (this.attr.list.length <= 1) {
        this.$message({
          showClose: true,
          message: "至少保留一个！"
        });
        return;
      }

      var index = this.attr.list.indexOf(item);
      if (index !== -1) {
        this.attr.list.splice(index, 1);
      }
    },

    openImageSelector(index) {
      this.selectedIndex = index;

      this.$refs.HImage.handleAdd();
    },

    /**
     * 图片选择
     */
    imageCallback(row) {
      // this.attr.list[this.selectedIndex].imageUrl = row.imgUrl;

      this.$set(this.attr.list, this.selectedIndex, { imageUrl: row.imgUrl });

      this.$forceUpdate();
    }
  }
};
</script>

<style></style>
