<template>
  <div>
    <!-- 样式1 -->
    <div class="item-style1" v-show="module.base.s == 0">
      <Index1 :module="module" />
    </div>
  </div>
</template>

<script>
import Index1 from "./index_1.vue";

export default {
  props: ["module"],
  components: { Index1 },
  data() {
    return {
      nav: [],
    };
  },
  computed: {},
};
</script>
