<template>
  <div class="module-common">
    <div class="header_1_style" style="background-color: #ffffff;">
      <div class="top-common-box-new">
        <div class="top-common-new">
          <div class="top-common-new-left">
            <div class="txt">
              <a href="/">
                <a-icon type="home" theme="filled" /> 首页
              </a>
            </div>
          </div>
          <div class="top-common-new-right">
            <div class="avaBox">
              <a href="#">登录/注册 </a>
            </div>

            <ul class="menu-ul">
              <li>
                <span>我的订单<i class="icon-com"></i></span>
              </li>

              <li>
                <span>联系客服<i class="icon-com"></i></span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="topBoxCommon">
        <div class="hearder-box-new">
          <div class="header-new">
            <a-avatar
              shape="square"
              :size="52"
              :style="{ backgroundColor: '#f56a00', verticalAlign: 'middle' }"
            >
              LOGO
            </a-avatar>
              <div  class="main-search-box">
                <a-input placeholder="请输入搜索关键词" style="border: 1px solid #ff6600;"/>
                <div class="main-search-hotwords">
                  <span class="tit">热门搜索：</span>
                  <a class="tis_value">爱奇艺</a>
                  <a class="tis_value">腾讯视频</a>
                  <a class="tis_value">芒果TV</a>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="newnav-new">
        <ul class="clearfix">

          <a>
            <li class="on">
              <span>首页</span>
            </li>
          </a>

          <a v-for="(item, index) in module.list">
            <li class="on">
              <span> {{ item.name }}</span>
            </li>
          </a>

        </ul>
        <!-- <a href="#">
            <div class="dhq-download">
              <img src="" width="40"
                class="download-icon" />
              <span>开通分站</span>
              <img src="" width="12" />
            </div>
          </a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
    };
  },
  computed: {},
  methods: {}
};
</script>

<style>
/* 新头部样式 */
.header_1_style .top-common-box-new {
  background: #f8f8f8;
  font-family: microsoft yahei, PingFang SC;
}

.header_1_style .top-common-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 0 auto;
}

.header_1_style .top-common-new .top-common-new-left,
.header_1_style .top-common-new .top-common-new-right {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
}

.header_1_style .top-common-new .top-common-new-left .txt {
  color: #666666;
  margin-left: 11px;
}

.header_1_style .top-common-new .top-common-new-right span,
a {
  cursor: pointer;
}

.header_1_style .top-common-new .top-common-new-right .avaBox {
  margin-right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header_1_style .top-common-new .top-common-new-right .avaBox a {
  margin-left: 6px;
}

.header_1_style .top-common-new .top-common-new-right .makeBox {
  margin-right: 41px;
}

.header_1_style .top-common-new .top-common-new-right .makeBox em {
  color: #ff4436;
  cursor: pointer;
  margin-right: 26px;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul {
  display: flex;
  align-items: center;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li {
  margin-right: 39px;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li::before {
  width: 1px;
  height: 12px;
  background: #ececec;
  position: absolute;
  right: -18px;
  content: "";
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .icon-com {
  /* background-image: url(../images/common-new/arrow-down-icon.png); */
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  margin-left: 2px;
  transition: 0.2s;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list {
  display: none;
  position: absolute;
  width: 180px;
  padding: 8px;
  border: #ececed 1px solid;
  top: 40px;
  right: -18px;
  background: #fff;
  z-index: 99999;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  box-sizing: border-box;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li:hover .top-menu-list {
  display: block;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li:hover .icon-com {
  /* background-image: url(../images/common-new/arrow-up-icon.png); */
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  margin-left: 2px;
  transform: rotate(360deg);
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list a {
  display: block;
  height: 44px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  color: #222;
  font-size: 13px;
  border-radius: 8px;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list a .hov {
  display: none;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list a img {
  margin-right: 6px;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list a:hover {
  background: #f7f9fa;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list a:hover .nom {
  display: none;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li .top-menu-list a:hover .hov {
  display: block;
}

.header_1_style .top-common-new .top-common-new-right .menu-ul li:hover span {
  color: #ff4436;
}

.header_1_style .top-common-box-new .tip-member-box {
  height: 35px;
  line-height: 35px;
}

.header_1_style .top-common-box-new .tip-member-box .tip-member-area {
  position: absolute;
  top: 35px;
  left: 50%;
  display: none;
  z-index: 99999;
  transform: translateX(-50%);
}

.header_1_style .header-box-new {
  border-bottom: none;
}

.header_1_style .header-new {
  margin: 0px 10px;
  display: flex;
  justify-content: space-between;
  /* height: 100px;
  align-items: center; */
}


.header_1_style .header-new .main-search-box {
  float: none;
  margin-top: 0px;
  /* border: 4px solid #fcf2f6; */
  border-radius: 22px;
  width: 660px;
  margin-right: 0px;
}

.header_1_style .header-new .main-search-box .main-search-top {
  border-radius: 22px;
  border: 1px solid #ff6600;
  height: 38px;
  width: 660px;
}

.header_1_style .header-new .main-search-box .main-search-top .main-search-item {
  height: 38px;
  line-height: 38px;
  padding: 0 16px;
}

.header_1_style .header-new .main-search-box .main-search-input input {
  width: 128px;
  height: 38px;
}

.header_1_style .header-new .main-search-item em.iconfont {
  font-size: 20px;
}

.header_1_style .header-new .main-search-item i {
  top: 14px;
  height: 12px;
}

.header_1_style .header-new .main-search-item p {
  font-size: 12px;
  color: #222;
}

.header_1_style .header-new .main-search-button {
  width: 56px;
  height: 32px;
  margin-top: 3px;
  margin-right: 5px;
}

.header_1_style .header-new .main-search-button a {
  height: 32px;
  line-height: 27px;
  border-radius: 20px;
}

.header_1_style .header-new .main-search-con {
  top: 48px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #eeeeee;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  width: 998px;
  box-sizing: border-box;
  left: -334px;
}

.header_1_style .header-new .mainsearch-item .mainsearch-item-top {
  height: 46px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: microsoft yahei, PingFang SC;
}

.header_1_style .header-new .seaname .mainsearch-item-top ul.gametype {
  display: flex;
  align-items: center;
  float: none;
  margin: 0px;
  padding-left: 29px;
}

.header_1_style .header-new .seaname .mainsearch-item-top ul.gametype li {
  font-size: 13px;
  color: #333;
  width: auto;
  margin-right: 30px;
  height: 46px;
  line-height: 46px;
}

.header_1_style .header-new .seaname .mainsearch-item-top ul.gametype li.on {
  color: #ff4436;
  font-weight: 600;
  background: transparent;
}

.header_1_style .header-new .seaname .mainsearch-item-top .seaname-top-right {
  float: none;
  margin: 0px;
}

.header_1_style .header-new .seaname .mainsearch-item-top .seaname-top-right .seaname-sea {
  margin-right: 19px;
  border-radius: 17px;
  border: 1px solid #eeeeee;
}

.header_1_style .header-new .seaname .mainsearch-item-top .seaname-top-right .seaname-sea input {
  height: 30px;
  line-height: 30px;
  border-radius: 17px;
  width: 120px;
  padding: 0px 10px 0 5px;
}

.header_1_style .header-new .seaname .mainsearch-item-top .seaname-top-right .seaname-sea img {
  padding-right: 0px;
}

.header_1_style .header-new .seaname .mainsearch-item-bot .az-list ul {
  height: 46px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.header_1_style .header-new .seaname .mainsearch-item-bot .az-list ul li {
  padding: 0px;
  width: 24px;
  height: 24px;
  border-radius: 14px;
  font-size: 12px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_1_style .header-new .seaname .mainsearch-item-bot .az-list ul li.on {
  border: none;
  background: #ff4436;
  color: #fff;
  padding: 0px;
}

.header_1_style .header-new .seaname .mainsearch-item-bot .az-list ul li.hot-tags {
  margin: 0;
  width: 44px;
  padding: 0px;
  margin-right: 5px;
}

.header_1_style .header-new .seaname .mainsearch-item-bot .az-list ul li.all-tags {
  margin: 0;
  width: 44px;
  padding: 0px;
  margin-right: 21px;
  position: relative;
}

.header_1_style .header-new .seaname .mainsearch-item-bot .az-list ul li.all-tags::before {
  content: "";
  height: 12px;
  width: 1px;
  background: #eee;
  position: absolute;
  right: -10px;
  top: 6px;
}

.header_1_style .header-new .mainsearch-item-bot .mainsearch-list {
  min-height: 40px;
  padding: 18px 33px 14px 45px;
  overflow: hidden;
}

.header_1_style .header-new .mainsearch-item-bot .mainsearch-list ul {
  height: 330px;
  width: 962px;
  overflow: hidden;
  overflow-y: scroll;
}

.header_1_style .header-new .mainsearch-item-bot .mainsearch-list ul li {
  font-size: 14px;
  color: #666;
  font-family: microsoft yahei, PingFang SC;
  width: 140px;
  box-sizing: border-box;
  margin-bottom: 13px;
  padding-left: 10px;
  /* background: url(../images/index-new/arrow-left.png) left 8px no-repeat; */
  background-size: 4px auto;
  margin-right: 12px;
}

.header_1_style .header-new .mainsearch-item-bot .mainsearch-list ul li span {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  display: inline-block;
}

.header_1_style .header-new .mainsearch-item-bot .mainsearch-list ul li:hover {
  color: #ff4436;
  transform: translateX(5px);
  /* background: url(../images/index-new/arrow-left-on.png) left 8px no-repeat; */
  background-size: 4px auto;
}

.header_1_style .header-new .mainsearch-item-bot .mainsearch-list ul li img {
  vertical-align: top;
}

.header_1_style .header-new .mainsearch-item .mainsearch-item-top .tt-all li.tt {
  color: #333;
}

.header_1_style .header-new .mainsearch-item .mainsearch-item-top .tt-all li.alls {
  color: #333;
}

.header_1_style .header-new .mainsearch-item .mainsearch-item-top .tt-all li.line {
  color: #666;
}

.header_1_style .header-new .main-input-bottom {
  display: none;
  position: absolute;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 668px;
  right: 0;
  top: 48px;
  padding: 4px 16px;
  box-sizing: border-box;
}

.header_1_style .header-new .main-input-bottom li {
  font-size: 14px;
  font-family: microsoft yahei, PingFang SC;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  height: 40px;
}

.header_1_style .header-new .main-input-bottom li:last-child {
  border: none;
}

.header_1_style .header-new .main-input-bottom li:hover a {
  color: #ff4436;
}

.header_1_style .header-new .main-input-bottom li img {
  margin-left: 10px;
}

.header_1_style .header-new .main-input-bottom li i {
  color: #ff4436;
}

.header_1_style .newnav-new {
  margin: 0px 10px;
  font-family: microsoft yahei, PingFang SC;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 15px;
}

.header_1_style .newnav-new ul {
  display: flex;
  align-items: center;
  position: relative;
}

.header_1_style .newnav-new ul li {
  margin-right: 70px;
  position: relative;
  z-index: 2;
}

.header_1_style .newnav-new ul .bg-img {
  position: absolute;
  bottom: -7px;
  display: none;
  z-index: 1;
  opacity: 0;
}

.header_1_style .newnav-new ul li img {
  position: absolute;
  top: -2px;
  right: -28px;
}

.header_1_style .newnav-new ul li {
  color: #333333;
  font-size: 16px;
}

.header_1_style .newnav-new ul li.on span {
  color: #222222;
  font-weight: 600;
  transform: scale(1.1);
}

.header_1_style .newnav-new ul li:hover span {
  color: #222222;
  font-weight: 600;
  transform: scale(1.1);
}

.header_1_style .newnav-new-border {
  width: 100%;
  height: 1px;
  background: #f5f8fa;
}

.header_1_style .newnav-new ul li.on::before {
  content: "";
  width: 100%;
  width: 31px;
  height: 15px;
  /* background: url(../images/common-new/on-icon.png) no-repeat; */
  background-size: 100% 100%;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.header_1_style .newnav-new .red-packet-tip {
  position: absolute;
  top: -18px;
  left: 105px;
}

.header_1_style .newnav-new .red-packet-tip {
  background: -webkit-linear-gradient(left, #ff4436, #fca534);
  background: -o-linear-gradient(left, #ff4436, #fca534);
  background: -moz-linear-gradient(left, #ff4436, #fca534);
  background: linear-gradient(left, #ff4436, #fca534);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#FF4436, endColorstr=#fca534);
  padding: 2px 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 11px;
  font-family: microsoft yahei, PingFang SC;
}

.header_1_style .newnav-new .red-packet-tip .triangle {
  width: 0;
  height: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 4px solid #fe8418;
  position: absolute;
  bottom: -4px;
  left: 22px;
}

.header_1_style .newnav-new .dhq-download {
  height: 36px;
  width: 150px;
  border-radius: 20px;
  background: #f5f8fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 12px 0px 48px;
  box-sizing: border-box;
  position: relative;
  color: #222;
  font-size: 14px;
  font-weight: 600;
}

.header_1_style .newnav-new .dhq-download .download-icon {
  position: absolute;
  left: 4px;
  bottom: 2px;
}

.header_1_style .newnav-new .dhq-download:hover {
  background: #edf2f5;
}

.header_1_style .newnav-new .dhq-download:hover img:last-child {
  /* content: url(../images/common-new/download-a-icon-on.png); */
  width: 12px;
}






.header_1_style .main-search-item em.iconfont.on {
  transform: rotate(180deg);
  color: #FF4436;
  top: 9px;
}

.header_1_style .main-search-item i {
  position: absolute;
  right: 0;
  top: 8px;
  display: block;
  width: 1px;
  height: 20px;
  border-left: #eee 1px solid
}

.header_1_style .main-search-input {
  float: left;
  padding-left: 10px
}

.header_1_style .main-search-input input {
  width: 203px;
  height: 36px;
  border: none;
  background: transparent;
}

.header_1_style .main-search-input input::-webkit-input-placeholder {
  color: #aaa !important;
  font-weight: 400
}

.header_1_style .main-search-input input:-moz-placeholder {
  color: #aaa !important;
  font-weight: 400
}

.header_1_style .main-search-input input::-moz-placeholder {
  color: #aaa !important;
  font-weight: 400
}

.header_1_style .main-search-input input:-ms-input-placeholder {
  color: #aaa !important;
  font-weight: 400
}

.header_1_style .main-search-button {
  float: right;
  width: 100px;
  height: 36px
}

.header_1_style .main-search-button a {
  display: block;
  width: 100%;
  height: 36px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: microsoft yahei, PingFang SC;
  background: #FF4436
}

.header_1_style .main-search-button a:hover {
  background: #f70
}

.header_1_style .main-search-bottom {
  display: none
}

.header_1_style .main-search-hotwords {
  color: #aaa;
  margin-top: 8px;
  font-size: 0
}

.header_1_style .main-search-hotwords .tit {
  margin-left: 5px;
  font-size: 12px;
  margin-right: 10px
}

.header_1_style .main-search-hotwords a {
  color: #aaa;
  font-size: 12px;
  margin-right: 14px
}

.header_1_style .main-search-hotwords a:hover {
  color: #222
}

.header_1_style .main-search-arrow {
  width: 9px;
  height: 6px;
  position: absolute;
  left: 60px;
  top: 48px;
  z-index: 10
}

.header_1_style .main-search-con {
  position: absolute;
  left: -480px;
  top: 60px;
  width: 1186px;
  border: #FF4436 2px solid;
  border-top: none;
  background: #fff
}

.header_1_style .mainsearch-item .mainsearch-item-top {
  height: 40px;
  background: #FF4436;
  width: 100%
}

.header_1_style .mainsearch-item .mainsearch-item-top .tt-all {
  margin-left: 42px
}

.header_1_style .mainsearch-item .mainsearch-item-top .tt-all li {
  float: left;
  font-size: 14px;
  font-family: microsoft yahei, PingFang SC;
  line-height: 40px
}

.header_1_style .mainsearch-item .mainsearch-item-top .tt-all li.line {
  color: #ff8533;
  margin: 0 18px
}

.header_1_style .mainsearch-item .mainsearch-item-top .tt-all li.tt {
  color: #fff;
  font-weight: 700
}

.header_1_style .mainsearch-item .mainsearch-item-top .tt-all li.alls {
  color: #fffc00;
  cursor: pointer
}
</style>
