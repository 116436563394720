<template>
  <div class="module-common" :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]">
    <!-- 首页分类栏目 rows -->

    <div class="mgoods3_index3">
      <div class="more-list">
        <div class="more-item" v-for="(item, key, index) in catesList" :key="index">
          <div class="title">
            <div class="img-box">
              <div style="width: 20px; height: 20px; margin-right: 3px">
                <van-image round :src="item.prcUrl" lazy-load />
              </div>
              <span class="title-header">
                {{ item.categoryName }}
              </span>
            </div>

            <router-link to="/cate">
              <span class="title-text"><span>查看更多&gt;</span></span>
            </router-link>
          </div>
          <div class="more-item-content">

            <a-row :gutter="5">
              <a-col class="gutter-row" :span="8" v-for="(goods, key, index) in item.children.slice(0, 6)" :key="index">
                <div class="catalogue-box" @click="toProductList(goods)">
                  <div class="xnj-image" style="margin-left: 5px;">
                    <van-image round :src="goods.prcUrl" class="recommend_img" lazy-load />
                  </div>
                  <div class="detail">
                    <span class="detail-name">
                      <span> {{ goods.categoryName }} </span>
                    </span>
                    <span class="title-text">
                      <span>立即购买</span>
                    </span>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from "vuex";

  export default {
    props: ["module"],
    components: {},
    data() {
      return {
        catesList: [],
        showSelectGoods: false,
      };
    },

    computed: {
      ...mapGetters({}),
      ...mapState({
        producCategorytList: (state) => state.index.producCategorytList,
      }),
    },
    mounted() { },
    created() {
      //查收商品分类
      this.getCateList();
    },
    methods: {
      /**
       * 查询商品分类
       */
      getCateList() {
        // 首页tab商品
        let data = { offset: 0, limit: 10 };
        this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
          this.catesList = this.producCategorytList.list;
        });
      },

      /**
       * 跳转到商品列表
       */
      toProductList(value) {
        console.log(value);
        this.$router.push({
          path: "product_list",
          query: { categoryId: value.id, categoryName: value.categoryName },
        });
      },
    },
  };
</script>

<style>
  .mgoods3_index3 {
    /* padding: 0 0.5625rem; */
  }

  .mgoods3_index3 .more-list {
    margin-top: 0.625rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    gap: 0.625rem;
  }

  .mgoods3_index3 .more-list .more-item {
    background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
    border-radius: 0.46875rem;
    padding: 0.59375rem 0.5rem;
  }

  .mgoods3_index3 .more-list .more-item .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mgoods3_index3 .more-list .more-item .title .img-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.875rem;
    color: #191e2e;
    font-weight: 550;
    gap: 0.15625rem;
  }

  .mgoods3_index3 .more-list .more-item .title .title-text {
    font-size: 0.78125rem;
    font-weight: 400;
    color: #9b9fa8;
  }

  .mgoods3_index3 .more-list .more-item .title .title-text:active {
    color: #fc3510;
  }

  .mgoods3_index3 .more-list .more-item .more-item-content {
    margin-top: 0.78125rem;
    gap: 0.4375rem;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .mgoods3_index3 .more-list .more-item .more-item-content .catalogue-box {
    height: 3.90625rem;
    background: #ffffff;
    box-shadow: 0 0.125rem 0.1875rem rgba(255, 128, 83, 0.14);
    border-radius: 0.46875rem;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-top: 10px;
  }

  .mgoods3_index3 .more-list .more-item .more-item-content .catalogue-box .detail {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    margin-left: 5px;
  }

  .mgoods3_index3 .more-list .more-item .more-item-content .catalogue-box .detail .detail-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 3.125rem;
    font-weight: 550;
    color: #191e2e;
    font-size: 0.75rem;
  }

  .mgoods3_index3 .more-list .more-item .more-item-content .catalogue-box .detail .title-text {
    font-weight: 400;
    color: #9b9fa8;
    font-size: 0.625rem;
  }

  .mgoods3_index3 .more-list .more-item .more-item-content .catalogue-box .detail .title-text:active {
    color: #fc3510;
  }

  .xnj-image {
    height: 2.8rem;
    border-radius: 50%;
  }

  .xnj-image img {
    width: 100%;
    height: 100%;
  }
</style>