<template>
  <div>
    <van-nav-bar
      left-text="返回"
      title="开通分站"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="indexContent">
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field
            label="分站名称"
            placeholder="请输入分站名称"
            v-model="fzForm.fzName"
            :rules="[{ required: true, message: '请输入分站名称' }]"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            label="绑定域名"
            placeholder="请输入域名"
            v-model="fzForm.domains"
            :rules="[{ required: true, message: '请输入域名' }]"
          >
            <template #button>{{ fzForm.domainsAfter }}</template>
          </van-field>
        </van-cell-group>

        <van-cell-group>
          <van-field
            label="管理账号"
            placeholder="请输入管理账号"
            v-model="fzForm.userName"
            :rules="[{ required: true, message: '请输入管理账号' }]"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            label="管理密码"
            placeholder="请输入管理密码"
            v-model="fzForm.password"
            :rules="[{ required: true, message: '请输入管理密码' }]"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field label="分站价格" v-model="fzForm.fzPrice" readonly>
            <template #button>元</template>
          </van-field>
        </van-cell-group>

        <van-cell-group>
          <van-collapse v-model="activeNames">
            <van-collapse-item title="分站功能" name="1">
              <div v-for="o in fzObject" :key="o" style="font-size: 15px">
                <i class="el-icon-circle-check"></i>&nbsp;{{ o.title }}
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-cell-group>

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submitForm"
            >确认开通</van-button
          >
        </div>
      </van-form>
    </div>

    <!-- 弹框提示 -->
    <van-dialog
      v-model="dialogOpenVisible"
      @confirm="onConfirm"
      confirmButtonText="确认"
    >
      <div style="padding: 10px">
        <div style="text-align: center; padding: 15px">
          <span style="font-size: 15px; font-weight: 600"
            >恭喜您开通成功,请保存以下信息!</span
          >
        </div>
        <van-cell-group>
          <van-cell title="我的商城地址" :value="'http://' + fzAddress" />
          <van-cell
            title="我的商城后台地址"
            :value="'http://' + fzAddress + 'fz/basic'"
          />
          <van-cell title="管理员账号" :value="userName" />
          <van-cell title="管理员密码" :value="password" />
        </van-cell-group>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { createFz } from "@/api/vip";
import { getDomain } from "@/api/index";
import { Toast } from "vant";
import { mapState } from "vuex";

export default {
  data() {
    return {
      activeNames: ["1"],
      dialogOpenVisible: false,
      fzAddress: "",
      userName: "",
      password: "",
      fzObject: [
        { title: "轮播图自定义" },
        { title: "商品标题修改" },
        { title: "商品图片修改" },
        { title: "商品价格修改" },
        { title: "商品内容修改" },
        { title: "允许查看订单" },
        { title: "继承主站价格体系" },
      ],
      fzForm: {
        fzName: "",
        domains: "",
        domainsAfter: "",
        fzPrice: "0",
        userName: "",
        password: "",
      },
      rules: {
        fzName: [
          { required: true, message: "请输入分站名称", trigger: "blur" },
          { min: 1, max: 20, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        domains: [
          { required: true, message: "请输入二级域名前缀", trigger: "change" },
        ],
        userName: [
          { required: true, message: "请输入管理账号", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入管理密码", trigger: "change" },
        ],
        // domainsAfter: [
        //   {
        //     required: true,
        //     message: "请至少选择一个后缀域名",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  computed: {
    ...mapState({
      branchId: (state) => state.user.branchId,
    }),
  },
  created() {
    // this.load();
    this.queryDomain();
  },
  methods: {
    /**
     * 查询主站后缀
     */
    queryDomain() {
      new Promise((resolve, rejust) => {
        getDomain()
          .then((res) => {
            this.fzForm.domainsAfter = res.result.domains;
            this.fzForm.fzPrice = res.result.fzPrice;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 创建分站
     */
    onSubmit() {
      createFz(this.fzForm).then((res) => {
        if (res.status == 0) {
          Toast.success(res.message || "开通失败");

          this.userName = res.result.userName;
          this.password = res.result.password;
          this.fzAddress = res.result.domains + "." + res.result.domainsAfter;

          this.dialogOpenVisible = true;
          this.resetForm();
        }
        // this.$store.dispatch("user/info");
      });
    },
    resetForm() {
      this.fzForm.fzName = "";
      this.fzForm.domains = "";
      this.fzForm.password = "";
      this.fzForm.userName = "";
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
.indexContent {
  padding: 10px;
}
</style>
