<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
      },
      { 'border-radius': module.base.round + 'px' }
    ]"
  >
    <div>
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        v-if="module.list.length > 0"
      >
        <van-swipe-item v-for="(item, index) in module.list" :key="index">
          <!-- <el-image :src="item.imageUrl" style="height: 80px;width: 100%;border-radius: 10px;">
          </el-image> -->

          <img :src="item.imageUrl" style="width: 100%;" />
        </van-swipe-item>
      </van-swipe>

      <template v-else>
        <a-empty
          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
        >
          <span slot="description"> 暂无数据 </span>
        </a-empty>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      navLists: [
        {
          categoryName: "腾讯视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1565395015.png"
        },
        {
          categoryName: "优酷视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
        },
        {
          categoryName: "PP视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        }
      ]
    };
  },
  computed: {}
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
  border-radius: 6px;
  margin: 10px auto 0;
  box-sizing: border-box;
  /* padding: 14px 10px 0; */
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.classify_item {
  padding: 10px;
  font-size: 13px;
  color: #3c3c3c;
  padding-bottom: 15px;
}

.classify_img {
  width: 38px;
  height: 38px;
  margin-bottom: 7px;
}

.classify_item.column {
  position: relative;
}

.classify_item ::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  width: 1px;
  /* 设置边线宽度 */
  background-color: #f7f8f8;
  /* 设置边线颜色 */
}
</style>
