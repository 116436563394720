<template>
  <div @click.stop>
    <div class="right-box" v-if="!this.attrData">
      <div style="display: flex;justify-content: center;align-items: center;">
        <el-empty description="点击组件可更改样式"></el-empty>
      </div>
    </div>

    <div class="right-box" v-else>
      <div class="mConfig-item" style="background-color: rgb(255, 255, 255);">
        <div class="mobile-config">
          <div class="setUpTop"></div>
          <div class="setUp">
            <div class="label">
              {{ this.attrData.name }}
            </div>
            <div class="setUp-title acea-row">
              <div class="item on">内容</div>
              <!-- <div class="item">样式</div> -->
            </div>
          </div>

          <div>
            <div class="setUpTop"></div>
            <div class="diy_right_title">展示设置</div>
          </div>
          <div class=" on mb5">
            <div class="c_row-item" style="padding: 0px 15px">
              <div class="c_label ivu-col ivu-col-span-4">
                选择风格
              </div>
              <div class="color-box ivu-col ivu-col-span-18">
                <el-radio-group
                  v-model="style_index"
                  @change="triggerParentMethod"
                >
                  <el-radio
                    :label="index"
                    v-for="(item, index) in this.attrData.base.sc"
                    :key="index"
                  >
                    {{ item.t }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.deleteStatus">
          <div class="setUpTop"></div>
          <div class="diy_right_title">内容设置</div>

          <!-- 搜索框 -->
          <AttrSearch :attr="attrData" v-if="attrData.type == 101" />

          <!-- 轮播图 -->
          <AttrBanner :attr="attrData" v-if="attrData.type == 102" />

          <!-- 推荐 -->
          <AttrRecommend :attr="attrData" v-if="attrData.type == 105" />

          <!-- 首页公告 -->
          <AttrNotice :attr="attrData" v-if="attrData.type == 106" />

          <!-- 广告 -->
          <AttrGuangGao :attr="attrData" v-if="attrData.type == 107" />

          <!-- 菜单 -->
          <AttrMenu :attr="attrData" v-if="attrData.type == 108" />

          <!-- 首页公告 -->
          <AttrCommon :attr="attrData" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "@/assets/css/attr.scss";

// 搜索框
import AttrSearch from "@/views/template/mobile/module/search/style/index.vue";

//轮播图
import AttrBanner from "@/views/template/mobile/module/banner/style/index.vue";

//推荐商品
import AttrRecommend from "@/views/template/mobile/module/recommend/style/index.vue";

//首页公告
import AttrNotice from "@/views/template/mobile/module/Notice/style/index.vue";

//广告
import AttrGuangGao from "@/views/template/mobile/module/guanggao/style/index.vue";

//菜单
import AttrMenu from "@/views/template/mobile/module/menu/style/index.vue";

export default {
  // props: ["attrData"],
  components: {
    AttrNotice,

    //搜索框
    AttrSearch,
    //轮播图
    AttrBanner,
    //推荐
    AttrRecommend,
    //广告
    AttrGuangGao,
    //菜单
    AttrMenu
  },
  data() {
    return {
      attrData: [],
      style_index: undefined,
      deleteStatus: true
    };
  },

  mounted() {},
  methods: {
    //点击模块
    childMethod(data) {
      this.deleteStatus = true;

      this.attrData = data;
      this.style_index = data.base.s;
      this.$forceUpdate();
    },

    //隐藏模块
    deleteMethod() {
      this.deleteStatus = false;
    },

    triggerParentMethod(k) {
      // 通过 $emit 向父组件发送事件
      // console.log(JSON.stringify(this.attrData))
      this.$emit("child-right", JSON.stringify(this.attrData), k);
    }
  }
};
</script>
