var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    },
    { 'border-radius': _vm.module.base.round + 'px' }
  ])},[_c('div',[(_vm.module.list.length > 0)?_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":3000,"indicator-color":"white"}},_vm._l((_vm.module.list),function(item,index){return _c('van-swipe-item',{key:index},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.imageUrl}})])}),1):[_c('a-empty',{attrs:{"image":"https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"}},[_c('span',{attrs:{"slot":"description"},slot:"description"},[_vm._v(" 暂无数据 ")])])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }