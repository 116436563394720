import request from '@/utils/request'


/**
 * 获取加载系统公告
 * @param {*} data 
 * @returns 
 */
export function queryNewsList(data) {
    return request({
        url: '/api/news/list',
        method: 'post',
        data
    })
}


/**
 * 获取系统推荐公告
 * @param {*} data 
 * @returns 
 */
export function queryNewsRecommendList(data) {
    return request({
        url: '/api/news/recommend/list',
        method: 'post',
        data
    })
}


/**
 * 获取加载系统详情
 * @param {*} data 
 * @returns 
 */
export function queryNewsDetail(data) {
    return request({
        url: '/api/news/detail',
        method: 'post',
        data
    })
}