<template>
    <div>

        <div style="padding:10px 15px;">
            <div class="list-box">

                <a-row>
                    <a-col :span="6">
                        <span style="display: flex;align-items: center;height: 30px;font-size: 12px;color: #999;">
                            提示文字
                        </span>
                    </a-col>

                    <a-col :span="18">
                        <a-input v-model="attr.style.text" />
                    </a-col>
                </a-row>


                <a-row style="margin-top: 20px;">
                    <a-col :span="6">
                        <span style="display: flex;align-items: center;height: 30px;font-size: 12px;color: #999;">
                            背景颜色
                        </span>
                    </a-col>

                    <a-col :span="18">

                        <el-color-picker v-model="attr.style.bgcolor"> </el-color-picker>

                    </a-col>
                </a-row>
            </div>
        </div>

        <!-- 公共图片 -->
        <HIMAGE ref="HImage" @imageCallback="imageCallback"></HIMAGE>
    </div>
</template>

<script>

import HIMAGE from "@/components/IMAGE/index.vue";

export default {
    components: {
        HIMAGE
    },
    props: ["attr"],
    data() {
        return {
            selectedIndex: undefined
        };
    },

    methods: {

        /**
         * 添加连接
         */
        insterLink() {
            this.attr.list.push({
                imageUrl: undefined,
                link: undefined
            });
        },

        /**
         * 删除
         */
        deleteLink(item) {
            var index = this.attr.list.indexOf(item);
            if (index !== -1) {
                this.attr.list.splice(index, 1);
            }
        },

        openImageSelector(index) {

            this.selectedIndex = index;

            this.$refs.HImage.handleAdd();
        },

        /** 
        * 图片选择
        */
        imageCallback(row) {

            // this.attr.list[this.selectedIndex].imageUrl = row.imgUrl;

            this.$set(this.attr.list, this.selectedIndex, { imageUrl: row.imgUrl });

            this.$forceUpdate()
        },
    }
};
</script>