var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common"},[_c('div',{staticClass:"plane"},[_c('div',{staticClass:"banner_left",style:([
        { backgroundColor: _vm.module.style.bgcolor },
        { color: _vm.module.style.color }
      ])},[_vm._l((_vm.catesList),function(item,key,index){return _c('div',{key:index,staticClass:"item",on:{"mouseenter":function($event){return _vm.showProducts(item.children)},"mouseleave":_vm.hideProducts}},[_c('div',{staticClass:"item-title"},[_vm._m(0,true),_vm._v(" "+_vm._s(item.categoryName)+" ")]),_c('div',{staticClass:"item-list"},_vm._l((item.children),function(value,key,index){return _c('span',{key:index,staticClass:"link",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(value.categoryName)+" ")])}),0)])}),_c('div',{staticClass:"more"},[_c('el-button',[_vm._v("查看全部")])],1)],2),_c('div',{staticClass:"banner"},[(_vm.module.list.length > 0)?[_c('el-carousel',_vm._l((_vm.module.list),function(item,index){return _c('el-carousel-item',{key:index},[_c('el-image',{staticStyle:{"border-radius":"10px","width":"100%","height":"100%"},attrs:{"src":item.imageUrl}})],1)}),1)]:[_c('el-image',{staticStyle:{"height":"100%","width":"100%","border-radius":"10px"}})]],2),_c('div',{staticClass:"banner_right"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"el-image avatar"},[_c('div',{staticClass:"el-image"},[_c('a-avatar',{attrs:{"size":64,"icon":"user"}})],1)]),_vm._m(1),_c('div',{staticClass:"login-box"},[_c('el-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("个人中心")]),_c('el-button',{attrs:{"size":"small"}},[_vm._v("退出登录")])],1)]),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-image icon"},[_c('i',{staticClass:"el-icon-goods"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-name"},[_c('span',[_vm._v("您好，欢迎使用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-enjoy"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"befor"}),_vm._v(" 登录即享 "),_c('div',{staticClass:"after"})]),_c('div',{staticClass:"icon-show"},[_c('div',{staticClass:"item"},[_c('i',{staticClass:"el-icon-goods index-icon"}),_c('div',{staticClass:"name"},[_vm._v("专属折扣")])]),_c('div',{staticClass:"item"},[_c('i',{staticClass:"el-icon-goods index-icon"}),_c('div',{staticClass:"name"},[_vm._v("专属优惠")])]),_c('div',{staticClass:"item"},[_c('i',{staticClass:"el-icon-goods index-icon"}),_c('div',{staticClass:"name"},[_vm._v("优惠特权")])]),_c('div',{staticClass:"item"},[_c('i',{staticClass:"el-icon-goods index-icon"}),_c('div',{staticClass:"name"},[_vm._v("低价预定")])])])])
}]

export { render, staticRenderFns }