<template>
  <div
    class="mobile-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
      { 'border-radius': module.base.round + 'px' },
    ]"
  >
    <div>
      <div>
        <div class="group-wrapper">
          <div class="hd">
            <div class="left">
              <span class="iconfont icon-hot3"><span></span></span>
              <div class="name">近期降价</div>
            </div>

            <router-link to="/mpriceLog">
              <span style="font-size: 0.8rem; font-weight: 400; color: #999"
                >查看更多<van-icon name="arrow" />
              </span>
            </router-link>
          </div>
          <div class="group-scroll">
            <vue-seamless-scroll
              :data="tableData"
              class="seamless-warp"
              :class-option="optionLeft"
            >
              <div class="scroll-view_H">
                <div>
                  <div style="overflow: auto hidden">
                    <div>
                      <!---->
                      <div
                        class="group-item"
                        v-for="(item, index) in tableData"
                        :key="index"
                        style="margin: 5px"
                        @click="toProductDetails(item.productId)"
                      >
                        <div
                          class="info"
                          style="align-items: center; text-align: center"
                        >
                          <div
                            style="
                              border: 1px solid rgba(0, 0, 0, 0.08);
                              height: 80px;
                              width: 80px;
                            "
                          >
                            <van-image
                              style="width: 78px; height: 78px"
                              radius="5"
                              :src="item.productImg"
                              lazy-load
                            />
                          </div>
                          <div class="name line1" style="color: #9b9fa8">
                            {{ item.productName }}
                          </div>
                          <!-- <div class="price-box">
                            <span class="tips"><span>最新</span></span>
                            <span class="pricelog-price">
                              <span>
                                <span style="font-size: 11px">
                                  <span>￥</span>
                                </span>
                                {{ item.priceAfter }}
                              </span>
                            </span>
                          </div> -->
                        </div>
                        <div class="bom-btn">
                          <span>
                            <img
                              src="@/assets/image/jiang.png"
                              style="width: 18px"
                            />
                            {{ item.priceAfter }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="scrollNew">
                <div
                  class="scrollWaper"
                  v-for="(item, index) in tableData"
                  :key="index"
                >
                  <router-link :to="{ path: '/detail/' + item.id }">
                    <div class="scrollimg">
                      <img :src="item.productImg" alt="" />
                    </div>
                    <div class="scrollName">{{ item.productName }}</div>
                    <div class="scrollMoney">
                      <div style="display: flex">
                        <div>{{ item.priceAfter }}</div>
                      </div>
                      <div class="addCard">+</div>
                    </div>
                  </router-link>
                </div>
              </div> -->
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryProductMonitorLog } from "@/api/product";

// import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  props: ["module"],

  components: {
    // vueSeamlessScroll,
  },

  data() {
    return {
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      optionLeft: {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        autoPlay: false,
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 0, // 单步运动停止的时间(默认值1000ms)}
      },
    };
  },

  created() {
    this.initData();
  },

  methods: {
    /**
     * 查询卡组信息
     */
    initData() {
      var then = this;
      new Promise((resolve, rejust) => {
        queryProductMonitorLog(this.queryParam)
          .then((res) => {
            then.tableData = res.result.list;
            then.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },

    toProductDetails(id) {
      this.$router.push(`/product_detail/${id}`);
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.group-wrapper {
  padding: 11px 11px 2px 5px;
  background: #fff;
  margin: 8px 0px 0px;
}

.group-wrapper .hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-wrapper .hd .left {
  display: flex;
  align-items: center;
}

.group-wrapper .hd .left .iconfont {
  color: #e93323;
  font-size: 16px;
  margin-right: 6px;
}

.group-wrapper .hd .left .name {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.group-wrapper .hd .more {
  font-size: 14px;
  color: #999;
}

.group-wrapper .hd .more .iconfont {
  font-size: 16px;
}

.group-wrapper .group-scroll {
  width: 100%;
  margin-top: 6px;
}

.group-wrapper .group-scroll .scroll-view_H {
  white-space: nowrap;
  width: 100%;
}

.group-wrapper .group-scroll .group-item {
  display: inline-block;
  width: 80px;
  /* box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.03); */
  border-radius: 8px;
  position: relative;
  background-color: #fff;
}

.group-wrapper .group-scroll .group-item uni-image {
  width: 100%;
  height: 92px;
  border-radius: 8px 8px 0 0;
}

.group-wrapper .group-scroll .group-item .info {
  padding: 1px 0px 5px 0px;
}

.group-wrapper .group-scroll .group-item .info .name {
  font-size: 12px;
  color: #9b9fa8;
  margin-top: 4px;
}

.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-wrapper .group-scroll .group-item .info .price-box {
  display: flex;
  align-items: center;
  margin-top: 3px;
  justify-content: space-around;
}

.group-wrapper .group-scroll .group-item .info .price-box .tips {
  background-color: rgba(233, 51, 35, 0.1);
  color: #e93323;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 16px;
  margin-right: 3px;
  border-radius: 2px;
  font-size: 9px;
}

.group-wrapper .group-scroll .group-item .info .price-box .pricelog-price {
  color: #e93323;
  font-size: 13px;
  font-weight: 700;
}

.group-wrapper .group-scroll .group-item .bom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 26px;
  border-radius: 0 0 8px 8px;
  color: #fff;
  font-size: 13px;
  background-color: #38b616;
}

.group-wrapper .group-scroll .group-item .bom-btn .iconfont {
  margin-right: 5px;
}

/* 默认两行展示我给他写了样式规定了高度使他只能展示一行 */
.seamless-warp {
  width: 100%;
  /* height: 3.2rem; */
  overflow: hidden;
  /* margin-top: 0.2rem; */
  margin: 0 auto;
  background: #ffffff;
}
</style>
