<template>
  <!-- <div class="module-common pc-common">
    <div class="model_guanggao">
      <el-row :gutter="10">
        <el-col
          v-for="(item, index) in module.list"
          :key="index"
          :span="24 / module.max"
        >
          <div class="model_guanggao_img">
            <div class="btn-wrap">
              <a :href="item.link" target="_blank">
                <img :src="item.imageUrl" class="model_guanggao_Banner" />
              </a>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div> -->
  <div class="module-common pc_style">

    <div class="tuwen_1_style pc_style">
      <div class="body-container-grid">
        <div class="img-item" style="border-radius: 4px; padding-top: 41.67%;">
          <div class="category-nav-default"><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAYAAAAdx42aAAAAAXNSR0IArs4c6QAAAwBJREFUSA3FV91LFFEUv+fO2rrr7lZQkT3Uk88RBNWuEfsaSURgukJRL/0D/QH7GEIPvidRoYEEQVIhlX2gIkKkkaFpH2JBZFS6rjuzu3NP92zcZeY6s5ru2ryce373nPP7zZ37NdAzajYBihYUGGNb+ACDJTR4P/8f5PSeyDAGtmjhW/LmyPLA+BznsOgcZBLBnUAt2pyzr3Y03JVKhG5MD3R2MW4MOXlqLsBAeHj+IGSJNJ1Oi5mBq4MM4JcSUXMBkd2hH4pMiWAofiqs5gKWvmUOKDKyfZO4DYDvVdiaAjhwO8D4oAxElfQvVvDAqb6h3H7K6R5aiBYXc2cRsUHVCKiGn0VRnD7XHH3ZO2oGmW0n/OL8cJrpBcBLvcPZgnyDOknuCl1zBDBQ95oyMoHgc7mMyt/OVWUdDpF7hVUUIHerzMyR4AdKvHwYCozBfa8im8EqCkAuJtIAQhG0Hwt9Bs5eKb8atqIAMMW4TmKZ4cfAMKPjG/X9BQCbTyVjrjVMJBeTYDIQDzZKqOf5CjCAlSafnkB+Kh6bMgAmvfp0jM6ACIavyfkzpfeR7ykAGCvEdoUrEnBr+ZFcFTmvogrjABPv86O3TzdDhon8mMKd1lsAwLuTTWA5A/V2a3LPshBiQMeVL+fus/Z4+F46mSwSlmre/kk/DQn3FCAPC9/hpyT1dCQi45zx0jJVmPSLBhN3U/HoC4WRBQC0UbxxYtReLUCeVG1H6+f0QD+/Pmz20yejfkmSZUW82ZaIvvWK55aY0PFVAuTmM05q9UA//8yhnb8FwlMObAHt/PX2E+F5v1haVXIf+eLsdwngnKNlmatUOhO82rNPOsdM63t3x/Ed5XPeK44wIZirPvQMZ9PlYMCPHfHIrbJfg0bfCIYKbOWKPFsNKu8aATnu65p8m9HVGoccCDGtargEFKyGGdVRS2sbfFbVd90HgsGVC3dGclXb5xWJbgXa++SaKcGB0g+CvDSQJ+8KjchEo55Qff8vOXFz+jsBDkvVJ6lckTiJ+w+l8gRLu2P8GwAAAABJRU5ErkJggg=="
              class="default-img">
            <div class="default-text">图文导航</div>
          </div>
        </div>
        <div class="img-item" style="border-radius: 4px; padding-top: 41.67%;">
          <div class="category-nav-default"><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAYAAAAdx42aAAAAAXNSR0IArs4c6QAAAwBJREFUSA3FV91LFFEUv+fO2rrr7lZQkT3Uk88RBNWuEfsaSURgukJRL/0D/QH7GEIPvidRoYEEQVIhlX2gIkKkkaFpH2JBZFS6rjuzu3NP92zcZeY6s5ru2ryce373nPP7zZ37NdAzajYBihYUGGNb+ACDJTR4P/8f5PSeyDAGtmjhW/LmyPLA+BznsOgcZBLBnUAt2pyzr3Y03JVKhG5MD3R2MW4MOXlqLsBAeHj+IGSJNJ1Oi5mBq4MM4JcSUXMBkd2hH4pMiWAofiqs5gKWvmUOKDKyfZO4DYDvVdiaAjhwO8D4oAxElfQvVvDAqb6h3H7K6R5aiBYXc2cRsUHVCKiGn0VRnD7XHH3ZO2oGmW0n/OL8cJrpBcBLvcPZgnyDOknuCl1zBDBQ95oyMoHgc7mMyt/OVWUdDpF7hVUUIHerzMyR4AdKvHwYCozBfa8im8EqCkAuJtIAQhG0Hwt9Bs5eKb8atqIAMMW4TmKZ4cfAMKPjG/X9BQCbTyVjrjVMJBeTYDIQDzZKqOf5CjCAlSafnkB+Kh6bMgAmvfp0jM6ACIavyfkzpfeR7ykAGCvEdoUrEnBr+ZFcFTmvogrjABPv86O3TzdDhon8mMKd1lsAwLuTTWA5A/V2a3LPshBiQMeVL+fus/Z4+F46mSwSlmre/kk/DQn3FCAPC9/hpyT1dCQi45zx0jJVmPSLBhN3U/HoC4WRBQC0UbxxYtReLUCeVG1H6+f0QD+/Pmz20yejfkmSZUW82ZaIvvWK55aY0PFVAuTmM05q9UA//8yhnb8FwlMObAHt/PX2E+F5v1haVXIf+eLsdwngnKNlmatUOhO82rNPOsdM63t3x/Ed5XPeK44wIZirPvQMZ9PlYMCPHfHIrbJfg0bfCIYKbOWKPFsNKu8aATnu65p8m9HVGoccCDGtargEFKyGGdVRS2sbfFbVd90HgsGVC3dGclXb5xWJbgXa++SaKcGB0g+CvDSQJ+8KjchEo55Qff8vOXFz+jsBDkvVJ6lckTiJ+w+l8gRLu2P8GwAAAABJRU5ErkJggg=="
              class="default-img">
            <div class="default-text">图文导航</div>
          </div>
        </div>
        <div class="img-item" style="border-radius: 4px; padding-top: 41.67%;">
          <div class="category-nav-default"><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAYAAAAdx42aAAAAAXNSR0IArs4c6QAAAwBJREFUSA3FV91LFFEUv+fO2rrr7lZQkT3Uk88RBNWuEfsaSURgukJRL/0D/QH7GEIPvidRoYEEQVIhlX2gIkKkkaFpH2JBZFS6rjuzu3NP92zcZeY6s5ru2ryce373nPP7zZ37NdAzajYBihYUGGNb+ACDJTR4P/8f5PSeyDAGtmjhW/LmyPLA+BznsOgcZBLBnUAt2pyzr3Y03JVKhG5MD3R2MW4MOXlqLsBAeHj+IGSJNJ1Oi5mBq4MM4JcSUXMBkd2hH4pMiWAofiqs5gKWvmUOKDKyfZO4DYDvVdiaAjhwO8D4oAxElfQvVvDAqb6h3H7K6R5aiBYXc2cRsUHVCKiGn0VRnD7XHH3ZO2oGmW0n/OL8cJrpBcBLvcPZgnyDOknuCl1zBDBQ95oyMoHgc7mMyt/OVWUdDpF7hVUUIHerzMyR4AdKvHwYCozBfa8im8EqCkAuJtIAQhG0Hwt9Bs5eKb8atqIAMMW4TmKZ4cfAMKPjG/X9BQCbTyVjrjVMJBeTYDIQDzZKqOf5CjCAlSafnkB+Kh6bMgAmvfp0jM6ACIavyfkzpfeR7ykAGCvEdoUrEnBr+ZFcFTmvogrjABPv86O3TzdDhon8mMKd1lsAwLuTTWA5A/V2a3LPshBiQMeVL+fus/Z4+F46mSwSlmre/kk/DQn3FCAPC9/hpyT1dCQi45zx0jJVmPSLBhN3U/HoC4WRBQC0UbxxYtReLUCeVG1H6+f0QD+/Pmz20yejfkmSZUW82ZaIvvWK55aY0PFVAuTmM05q9UA//8yhnb8FwlMObAHt/PX2E+F5v1haVXIf+eLsdwngnKNlmatUOhO82rNPOsdM63t3x/Ed5XPeK44wIZirPvQMZ9PlYMCPHfHIrbJfg0bfCIYKbOWKPFsNKu8aATnu65p8m9HVGoccCDGtargEFKyGGdVRS2sbfFbVd90HgsGVC3dGclXb5xWJbgXa++SaKcGB0g+CvDSQJ+8KjchEo55Qff8vOXFz+jsBDkvVJ6lckTiJ+w+l8gRLu2P8GwAAAABJRU5ErkJggg=="
              class="default-img">
            <div class="default-text">图文导航</div>
          </div>
        </div>
        <div class="img-item" style="border-radius: 4px; padding-top: 41.67%;">
          <div class="category-nav-default"><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAbCAYAAAAdx42aAAAAAXNSR0IArs4c6QAAAwBJREFUSA3FV91LFFEUv+fO2rrr7lZQkT3Uk88RBNWuEfsaSURgukJRL/0D/QH7GEIPvidRoYEEQVIhlX2gIkKkkaFpH2JBZFS6rjuzu3NP92zcZeY6s5ru2ryce373nPP7zZ37NdAzajYBihYUGGNb+ACDJTR4P/8f5PSeyDAGtmjhW/LmyPLA+BznsOgcZBLBnUAt2pyzr3Y03JVKhG5MD3R2MW4MOXlqLsBAeHj+IGSJNJ1Oi5mBq4MM4JcSUXMBkd2hH4pMiWAofiqs5gKWvmUOKDKyfZO4DYDvVdiaAjhwO8D4oAxElfQvVvDAqb6h3H7K6R5aiBYXc2cRsUHVCKiGn0VRnD7XHH3ZO2oGmW0n/OL8cJrpBcBLvcPZgnyDOknuCl1zBDBQ95oyMoHgc7mMyt/OVWUdDpF7hVUUIHerzMyR4AdKvHwYCozBfa8im8EqCkAuJtIAQhG0Hwt9Bs5eKb8atqIAMMW4TmKZ4cfAMKPjG/X9BQCbTyVjrjVMJBeTYDIQDzZKqOf5CjCAlSafnkB+Kh6bMgAmvfp0jM6ACIavyfkzpfeR7ykAGCvEdoUrEnBr+ZFcFTmvogrjABPv86O3TzdDhon8mMKd1lsAwLuTTWA5A/V2a3LPshBiQMeVL+fus/Z4+F46mSwSlmre/kk/DQn3FCAPC9/hpyT1dCQi45zx0jJVmPSLBhN3U/HoC4WRBQC0UbxxYtReLUCeVG1H6+f0QD+/Pmz20yejfkmSZUW82ZaIvvWK55aY0PFVAuTmM05q9UA//8yhnb8FwlMObAHt/PX2E+F5v1haVXIf+eLsdwngnKNlmatUOhO82rNPOsdM63t3x/Ed5XPeK44wIZirPvQMZ9PlYMCPHfHIrbJfg0bfCIYKbOWKPFsNKu8aATnu65p8m9HVGoccCDGtargEFKyGGdVRS2sbfFbVd90HgsGVC3dGclXb5xWJbgXa++SaKcGB0g+CvDSQJ+8KjchEo55Qff8vOXFz+jsBDkvVJ6lckTiJ+w+l8gRLu2P8GwAAAABJRU5ErkJggg=="
              class="default-img">
            <div class="default-text">图文导航</div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { queryBannerList } from "@/api/index";

export default {
  props: ["module"],
  data() {
    return {
      bannerList: [],
    };
  },

  created() {
    //获取轮播图
    this.getBannerList();
  },
  methods: {
    /**
     * 查询轮播图
     */
    getBannerList() {
      let data = { banType: "PC_2" };

      queryBannerList(data).then((response) => {
        if (response.status == 0) {
          this.bannerList = response.result;
        }
      });
    },
  },
};
</script>

<style>
.tuwen_1_style .body-container-grid {
  display: grid;
  -webkit-justify-content: center;
  justify-content: center;
  justify-items: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}


.img-item {
  position: relative;
  overflow: hidden;
}

.category-nav-default {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  background: #F1F3F7;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.category-nav-default .default-img {
  height: 27px;
  width: 32px;
}

.category-nav-default .default-text {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  color: #bed4fa;
  line-height: 18px;
}
</style>
