<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
    },
    { 'border-radius': module.base.round + 'px' }
  ]">
    <div :style="[{ 'border-radius': module.base.round + 'px' }]" class="menu_class">
      <van-grid :column-num="module.max" :border="false" style="background-color: #FFFFFF;">
        <van-grid-item icon="photo-o" :text="item.name" v-for="(item, index) in module.list" :key="index">
          <van-image round :src="item.imageUrl" style="width: 45px; height: 45px; border-radius: 5px" />

          <!-- 自定义文字 -->
          <div style="font-size: 12px; color: #303133; text-align: center;margin-top: 10px;">
            {{ item.name }}
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      navLists: [
        {
          categoryName: "腾讯视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1565395015.png"
        },
        {
          categoryName: "优酷视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
        },
        {
          categoryName: "PP视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        }
      ]
    };
  },
  computed: {}
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
  border-radius: 6px;
  margin: 10px auto 0;
  box-sizing: border-box;
  /* padding: 14px 10px 0; */
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}



.menu_class .van-grid-item__content{
  padding: 8px 8px;
}
</style>
