<template>
  <div>
    <van-nav-bar
      title="帮助中心"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mNotice" style="margin-bottom: 11px">
      <div
        class="item"
        v-for="(item, index) in listData"
        :key="index"
        @click="toNoticeDetails(item)"
      >
        <div class="notice-header">
          <!---->
          <div class="name">
            <div class="text" style="color: rgb(0, 0, 0)">
              {{ item.noticeTitle }}
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNoticeList } from "@/api/notice";

export default {
  data() {
    return {
      queryParam: {
        noticeType: "3",
      },
      listData: [],
    };
  },

  created() {
    this.initData();
  },
  methods: {
    /**
     * 查询首页公告
     */
    initData() {
      getNoticeList(this.queryParam).then((response) => {
        if (response.status == 0) {
          this.listData = response.result.list;
        }
      });
    },

    /**
     * 展示公告详情
     */
    toNoticeDetails(data) {
      this.$router.push({
        path: "mNoticeDetails",
        query: { noticeId: data.id },
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.mNotice {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 11px;
}

.mNotice .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 394px;
  border-radius: 5px;
  background: #fff;
  padding: 16px 11px;
  box-sizing: border-box;
}

.mNotice .item .notice-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
}

.mNotice .item .notice-header .name {
  flex: 1;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.mNotice .item .notice-header .name .text {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
}

.mNotice .item .notice-header .name .time {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9fa8;
}

.mNotice .item .title {
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
  margin-top: 13px;
  white-space: pre-line;
}
</style>
