<template>
  <div class="app-container" style="margin-top: 10px">
    <el-card shadow="never">
      <el-button type="primary" @click="showAddModal"  size="small">新增</el-button>
      <br />
      <br />

      <el-table :data="list" style="width: 100%">
        <el-table-column prop="icon" label="图标" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.icon"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="标签" width="180">
        </el-table-column>
        <el-table-column prop="value" label="联系方式"> </el-table-column>

        <el-table-column prop="link" label="客服链接"> </el-table-column>

        <el-table-column prop="uploadImg" label="二维码/图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.uploadImg"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button @click="editItem(scope.row)" type="text">编辑</el-button>
            <el-button @click="deleteItem(scope.row)" type="text" v-preventClick>
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 新增修改弹框 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="editIndex > -1 ? '编辑' : '新增'"
        center
        width="70%"
        append-to-body
      >
        <el-form label-position="top">
          <el-form-item label="图标">
            <el-upload
              class="avatar-uploader"
              action="/api/upload/public_pic"
              list-type="picture-card"
              :headers="headers"
              :on-success="iconUploadOnSuccess"
              :before-upload="iconBeforeAvatarUpload"
              :on-exceed="iconHandleExceed"
              :limit="1"
            >
              <img
                v-if="form.icon"
                :src="form.icon"
                class="avatar"
                style="width: 100%"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="标签">
            <el-input v-model="form.label" placeholder="标签"></el-input>
          </el-form-item>

          <el-form-item label="联系方式">
            <el-input v-model="form.value" placeholder="联系方式"></el-input>
          </el-form-item>

          <el-form-item label="客服链接">
            <el-input v-model="form.link" placeholder="客服链接"></el-input>
          </el-form-item>

          <el-form-item label="二维码/图片">
            <el-upload
              class="avatar-uploader"
              action="/api/upload/public_pic"
              list-type="picture-card"
              :headers="headers"
              :on-success="UploadOnSuccess"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              :limit="1"
            >
              <img
                v-if="form.uploadImg"
                :src="form.uploadImg"
                class="avatar"
                style="width: 100%"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="addItem" v-preventClick>立即创建</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getCustomerDetail, customerSave } from "@/api/vip";
import { getToken, getUserId } from "@/utils/auth";

export default {
  data() {
    return {
      columns: [
        {
          title: "图标",
          dataIndex: "icon",
          scopedSlots: { customRender: "icon" },

          align: "center",
        },
        {
          title: "标签",
          dataIndex: "label",
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "value",
          width: "20%",
          align: "center",
        },
        {
          title: "客服链接",
          dataIndex: "link",
          align: "center",
        },
        {
          title: "二维码/图片",
          dataIndex: "uploadImg",
          scopedSlots: { customRender: "uploadImg" },

          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          align: "center",
        },
      ],
      list: [], // 列表数据
      dialogVisible: false, // 是否显示新增弹框
      newItem: "", // 新增的项
      editIndex: -1, // 编辑的项的索引
      form: {
        label: undefined,
        value: undefined,
        link: undefined,
        iconId: undefined,
        uploadId: undefined,
      },
      headers: {
        token: getToken(),
        userId: getUserId(),
      },
    };
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.load();
      }
    },

    async load() {
      try {
        const res = await getCustomerDetail();
        this.list = res.result;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 保存客服
     */
    async onSubmit() {
      try {
        const jsonData = JSON.stringify(this.list);

        const value = await customerSave(jsonData);
        console.log(value);
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.load();
      } catch (error) {
        console.error(error);
        this.$message({
          message: "修改失败",
          type: "error",
        });
      }
      this.dialogVisible = false;
    },

    /**
     * 新增操作
     */
    showAddModal() {
      this.dialogVisible = true;
      this.form = {
        label: undefined,
        value: undefined,
        link: undefined,
        uploadId: undefined,
        uploadImg: undefined,
        iconId: undefined,
        icon: undefined,
      };
    },

    addItem() {
      if (this.editIndex > -1) {
        this.list.splice(this.editIndex, 1, {
          label: this.form.label,
          value: this.form.value,
          link: this.form.link,
          uploadId: this.form.uploadId,
          uploadImg: this.form.uploadImg,
          iconId: this.form.iconId,
          icon: this.form.icon,
        });
      } else {
        this.list.push({
          label: this.form.label,
          value: this.form.value,
          link: this.form.link,
          uploadId: this.form.uploadId,
          uploadImg: this.form.uploadImg,
          iconId: this.form.iconId,
          icon: this.form.icon,
        });
      }

      this.editIndex = -1;
      this.dialogVisible = false;
      this.onSubmit();
    },

    /**
     * 编辑操作
     */
    editItem(record) {
      const index = this.list.indexOf(record);
      this.editIndex = index;
      this.form = {
        label: record.label,
        value: record.value,
        link: record.link,
        uploadId: record.uploadId,
        uploadImg: record.uploadImg,
        iconId: record.iconId,
        icon: record.icon,
      };
      this.dialogVisible = true;
    },
    deleteItem(index) {
      this.list.splice(index, 1);
      this.onSubmit();
    },

    //图片上传
    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },
    UploadOnSuccess(res, file, fileList) {
      console.log(file, fileList);
      this.$set(this.form, "uploadId", res.id);
      this.$forceUpdate();
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    //icon上传
    iconBeforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },
    iconUploadOnSuccess(res, file, fileList) {
      console.log(file, fileList);
      this.$set(this.form, "iconId", res.id);
      this.$forceUpdate();
    },

    iconHandleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
};
</script>
