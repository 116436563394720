<template>
  <div class="credit-page">
    <el-card shadow="never">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="在线充值" name="1">
          <div>
            <div class="recharge-activity">
              <span
                class="dx2-tag"
                v-for="(item, index) in rechargeActivityList"
                :key="index"
                @click="toRechargeActivity(index, item)"
                :class="{ dx2TagAct: index === selectActivityId }"
              >
                <span class="dx2-tag-desc">满</span>
                <span class="dx2-tag-title">￥{{ item.rechargeAmount }}</span>
                <span class="dx2-tag-desc">赠￥{{ item.amount }}</span>
                <div class="dx2-tag-tips">{{ item.type }}</div>
              </span>
            </div>

            <div class="item active">
              <p class="re_title">充值金额：</p>
              <a-input-number size="large" v-model="amount"  :min="0.1" :max="9999999" style="width:50%"/>
            </div>

            <el-card shadow="never">
              <div slot="header">
                <span style="font-size: 16px">请选择支付方式</span>
              </div>

              <div v-if="rechargeList && rechargeList.length > 0">
                <el-radio-group v-model="payConfig">
                  <el-radio
                    @change="toPopPayTis(value)"
                    border
                    v-for="(value, key, index) in rechargeList"
                    :key="index"
                    :label="value"
                  >
                    {{ value.payName }}
                  </el-radio>
                </el-radio-group>

                <div v-if="payConfig">
                  <el-alert
                    type="success"
                    style="border: 1px solid #ffe58f"
                    :closable="false"
                  >
                    <span style="font-size: 14px; color: #564949">
                      手续费：{{ payConfig.payCommission }}%， 最低充值：{{
                        payConfig.minPrice
                      }}元， 最高充值：{{ payConfig.maxPrice }}元
                    </span>
                  </el-alert>
                </div>

                <div style="text-align: center; margin-top: 20px">
                  <el-button
                    type="primary"
                    @click="handlePay"
                    style="width: 280px"
                    v-preventClick
                  >
                    立即支付
                  </el-button>
                </div>
              </div>

              <el-empty v-else description="商家暂时未开通在线充值"></el-empty>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane label="充值卡充值" name="2">
          <el-form label-width="80px">
            <el-form-item label="充值卡">
              <el-input v-model="plusCdk" placeholder="请输入充值卡">
              </el-input>
            </el-form-item>

            <el-form-item style="text-align: center">
              <el-button
                type="primary"
                @click="upCardRecharge"
                style="width: 280px"
                v-preventClick
              >
                立即兑换
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <Payment
      ref="paymentComponent"
      :amount="this.amount"
      :payConfig="this.payConfig"
      :isMobile="false"
    ></Payment>

    <!-- 支付提示 -->
    <a-modal title="支付提示" :visible="dialogPayTisVisible">
      <div style="padding: 4px">
        <span v-html="dialogPayTis"></span>
      </div>

      <span slot="footer"">
        <a-button type="primary" @click="dialogPayTisVisible = false">
          我已知晓
        </a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Payment from "@/components/payment/RechargePayment";
import { Toast } from "vant";
import { camiRecharge, rechargeActivity } from "@/api/recharge";

export default {
  data() {
    return {
      dialogPayTisVisible: false,
      dialogPayTis: undefined,

      selectActivityId: undefined,
      plusCdk: undefined,
      dialogVisible: false,
      rechargeList: {},
      moneyData: [50, 100, 200, 500],
      payConfig: undefined,
      amount: undefined,
      activeName: "1",
      rechargeActivityList: [],
    };
  },
  components: {
    Payment,
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      rechargeConfig: (state) => state.index.rechargeConfig,
    }),
  },
  created() {
    this.$store.dispatch("balance/detail");
    //初始化支付通道
    this.initPayConfig();
    //查询充值活动详情
    this.queryRechargeActivity();
  },
  methods: {
    //初始化支付通道
    initPayConfig() {
      let data = {};
      this.$store.dispatch("index/queryRechargeConfig", data).then(() => {
        this.rechargeList = this.rechargeConfig.payList;
        // this.$forceUpdate();
      });
    },

    /**
     * 弹框支付提示
     */
    toPopPayTis(value) {
      console.log(value);
      console.log("====================================================");
      let tip = value.payTip;
      if (tip) {
        this.dialogPayTisVisible = true;
        this.dialogPayTis = tip;
      }
    },

    //查询充值活动详情
    queryRechargeActivity() {
      new Promise((resolve, rejust) => {
        rechargeActivity({})
          .then((res) => {
            this.rechargeActivityList = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 选中充值活动
     */
    toRechargeActivity(index, item) {
      this.selectActivityId = index;

      this.amount = item.rechargeAmount;
    },

    changeMoney(value) {
      this.amount = value;
    },

    /**
     * 支付订单
     */
    handlePay() {
      this.$refs.paymentComponent.toPayment();
    },

    /**
     * 加款卡充值
     */
    upCardRecharge() {
      if (this.plusCdk == "") {
        Toast("请输入加款卡");
        return;
      }
      let data = {
        plusCdk: this.plusCdk,
      };
      new Promise((resolve, rejust) => {
        camiRecharge(data)
          .then((res) => {
            if (res.status == 0) {
              Toast.success("兑换成功!");
            }
            //刷新余额
            this.$store.dispatch("balance/detail");
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 清空二维码,避免生成多个二维码
    delQrcode() {
      setTimeout(() => {
        this.$refs.qrCodeDiv.innerHTML = "";
      }, 0);
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>

<style scoped>
.back-img {
  height: 20px;
  margin-right: 10px;
}
.back-btn {
  border-radius: 5px;
  width: 140px;
  height: 40px;
  color: #5f5f5f;
  border: 2px solid #5f5f5f;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
}
.back-btn.active {
  border: 2px solid #014785;
}
p {
  padding: 0px;
  margin: 0px;
}
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.re_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
}


.item.active {
  background: #f8f8f8;
  padding: 10px 20px;
  /* padding-right: 40px;  */
  width: auto;
}

/* 支付弹框 */

.section .zf_container {
  margin: 18px auto 0;
  /* padding-top: 38px; */
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.section .con {
  margin: 3px auto 0;
  padding-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.time span {
  font-size: 18px;
  color: #545454;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
}
.time span p {
  color: #3259ff;
  display: inline-block;
}
.order {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: center;
}
.order span:first-child {
  color: #999;
  font-size: 15px;
  margin-left: 14px;
}
.order span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.goods_name {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}
.goods_name span:nth-child(2) {
  margin-left: 14px;
}
.yuanmoney {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: left;
}
.yuanmoney span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.pay_type {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.pay_type img {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
}
.pay_type span {
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
}
.code_cs,
.code {
  height: 208px;
  background: #fbfbfb;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
}
.code_cs {
  height: 208px;
  background: #ffffff;
}
.code_cs img {
  position: absolute;
  width: 49px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  padding: 10px;
}
.code {
  border: 5px solid #d8d1d1;
  border-radius: 5px;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
  display: block;
  padding: 3px;
}
.price {
  color: #386cfa;
  width: 100%;
  text-align: center;
  top: 65px;
}
.price span:first-child {
  font-size: 28px;
}
.price span {
  font-weight: 700;
}
.price span:nth-child(2) {
  font-size: 17px;
}
.price span {
  font-weight: 700;
}
.shanxinzha {
  margin-top: 32px;
  width: 100%;
  text-align: center;
}
.shanxinzha img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  -webkit-animation: xuanzhuan 0.8s linear infinite;
}

.shanxinzha span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.shanxinzha span p {
  display: inline-block;
  color: #386cfa;
}
.section--last {
  margin-bottom: 20px;
}

/* 充值活动相关css */


.recharge-activity .dx2-tag {
  position: relative;
  line-height: normal;
  padding: 15px 20px;
  cursor: pointer;
  color: #c41d7f;
  background: #ffffff;
  border-color: #ffadd2;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.recharge-activity .dx2TagAct {
  position: relative;
  line-height: normal;
  padding: 15px 20px;
  cursor: pointer;
  color: #ffffff;
  background: #67c23a;
  border-color: #ffadd2;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 20px;
}

.recharge-activity .dx2-tag-desc {
  margin-left: 8px;
  font-size: 12px;
}

.recharge-activity .dx2-tag-title {
  font-size: 20px;
}

.recharge-activity .dx2-tag-desc {
  margin-left: 8px;
  font-size: 12px;
}

.recharge-activity .dx2-tag-tips {
  position: absolute;
  top: 0;
  right: 0;
  background: #fa2c19;
  color: #fff;
  font-size: 12px;
  padding: 2px 3px;
}
</style>
