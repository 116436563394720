<template>
  <div>
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />

    <div class="setup">
      <van-form>
        <van-field
          readonly
          v-model="userid"
          name="用户ID"
          label="用户ID"
          placeholder="用户ID"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />

        <van-field
          readonly
          v-model="username"
          name="用户账号"
          label="用户账号"
          placeholder="用户账号"
          :rules="[{ required: true, message: '请填写用户名' }]"
        >
          <template #button>
            <van-button size="small" type="info" @click="modifyAccount()">
              修改账号
            </van-button>
          </template>
        </van-field>
      </van-form>
    </div>

    <div class="setup">
      <van-cell title="邮箱绑定" is-link>
        <template #right-icon v-if="email"> 已绑定 </template>
        <template #right-icon v-else>
          <span @click="dialogVisible.email = true" style="color: #2979ff"
            >绑定</span
          >
        </template>
      </van-cell>

      <van-cell title="手机绑定" is-link>
        <template #right-icon v-if="phone"> 已绑定 </template>
        <template #right-icon v-else>
          <span @click="dialogVisible.phone = true" style="color: #2979ff"
            >绑定</span
          >
        </template>
      </van-cell>

      <van-cell title="实名认证" is-link>
        <template #right-icon v-if="isCardNo"> 已绑定 </template>
        <template #right-icon v-else>
          <span @click="dialogVisible.auth = true" style="color: #2979ff"
            >绑定</span
          >
        </template>
      </van-cell>
    </div>

    <div class="setup">
      <van-cell-group>
        <van-cell title="修改密码" is-link @click="dialogVisible.pwd = true" />
      </van-cell-group>
    </div>

    <div style="margin: 30px 22px" @click="handleLogout()">
      <van-button round block type="danger" native-type="submit"
        >退出登录</van-button
      >
    </div>

    <!-- 修改密码 -->


    <a-modal
      title="修改密码"
      :visible="dialogVisible.pwd"
      :footer="null"
      @cancel="dialogVisible.pwd = false"
    >
      <div>
        <van-form @submit="handlePwdReset">
          <van-field
            v-model="pwd.oldPwd"
            type="password"
            name="旧密码"
            label="旧密码"
            placeholder="旧密码"
            :rules="[{ required: true, message: '请填写旧密码' }]"
          />

          <van-field
            v-model="pwd.newPwd"
            type="password"
            name="新密码"
            label="新密码"
            placeholder="新密码"
            :rules="[{ required: true, message: '请填写新密码' }]"
          />

          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >确认</van-button
            >
            <br />
            <van-button
              round
              block
              type="default"
              @click="dialogVisible.pwd = false"
              >取消</van-button
            >
          </div>
        </van-form>
      </div>
    </a-modal>

    <!-- 邮箱绑定 -->
    <van-popup
      title="邮箱绑定"
      v-model="dialogVisible.email"
      round
      :style="{ width: '90%', padding: '15px 10px' }"
    >
      <div v-if="email">
        <van-field label="原邮箱">
          <template #default>
            <p>{{ email }}</p>
          </template>
        </van-field>
      </div>

      <div v-if="email">
        <van-field label="验证码" v-model="pwdFrom.removecode">
          <template #button>
            <van-button size="small" type="primary" @click="handleEmailCode"
              >获取验证码</van-button
            >
          </template>
        </van-field>
      </div>

      <div v-if="email">
        <van-form-item>
          <van-button type="primary" @click="handleEmailMove">解绑</van-button>
        </van-form-item>
      </div>

      <van-field
        name="新邮箱"
        label="新邮箱"
        placeholder="新邮箱"
        v-model="pwdFrom.newEmail"
      >
      </van-field>

      <van-field label="验证码" v-model="pwdFrom.code">
        <template #button>
          <van-button size="small" type="primary" @click="handleEmailVerifyCode"
            >获取验证码</van-button
          >
        </template>
      </van-field>

      <div style="margin: 16px; text-align: center">
        <van-button type="primary" @click="handelEmailUpdate" block
          >确 定</van-button
        >
      </div>
    </van-popup>

    <!-- 手机号绑定 -->
    <van-popup
      title="手机号绑定"
      v-model="dialogVisible.phone"
      round
      :style="{ width: '90%', padding: '15px 10px' }"
    >
      <div v-if="phone">
        <van-field label="原手机号">
          <p>{{ phone }}</p>
        </van-field>
      </div>

      <div v-if="phone">
        <van-field label="验证码" v-model="phoneFrom.removecode">
          <template #button>
            <van-button size="small" type="primary" @click="handlePhoneCode"
              >获取验证码</van-button
            >
          </template>
        </van-field>
      </div>

      <div v-if="phone">
        <van-form-item>
          <van-button type="primary" @click="handlePhoneMove">解绑</van-button>
        </van-form-item>
      </div>

      <van-field label="手机号" v-model="phoneFrom.newPhone"> </van-field>

      <van-field label="验证码" v-model="phoneFrom.code">
        <template #button>
          <van-button size="small" type="primary" @click="handlePhoneVerifyCode"
            >获取验证码</van-button
          >
        </template>
      </van-field>

      <div style="margin: 16px; text-align: center">
        <van-button type="primary" @click="handelPhoneUpdate" block
          >确 定</van-button
        >
      </div>
    </van-popup>

    <!-- 实名认证 -->
    <van-popup
      title="实名认证"
      v-model="dialogVisible.auth"
      round
      :style="{ width: '90%', padding: '15px 10px' }"
    >
      <van-field label="姓名" v-model="authFrom.realName"> </van-field>

      <van-field label="身份证号" v-model="authFrom.cardNo"> </van-field>

      <div style="margin: 16px; text-align: center">
        <van-button type="primary" @click="handelCardAuthUpdate" block>
          确 定
        </van-button>
      </div>
    </van-popup>

    <!-- 修改账号 -->
    <van-popup
      title="修改账号"
      v-model="dialogUpdateAccountVisible"
      round
      :style="{ width: '90%', padding: '25px 10px' }"
    >
      <van-field
        label="登录账号"
        v-model="newUsername"
        placeholder="请输入新的登录账号"
      >
      </van-field>

      <div style="margin: 16px; text-align: center">
        <van-button
          type="primary"
          size="normal"
          @click="confirmUpdateAccount"
          block
        >
          确 定
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import { updateUsername } from "@/api/user";
export default {
  data() {
    return {
      newUsername: undefined,
      dialogUpdateAccountVisible: false,
      pwd: {
        oldPwd: "",
        newPwd: "",
      },
      pwdFrom: {
        removecode: "",
        newEmail: "",
        code: "",
      },
      phoneFrom: {
        removecode: "",
        newPhone: "",
        code: "",
      },
      authFrom: {
        realName: undefined,
        cardNo: undefined,
      },
      dialogVisible: {
        pwd: false,
        email: false,
        phone: false,
        auth: false,
      },
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.commission.balanceAllowTransfer,
      balanceIsRejected: (state) => state.commission.balanceIsRejected,
      balanceIsTransfering: (state) => state.commission.balanceIsTransfering,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      secretKey: (state) => state.user.secretKey,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
      branchId: (state) => state.user.branchId,
      levelName: (state) => state.user.levelName,
      levelNum: (state) => state.user.levelNum,
      userid: (state) => state.user.userid,
      isCardNo: (state) => state.user.isCardNo,
    }),
  },
  created() {
    this.$store.dispatch("user/info").then();
  },
  methods: {
    /**
     * 修改账号
     */
    modifyAccount() {
      this.newUsername = undefined;
      this.dialogUpdateAccountVisible = true;
    },

    /**
     * 确认修改账号
     */
    confirmUpdateAccount() {
      let data = {
        username: this.newUsername,
      };
      updateUsername(data)
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.dialogUpdateAccountVisible = false;

            this.$store.dispatch("user/info").then();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },

    /**
     * 修改密码
     */
    handlePwdReset() {
      this.$store
        .dispatch("user/pwd_reset", {
          passwordOld: this.pwd.oldPwd,
          passwordNew: this.pwd.newPwd,
        })
        .then(() => {
          this.pwd.oldPwd = "";
          this.pwd.newPwd = "";
          this.dialogVisible.pwd = false;
        });
    },

    /**
     * 验证邮箱
     */
    handleEmailVerifyCode() {
      this.$store.dispatch("user/verify_code", {
        email: this.pwdFrom.newEmail,
      });
    },

    //邮箱修改
    handelEmailUpdate() {
      this.$store
        .dispatch("user/email_update", {
          email: this.pwdFrom.newEmail,
          verifyCode: this.pwdFrom.code,
        })
        .then(() => {
          this.$store.commit("user/SET_EMAIL", this.pwdFrom.newEmail);
          this.pwdFrom.newEmail = "";
          this.pwdFrom.code = "";
          this.pwdFrom.removecode = "";
          this.dialogVisible.email = false;
          this.dialogVisible.email = false;
        });
    },
    handlePhoneCode() {
      this.$store.dispatch("user/phone_remove_code", {});
    },

    //取消授权
    handlePhoneMove() {
      this.$store
        .dispatch("user/phone_remove", {
          verifyCode: this.phoneFrom.removecode,
        })
        .then(() => {
          this.$store.commit("user/SET_PHONE", "");
        });
    },

    //获取验证码
    handlePhoneVerifyCode() {
      this.$store.dispatch("user/phone_code", {
        phone: this.phoneFrom.newPhone,
      });
    },

    //手机号认证
    handelPhoneUpdate() {
      this.$store
        .dispatch("user/phone_update", {
          phone: this.phoneFrom.newPhone,
          verifyCode: this.phoneFrom.code,
        })
        .then(() => {
          this.$store.commit("user/SET_PHONE", this.phoneFrom.newPhone);
          // this.phone = this.phoneFrom.newPhone
          this.phoneFrom.code = "";
          this.phoneFrom.newPhone = "";
          this.phoneFrom.removecode = "";
          this.dialogVisible.phone = false;
        });
    },

    //实名认证
    handelCardAuthUpdate() {
      this.$store
        .dispatch("user/card_auth", {
          realName: this.authFrom.realName,
          cardNo: this.authFrom.cardNo,
        })
        .then(() => {
          this.$store.dispatch("user/info").then();
          this.authFrom.realName = "";
          this.authFrom.cardNo = "";
          this.dialogVisible.auth = false;
        });
    },

    /**
     * 退出登录
     */
    handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          this.$router.push({ path: "/login" });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },

    /**
     * 确认修改密码
     */
    onSubmit() {
      this.$store
        .dispatch("user/pwd_reset", {
          passwordOld: this.pwd.oldPwd,
          passwordNew: this.pwd.newPwd,
        })
        .then(() => {
          this.pwd.oldPwd = "";
          this.pwd.newPwd = "";
          this.dialogVisible = false;
        });
    },

    /**
     * 返回上页
     */
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.setup {
  margin: 10px 12px;
  border-radius: 7px;
}
</style>
