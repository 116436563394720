var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('div',{staticClass:"banner_con"},[_c('van-swipe',{staticClass:"guanggao-swipe",attrs:{"show-indicators":false,"autoplay":3000,"indicator-color":"white"},on:{"change":_vm.onSwipeChange}},_vm._l((_vm.module.list),function(item,index){return _c('van-swipe-item',{key:index},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{attrs:{"src":item.imageUrl}})])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }