var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    },
    { 'border-radius': _vm.module.base.round + 'px' }
  ])},[_c('div',{style:([{ 'border-radius': _vm.module.base.round + 'px' }])},[_c('van-grid',[_c('van-grid-item',{attrs:{"icon":"photo-o","text":"腾讯视频"}}),_c('van-grid-item',{attrs:{"icon":"photo-o","text":"酷狗音乐"}}),_c('van-grid-item',{attrs:{"icon":"photo-o","text":"哔哩哔哩"}}),_c('van-grid-item',{attrs:{"icon":"photo-o","text":"芒果TV"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }