<template>
  <div class="module-common pc_style">
    <div class="nice-goods-box">
      <div class="title">
        <p>诚意推荐 品质商品</p>
      </div>
      <div class="content">
        <div class="menu">
          <div class="item-out-box" v-for="(value, index) in catesList">
            <div class="item active" @click="handleClick(value, index)">
              {{ value.categoryName }}
            </div>
          </div>
        </div>
        <div class="detail-list">
          <div
            class="item"
            v-for="(item, index) in catesList[0].children"
            @click="toProductList(item)"
          >
            <div class="xunijiang_image">
              <a-avatar :size="64" icon="appstore" />
            </div>
            <div class="goods-name">{{ item.categoryName }}</div>
          </div>
        </div>
        <div class="footer">
          <span>找不到想要的商品? 您可以联系客服咨询相关信息！</span
          ><span class="link" @click="toCategory()">查看更多商品</span>
        </div>
        <div class="el-loading-mask" style="display: none">
          <div class="el-loading-spinner">
            <svg viewBox="25 25 50 50" class="circular">
              <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: []
        },
        {
          categoryName: "美食餐饮",
          children: []
        },
        {
          categoryName: "美食餐饮",
          children: []
        },
        {
          categoryName: "美食餐饮",
          children: []
        },
        {
          categoryName: "美食餐饮",
          children: []
        }
      ]
    };
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

<style>
.nice-goods-box {
  width: 100%;
  overflow: hidden;
  border-radius: 9px;
}

.nice-goods-box .title {
  width: 100%;
  height: 60px;
  border-radius: 9px 9px 0 0;
  background: linear-gradient(90deg, #209bff, #32baff);
  display: flex;
  justify-content: space-between;
  padding-left: 17px;
  cursor: pointer;
}

.nice-goods-box .title p {
  flex: 1;
  line-height: 52px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #fff;
}

.nice-goods-box .title:before {
  content: "";
  /* background-image: url(../../../../../assets/icon/tuijian.png); */
  width: 96px;
  height: 23px;
  background-size: cover;
  display: block;
  margin-right: 15px;
  margin-top: 16px;
}

.nice-goods-box .title:after {
  content: "";
  /* background-image: url(../../../../../assets/icon/title_after.472baa5d.svg); */
  width: 314px;
  height: 74px;
  background-size: cover;
  display: block;
}

.nice-goods-box .content {
  background: #fff;
  box-shadow: 0 2px 7px 0 #dddede;
  height: auto;
  transform: translateY(-8px);
  border-radius: 9px;
}

.nice-goods-box .content .menu {
  height: 53px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.nice-goods-box .content .menu .item-out-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nice-goods-box .content .menu .item-out-box:hover .item {
  background-image: linear-gradient(180deg, #59f, #1ba4ff);
  color: #fff;
  box-shadow: 0 3px 13px rgba(85, 153, 255, 0.5);
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
}

.nice-goods-box .content .menu .item {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nice-goods-box .content .menu .active {
  background-image: linear-gradient(180deg, #59f, #1ba4ff);
  color: #fff;
  box-shadow: 0 3px 13px rgba(85, 153, 255, 0.5);
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
}

.nice-goods-box .content .detail-list {
  height: 300px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: start;
  justify-items: center;
  align-content: start;
  overflow: hidden;
  padding: 0 18px;
}

.nice-goods-box .content .detail-list .item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  flex-direction: column;
  width: 220px;
  height: 150px;
  cursor: pointer;
}

.nice-goods-box .content .detail-list .item:hover .goods-name {
  color: #1990ff;
}

.nice-goods-box .content .detail-list .item .goods-name {
  font-size: 13px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #252525;
}

.nice-goods-box .content .footer {
  height: 52px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 56px;
  font-size: 13px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #5b5b5b;
}

.nice-goods-box .content .footer .link {
  color: #1990ff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.xunijiang_image {
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.xunijiang_image img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
}
</style>
