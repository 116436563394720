<template>
    <div>

        <div style="padding: 0px 10px">
            <div class="hot_imgs">
                <div class="hot_imgs">
                    <div class="list-box">

                        <a-row style="padding:10px">
                            <a-col :span="6">
                                <span
                                    style="display: flex;align-items: center;height: 30px;font-size: 12px;color: #999;">
                                    每行展示数量
                                </span>
                            </a-col>

                            <a-col :span="18">
                                <a-radio-group v-model="attr.max">
                                    <a-radio :value="4">
                                        4个
                                    </a-radio>
                                    <a-radio :value="5">
                                        5个
                                    </a-radio>
                                </a-radio-group>
                            </a-col>
                        </a-row>


                        <div class=" list-group">
                            <div class="item" v-for="(item, index) in attr.list" :key="index">
                                <div>
                                    <div class="delect-btn">
                                        <a-button type="link" @click="deleteLink(item)">
                                            <a-icon type="close" />
                                        </a-button>
                                    </div>

                                    <div class="info">
                                        <div class="info-item">
                                            <span class="span">图片</span>
                                            <div class="img-box" @click="openImageSelector(index)">
                                                <div class="upload-box" v-if="!item.imageUrl">
                                                    <a-icon type="plus" />
                                                </div>

                                                <div class="img-box" v-else>
                                                    <img :src="item.imageUrl" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="info">
                                        <div class="info-item"><span class="span">名称</span>
                                            <div class="input-box">
                                                <a-input placeholder="请输入导航名称" v-model="item.name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <div class="info-item"><span class="span">链接</span>
                                            <div class="input-box">
                                                <a-input placeholder="请输入链接" v-model="item.link" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="add-btn">
                        <button type="button" class="btn ivu-btn ivu-btn-primary ivu-btn-ghost" @click="insterLink">
                            添加
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 公共图片 -->
        <HIMAGE ref="HImage" @imageCallback="imageCallback"></HIMAGE>
    </div>
</template>

<script>

import HIMAGE from "@/components/IMAGE/index.vue";

export default {
    components: {
        HIMAGE
    },
    props: ["attr"],
    data() {
        return {
            selectedIndex: undefined
        };
    },

    methods: {

        /**
         * 添加连接
         */
        insterLink() {
            this.attr.list.push({
                imageUrl: undefined,
                name: "导航名称",
                link: undefined
            });
        },

        /**
         * 删除
         */
        deleteLink(item) {
            // 确保至少保留一个元素
            if (this.attr.list.length <= 1) {
                this.$message({
                    showClose: true,
                    message: '至少保留一个！'
                });
                return;
            }

            var index = this.attr.list.indexOf(item);
            if (index !== -1) {
                this.attr.list.splice(index, 1);
            }
        },

        openImageSelector(index) {

            this.selectedIndex = index;

            this.$refs.HImage.handleAdd();
        },

        /** 
        * 图片选择
        */
        imageCallback(row) {

            // 获取要更新的行
            const updatedRow = {
                ...this.attr.list[this.selectedIndex], // 先解构获取的当前行对象
                imageUrl: row.imgUrl, // 更新 imageUrl 属性
            };

            // 使用 Vue 的 $set 方法更新数组中的对象
            this.$set(this.attr.list, this.selectedIndex, updatedRow);

            // this.$set(this.attr.list, this.selectedIndex, { imageUrl: row.imgUrl });
            this.$forceUpdate()
        },
    }
};
</script>

<style></style>