<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
      }
    ]"
  >
    <!-- 首页分类栏目 rows -->

    <div class="mgoods_index3">
      <div class="more-list">
        <div
          class="more-item"
          v-for="(value, key, index) in catesList"
          :key="index"
        >
          <div class="title">
            <div class="img-box">
              <div class="xnj-image">
                <a-icon
                  type="appstore"
                  theme="twoTone"
                  style="font-size:16px"
                />
              </div>
              <uni-text class="title-header"><span>影视会员</span></uni-text>
            </div>
            <uni-text class="title-text"><span>查看更多&gt;</span></uni-text>
          </div>
          <div class="more-item-content">
            <div
              class="catalogue-box"
              v-for="(goods, key, index) in value.children"
              :key="index"
            >
              <div class="xnj-image">
                <a-avatar size="large" icon="instagram" />
              </div>
              <div class="detail">
                <span class="detail-name">
                  <span>分类名称</span>
                </span>
                <span class="title-text">
                  <span>立即购买</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        }
      ]
    };
  },

  computed: {},

  mounted() {},
  methods: {}
};
</script>

<style>
/* .mgoods_index3 {
  padding: 0 0.5625rem;
} */

.mgoods_index3 .more-list {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}

.mgoods_index3 .more-list .more-item {
  /* width: 22.3125rem; */
  background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
  border-radius: 0.46875rem;
  padding: 0.59375rem 0.5rem;
}

.mgoods_index3 .more-list .more-item .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mgoods_index3 .more-list .more-item .title .img-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  color: #191e2e;
  font-weight: 550;
  gap: 0.15625rem;
}

.mgoods_index3 .more-list .more-item .title .title-text {
  font-size: 0.78125rem;
  font-weight: 400;
  color: #9b9fa8;
}

.mgoods_index3 .more-list .more-item .title .title-text:active {
  color: #fc3510;
}

.mgoods_index3 .more-list .more-item .more-item-content {
  margin-top: 0.78125rem;
  display: flex;
  gap: 0.4375rem;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.mgoods_index3 .more-list .more-item .more-item-content .catalogue-box {
  width: 6.8125rem;
  height: 3.90625rem;
  background: #ffffff;
  box-shadow: 0 0.125rem 0.1875rem rgba(255, 128, 83, 0.14);
  border-radius: 0.46875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.40625rem;
}

.mgoods_index3 .more-list .more-item .more-item-content .catalogue-box .detail {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.mgoods_index3
  .more-list
  .more-item
  .more-item-content
  .catalogue-box
  .detail
  .detail-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 3.125rem;
  font-weight: 550;
  color: #191e2e;
  font-size: 0.75rem;
}

.mgoods_index3
  .more-list
  .more-item
  .more-item-content
  .catalogue-box
  .detail
  .title-text {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.625rem;
}

.mgoods_index3
  .more-list
  .more-item
  .more-item-content
  .catalogue-box
  .detail
  .title-text:active {
  color: #fc3510;
}

.xnj-image img {
  width: 100%;
  height: 100%;
}
</style>
