var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    },
    { 'border-radius': _vm.module.base.round + 'px' }
  ])},[_c('div',[_c('div',[_c('div',{staticClass:"group-wrapper"},[_c('div',{staticClass:"hd"},[_c('div',{staticClass:"price-left"},[_c('uni-text',{staticClass:"iconfont icon-hot3"},[_c('span')]),_c('div',{staticClass:"name"},[_vm._v("近期降价")])],1),_c('uni-navigator',{staticClass:"more"},[_vm._v(" 查看更多 "),_c('uni-text',{staticClass:"iconfont icon-jinrujiantou"},[_c('span')])],1)],1),_c('div',{staticClass:"group-scroll"},[_c('uni-scroll-view',{staticClass:"scroll-view_H"},[_c('div',{staticClass:"uni-scroll-view"},[_c('div',{staticClass:"uni-scroll-view",staticStyle:{"overflow":"auto hidden"}},[_c('div',{staticClass:"uni-scroll-view-content"},[_c('div',{staticClass:"group-item"},[_c('van-image',{staticClass:"choice_img"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name line1"},[_vm._v("爱奇艺-月卡")])]),_c('div',{staticClass:"bom-btn"},[_c('uni-text',{staticClass:"iconfont icon-31jiangjia"},[_c('span')]),_c('uni-text',{staticStyle:{"font-size":"11px"}},[_c('span',[_vm._v("￥")])]),_vm._v(" 1.00 ")],1)],1)])])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }