<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
      },
      { 'border-radius': module.base.round + 'px' }
    ]"
  >
    <div :style="[{ 'border-radius': module.base.round + 'px' }]">
      <van-grid>
        <van-grid-item icon="photo-o" text="腾讯视频" />
        <van-grid-item icon="photo-o" text="酷狗音乐" />
        <van-grid-item icon="photo-o" text="哔哩哔哩" />
        <van-grid-item icon="photo-o" text="芒果TV" />
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      navLists: [
        {
          categoryName: "腾讯视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1565395015.png"
        },
        {
          categoryName: "优酷视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
        },
        {
          categoryName: "PP视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        }
      ]
    };
  },
  computed: {}
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
  border-radius: 6px;
  margin: 10px auto 0;
  box-sizing: border-box;
  /* padding: 14px 10px 0; */
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.classify_item {
  padding: 10px;
  font-size: 13px;
  color: #3c3c3c;
  padding-bottom: 15px;
}

.classify_img {
  width: 38px;
  height: 38px;
  margin-bottom: 7px;
}

.classify_item.column {
  position: relative;
}

.classify_item ::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  width: 1px; /* 设置边线宽度 */
  background-color: #f7f8f8; /* 设置边线颜色 */
}
</style>
