var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
  !_vm.module.base.m ? { 'margin-top': '0' } : {},
  {
    padding:
      '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
  }
])},[_c('div',[_c('div',[_c('div',{staticClass:"aui-flex"},[_c('span',{staticClass:"aui-flex-box"},[_vm._v(" "+_vm._s(_vm.module.style.text)+" ")]),_c('div',{staticClass:"aui-arrow"},[_c('a',{attrs:{"href":"#"}},[_vm._v("查看更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)])])])]),_c('div',{staticClass:"flex-shrink-0 has-footer"},[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"kind-show goods-list"},[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"goods-content"},_vm._l((_vm.listData),function(v,k,i){return _c('div',{key:i,staticClass:"goods-item",attrs:{"title":v.productName},on:{"click":function($event){return _vm.toProductDetails(v)}}},[_c('div',{staticClass:"goods-img"},[_c('van-image',{staticClass:"choice_img",attrs:{"src":v.productImg}})],1),_c('div',{staticClass:"product_centent"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(v.productName))]),_c('div',{staticClass:"fun"},[_c('span',{staticClass:"money"},[_c('span',[_c('span',{staticClass:"now-money"},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("￥"+_vm._s(v.productPrice))])])])])])])])}),0)])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }