var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('div',{staticClass:"mgoods3_index3"},[_c('div',{staticClass:"more-list"},_vm._l((_vm.catesList),function(item,key,index){return _c('div',{key:index,staticClass:"more-item"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"img-box"},[_c('div',{staticStyle:{"width":"20px","height":"20px","margin-right":"3px"}},[_c('van-image',{attrs:{"round":"","src":item.prcUrl,"lazy-load":""}})],1),_c('span',{staticClass:"title-header"},[_vm._v(" "+_vm._s(item.categoryName)+" ")])]),_c('router-link',{attrs:{"to":"/cate"}},[_c('span',{staticClass:"title-text"},[_c('span',[_vm._v("查看更多>")])])])],1),_c('div',{staticClass:"more-item-content"},[_c('a-row',{attrs:{"gutter":5}},_vm._l((item.children.slice(0, 6)),function(goods,key,index){return _c('a-col',{key:index,staticClass:"gutter-row",attrs:{"span":8}},[_c('div',{staticClass:"catalogue-box",on:{"click":function($event){return _vm.toProductList(goods)}}},[_c('div',{staticClass:"xnj-image",staticStyle:{"margin-left":"5px"}},[_c('van-image',{staticClass:"recommend_img",attrs:{"round":"","src":goods.prcUrl,"lazy-load":""}})],1),_c('div',{staticClass:"detail"},[_c('span',{staticClass:"detail-name"},[_c('span',[_vm._v(" "+_vm._s(goods.categoryName)+" ")])]),_c('span',{staticClass:"title-text"},[_c('span',[_vm._v("立即购买")])])])])])}),1)],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }