<!-- 收货地址 -->

<template>
  <div>
    <van-nav-bar
      title="收货地址"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mcontact">
      <van-address-list
        :switchable="false"
        v-model="chosenContactId"
        :list="listData"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
      >
      </van-address-list>
    </div>

    <!-- 添加收货地址 -->
    <van-popup
      v-model="dialogVisible"
      position="bottom"
      :style="{ height: '100%', backgroundColor: '#f7f8fa' }"
    >
      <van-nav-bar
        title="收货地址"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />

      <div style="padding: 15px">
        <van-form>
          <van-field
            v-model="ruleForm.deliveryPerson"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
          />

          <van-field
            v-model="ruleForm.deliveryPhone"
            name="联系电话"
            label="联系电话"
            placeholder="联系电话"
            :rules="[{ required: true, message: '请输入联系电话' }]"
          />

          <van-field
            is-link
            readonly
            v-model="fieldValue"
            label="地区"
            placeholder="请选择所在地区"
            @click="dialogsRegionVisible = true"
          />

          <van-field
            v-model="ruleForm.deliveryAddress"
            name="详细地址"
            label="详细地址"
            placeholder="详细地址"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />

          <div style="margin: 16px 0px">
            <van-button round block type="danger" @click="onSubmit()"
              >提交</van-button
            >
          </div>

          <div style="margin: 16px 0px">
            <van-button round block @click="deleteRow()">删除</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>

    <van-popup v-model="dialogsRegionVisible" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="treeList"
        :field-names="fieldNames"
        @close="dialogsRegionVisible = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>
<script>
import {
  getAddressList,
  regionTree,
  address,
  deleteRess,
  updateRess,
} from "@/api/useraddress";
import { Toast } from "vant";

import { Dialog } from "vant";

export default {
  data() {
    return {
      fieldValue: undefined,
      fieldNames: {
        text: "regionName",
        value: "id",
        children: "children",
      },
      dialogsRegionVisible: false,
      cascaderValue: "",

      ruleForm: {
        deliveryPerson: undefined,
        deliveryPhone: undefined,
        deliveryAddress: undefined,

        //省
        regionProvince: undefined,
        regionProvinceName: undefined,

        regionCity: undefined,
        regionCityName: undefined,

        regionCountry: undefined,
        regionCountryName: undefined,
      },
      dialogVisible: false,
      listData: [],
      chosenContactId: "1",
      list: [
        {
          id: "1",
          name: "张三",
          tel: "13000000000",
          isDefault: true,
        },
        {
          id: "2",
          name: "李四",
          tel: "1310000000",
        },
      ],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      treeList: [],
    };
  },
  created() {
    //查询收货地址
    this.loadData();

    //查询收货地址
    this.getRegionTree();
  },
  methods: {
    loadData() {
      new Promise((resolve, rejust) => {
        getAddressList(this.queryParam)
          .then((res) => {
            this.listData = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    getRegionTree() {
      new Promise((resolve, rejust) => {
        regionTree({})
          .then((res) => {
            this.treeList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 删除收货地址
     */
    deleteRow() {
      Dialog.confirm({
        title: "",
        message: "确认删除吗?",
      })
        .then(() => {
          let id = {
            id: this.ruleForm.id,
          };
          new Promise((resolve, rejust) => {
            deleteRess(id)
              .then((res) => {
                console.log(res);
                if (res.status == 0) {
                  Toast.success({
                    message: "删除成功",
                  });
                  this.dialogVisible = false;
                  this.loadData();
                }
                resolve();
              })
              .catch((error) => {
                rejust(error);
              });
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    /**
     * 新增收货地址
     */
    onSubmit() {
      if (this.ruleForm.id) {
        new Promise((resolve, rejust) => {
          updateRess(this.ruleForm)
            .then((res) => {
              console.log(res);
              if (res.status == 0) {
                Toast.success({
                  message: "修改成功",
                });
                this.dialogVisible = false;
                this.loadData();
              }
              resolve();
            })
            .catch((error) => {
              rejust(error);
            });
        });
      } else {
        new Promise((resolve, rejust) => {
          address(this.ruleForm)
            .then((res) => {
              console.log(res);
              if (res.status == 0) {
                Toast.success({
                  message: "添加成功",
                });
                this.dialogVisible = false;
                this.loadData();
              }
              resolve();
            })
            .catch((error) => {
              rejust(error);
            });
        });
      }
      //   new Promise((resolve, rejust) => {
      //     address(this.ruleForm)
      //       .then((res) => {
      //         console.log(res);
      //         if (res.status == 0) {
      //           Toast.success({
      //             message: "添加成功",
      //           });
      //           this.dialogVisible = false;
      //           this.loadData();
      //         }
      //         resolve();
      //       })
      //       .catch((error) => {
      //         rejust(error);
      //       });
      //   });
    },

    onAdd() {
      this.fieldValue = undefined;

      this.ruleForm.deliveryPerson = undefined;
      this.ruleForm.deliveryPhone = undefined;
      this.ruleForm.deliveryAddress = undefined;

      //省
      this.ruleForm.regionProvince = undefined;
      this.ruleForm.regionProvinceName = undefined;

      this.ruleForm.regionCity = undefined;
      this.ruleForm.regionCityName = undefined;

      this.ruleForm.regionCountry = undefined;
      this.ruleForm.regionCountryName = undefined;

      this.dialogVisible = true;
    },

    //选择地区
    onFinish({ selectedOptions }) {
      //省
      this.ruleForm.regionProvince = selectedOptions[0].id;
      this.ruleForm.regionProvinceName = selectedOptions[0].regionName;

      this.ruleForm.regionCity = selectedOptions[1].id;
      this.ruleForm.regionCityName = selectedOptions[1].regionName;

      this.ruleForm.regionCountry = selectedOptions[2].id;
      this.ruleForm.regionCountryName = selectedOptions[2].regionName;

      this.dialogsRegionVisible = false;
      this.fieldValue = selectedOptions
        .map((option) => option.regionName)
        .join("/");
    },

    onClickLeft() {
      // this.$router.push("/my");
      this.$router.back();
    },

    onEdit(row) {
      this.ruleForm.id = row.id;

      this.ruleForm.deliveryPerson = row.name;
      this.ruleForm.deliveryPhone = row.tel;
      this.ruleForm.deliveryAddress = row.address;

      this.dialogVisible = true;
    },
    // onSelect(contact) {
    //   Toast('选择' + contact.id);
    // },
  },
};
</script>
