<template>
  <div class="balance-page">
    <a-card :bordered="false">
      <!-- 资金明细 -->
      <span class="member-user-title">资金明细</span>

      <div>
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="6">
            <div class="balance-container" style="background-color: #f5f7fd">
              <div class="balance-title">余额</div>
              <span class="balance-amount">{{ balanceTotal }} </span>
            </div>
          </a-col>

          <a-col class="gutter-row" :span="6">
            <div class="balance-container" style="background-color: #f5f7fd">
              <div class="balance-title">佣金</div>
              <span class="balance-amount">{{ balanceAllowTransfer }}</span>
              <a-button
                type="link"
                @click.native="handleSettlement"
                v-preventClick
              >
                结算到余额
              </a-button>
            
            </div>
          </a-col>

          <a-col class="gutter-row" :span="6">
            <div class="balance-container" style="background-color: #f5f7fd">
              <div class="balance-title">正在提现</div>
              <span class="balance-amount">{{ totalWithdrawing }}</span>
            </div>
          </a-col>

          <a-col class="gutter-row" :span="6">
            <div class="balance-container" style="background-color: #f5f7fd">
              <div class="balance-title">累计提现</div>
              <span class="balance-amount">{{ totalWithdrawSuccess }}</span>
            </div>
          </a-col>
        </a-row>
      </div>

      <!-- <div class="balance-box" style="margin-bottom: 20px">
        <div class="money-box">
          <div class="money-item">
            <p>{{ balanceTotal }}</p>
            <span>可用余额(元)：</span>
          </div>
          <div class="money-item">
            <p>{{ balanceAllowTransfer }}</p>

            <span>我的佣金(元)：</span>
            <el-button
              type="text"
              @click.native="handleSettlement"
              v-preventClick
            >
              结算到余额
            </el-button>
          </div>

          <div class="money-item">
            <p>{{ totalWithdrawing }}</p>

            <span>正在提现(元)：</span>
          </div>

          <div class="money-item">
            <p>{{ totalWithdrawSuccess }}</p>

            <span>累计提现(元)：</span>
          </div>
        </div>
      </div> -->

      <a-tabs default-active-key="1">
        <a-tab-pane tab="资金明细" key="1">
          <a-table :dataSource="tableData" :pagination="false">
            <a-table-column title="订单号" key="serialNum">
              <template slot-scope="text, record">
                {{ record.serialNum }}
              </template>
            </a-table-column>

            <a-table-column title="交易类型" key="eventText">
              <template slot-scope="text, record">
                {{ record.eventText }}
              </template>
            </a-table-column>

            <a-table-column title="交易金额" key="amount">
              <template slot-scope="text, record">
                {{ record.amount }}
              </template>
            </a-table-column>

            <a-table-column title="收支类型" key="typeText">
              <template slot-scope="text, record">
                {{ record.typeText }}
              </template>
            </a-table-column>

            <a-table-column title="交易时间" key="datelineReadable">
              <template slot-scope="text, record">
                {{ record.datelineReadable }}
              </template>
            </a-table-column>

            <a-table-column title="备注" key="remarks">
              <template slot-scope="text, record">
                {{ record.remarks }}
              </template>
            </a-table-column>
          </a-table>
          <!-- 分页 -->
          <div style="float: right; padding: 20px">
            <pagination
              :total="total"
              :current-page="queryParams.pageNum"
              :page-size="queryParams.pageSize"
              @currentChange="handleCurrentChange"
              @editPagesizes="editPageSizes"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane tab="佣金明细" key="2">
          <el-table
            border
            ref="singleTable"
            :data="commissionData"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column
              align="center"
              property="serialNum"
              label="订单号"
            />
            <!-- <el-table-column
              align="center"
              property="productName"
              label="来源订单商品"
            /> -->

            <el-table-column
              align="center"
              property="amount"
              label="收益金额"
            />

            <el-table-column
              align="center"
              property="commissionTypeText"
              label="佣金类型"
            />
            <el-table-column
              align="center"
              property="statusText"
              label="佣金状态"
            />
            <el-table-column
              align="center"
              property="datelineReadable"
              label="创建时间"
            />
          </el-table>
        </a-tab-pane>

        <a-tab-pane tab="充值记录" key="3">
          <el-table
            border
            ref="singleTable"
            :data="rechargeList"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column
              align="center"
              property="serialNum"
              label="订单号"
            />

            <el-table-column
              align="center"
              property="payTypeText"
              label="支付方式"
            />

            <el-table-column
              align="center"
              property="amount"
              label="充值金额"
            />

            <el-table-column
              align="center"
              property="commission"
              label="手续费"
            />

            <el-table-column
              align="center"
              property="payAmount"
              label="支付金额"
            />

            <el-table-column align="center" property="status" label="支付状态">
              <template slot-scope="scope">
                <el-tag
                  effect="plain"
                  v-if="scope.row.status === 0"
                  size="small"
                  type="danger"
                >
                  等待支付
                </el-tag>

                <el-tag
                  effect="dark"
                  v-if="scope.row.status === 1"
                  size="small"
                >
                  充值成功
                </el-tag>

                <el-tag
                  effect="dark"
                  type="danger"
                  v-if="scope.row.status === 2"
                  size="small"
                >
                  充值失败
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              property="createTime"
              label="创建时间"
            />

            <el-table-column
              align="center"
              property="receiptTime"
              label="到账时间"
            />
          </el-table>
        </a-tab-pane>

        <a-tab-pane tab="提现记录" key="4">
          <el-table
            border
            ref="singleTable"
            :data="withdrawList"
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column
              align="center"
              property="platformAccount"
              label="收款账号"
            />

            <el-table-column
              align="center"
              property="platformPerson"
              label="真实姓名"
            />

            <el-table-column
              align="center"
              property="platformText"
              label="收款平台"
            />

            <el-table-column
              align="center"
              property="amount"
              label="提现金额(元)"
            />

            <el-table-column
              align="center"
              property="amountFee"
              label="手续费(元)"
            />

            <el-table-column
              align="center"
              property="amountActual"
              label="实际到账(元)"
            />

            <el-table-column
              align="center"
              property="statusText"
              label="提现状态"
            >
            </el-table-column>

            <el-table-column
              align="center"
              property="datelineCreateReadable"
              label="创建时间"
            />
          </el-table>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import { list } from "@/api/balance";

import { queryRechargeList } from "@/api/recharge";
import { getWithdrawList } from "@/api/withdraw";
import { commissionList } from "@/api/commisson";

import { mapState } from "vuex";
export default {
  data() {
    return {
      rechargeList: [],
      activeName: "1",
      commissionData: [],
      itemData: [
        [
          { icon: require("@/assets/icon/1.png"), title: "可用金额", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "累计充值", num: 0 },
        ],
        [
          { icon: require("@/assets/icon/1.png"), title: "冻结金额", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "累计提现", num: 0 },
        ],
        [
          { icon: require("@/assets/icon/1.png"), title: "可提现", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "提现中", num: 0 },
        ],
      ],
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      withdrawList: [],
    };
  },
  components: {
    // Card,
    // Button,
  },
  filters: {
    price(value) {
      return "￥" + value;
    },
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      balanceAllowTransfer: (state) => state.balance.balanceAllowTransfer,

      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      totals: (state) => state.balance.totals,
      statusText: (state) => state.user.statusText,
    }),
  },
  created() {
    this.$store.dispatch("user/info");
    this.$store.dispatch("balance/detail").then(() => {
      this.itemData[0][0].num = this.balanceTotal;
      this.itemData[0][1].num = this.totalRechargeSuccess;
      this.itemData[1][0].num = this.totalWithdrawRejected;
      this.itemData[1][1].num = this.totalWithdrawSuccess;
      this.itemData[2][0].num = this.balanceCanWithdraw;
      this.itemData[2][1].num = this.totalWithdrawing;
    });
    //资金明细
    this.loadData();

    //查询佣金明细
    this.getCommission();

    //查询余额充值记录
    this.loadRechargeList();

    //查询提现申请记录
    this.queryWithdrawList();
  },
  methods: {
    /**
     * 结算到余额
     */
    handleSettlement() {
      this.$store.dispatch("commission/cash").then(() => {
        this.$router.push("/balance");
      });
    },

    /**
     * 查询佣金明细
     */
    getCommission() {
      new Promise((resolve, rejust) => {
        commissionList({})
          .then((res) => {
            this.commissionData = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询列表
     */
    loadData() {
      new Promise((resolve, rejust) => {
        list(this.queryParams)
          .then((res) => {
            this.tableData = res.result.list;
            this.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询充值列表
     */
    loadRechargeList() {
      new Promise((resolve, rejust) => {
        queryRechargeList({})
          .then((res) => {
            this.rechargeList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    queryWithdrawList() {
      new Promise((resolve, rejust) => {
        getWithdrawList({})
          .then((res) => {
            this.withdrawList = res.result.list;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParams.pageSize = val;
      this.loadData();
    },

    goCash() {
      let mainRoute = this.$route.path.split("/")[1];
      let subRoute;

      if (mainRoute === "balance") {
        subRoute = "cash";
      } else if (mainRoute === "balance2") {
        subRoute = "cash2";
      } else if (mainRoute === "balance3") {
        subRoute = "cash3";
      } else {
        return;
      }
      this.$router.push(subRoute);
    },
  },
};
</script>

<style scoped>
.custom-descriptions .el-descriptions-item__label {
  font-weight: bold;
  color: #333;
}

.custom-descriptions .el-descriptions-item__content {
  color: #666;
}

.custom-descriptions .el-descriptions-item {
  padding: 8px 0;
}

.custom-descriptions .el-descriptions__item {
  border-bottom: 1px solid #eee;
}

.highlight-text {
  color: red;
  font-weight: bold;
}

.balance-box .money-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 60px;
}

.balance-box .money-box .money-item {
  display: inline-block;
  text-align: center;
}

.balance-box .money-box .money-item span {
  color: #969696;
  font-size: 14px;
}
.balance-box .money-box .money-item p {
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;
  color: #e93323;
}

.balance-box .tab-box {
  margin: 30px 0 0;
}

/* styles.css */
.balance-container {
  padding: 20px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
}

.balance-title {
  color: #7d8592;
  font-size: 14px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.balance-amount {
  display: inline-block;
  font-size: 22px;
  color: #3d4a5d;
  line-height: 1;
  font-family: impact;
}

.balance-description {
  display: block;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
}
</style>
