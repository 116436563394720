import requestJSON from '@/utils/requestJSON'

import request from '@/utils/request'


// 查询图片分类树
export function cateTreeList(query) {
    return request({
        url: '/api/vip/zhuangxiu/querUploadfileTree',
        method: 'get',
        params: query
    })
}



// 查询图片列表
export function getImageList(query) {
    return request({
        url: '/api/vip/zhuangxiu/querUploadfileList',
        method: 'get',
        params: query
    })
}


// 删除图片
export function deleteImgeList(id) {
    return request({
        url: '/api/vip/zhuangxiu/' + id,
        method: 'delete'
    })
}


// 修改图片分类id
export function updateImageCateId(data) {
    return requestJSON({
        url: '/api/vip/zhuangxiu/updateImageCateId',
        method: 'post',
        data: data
    })
}


//============图片分类=============

// 新增图片分类树
export function insertImageCate(data) {
    return requestJSON({
        url: '/api/vip/zhuangxiu/category',
        method: 'post',
        data: data
    })
}


// 修改图片分类树
export function updateImageCate(data) {
    return requestJSON({
        url: '/api/vip/zhuangxiu/category',
        method: 'put',
        data: data
    })
}

// 删除图片分类树
export function delImageCate(id) {
    return request({
        url: '/api/vip/zhuangxiu/category/' + id,
        method: 'delete'
    })
}