<template>
  <div class="module-common pc_style">
    <div class="tuwen_1_style pc_style">
      <div class="body-container-grid">
        <div
          class="img-item"
          style="border-radius: 4px; padding-top: 41.67%"
          v-for="(item, index) in module.list"
          :key="index"
        >
          <a :href="item.link" target="_blank">
            <img
              class="category-nav-default"
              :src="item.imageUrl"
              :onerror="$defaultImage"
            />
            <!-- <div class="category-nav-default">
              <div class="default-text">图文导航</div>
            </div> -->
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryBannerList } from "@/api/index";

export default {
  props: ["module"],
  data() {
    return {
      bannerList: [],
    };
  },

  created() {
    //获取轮播图
    this.getBannerList();
  },
  methods: {
    /**
     * 查询轮播图
     */
    getBannerList() {
      let data = { banType: "PC_2" };

      queryBannerList(data).then((response) => {
        if (response.status == 0) {
          this.bannerList = response.result;
        }
      });
    },
  },
};
</script>

<style>
.tuwen_1_style .body-container-grid {
  display: grid;
  -webkit-justify-content: center;
  justify-content: center;
  justify-items: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}

.img-item {
  position: relative;
  overflow: hidden;
}

.category-nav-default {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  background: #f1f3f7;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.category-nav-default .default-img {
  height: 27px;
  width: 32px;
}

.category-nav-default .default-text {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  color: #bed4fa;
  line-height: 18px;
}
</style>
