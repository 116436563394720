import requestJSON from '@/utils/requestJSON'

import request from '@/utils/request'

// // 获取模板列表
// export function getTemplateList(data) {
//     return requestJSON({
//         url: '/api/admin/template/list',
//         method: 'post',
//         data
//     })
// }




// /**
//  * 修改
//  * @param {}} data 
//  * @returns 
//  */
// export function usingTemplates(data) {
//     return requestJSON({
//         url: '/api/admin/template/usingTemplates',
//         method: 'post',
//         data
//     })
// }


// /**
//  * 新增模板记录
//  * @param {}} data 
//  * @returns 
//  */
// export function insertHTemplate(data) {
//     return requestJSON({
//         url: '/api/admin/template/insertHTemplate',
//         method: 'post',
//         data
//     })
// }

// // 删除【模板记录】
// export function delTemplate(id) {
//     return request({
//         url: '/api/admin/template/' + id,
//         method: 'delete'
//     })
// }



/**
 * 修改装修页面
 * @param {}} data 
 * @returns 
 */
export function updateTenantTemplate(data) {
    return requestJSON({
        url: '/api/vip/zhuangxiu/template/updateTenantTemplate',
        method: 'post',
        data
    })
}



/**
 * 初始化页面
 * @param {}} data 
 * @returns 
 */
export function initPageStyle(data) {
    return requestJSON({
        url: '/api/vip/zhuangxiu/template/initPageStyle',
        method: 'post',
        data
    })
}