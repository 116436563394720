<!-- 自助收款 -->

<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>自助收银付款</span>
      </div>
      <div class="dx2-cashier">
        <el-alert
          title="操作提示:"
          type="success"
          description="扫码付款之后点击我已付款,系统将尽快进行审核。审核通过后，款项将会直接充值到您的余额。如需加急审核，请联系客服进行催促。"
          show-icon
        >
        </el-alert>
        <!---->
        <div class="ant-card-body">
          <div class="dx2-cashier-body">
            <div class="dx2-cashier-body-money">
              ￥{{ this.rechargeDetails.amount }}
            </div>
            <div style="margin-top: 20px">
              <div style="width: 280px; margin: 0 auto">
                <el-image
                  :src="payDetails.qrCode"
                  :preview-src-list="srcList"
                  @click="handleImageClick(payDetails.qrCode)"
                ></el-image>
              </div>
              <!---->
            </div>

            <div style="padding-top: 15px">
              <el-input
                v-model="outTradeNo"
                style="width: 300px"
                placeholder="请输入转账订单号"
              ></el-input>
            </div>

            <div style="padding: 20px">
              <el-button
                type="primary"
                @click="submitZhuanZhaPayment"
                size="small"
                v-preventClick
              >
                我已付款，点击查看订单
              </el-button>
            </div>

            <div style="color: red">
              提示：必须付款：
              {{ this.rechargeDetails.amount }}
              元。否则将付款失败。 <br />付款后请联系客服确认支付结果。
            </div>
          </div>
          <div class="dx2-cashier-tip">
            <div class="dx2-cashier-tip-scan">
              <span class="anticon anticon-scan" style="font-size: 55px"
                ><svg
                  focusable="false"
                  data-icon="scan"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  viewBox="64 64 896 896"
                >
                  <path
                    d="M136 384h56c4.4 0 8-3.6 8-8V200h176c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H196c-37.6 0-68 30.4-68 68v180c0 4.4 3.6 8 8 8zm512-184h176v176c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V196c0-37.6-30.4-68-68-68H648c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zM376 824H200V648c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v180c0 37.6 30.4 68 68 68h180c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm512-184h-56c-4.4 0-8 3.6-8 8v176H648c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h180c37.6 0 68-30.4 68-68V648c0-4.4-3.6-8-8-8zm16-164H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"
                  ></path>
                </svg>
              </span>
            </div>
            <div class="dx2-cashier-tip-text">
              <div>请使用 支付APP 扫一扫</div>
              <div>扫描二维码完成支付</div>
            </div>
          </div>
        </div>
        <!---->
      </div>
    </el-card>
  </div>
</template>

<script>
import { queryPayDetails } from "@/api/payConfig";

import { confirmZhuanZhang, queryRechargeDetails } from "@/api/recharge";

export default {
  name: "Payment",

  data() {
    return {
      rechargeDetails: undefined,

      payDetails: {},
      srcList: [],
      stepsActive: 1,
      dialogZhuanZhangVisible: false,
      dialogPayTipVisible: false,
      dialogVisible: false,
      payData: {},
      outTradeNo: undefined,
    };
  },
  created() {
    this.getPayDetails();

    //查询充值订单详情
    this.getRechargeDetails();
  },
  methods: {
    /**
     * 查询支付详情
     */
    getPayDetails() {
      let data = {
        id: this.$route.params.id,
      };
      //在线支付
      new Promise((resolve, rejust) => {
        queryPayDetails(data)
          .then((res) => {
            if (res.status == 0) {
              this.payDetails = res.result;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //查询充值订单详情
    getRechargeDetails() {
      let data = {
        id: this.$route.params.rechargeId,
      };
      //在线支付
      new Promise((resolve, rejust) => {
        queryRechargeDetails(data)
          .then((res) => {
            if (res.status == 0) {
              this.rechargeDetails = res.result;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 点击放大图片
     */
    handleImageClick(img) {
      const images = [img];
      this.srcList = images;
    },

    /**
     * 转账支付提交
     */
    submitZhuanZhaPayment() {
      //在线支付
      let data = {
        id: this.rechargeDetails.id,
        outTradeNo: this.outTradeNo,
      };
      new Promise((resolve, rejust) => {
        confirmZhuanZhang(data)
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                message: "提交成功,正在充值中",
                type: "success",
              });

              this.$router.push("/user_info");
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style>
.dx2-cashier {
  min-height: calc(100vh - 150px);
}

.dx2-cashier-header {
  margin: 8px 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dx2-cashier-header-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 12px;
}

.dx2-cashier-header-img img {
  width: 30px;
  height: 30px;
}

.dx2-cashier-header-title {
  font-size: 16px;
  font-weight: 700;
}

.dx2-cashier-body {
  margin-top: 32px;
  text-align: center;
}

.dx2-cashier-body-money {
  color: #fa2c19;
  font-size: 32px;
}

.dx2-cashier-body-qrcode {
  width: 210px;
  height: 210px;
  margin: 0 auto;
  margin-top: 20px;
}

.dx2-cashier-detail {
  margin-top: 48px;
}

.dx2-cashier-tip {
  margin: 32px 0 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dx2-cashier-tip-scan {
  color: #fa2c19;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dx2-cashier-tip-text {
  margin-left: 23px;
  text-align: left;
  color: #515a6e;
  font-size: 16px;
  line-height: 27px;
}

.dx2-layout {
  min-width: 1332px;
}

.dx2-body {
  margin: 116px auto 16px;
  width: 1300px;
  min-height: auto;
}

.dx2-body-breadcrumb {
  height: 48px;
  line-height: 48px;
  padding-left: 18px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.dx2-body-breadcrumb a,
.dx2-body-breadcrumb-name {
  font-weight: 400;
  color: #fff;
}

.dx2-body-breadcrumb a:hover {
  color: #f1ebe9;
}

.dx2-copyright {
  text-align: center;
  padding: 16px;
  color: #808695;
}

.dx2-footer-index {
  width: 100%;
}

.dx2-footer {
  background: transparent;
  padding: 0 16px;
  margin: 0 auto;
}

.dx2-other-login {
  height: calc(100vh - 150px);
  min-height: 500px;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
}

.dx2-input-color .zs-color-picker-btn {
  border: 0 !important;
}

.dx2-input-color .zs-color-picker-panel {
  right: 0;
}

.dx2-input-color-left .zs-color-picker-btn {
  border: 0 !important;
}

.dx2-input-color-left .zs-color-picker-panel {
  left: 0;
}

.zs-color-picker-panel__visible {
  z-index: 999 !important;
}

.ant-typography {
  color: inherit;
}

.dx2-body-breadcrumb.ant-breadcrumb .ant-breadcrumb-separator,
.dx2-body-breadcrumb
  .dx2-layout-header-left.ant-breadcrumb
  .ant-breadcrumb-separator {
  color: #fff;
}

.dx2-body-breadcrumb a {
  display: inline;
}
</style>
