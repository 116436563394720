<template>
  <div style="z-index: 99999">
    <div>
      <van-popup
        v-model="dialogVisible"
        position="bottom"
        :style="{ height: '100%', backgroundColor: 'rgb(214 60 64)' }"
      >
        <div style="padding: 5px; margin-top: 70px">
          <el-carousel
            @change="handleCarouselChange"
            type="card"
            height="420px"
            :autoplay="false"
          >
            <el-carousel-item v-for="(item, index) in haiBaoList" :key="index">
              <img style="width: 100%; height: 100%" :src="item.url" />
            </el-carousel-item>
          </el-carousel>

          <div style="padding: 10px">
            <van-button
              type="warning"
              block
              round
              color="#fe8a55"
              @click="shareHaiBao()"
            >
              生成专属海报
            </van-button>
          </div>

          <div style="padding: 10px" @click="dialogVisible = false">
            <van-button plain block round type="info"> 关闭 </van-button>
          </div>
        </div>
      </van-popup>
    </div>

    <van-dialog v-model="qrcodeVisible" confirm-button-text="关闭">
      <div>
        <vue-canvas-poster
          v-if="showCanvasPoster"
          :painting="painting"
          @success="success"
        >
        </vue-canvas-poster>
        <img v-else :src="posterImg" style="width: 100%" />

        <!-- 提示 -->
        <div style="text-align: center; font-size: 12px">
          温馨提示：长按图片保存到本地
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Payment",
  props: {
    payConfig: {
      type: Object,
    },
    amount: {
      type: Number, //充值金额
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentImage: undefined,
      haiBaoList: [
        {
          url: "/image/poster-1.png",
        },
        {
          url: "/image/poster-3.png",
        },
        {
          url: "/image/poster-4.png",
        },
        {
          url: "/image/poster-5.png",
        },
        {
          url: "/image/poster-6.png",
        },
        {
          url: "/image/poster-7.png",
        },
        {
          url: "/image/poster-8.png",
        },
        {
          url: "/image/poster-9.png",
        },
        {
          url: "/image/poster-10.png",
        },
      ],
      showCanvasPoster: true,
      posterImg: undefined,
      qrcodeVisible: false,
      srcList: [],
      stepsActive: 1,
      dialogZhuanZhangVisible: false,
      dialogPayTipVisible: false,
      dialogVisible: false,
      payData: {},
      outTradeNo: undefined,

      //海报初始化
      painting: {
        width: "786px",
        height: "1499px",
        background: require("@/assets/haibao.png"),
        views: [
          {
            type: "image",
            url: "",
            css: {
              top: "0px",
              left: "0px",
              borderRadius: "0px",
              width: "786px",
              height: "1299px",
            },
          },
          {
            type: "qrcode",
            content: "",
            css: {
              bottom: "40px",
              right: "50px",
              color: "#000",
              background: "#fff",
              width: "140px",
              height: "140px",
              borderWidth: "5px",
              borderColor: "#fff",
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      inviteUrl: (state) => state.user.inviteUrl,
    }),
  },
  created() {
    // 在组件创建时设置当前图片
    this.currentImage = this.haiBaoList[0].url;
  },
  methods: {
    //拉起弹框
    SubmitOpen() {
      this.dialogVisible = true;
    },

    handleCarouselChange(index) {
      console.log(index);
      console.log(this.haiBaoList[index].url);
      // 根据索引获取当前选中的图片
      this.currentImage = this.haiBaoList[index].url;
    },

    // 海报生成
    success(src) {
      console.log("来了 老铁");
      this.posterImg = src;
      this.showCanvasPoster = false;
    },

    /**
     * 分享商品
     */
    shareHaiBao() {
      this.qrcodeVisible = true;
      this.painting.views[0].url = this.currentImage; // 使用require函数加载图片资源
      this.painting.views[1].content = this.inviteUrl;
      this.showCanvasPoster = true;
    },
  },
};
</script>

<style>
.mFenXiang .canvas {
  position: relative;
  top: 20px;
  width: 100% !important;
  height: 100% !important;
}
</style>
