import request from '@/utils/request'

/**
 * 查询用户收货地址
 */
export function getAddressList(data) {
    return request({
        url: '/api/user/address/list',
        method: 'post',
        data
    })
}


/**
 * 新增用户收货地址
 */
export function address(data) {
    return request({
        url: '/api/user/address/save',
        method: 'post',
        data
    })
}



/**
 * 查询行政区划树
 */
export function regionTree(data) {
    return request({
        url: '/api/region/tree',
        method: 'post',
        data
    })
}

/**
 * 修改收货地址
 */
export function updateRess(data) {
    return request({
        url: '/api/user/ress/update',
        method: 'post',
        data
    })
}


/**
 * 删除收货地址
 */
export function deleteRess(data) {
    return request({
        url: '/api/user/ress/delete',
        method: 'post',
        data
    })
}