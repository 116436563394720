import request from '@/utils/request'
// import requestForm from '@/utils/requestPic'
// import requestJSON from '@/utils/requestJSON'



/**
 * 查询系统公告
 * @param {*} data 
 * @returns 
 */
export function getNoticeList(data) {
    return request({
        url: '/api/sys_notice/list',
        method: 'post',
        data
    })
}


/**
 * 根据id查询系统公告
 * @param {*} data 
 * @returns 
 */
// 查询卡密详细
export function getNoticeById(id) {
    return request({
        url: '/api/sys_notice/' + id,
        method: 'get'
    })
}