<template>
  <div style="position: relative">
    <div @click="toSearch()">
      <van-sticky>
        <van-search
          readonly
          shape="round"
          :placeholder="module.style.text"
          :background="module.style.bgcolor"
        />
      </van-sticky>
    </div>
  </div>
</template>
<script>
export default {
  props: ["module"],
  methods: {
    toSearch() {
      this.$router.push("/search");
    },
  },
};
</script>
