import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Dialog } from "vant";
import { MessageBox } from 'element-ui'

// import LoginModal from '../components/LoginModal.vue';
// import Vue from 'vue';
import { Message } from 'element-ui'
import { Toast, } from "vant";

import { getToken, getUserId, getGuestToken } from '@/utils/auth'
// import { Loading } from 'element-ui';

const service = axios.create({
        baseURL: process.env.VUE_APP_BASE_API,
        timeout: 100000, // request timeout
        headers: { 'Content-Type': 'application/json' }
    })
    // const Loadings = Loading;

// request interceptor
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['token'] = getToken()
            config.headers['userId'] = getUserId()
        }
        config.headers['shopping_cart_token'] = getGuestToken()
            // startLoading()
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
            // endLoading();

        //游客登录操作    
        if (res.status === 441) {
            if (isMobile()) {
                Dialog.confirm({
                        title: '',
                        message: '您还未登录,点击游客快捷登录~',
                        confirmButtonText: "游客登录",
                    })
                    .then(() => {
                        // router.push("/login");
                        store.dispatch("user/visitorLogin", {}).then(() => {
                            Toast.success({
                                message: "登录成功"
                            })
                        });

                    })
                    .catch(() => {

                    });

            } else {
                MessageBox.confirm('您还未登录,点击游客快捷登录~', {
                    confirmButtonText: '游客登录',
                    type: 'success'
                }).then(() => {

                    // router.push("/plogin");
                    store.dispatch("user/visitorLogin", {}).then(() => {
                        location.reload()

                        Message({
                            message: "登录成功",
                            type: 'success',
                            duration: 5 * 1000
                        })
                    });

                }).catch(() => {

                });
            }
            return;
        }


        // 未登录操作  
        if (res.status === 41) {
            if (isMobile()) {
                router.push("/login");

            } else {
                router.push("/plogin");
            }
            return;
        }

        if (res.status !== 0) {
            //判断pc和移动端不一样的提示
            if (isMobile()) {
                //移动端
                Toast.fail({
                    message: res.message || 'Error'
                })
            } else {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })

            }
            if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
                console.log('error')
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        // endLoading()
        console.log('err' + error) // for debug    
        return Promise.reject(error)
    }
)

// let loading;

// function startLoading() {
//     const options = {
//         lock: true,
//         text: '正在加载中....',
//         // spinner: 'el-icon-loading',
//         background: 'rgba(255, 255, 255, 0)',
//         customClass: "customClass"
//     }
//     loading = Loading.service(options)
// }


// //结束
// function endLoading() {

//     setTimeout(() => {
//         loading.close();
//     }, 1);
// }


//结束
function isMobile() {

    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
}

export default service