<!-- pc登录页 -->
<template>
  <div class="login_p">
    <Verify @success="success" :mode="'pop'" :captchaType="'clickWord'" :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"></Verify>

    <div class="page-account">
      <div class="page-account-container">
        <div class="page-account-top">
          <div class="page-account-top-desc">账号注册</div>
        </div>

        <a-form-model style="margin-top: 10px" ref="loginForm" :model="loginForm" :rules="loginRules">
          <a-form-model-item prop="username">
            <a-input placeholder="请输入账号" v-model="loginForm.username" size="large">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item>

          <a-form-model-item prop="password">
            <a-input placeholder="至少6位密码" v-model="loginForm.password" size="large">
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-model-item>

          <a-form-model-item prop="checkPass">
            <a-input placeholder="请确认密码" v-model="loginForm.checkPass" size="large">
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-model-item>

          <!-- 验证码 -->
          <a-form-model-item>
            <div>
              <Verify @success="success" :mode="'pop'" :captchaType="'clickWord'"
                :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>

              <div class="captcha_intelli-control" @click="useVerify">
                <div class="captcha_intelli-tips">
                  <span class="captcha_intelli-icon">
                    <img src="@/assets/image/captcha.png" style="width: 22px" />
                  </span>

                  <span class="captcha_intelli-text">
                    <template v-if="!verifyStatus"> 点击完成验证 </template>
                    <template v-else> 验证成功 </template>
                  </span>
                </div>
              </div>
            </div>
          </a-form-model-item>

          <a-button type="primary" block style="height: 40px" @click.native.prevent="handleRegister" v-preventClick>
            注册
          </a-button>
        </a-form-model>

        <div style="float: right; padding: 30px 0px">
          <div>
            <router-link to="/plogin">
              <a-button type="link" style="padding: 0px">
                使用已有账户登录
              </a-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getUserId } from "@/utils/auth";
import { getSysXieYi, getLoginNotice, getQuickLogin } from "@/api/index";
import {
  getScanLogin,
  sendPhoneCode,
  resetPwdVerifyCode,
  pwdReset,
} from "@/api/user";
import { mapState } from "vuex";

// import Captcha from "@/components/captcha/CaptchaB.vue";
import Verify from "@/components/verifition/Verify";

export default {
  components: { Verify },

  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("请输入不少于6位的密码"));
      } else {
        if (this.loginForm.checkPass !== "") {
          this.$refs.registerForm.validateField("checkPass");
        }
        callback();
      }
    };

    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateCheckPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };

    var validateCheckVerificationCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      loginRules: {
        username: [
          { required: true, message: "请输入帐户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPass: [{ required: true, message: "请确认密码", trigger: "blur" }],
      },
      //重置密码发送邮件
      resetPassEmail: {
        phoneOrEmail: undefined,
      },

      //1登录 2注册 3忘记密码
      loginStatus: 1,

      dialogPrivacyAgreement: false,
      dialogUseAgreement: false,
      getUserId: getUserId(),
      rules: {
        username: [{ validator: validateUserName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validateCheckPass, trigger: "blur" }],
        phoneNumber: [{ validator: validateCheckPhone, trigger: "blur" }],
        smsVerificationCode: [
          { validator: validateCheckVerificationCode, trigger: "blur" },
        ],
      },
      dialogLoginVisible: true,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
        platformType: "1",
        validateCaptcha: undefined,
      },

      xieyiObj: {},
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
      inviter_code: "",
      //true是登录 false是注册
      LoginRegister: true,
      loginNoticeVisible: false,
      loginNoticeContent: undefined,
      quickLoginList: [],
      verifyStatus: false,
    };
  },
  computed: {
    ...mapState({
      //查询网站系统配置
      setting: (state) => state.index.setting,
      webSetting: (state) => state.index.webSetting,
    }),
  },

  mounted() {
    // // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = "accountLogin";
    } else if (this.setting.phoneLogin) {
      this.activeName = "phoneLogin";
    } else {
      this.activeName = "accountLogin";
    }
  },
  created() {
    //有推广码的话授权
    if (localStorage.getItem("inviterCode")) {
      this.loginForm.inviterCode = localStorage.getItem("inviterCode");
    }

    //加载协议
    this.loadXieYi();

    //加载登录公告
    this.queryLoginNotice();

    //加载快捷登录配置
    this.loadQuickLogin();
  },
  methods: {
    /**
     * 验证成功
     */
    success(params) {
      //验证成功
      this.verifyStatus = true;

      this.loginForm.validateCaptcha = params.captchaVerification;
    },

    /**
     * 点击验证
     */
    useVerify() {
      this.$refs.verify.show();
    },

    /**
     * 注册
     *
     * */
    handleRegister(formName) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/register", this.loginForm)
            .then(() => {
              this.$store.dispatch("user/login", this.loginForm).then(() => {
                Toast.success({
                  message: "注册成功",
                });
                this.dialogLoginVisible = false;

                this.$router.push("/index");
              });
            })
            .catch(() => { });
        } else {
          return false;
        }
      });
    },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    closeLogin() {
      this.dialogLoginVisible = false;
      this.$emit("close"); // 触发关闭事件，通知父组件
    },
  },
};
</script>
