<template>
  <div
    class="mobile-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]"
  >
    <div>
      <div>
        <div class="aui-flex">
          <span class="aui-flex-box">
            <van-icon name="fire" size="18" color="#ee0a24" /> 推荐商品
          </span>
          <div @click="tohotProduct()">
            <van-icon name="arrow" />
          </div>
        </div>
        <!-- <div class="aui-grids"></div> -->
      </div>
    </div>

    <div class="flex-shrink-0 has-footer">
      <div class="kind-show goods-list">
        <!---->
        <div class="goods-content">
          <div
            class="goods-item"
            v-for="(v, k, i) in listData"
            :key="i"
            :title="v.productName"
            @click="toProductDetails(v)"
          >
            <div class="goods-img">
              <el-image
                style="background-position: 0% 0%; background-size: 100% 100%"
                :src="v.picUrl"
              ></el-image>
            </div>

            <div class="product_centent">
              <span class="name">{{ v.productName }}</span>
              <div class="fun">
                <span
                  style="
                    font-size: 16px;
                    font-weight: 500;
                    color: #f84d43;
                    font-size: 16px;
                  "
                  >￥{{ v.price }}</span
                >

                <!-- 按钮 -->
                <div style="padding: 0px 10px 10px 0px">
                  <van-icon name="cart-circle" size="24" color="#eb6253" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      listData: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
    }),
  },
  created() {
    //初始化
    this.loadData();
  },
  methods: {
    loadData() {
      let data = { offset: 0, limit: 10 };

      //前台获取推荐产品列
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.listData = this.recommendList;
      });

      // //前台获取热卖产品列表
      // this.$store.dispatch("index/queryHotList", data).then(() => {
      //   this.hotLists = this.hotList;
      // });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/product_detail/${v.id}`);
    },

    /**
     * 跳转到热门商品列表页面
     */
    tohotProduct() {
      this.$router.push("/hotProduct");
    },
  },
};
</script>

<style>
.uni-scroll-view {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-view-content {
  width: 100%;
  height: 100%;
}

.goods-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}

.goods-content .goods-item {
  width: 100%;
  /* height: 6.25rem; */
  background: #ffffff;
  box-shadow: 0 0.125rem 0.1875rem #f5f7fa;
  border-radius: 0.3125rem;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 0.625rem;
  padding: 0.625rem;
}

.goods-content .goods-item .goods-img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 5px;
  overflow: hidden;
}

.goods-content .goods-item .product_centent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  /* gap: 10px; */
}

.goods-content .goods-item .product_centent .name {
  color: #191e2e;
  font-weight: 600;
  /* font-size: 0.8125rem; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14.375rem; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 42px;
}

.goods-content .goods-item .product_centent .fun {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.goods-content .goods-item .product_centent .fun .money {
  font-weight: 500;
  color: #f84d43;
  font-size: 1.125rem;
}

.goods-content .goods-item .product_centent .fun .money .default-money {
  font-weight: 400;
  color: #d2d5dd;
  font-size: 0.6875rem;
  text-decoration: line-through;
  margin-left: 0.53125rem;
}

.goods-content .goods-item .product_centent .fun .buy-btn {
  width: 4.0625rem;
  height: 1.5625rem;
  background: linear-gradient(90deg, #fe8a56 0%, #ff504b 100%);
  border-radius: 0.78125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}

/* 推荐框 */
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 150px; */
  text-align: center;
  /* background-color: #39a9ed; */
}

.aui-flex {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 15px 5px 8px;
  position: relative;
  /* background: linear-gradient(to bottom, #ffccd7, white); */
  /* background: linear-gradient(#fef0eb 0%, rgb(255, 255, 255) 50%); */
  background-color: #ffffff;
  /* border-bottom: 1px solid #f4f0f0; */
}

.aui-flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.reco_gd {
  position: relative;
  font-size: 16px;
}

.reco_gd a {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  /* gap: 0.375rem; */
}

.aui-grids {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin-top: 15px; */
  background-color: #ffffff;
}

.aui-grids-well {
  position: relative;
  /* float: left; */
  /* padding: 5px 10px 0px 10px; */
  /* width: 20%; */
  box-sizing: border-box;
}

.aui-grids-well-img {
  width: 60px;
  height: 60px;
  padding: 4px;
  margin: 0 auto;
}

.aui-grids-well-text {
  text-align: center;
  font-weight: 500;
  /* margin-top: 8px; */
}
</style>
