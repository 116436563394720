<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
    }
  ]">
    <van-row>
      <van-col span="24">
        <div>
          <div>
            <div class="aui-flex">
              <span class="aui-flex-box">
                {{module.style.text}}
              </span>
              <div style="font-size: 0.8rem;font-weight: 400;color: #999;" @click="tohotProduct()">
                <a>查看更多<van-icon name="arrow" /></a>
              </div>
            </div>
            <div class="recommend-grids">
              <div class="choice_list">
                <van-swipe class="recommend" :autoplay="2000" indicator-color="white" :width="90">
                  <van-swipe-item v-for="(value, key, index) in listData" :key="index">
                    <div class="choice_item" @click="toProductDetails(value)">
                      <div class="choice_img_view">
                        <van-image class="choice_img" fit="contain" :src="value.picUrl" />
                      </div>
                      <div class="choice_name">{{ value.productName }}</div>
                      <div class="choice_price">
                        <span class="choice_symbol"> <span>￥</span> </span>{{ value.price }}
                      </div>
                    </div>
                  </van-swipe-item>
                </van-swipe>
              </div>
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      listData: [
        {
          productImg: "http://img.huanhuanle.cn/2023-10-11-1592009509.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-11-1592009509.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-11-1592009509.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-11-1592009509.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-11-1592009509.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          price: "2.5"
        }
      ]
    };
  },
  computed: {}
};
</script>

<style scoped>
/* 推荐框 */
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 150px; */
  text-align: center;
  /* background-color: #39a9ed; */
}

.aui-arrow {
  position: relative;
  font-size: 16px;
}

.aui-arrow a {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  /* gap: 0.375rem; */
}

.recommend-grids {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin-top: 15px; */
  background-color: #ffffff;
}

.choice_list {
  /* width: 161px; */
  margin: 10px 10px 10px 10px;
}

.choice_item {
  max-width: 78px;
}

.choice_img_view {
  width: 78px;
  height: 71px;
  background-color: #f7f7f7;
  border-radius: 6px;
  /* padding-top: 17px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.choice_name {
  /* width: 17rpx; */
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-left: -6rpx; */
  margin-top: 4px;
  text-align: center;
  color: black;
}

.choice_price {
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #ff1c2f;
  margin-top: 3px;
  text-align: center;
}

.choice_symbol {
  font-size: 9px;
}

.choice_title {
  height: 20px;
  font-size: 14px;
  color: #323232;
  margin-left: 11px;
  font-weight: 700;
}

.choice_title_text {
  /* padding: 5px 0px 0px 0px; */
  margin-left: 1px;
}

.choice_title_text>span {
  font-size: 10px;
  color: #fff;
  font-weight: 400;
}

.recommend_view {
  /* width: 347px; */
  background-color: #fff;
  border-radius: 6px;
  margin: 1px auto 0;
  box-sizing: border-box;
}

.recommend_title {
  font-size: 13px;
  color: #1e1e1e;
  padding-left: 18px;
  padding-top: 13px;
}

.recommend_con {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 20px;
}

.recommend_item {
  max-width: 25%;
  width: 25%;
  font-size: 13px;
  color: #545454;
  padding-bottom: 22px;
}

.recommend_img {
  width: 41px;
  height: 41px;
  margin-bottom: 6px;
}

.image-view-area,
.image-view-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.image-view-img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.between {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

uni-page-head {
  display: none;
}

.more_title {
  font-size: 15px;
  color: #838383;
}

.choice_img {
  width: 100%;
  /* 设置图片宽度为父容器的宽度 */
  height: 100%;
  /* 自适应高度，保持原始宽高比例 */
  aspect-ratio: 12/9;
  /* 设置宽高比例为 16:9 （根据实际情况进行调整）*/
}
</style>
