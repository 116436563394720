var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc_style module-common"},[_c('div',{staticClass:"banner_1_style"},[_c('div',{staticClass:"index-wrap-new"},[_c('div',{staticClass:"main-area01-new"},[_c('div',{staticClass:"flex-box"},[_c('div',{staticClass:"left-card"},[_c('div',{staticClass:"left-box"},[_c('div',{staticClass:"fenlei_title"},[_c('span',{staticStyle:{"color":"#333"},on:{"click":function($event){return _vm.toCategory()}}},[_vm._v(" 更多分类 ")])]),_c('ul',{staticClass:"left-game-list"},_vm._l((_vm.catesList.slice(0, 5)),function(item,key,index){return _c('a',{key:index},[_c('li',[_c('div',{staticClass:"txt"},[_c('p',[_vm._v(_vm._s(item.categoryName))]),_c('div',{staticClass:"category-container"},_vm._l((item.children.slice(
                          0,
                          3
                        )),function(value,key,index){return _c('span',{key:index,staticClass:"category-item",on:{"click":function($event){return _vm.toProductList(value)}}},[_vm._v(_vm._s(value.categoryName)+" ")])}),0)]),_c('a-icon',{staticStyle:{"color":"#efefef"},attrs:{"type":"play-circle","theme":"filled"}})],1)])}),0)])]),_c('div',{staticClass:"banner-box-bg"},[_c('div',{staticClass:"banner-box-new"},[_c('el-carousel',{staticStyle:{"height":"400px"}},_vm._l((_vm.module.list),function(item,index){return _c('el-carousel-item',{key:index,staticStyle:{"height":"400px"}},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('el-image',{staticStyle:{"border-radius":"10px","width":"100%","height":"100%"},attrs:{"src":item.imageUrl}})],1)])}),1)],1)]),(this.loginStatus)?[_c('div',{staticClass:"right-card"},[_c('div',{staticStyle:{"height":"240px"}},[_c('a-avatar',{attrs:{"size":64,"src":_vm.avatarPicUrl}}),_c('div',{staticClass:"name"},[_c('h2',[_vm._v(_vm._s(_vm.username))]),_c('div',{staticClass:"tip-member-box member-row-box"},[_vm._v(" "+_vm._s(_vm.levelName)+" ")])]),_c('ul',{staticClass:"order-info"},[_c('li',[_c('a-button',{directives:[{name:"preventClick",rawName:"v-preventClick"}],attrs:{"type":"primary"},on:{"click":_vm.toUserInfo}},[_vm._v(" 个人中心 ")])],1),_c('li',[_c('a-button',{directives:[{name:"preventClick",rawName:"v-preventClick"}],on:{"click":function($event){return _vm.handleLogout()}}},[_vm._v("退出登录")])],1)])],1),_vm._m(0)])]:[_c('div',{staticClass:"right-card"},[_c('div',{staticStyle:{"height":"240px"}},[_vm._m(1),_c('ul',{staticClass:"order-info"},[_c('li',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.clickLogin()}}},[_vm._v(" 立即登录 ")])],1)])]),_vm._m(2)])]],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"border-top":"1px solid #e8e8e8"}},[_c('div',{staticClass:"order-box-no"},[_c('div',[_c('div',{staticClass:"title2"},[_vm._v("海量精品商品等您翻牌")]),_c('span',[_vm._v("快去浏览购买吧")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"64px"},attrs:{"src":require("@/assets/user/avatar.png")}}),_c('div',{staticStyle:{"margin-top":"20px"}},[_vm._v("Hi，欢迎回来")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"border-top":"1px solid #e8e8e8"}},[_c('div',{staticClass:"order-box-no"},[_c('div',[_c('div',{staticClass:"title2"},[_vm._v("海量精品商品等您翻牌")]),_c('span',[_vm._v("快去浏览购买吧")])])])])
}]

export { render, staticRenderFns }