<template>
  <div>
    <div class="full-wrapper">
      <div class="full-container">
        <div class="price-header">
          <div class="breadcrumb">
            <a href="/" class="router-link-active"> 首页 - 近期调价记录 </a>
          </div>
          <div style="margin-right: 20px">
            <div>
              <el-input placeholder="请输入关键字" size="small"></el-input>
            </div>
          </div>
        </div>
        <div class="price-table">
          <div style="padding: 15px">
            <el-table :data="tableData" style="width: 100%" border>
              <el-table-column prop="date" label="商品图片" align="center">
                <template slot-scope="scope">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="scope.row.productImg"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="productName"
                label="商品名称"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="toProductDetails(scope.row.productId)"
                    v-preventClick
                    >
                  {{ scope.row.productName }}
                  </el-button>
                </template>
              </el-table-column>

              <el-table-column
                prop="createTime"
                label="调价时间"
                align="center"
              >
              </el-table-column>

              <el-table-column prop="priceFront" label="调价前" align="center">
              </el-table-column>

              <el-table-column prop="priceAfter" label="调价后" align="center">
              </el-table-column>

              <el-table-column
                prop="priceChangesStatus"
                label="变化"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag
                    type="danger"
                    effect="dark"
                    size="small"
                    v-if="scope.row.priceChangesStatus == 1"
                  >
                    <img
                      src="@/assets/image/zhangjia.png"
                      style="width: 25px"
                    />
                    涨价
                  </el-tag>

                  <el-tag
                    type="success"
                    effect="dark"
                    size="small"
                    v-else-if="scope.row.priceChangesStatus == 2"
                  >
                    <img
                      src="@/assets/image/jiangjia.png"
                      style="width: 25px"
                    />
                    降价
                  </el-tag>

                  <el-tag
                    effect="dark"
                    size="small"
                    v-else-if="scope.row.priceChangesStatus == 3"
                  >
                    商品上架
                  </el-tag>

                  <el-tag
                    type="warning"
                    effect="dark"
                    size="small"
                    v-else-if="scope.row.priceChangesStatus == 4"
                  >
                    商品下架
                  </el-tag>
                </template>
              </el-table-column>

              <!-- <el-table-column prop="address" label="状态" align="center">
              </el-table-column> -->
            </el-table>
          </div>

          <div class="el-pagination is-background">
            <!-- 分页 -->
            <div style="float: right; margin: 16px 0">
              <pagination
                :total="total"
                :current-page="queryParam.pageNum"
                :page-size="queryParam.pageSize"
                @currentChange="handleCurrentChange"
                @editPagesizes="editPageSizes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryProductMonitorLog } from "@/api/product";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      tableData: [],

      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      productList: [],
    };
  },

  created() {
    this.initData();
  },

  methods: {
    /**
     * 查询卡组信息
     */
    initData() {
      var then = this;
      new Promise((resolve, rejust) => {
        queryProductMonitorLog(this.queryParam)
          .then((res) => {
            then.tableData = res.result.list;
            then.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 跳转到商品详情
     */
    toProductDetails(id) {
      this.$router.push(`/pgoodsDetail/${id}`);
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },
  },
};
</script>
<style>
.price-header {
  background: url("https://demo.kasushou.com/template/front/default/static/img/backgroundttt.7c51b044.png")
    no-repeat;
  background-size: cover;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 18px 18px 0 0;
  overflow: hidden;
}

.price-header .breadcrumb {
  margin-left: 20px;
}

.price-header .breadcrumb a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.price-header .breadcrumb a img {
  margin-right: 10px;
}

.price-header .breadcrumb a:hover {
  color: #fff;
}

.price-table {
  min-height: 660px;
  background: #fff;
}

.full-wrapper {
  min-width: 1300px;
}

.full-wrapper .container {
  padding: 16px 0 60px;
  margin-top: 120px;
}

.full-wrapper .full-container {
  width: 1300px;
  margin: 0 auto;
  padding: 16px 0 60px;
}
</style>
