var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
    { 'border-radius': _vm.module.base.round + 'px' },
  ])},[_c('div',{staticStyle:{"background-color":"#ffffff"}},[_c('van-grid',{staticClass:"menu_class",attrs:{"column-num":_vm.module.max,"border":false}},_vm._l((_vm.module.list),function(item,index){return _c('van-grid-item',{key:index},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('van-badge',{attrs:{"content":item.mentuBadge}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('van-image',{staticStyle:{"width":"48px","height":"48px","border-radius":"5px"},attrs:{"round":"","src":item.imageUrl,"lazy-load":""}}),_c('div',{staticStyle:{"font-size":"12px","color":"#303133","text-align":"center"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])],1)])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }