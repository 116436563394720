<template>
  <div>
    <div class="mcontact">
      <!---->
      <div>
        <img
          class="bgcImg"
          width="100%"
          src="@/assets/kf_bg.png"
          draggable="false"
        />
        <div class="contact_content" style="padding-top: 0px">
          <div class="mainContent">
            <div class="titleCon">
              <img
                width="100%"
                class="hello"
                src="@/assets/contact2.png"
                draggable="false"
              />
              <img
                class="mascot"
                width="100%"
                src="@/assets/kefuchatu.png"
                draggable="false"
              />
            </div>

            <div
              style="
                padding: 0px 10px;
                margin: 10px;
                background-color: #fff;
                border-radius: 10px;
              "
            >
              <van-grid :border="false">
                <van-grid-item
                  v-for="(item, index) in configList"
                  :key="index"
                  @click="toCustomer(item)"
                >
                  <van-image
                    round
                    :src="item.icon"
                    style="width: 40px; height: 40px; border-radius: 5px"
                    lazy-load
                    />

                  <!-- 自定义文字 -->
                  <div style="padding-top: 10px; font-weight: 600">
                    {{ item.label }}
                  </div>
                </van-grid-item>
              </van-grid>
            </div>

            <!-- 系统公告 -->
            <div
              style="
                padding: 0px 10px;
                margin: 10px;
                background-color: #fff;
                border-radius: 10px;
              "
            >
              <div style="padding: 10px">
                <div
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    padding: 10px 0px 5px 0px;
                  "
                >
                  平台公告
                </div>
                <van-empty
                  description="未发布公告信息~"
                  v-if="noticeList.length < 1"
                />

                <div style="padding: 12px 0px 10px 0px">
                  <div class="contact_title">
                    <div style="background-color: #ffffff">
                      <van-tabs @click="toPCate">
                        <van-tab
                          :title="item.cateName"
                          v-for="(item, index) in noticeList"
                          :key="index"
                          :name="item.id"
                        >
                          <van-empty v-if="item.contentList.length < 1">
                            <template slots="description">
                              <span style="color: #969799">
                                {{ item.cateName }}没有内容~
                              </span>
                            </template>
                          </van-empty>

                          <div
                            style="margin-top: 20px"
                            v-for="(item1, index) in item.contentList"
                            :key="index"
                            @click="toNoticeDetails(item1)"
                          >
                            <van-row gutter="20" style="padding: 5px">
                              <van-col span="1">
                                <span class="serial_number">
                                  {{ index + 1 }}
                                </span>
                              </van-col>
                              <van-col span="21">
                                <div style="margin-top: 2px">
                                  {{ item1.contentTitle }}
                                </div>
                              </van-col>
                              <!-- <van-col span="2">
                                <van-icon name="arrow" />
                              </van-col> -->
                            </van-row>
                            <van-divider />
                          </div>
                        </van-tab>
                      </van-tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :destroy-on-colse="true"
      title="联系客服"
      :visible.sync="customerVisible"
      append-to-body
      width="90%"
    >
      <div v-if="customerFrom.uploadImg">
        <img :src="customerFrom.uploadImg" style="width: 100%" />
      </div>

      <!-- 客服联系方式 -->
      <div>
        <van-field
          v-model="customerFrom.value"
          :label="customerFrom.label"
          readonly
        />
      </div>

      <!-- 联系客服 -->
      <div v-if="customerFrom.link" style="margin-top: 10px">
        <a :href="customerFrom.link" target="_blank">
          <van-button type="info" block>联系客服</van-button>
        </a>
      </div>
    </el-dialog>

    <NavModule />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Toast } from "vant";
import { ImagePreview } from "vant";
import NavModule from "@/pages/mobile/common/navModule.vue";
import { queryNewsList } from "@/api/news";

export default {
  components: { NavModule },

  name: "contact",
  data() {
    return {
      noticeList: [],
      customerFrom: {},
      customerVisible: false,
      // contactQQ: "",
      // contactWeChat: "",
      // contactQRUrl: "",
      // contactPhone: "",
      phoneNum: "",
      qqNum: "",
      qqQrCode: null,
      qqQrCodeImg: "",
      wechatNum: "",
      wechatQrCode: null,
      wechatQrCodeImg: "",
      configList: [],
    };
  },
  computed: {
    ...mapGetters(["token"]),
    ...mapState({
      customerDetail: (state) => state.index.customerDetail,
    }),
  },
  created() {
    document.title = "联系客服";

    this.reload();
    //加载系统公告
    this.initNotice();
  },
  methods: {
    /**
     * 查询首页公告
     */
    initNotice() {
      queryNewsList({}).then((response) => {
        if (response.status == 0) {
          this.noticeList = response.result;
        }
      });
    },

    /**
     * 展示公告详情
     */
    toNoticeDetails(data) {
      this.$router.push({ name: "mNoticeDetails", params: { id: data.id } });
    },

    /**
     * 客服弹框
     */
    toCustomer(item) {
      this.customerVisible = true;
      this.customerFrom = item;
    },

    //页面加载
    reload() {
      let data = {};
      this.$store.dispatch("index/getCustomerSetting", data).then(() => {
        this.configList = this.customerDetail.result;
      });
    },

    copyWeChat() {
      // if (this.contactWeChat == null || this.contactWeChat == "") {
      //   Toast.fail({
      //     message: "商家未配置微信客服",
      //   });
      // }
      const input = document.createElement("input");
      input.setAttribute("value", this.contactWeChat);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success({
        message: "官方微信已复制",
      });
    },

    copyQQ() {
      // if (this.contactQQ == null || this.contactQQ == "") {
      //   Toast.fail({
      //     message: "商家未配置QQ客服",
      //   });
      // }
      const input = document.createElement("input");
      input.setAttribute("value", this.contactQQ);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success({
        message: "官方QQ已复制",
      });
    },

    /**
     * 点击放大图片
     */
    handleImageClick(img) {
      const images = [img];
      ImagePreview({ images });
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/mindex");
    },
  },
};
</script>
<style>
.mcontact {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.mcontact .bgcImg {
  width: 100vw;
  height: 100vh;
}

.mcontact .bgcView {
  width: 100vw;
  height: 40vh;
  background: linear-gradient(0deg, #f41c0d 0%, #ff5731 100%);
}

.mcontact .contact_content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.mcontact .contact_content .navback {
  height: 1rem;
}

.mcontact .contact_content .navback img {
  width: 1rem;
  height: 1rem;
  margin-left: 0.625rem;
}

/* .mcontact .contact_content .mainContent {
  margin-top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
} */

.mcontact .contact_content .back img {
  width: 0.75rem;
  height: 0.75rem;
}

.mcontact .contact_content .titleCon {
  height: 9.5rem;
  position: relative;
}

.mcontact .contact_content .titleCon .hello {
  width: 14rem;
  /* height: 8rem; */
  position: absolute;
  left: 1rem;
  bottom: 0.875rem;
}

.mcontact .contact_content .titleCon .mascot {
  width: 7rem;
  /* height: 9.4375rem; */
  position: absolute;
  right: 1.625rem;
  bottom: -0.9375rem;
}

.mcontact .contact_content .titleCon .name {
  margin-top: 1.15625rem;
  height: 0.96875rem;
  font-size: 1rem;
  font-family: Adobe Heiti Std;
  font-weight: 400;
  color: #433e65;
  line-height: 2.375rem;
}

.mcontact .contact_content .titleCon .titleConH1 {
  font-family: TsangerFeiBai;
  font-weight: 700;
  font-size: 1.9375rem;
  color: #fff;
  line-height: 2.5625rem;
  position: absolute;
  left: 1.625rem;
  bottom: 4.375rem;
}

.mcontact .contact_content .titleCon .titleConH2 {
  font-family: TsangerFeiBai;
  font-weight: 400;
  font-size: 1.5625rem;
  color: #fff;
  line-height: 2.5625rem;
  position: absolute;
  left: 1.625rem;
  bottom: 1.875rem;
}

.mcontact .contact_content .formContent {
  margin-top: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;
  z-index: 2;
}

.mcontact .contact_content .formContent .formContentbox {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    transparent 0,
    transparent 90px,
    #ffffff 90px,
    #ffffff 100%
  );
}

.mcontact .contact_content .formContent .choiceItem {
  border-radius: 1rem 1rem 0 0;
  background-color: rgba(255, 255, 255, 0.6);
  height: 2.8125rem;
  /* display: flex; */
  line-height: 2.8125rem;
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666;
}

.mcontact .contact_content .formContent .choiceItem .item {
  width: 50%;
  text-align: center;
}

.mcontact .contact_content .formContent .choiceItem .active {
  border-radius: 1rem 1rem 0 0;
  position: relative;
  width: 50%;
  text-align: center;
  color: #ff3000;
  background-color: #fff;
}

.mcontact .contact_content .formContent .choiceItem .active:after {
  display: table;
  content: "";
  width: 1.25rem;
  height: 0.1875rem;
  background-color: #ff3000;
  transform: translate(-50%);
  position: absolute;
  left: 50%;
  bottom: 0.25rem;
}

.mcontact .contact_content .formContent .wapper {
  padding: 1.875rem 1.71875rem 0;
  background-color: #fff;
}

.mcontact .contact_content .formContent .wapper .formItem {
  overflow: hidden;
}

.mcontact .contact_content .formContent .wapper .formItem uni-input {
  box-sizing: border-box;
  padding: 0 0.875rem;
  width: 18.75rem;
  height: 3.0625rem;
  line-height: 3.0625rem;
  background-color: #f2f6f9;
  border-radius: 0.4375rem;
  margin-bottom: 1rem;
}

uni-input {
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
}

uni-input[hidden] {
  display: none;
}

.uni-input-wrapper,
.uni-input-placeholder,
.uni-input-form,
.uni-input-input {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: inherit;
}

.uni-input-wrapper,
.uni-input-form {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.uni-input-placeholder,
.uni-input-input {
  width: 100%;
}

.uni-input-placeholder {
  position: absolute;
  top: auto !important;
  left: 0;
  color: gray;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
}

.uni-input-input {
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.uni-input-input[type="search"]::-webkit-search-cancel-button,
.uni-input-input[type="search"]::-webkit-search-decoration {
  display: none;
}

.uni-input-input::-webkit-outer-spin-button,
.uni-input-input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.uni-input-input[type="number"] {
  -moz-appearance: textfield;
}

.mcontact .contact_content .formContent .wapper .option {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff3000;
}

.mcontact .contact_content .formContent .wapper .login {
  margin-top: 1.875rem;
  width: 18.75rem;
  height: 2.8125rem;
  line-height: 2.8125rem;
  background: #ff3000;
  border-radius: 0.4375rem;
  font-size: 0.9375rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
}

.mcontact .contact_content .formContent .wapper .or {
  margin: 1.25rem 0;
  text-align: center;
}

.mcontact .contact_content .formContent .wapper .OneClickLogin {
  width: 18.75rem;
  height: 2.8125rem;
  line-height: 2.8125rem;
  background: #ffe5df;
  border-radius: 0.4375rem;
  font-size: 0.9375rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff3000;
}

.mcontact .contact_content .formContent .agreement {
  margin-left: 24px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 0.9375rem;
  line-height: 0.9375rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999;
}

.mcontact .contact_content .formContent .agreement .TextContent .text {
  font-weight: 500;
  color: #3288cb;
}

.mcontact .contact_content .formContent.isForgetPassword {
  background-color: #fff;
  margin-top: 2.125rem;
  width: 22.1875rem;
  margin-left: 0.625rem;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;
  padding: 0 1.71875rem;
  transform: translateY(-1.5625rem);
}

.mcontact .contact_content .paddingCss {
  padding: 0 0.625rem;
}

.OneClickLoginCss {
  width: 100%;
  background: #ffffff;
  border-radius: 1.375rem 1.375rem 0 0;
  padding: 3.125rem 2.34375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OneClickLoginCss .WeChatAvatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-bottom: 0.8125rem;
}

.OneClickLoginCss .WeChatName {
  font-family: PingFang SC;
  font-weight: 700;
  font-size: 1.0625rem;
  color: #333;
  text-align: center;
  margin-bottom: 2.6875rem;
}

.OneClickLoginCss .OneClickLogin {
  width: 100%;
  height: 2.8125rem;
  background: #ff3000;
  border-radius: 0.4375rem;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mlogin_tabs .van-tabs {
  font-size: 16px;
}

.mlogin_tabs .van-tabs__nav {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 1rem 1rem 0 0;
}

.mlogin_tabs .van-tab--active {
  border-radius: 1rem 1rem 0 0;
  position: relative;
  width: 50%;
  text-align: center;
  color: #ff3000;
  background-color: #fff;
}

.mcontact .more-login {
  width: 100%;
  margin-top: 4.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.15625rem;
}

.mcontact .more-login .text {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.875rem;
  position: relative;
}

.mcontact .more-login .login-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.mcontact .more-login .text :before {
  content: "";
  width: 4.375rem;
  height: 0.03125rem;
  background: #d6dae2;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 5.125rem;
}

.mcontact .more-login .text :after {
  content: "";
  width: 4.375rem;
  height: 0.03125rem;
  background: #d6dae2;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 5.125rem;
}

.mcontact .more-login .login-icon-box .icon {
  width: 2rem;
  height: 2rem;
  color: #cacdd5;
}

/deep/.el-dialog {
  border-radius: 10px;
}

/* ========================== */
/* 新样式 */
/* // 设置整个标签栏样式 */
.contact_title .van-tab--active {
  border-radius: 50px;
  background-color: rgb(58, 144, 255);
  font-size: 14px;
  color: #fff;
  margin: 8px;
}

.contact_title .van-tabs__line {
  display: none;
}

.contact_title .van-tabs__nav {
  /* background-color: transparent; */
}

.contact_title .van-tabs__nav--line.van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
}

.contact_title .van-grid-item__text {
  font-size: 14px;
}

.serial_number {
  font-family: "Georgia", serif;
  background: linear-gradient(to right, #fecf36, #ff0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
}
</style>
