<template>
  <div>
    <el-card shadow="never" style="margin-top: 20px">
      <div class="user-item">
        <el-button type="primary" @click.native="handleSettlement" v-preventClick>
          结算到余额（{{ balanceAllowTransfer | price }}）
        </el-button>

        <p class="title" style="margin-bottom: 26px; margin-top: 5px">
          先转入余额，再申请提现
        </p>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-statistic title="我的收益">
            <template slot="formatter">
              <span class="price_color" style="margin-top: 10px">
                {{ balanceAll | price }}
              </span>
            </template>
          </el-statistic>
        </el-col>
        <el-col :span="6">
          <el-statistic title="可结算收益">
            <template slot="formatter">
              <span class="price_color" style="margin-top: 10px">
                {{ balanceAllowTransfer | price }}
              </span>
            </template>
          </el-statistic>
        </el-col>
        <el-col :span="6">
          <el-statistic title="锁定收益">
            <template slot="formatter">
              <span class="price_color" style="margin-top: 10px">
                {{ balanceIsRejected | price }}
              </span>
            </template>
          </el-statistic>
        </el-col>

        <el-col :span="6">
          <el-statistic title="结转中">
            <template slot="formatter">
              <span class="price_color" style="margin-top: 10px">
                {{ balanceIsTransfering | price }}
              </span>
            </template>
          </el-statistic>
        </el-col>
      </el-row>

      <!-- <el-card shadow="never" style="margin-top: 20px"> -->
      <el-divider>收益明细</el-divider>
      <br />
      <el-table border ref="singleTable" :data="tableData" highlight-current-row style="width: 100%">
        <el-table-column align="center" property="serialNum" label="订单号" />
        <el-table-column align="center" property="amount" label="收益金额" />
        <el-table-column align="center" property="commissionTypeText" label="佣金类型" />
        <el-table-column align="center" property="statusText" label="佣金状态" />
        <el-table-column align="center" property="datelineReadable" label="创建时间" />
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {

      tableData: [],
      price: 123,
    };
  },
  components: {},
  computed: {
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.commission.balanceAllowTransfer,
      balanceIsRejected: (state) => state.commission.balanceIsRejected,
      balanceIsTransfering: (state) => state.commission.balanceIsTransfering,
      list: (state) => state.commission.list,
    }),
  },
  filters: {
    price(value) {
      return value;
    },
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.$store.dispatch("commission/detail");
        this.$store.dispatch("commission/list", {}).then(() => {
          this.tableData = this.list;
        });
      }
    },

    handleSettlement() {
      // this.$router.push('/settlement')
      this.$store.dispatch("commission/cash").then(() => {
        // this.$router.push("/balance");
        this.$store.dispatch("commission/detail");
        this.$store.dispatch("commission/list", {}).then(() => {
          this.tableData = this.list;
        });
      });
    },
  },
};
</script>

<style scoped>
p {
  padding: 0px;
  margin: 0px;
}

.balance-page {
  display: flex;
  flex-direction: column;
}

.user-info {
  background: #fff;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-self: center;
}

.user-info-left {
  display: flex;
  width: 100%;
}

.user-info-right {
  display: flex;
}

.right-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 200px;
}

.user-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  margin-right: 20px;
  position: relative;
  width: 100%;
}

.back {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
}

.back:nth-child(n) {
  margin-bottom: 10px;
}

.icon {
  /* width: 20px; */
  height: 26px;
  margin-right: 10px;
}

.row {}

.title {
  font-size: 12px;
  color: #5f5f5f;
}

.price {
  font-size: 10px;
  /* margin-top:4px; */
  color: #1b1b1b;
}

.price_color {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 700;
  color: #e93323;
}
</style>
