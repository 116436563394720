<template>
  <div class="app-container">
    <el-card shadow="never">
      <div class="table-operations">
        <a-button type="primary" size="medium" @click="insertVip()" v-preventClick>
          开通分站
        </a-button>
      </div>

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="userId" label="用户编号" align="center">
        </el-table-column>

        <el-table-column prop="domains" label="分站地址" align="center">

          <template slot-scope="scope">
            <div v-for="(v, i) in scope.row.domains" :key="i">
              {{v}}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <!-- <div style="float: right; margin: 16px 0">
        <pagination
          :total="total"
          :current-page="queryParam.pageNum"
          :page-size="queryParam.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div> -->
    </el-card>

    <!-- 添加分站 -->
    <a-modal
      title="开通分站"
      :visible="insertVipVisible"
      width="40%"
    >

      <el-form :model="vipForm" :rules="rules" ref="vipForm" label-width="100px">
        <el-form-item label="用户编号" prop="userId">
          <a-input v-model="vipForm.userId"></a-input>
        </el-form-item>

        <el-form-item label="分站名称" prop="name">
          <a-input v-model="vipForm.name"></a-input>
        </el-form-item>

        <el-form-item label="套餐版本" prop="mealId">
          <el-radio-group v-model="vipForm.mealId">
            <el-radio v-for="item in mealList" :label="item.id" :key="item.id">
              {{ item.mealName }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="分站域名" prop="prefixDomain">


          <a-input-group compact>

            <a-input style="width: 50%" placeholder="请输入域名前缀" v-model="vipForm.prefixDomain"/>

            <a-select v-model="vipForm.suffixDomain" placeholder="请选择" style="width: 50%;">
              <a-select-option v-for="item in domainList" :key="item.id"  :value="item.domain">
                {{item.domain}}
              </a-select-option>
            </a-select>
          </a-input-group>

          <!-- <div style="display: flex; align-items: center">
            <div>
              <el-input placeholder="请输入域名前缀" v-model="vipForm.prefixDomain" />
            </div>

            <div style="margin-right: 5px">
              <el-select v-model="vipForm.suffixDomain" placeholder="请选择">
                <el-option v-for="item in domainList" :key="item.id" :label="item.domain" :value="item.domain">
                </el-option>
              </el-select>
            </div>
          </div> -->
        </el-form-item>

        <el-form-item label="分站备注">
          <a-input v-model="vipForm.remark"></a-input>
        </el-form-item>


      </el-form>

      <span slot="footer" class="dialog-footer">
        <a-button type="primary" @click="submitForm('vipForm')" v-preventClick>
            立即搭建
          </a-button>
          <a-button @click="insertVipVisible = false">取消</a-button>
        </span>
    </a-modal>  
  </div>
</template>
<script>
import {
  queryVipList,
  queryLowerVipMealList,
  getDomainList,
  vipOpenVip,
} from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      domainList: [],
      mealList: [],
      insertVipVisible: false,
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      vipForm: {
        userId: "",
      },
      rules: {
        userId: [
          { required: true, message: "请输入用户编号", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入分站名称", trigger: "blur" }],
        mealId: [
          { required: true, message: "请选择套餐版本", trigger: "blur" },
        ],
        prefixDomain: [
          { required: true, message: "请输入分站域名前缀", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.verifyIsLogin();

    //查询分站套餐
    this.getLowerVipMealList();

    //查询域名后缀
    this.queryDomainList();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initData();
      }
    },

    /**
     * 加载分站列表
     */
    initData() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        queryVipList(this.queryParam)
          .then((res) => {
            //主站
            this.tableData = res.result.list;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询分站套餐
     */
    getLowerVipMealList() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        queryLowerVipMealList({})
          .then((res) => {
            //主站
            this.mealList = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询域名后缀
     */
    queryDomainList() {
      new Promise((resolve, rejust) => {
        getDomainList({})
          .then((res) => {
            //主站
            this.domainList = res.result.list;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 添加分站
     */
    insertVip() {
      this.insertVipVisible = true;
    },

    /**
     * 确认添加分站
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //主站商品列表
          new Promise((resolve, rejust) => {
            vipOpenVip(this.vipForm)
              .then((res) => {
                if (res.status == 0) {

                  //刷新列表
                  this.initData()

                  this.insertVipVisible = false;

                  this.$notify({
                    title: "提示信息",
                    message: "开通成功",
                    type: "success",
                    duration: 2000,
                  });
                }
                resolve();
              })
              .catch((error) => {
                rejust(error);
              });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },

    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },
  },
};
</script>
