<template>
  <div>
    <!-- <Header /> -->

    <div class="container">
      <div class="order-content">
        <div class="buy-wrapper goods-detail">
          <!-- <div class="title">确认订单</div> -->
          <div class="payment">
            <div class="orderTip">
              <div>订单提交成功！去付款~</div>
              <!-- <div class="times acea-row row-middle">
                <div>剩余时间：</div>
                <div class="time acea-row row-middle">0小时04分钟51秒</div>
              </div> -->
            </div>

            <div class="detail">
              <div class="item line1">
                商品名称：{{ orderDetails.orderFormProduct.productName }}
              </div>

              <div class="item">订单编号：{{ orderDetails.serialNum }}</div>

              <div class="item line1">
                下单数量：{{ orderDetails.orderFormProduct.quantity }}
              </div>

              <div class="item">
                订单价格：<span>{{ orderDetails.amountInitial }}元</span>
              </div>

              <div class="item" v-if="orderDetails.deliveryCost">
                运费价格：<span>{{ orderDetails.deliveryCost }}元</span>
              </div>

              <div class="item line1">
                <el-divider>请确认充值信息</el-divider>

                <div style="padding: 10px 0px 0px 0px">
                  <el-form label-position="left" label-width="120px">
                    <div>
                      <orderParam
                        :orderParams="orderDetails.orderParams"
                        v-model="orderDetails.orderParams"
                      >
                      </orderParam>
                    </div>
                  </el-form>
                </div>
              </div>

              <div class="item line1">
                下单备注：{{ orderDetails.deliveryRemark }}
              </div>

              <!-- <div class="item">
                收货地址： <span>{{ orderDetails.deliveryProvince }}</span>
                <span>{{ orderDetails.deliveryCity }}</span>
                <span>{{ orderDetails.deliveryCountry }}</span>
                <span>{{ orderDetails.deliveryAddress }}</span>
                <span>{{ orderDetails.deliveryPerson }}</span>
                <span>{{ orderDetails.deliveryPhone }}</span>
              </div> -->
            </div>
            <div class="payType">
              <div class="title">支付方式</div>
              <div class="type pay-types">
                <el-radio-group v-model="payConfigDetails">
                  <el-radio border label="balance">
                    余额支付（￥{{ balanceTotal }}）
                  </el-radio>
                  <el-radio
                    border
                    v-for="(value, key, index) in payList"
                    :key="index"
                    :label="value"
                  >
                    {{ value.payName }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div class="goPay acea-row row-right" @click="handleGoBuy()">
                <button
                  type="button"
                  class="el-button btn bg-color el-button--danger"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span>去支付({{ orderDetails.amountFinally }}元)</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Payment
      ref="paymentComponent"
      :orderId="this.$route.query.orderId"
      :payConfig="this.payConfigDetails"
      :orderParams="this.orderDetails.orderParams"
      :deliveryRemark="this.orderDetails.deliveryRemark"
      :addressId="this.addressId"
    ></Payment>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Payment from "@/components/payment/Payment";
import orderParam from "@/components/orderParam.vue";
export default {
  data() {
    return {
      addressId: undefined,
      selectedIndex: undefined,
      payConfigDetails: "balance",
      dialogVisible: false,
      spaecsPirce: 0,
      payList: {},
      rechargeAccount: "",
      orderDetails: [],
      queryParam: {
        specsId: undefined,
        rechargeAccount: undefined,
      },
    };
  },
  components: {
    Payment,
    orderParam,
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      orderDetail: (state) => state.order.orderDetail,
      payConfig: (state) => state.index.payConfig,
      orderState: (state) => state.order.orderState,
      balanceTotal: (state) => state.balance.balanceTotal,
    }),
  },
  created() {
    //查询我的余额
    this.$store.dispatch("balance/detail");

    //初始化支付通道
    this.initPayConfig();
    //获取获取商品详情
    this.getProductDetail();
  },
  methods: {
    //初始化支付通道
    initPayConfig() {
      let data = {};
      this.$store.dispatch("index/queryPayConfig", data).then(() => {
        this.payList = this.payConfig.payList;
        // this.$forceUpdate();
      });
    },

    //获取获取商品详情
    getProductDetail() {
      let id = this.$route.query.orderId;
      this.$store.dispatch("order/detail", id).then(() => {
        this.orderDetails = this.orderDetail;
        // this.$forceUpdate();
      });
    },

    /**
     * 选择收货地址
     */
    selectAddr(item, index) {
      this.selectedIndex = index;
      this.addressId = item.addressId;
    },

    /**
     * 支付确认关闭事件
     */
    handleClose(done) {
      this.$confirm("确认取消支付？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },

    //支付方法
    handleGoBuy() {
      this.$refs.paymentComponent.toPayment();
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/index");
    },
    /**
     * 返回上页
     */
    onClickLeft() {
      this.$router.back();
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>

<style scoped>
.buy-wrapper {
  background-color: #fff;
  padding-bottom: 56px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
  min-height: 732px;
}

.buy-wrapper .title {
  font-size: 18px;
  height: 64px;
  line-height: 64px;
  padding: 0 18px;
}

.buy-wrapper .payment {
  margin: 0 auto;
  margin-top: -30px;
}

.payment .orderTip {
  background: url(../../assets/orderpayment.png) no-repeat;
  background-size: 100%;
  color: #fff;
  font-size: 24px;
  padding: 55px 30px 31px;
}

.payment .detail {
  background-color: #fff;
  min-height: 130px;
  padding: 30px 30px;
}

.payment .orderTip .times {
  font-size: 14px;
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment .detail .item {
  font-size: 15px;
  padding: 10px 0px;
}

.payment .detail .item ~ .item {
  margin-top: 12px;
}

.payment .payType .title {
  border-bottom: 1px dotted #cecece;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 18px;
}

.payment .payType .type {
  padding: 22px 30px 60px 30px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment .payType .type .item.on {
  border-color: #e93323;
}

.payment .payType .type .item {
  border: 1px solid #d4d4d4;
  cursor: pointer;
  height: 68px;
  position: relative;
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.payment .payType .goPay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
}

.payment .payType .goPay .btn {
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  width: 180px;
}

.buy-wrapper .bnt {
  cursor: pointer;
  margin: 38px 20px 0 0;
}

.bg-color {
  background-color: #e93323 !important;
}

.payment .payType .type .item .iconfont.icon-yue1 {
  color: #fe9c01;
  font-size: 32px;
}

.payment .payType .type .item ~ .item {
  margin-left: 20px;
}
</style>
