var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    }
  ])},[_c('van-notice-bar',{attrs:{"left-icon":"volume-o","scrollable":"","color":_vm.module.style.color,"background":_vm.module.style.bgcolor}},[_vm._v(" 欢迎使用数字产品销售系统 ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }