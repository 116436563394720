<template>
  <div style="background-color: #f5f7fa">
    <van-nav-bar
      left-text="返回"
      title="售后管理"
      left-arrow
      @click-left="onClickLeft"
    />

    <van-list
      v-model="loadingState"
      :finished="finished"
      finished-text="没有更多了"
      @load="loadMore"
    >
      <div style="padding: 5px 10px 10px 10px">
        <div
          class="scroll-item"
          v-for="(v, key, index) in refundList"
          :key="index"
        >
          <div class="item-top">
            <div class="order-text">
              订单号：{{ v.orderFormSerialNum }}
              <van-icon name="label" />
            </div>
            <uni-text class="link">
              <span>{{ v.statusText }}</span>
            </uni-text>
          </div>
          <div class="center">
            <van-image
              width="100"
              height="100"
              lazy-load
              :src="v.orderFormRefundProductList[0].picUrl"
            />

            <div class="center-right">
              <span class="center-title">
                <span>{{ v.orderFormRefundProductList[0].productName }}</span>
              </span>

              <div class="center-num">
                <!-- <span class="number">
                  <span>
                    数量：{{ v.orderFormRefundProductList[0].quantity }}
                  </span>
                </span> -->
              </div>
            </div>
          </div>
          <div class="order-bottom">
            <div class="order-bottom-left">
              订单价格:
              <uni-text>
                <span>
                  <uni-text class="money">
                    <span>{{
                      v.orderFormRefundProductList[0].productPriceDeal
                    }}</span>
                  </uni-text>
                </span>
              </uni-text>
            </div>
            <div class="order-bottom-right">
              <van-button type="warning" round size="small" @click="toUrgent(v)"
                >催促处理</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
// import Order from "@/components/orders";
// import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
import { Toast } from "vant";

export default {
  data() {
    return {
      activeName: "first",
      refundList: [],
      total: 0,
      dialogVisible: false,
      orderId: null,
      loadingState: false,
      finished: false,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      isFetching: false, // 是否有正在进行中的请求
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.refund.refundList,
      refundTotal: (state) => state.refund.refundTotal,
    }),
  },
  components: {
    // Order,
    // Pagination,
  },
  created() {
    // this.loadData({ item: 0, number: 1 });
    this.loadData();
  },
  methods: {
    // handleClick(tab) {
    //   this.loadData({ item: tab.$attrs.item, number: 1 });
    // },
    // changeNumber(value) {
    //   this.loadData(value);
    // },

    /**
     * 加载更多商品
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;

      this.loadData();
    },

    loadData() {
      this.isFetching = true;

      this.$store.dispatch("refund/list", this.queryParam).then(() => {
        this.refundList = this.refundList.concat(this.list);
        if (this.list.length == 0) {
          this.finished = true;
        }
        this.loadingState = false;
        this.isFetching = false;
      });
    },

    /**
     * 催促处理
     */
    toUrgent() {
      Toast("已催促尽快处理");
    },

    /**
     * 售后详情
     */
    toServiceDetail(id) {
      console.log(this.dialogVisible);
      this.orderId = id;
      this.dialogVisible = !this.dialogVisible;
    },

    /**
     * 关闭售后详情弹框
     */
    clickClose() {
      this.dialogVisible = !this.dialogVisible;
    },

    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
<style>
.my-order {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 23.4375rem;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
}

.my-order .content-box {
  width: 23.4375rem;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-order .scroll-box {
  width: 23.4375rem;
  height: calc(100vh - 6.25rem);
  position: relative;
  background: #f5f7fa;
  margin-top: 0.625rem;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div-content {
  width: 100%;
  height: 100%;
}

.my-order .scroll-box .scroll-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5625rem;
  gap: 0.9375rem;
}

.scroll-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* width: 22.3125rem; */
  /* height: 12.8125rem; */
  background: #ffffff;
  border-radius: 0.46875rem;
  padding: 0px 10px 15px 10px;
  margin-top: 10px;
}

.scroll-item .item-top {
  margin-top: 0.53125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.scroll-item .item-top .order-text {
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.copy-icon:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.09375rem;
  border: 0.0625rem solid;
  border-color: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.scroll-item .item-top .link {
  color: #fe5f4d;
  font-weight: 600;
  font-size: 14px;
}

.scroll-item .center {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
  margin-top: 1.09375rem;
}

.scroll-item .center .goods-img {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 0.46875rem;
}

.goods-img .goodsImg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

/* .goods-img img {
        -webkit-touch-callout: none;
        user-select: none;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    } */

.scroll-item .center .center-right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.scroll-item .center .center-right .center-title {
  font-weight: 550;
  color: #1e2331;
  font-size: 0.8125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 12.5rem;
}

.scroll-item .center .center-right .center-buy-info {
  margin-top: 0.71875rem;
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.scroll-item .center .center-right .center-num {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.375rem;
  color: #1e2331;
  font-weight: 400;
  font-size: 0.625rem;
}

.scroll-item .center .center-right .center-num .number {
  color: #9b9fa8;
  font-size: 0.8125rem;
}

.scroll-item .order-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.03125rem;
  height: 2rem;
}

.scroll-item .order-bottom .order-bottom-left {
  font-weight: 400;
  color: #1e2331;
  font-size: 0.875rem;
}

.scroll-item .order-bottom .order-bottom-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}

.scroll-item .order-bottom .order-bottom-right .info {
  /* background-color: #fff;
  border: 0.0625rem solid #a0a3ac;
  color: #a0a3ac; */
  background-color: #e11919;
  border: 0.0625rem solid #a0a3ac;
  color: #ffffff;
}

.scroll-item .order-bottom .order-bottom-right .order-btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-right .submit {
  color: #fff;
  background: linear-gradient(90deg, #fe8754 0%, #fe5f4d 100%);
}

.scroll-item .order-bottom .order-bottom-right .btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-left .money {
  color: #fd4d20;
  font-weight: 550;
  font-size: 1.0625rem;
}
</style>
