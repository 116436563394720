<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
      }
    ]"
  >
    <!-- 首页分类栏目 rows -->
    <div class="slide_view">
      <div
        class="recommend_view"
        v-for="(value, key, index) in catesList"
        :key="index"
      >
        <div>
          <div class="recommend_title">
            {{ value.categoryName }}
          </div>
          <div class="recommend_con rows">
            <div
              class="recommend_item column"
              v-for="(goods, key, index) in value.children"
              :key="index"
            >
              <van-image width="40" height="40" :src="goods.prcUrl" />
              <div style="margin-top: 5px;">{{ goods.categoryName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        }
      ]
    };
  },

  computed: {},

  mounted() {},
  methods: {}
};
</script>

<style>
.slide_view {
  background-color: #fff;
  border-radius: 6px;
  /* margin: 10px auto 0; */
}

.slide_view::-webkit-scrollbar {
  display: none;
}

.slide_item {
  min-width: 68px;
  font-size: 13px;
  color: #5b5b5b;
  text-align: center;
}

.sel_slide {
  max-width: 76px;
  height: 26px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #f1d2a9;
  background-color: #333;
  border-radius: 13px;
  margin-right: 18px;
}

.recommend_view {
  /* width: 347px; */
  background-color: #fff;
  border-radius: 6px;
  margin: 1px auto 0;
  box-sizing: border-box;
}

.recommend_title {
  font-size: 13px;
  color: #1e1e1e;
  padding-left: 18px;
  padding-top: 13px;
  font-size: 0.875rem;
  color: #191e2e;
  font-weight: 550;
}

.recommend_con {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 20px;
}

.recommend_item {
  max-width: 25%;
  width: 25%;
  font-size: 13px;
  color: #545454;
  padding-bottom: 22px;
}

.recommend_img {
  width: 41px;
  height: 41px;
  margin-bottom: 6px;
}

.image-view-area,
.image-view-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.image-view-img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

uni-image {
  width: 320px;
  height: 240px;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

uni-image[hidden] {
  display: none;
}

uni-image > div,
uni-image > img {
  width: 100%;
  height: 100%;
}

uni-image > img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0; */
}

uni-image > .uni-image-will-change {
  will-change: transform;
}

.rows {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.between {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* 加载更多数据 */
.loading {
  font-size: 15px;
  color: #a6a6a6;
  text-align: center;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.more_icon {
  width: 4vw;
  height: 4vw;
  margin-right: 2vw;
  animation: circle 1s linear infinite;
  -webkit-animation: circle 1s linear infinite;
}

/* 搜索框 */

.van-search__content {
  border-style: solid;
  border-color: #ff1c2f;
  border-width: 1px;
}

/* 弹框 */
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}

.choice_img {
  width: 100%;
  /* 设置图片宽度为父容器的宽度 */
  height: 100%;
  /* 自适应高度，保持原始宽高比例 */
  aspect-ratio: 12/9;
  /* 设置宽高比例为 16:9 （根据实际情况进行调整）*/
}
</style>
