<template>
  <div style="margin-top: 20px">
    <div class="member-main container">
      <div>
        <a-row :gutter="10">
          <a-col :span="5">
            <!-- 左侧菜单 -->
            <div
              class="sidebar ceo-first-column"
              style="min-height: 1000px; background-color: #ffffff"
            >
              <div class="theiaStickySidebar">
                <div class="member-module ceo-background-default">
                  <div class="ceo-text-center member-img">
                    <div class="img">
                      <img
                        alt="头像"
                        :src="avatarPicUrl"
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                  <div class="member-text ceo-text-center">
                    <p class="member-name">
                      {{ username }}
                    </p>
                    <p class="member-desc-n"  @click="dialogVisibleLevel = true"                    >
                      <i class="ceofont ceoicon-vip-crown-2-line"></i>
                      {{ levelName }}
                      <a class="btn-ceo-svip">升级</a>
                    </p>
                    <div>
                      <a-space>
                        <a-button type="primary" @click="goCredit()">
                          充值
                        </a-button>

                        <a-button type="dashed" @click="goCash()">
                          提现
                        </a-button>
                      </a-space>
                    </div>
                  </div>
                </div>
                <div class="member-data ceo-background-default">
                  <div class="member-nav">
                    <li :class="{ active: $route.path === '/user_info' }">
                      <router-link to="/user_info"> 用户中心 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/ucenter' }">
                      <router-link to="/ucenter"> 账号管理 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/porder' }">
                      <router-link to="/porder"> 订单列表 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/service' }">
                      <router-link to="/service"> 售后订单 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/balance' }">
                      <router-link to="/balance"> 资金明细 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/invited' }">
                      <router-link to="/invited"> 推广分销 </router-link>
                    </li>
                    <li :class="{ active: $route.path === '/address' }">
                      <router-link to="/address"> 收货地址 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/convertManage' }">
                      <router-link to="/convertManage"> 兑换管理 </router-link>
                    </li>

                    <li :class="{ active: $route.path === '/openFz' }">
                      <router-link to="/openFz"> 开通分站 </router-link>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </a-col>

          <!-- 右边 -->
          <a-col :span="19">
            <div>
              <router-view />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <!-- 提示等级弹框 -->
    <a-modal
      title="提升等级"
      v-model:dialogVisibleLevel="dialogVisibleLevel"
      width="40%"
      :footer="null"
    >
      <USERLEVEL></USERLEVEL>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";
import USERLEVEL from "@/pages/pc/userlevel.vue";

import { getVipAddress } from "@/api/vip";

export default {
  data() {
    return {
      dialogVisibleLevel: false,
    };
  },

  components: { USERLEVEL },
  computed: {
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.commission.balanceAllowTransfer,
      balanceIsRejected: (state) => state.commission.balanceIsRejected,
      balanceIsTransfering: (state) => state.commission.balanceIsTransfering,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      userid: (state) => state.user.userid,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
      balanceTotal: (state) => state.balance.balanceTotal,
      branchId: (state) => state.user.branchId,
      levelName: (state) => state.user.levelName,
      levelNum: (state) => state.user.levelNum,
    }),
  },
  created() {
    this.$store.dispatch("user/info");
    this.$store.dispatch("balance/detail").then(() => {
      this.itemData[0][0].num = this.balanceTotal;
      this.itemData[0][1].num = this.totalRechargeSuccess;
      this.itemData[1][0].num = this.totalWithdrawRejected;
      this.itemData[1][1].num = this.totalWithdrawSuccess;
      this.itemData[2][0].num = this.balanceCanWithdraw;
      this.itemData[2][1].num = this.totalWithdrawing;
    });

    //验证是否登录
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      }
    },

    /**
     * 跳转到分站后台
     */
    toVipAdmin() {
      new Promise((resolve, rejust) => {
        getVipAddress()
          .then((res) => {
            // let isMain = res.result.isMain;

            //主站访问 直接跳转到分站登录页
            // if (isMain) {
            let url = res.result.url + "/viplogin";
            setTimeout(() => window.open(url, "_blank"));
            // }

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //充值页面
    goCredit() {
      this.$router.push("/credit");
    },

    //提现页面
    goCash() {
      this.$router.push("/cash");
    },
    isActive(tag) {
      return tag === this.$route.path;
    },
  },
};
</script>

<style>
/***********************商城中心模块***********************/
.member-module {
  position: relative;
}
.member-module .member-sign {
  position: absolute;
  top: 20px;
  left: 20px;
  color: var(--primary-color);
  background: rgba(0, 102, 255, 0.08);
  border-radius: 50px;
  padding: 3px 15px;
  font-size: 12px;
}
.member-module .member-sign:hover {
  color: #fff;
  background: var(--primary-color);
}
.member-module .member-img {
  display: block;
  margin-bottom: 8px;
  padding-top: 30px;
}
.member-module .member-img .img {
  display: inline-block;
  position: relative;
}
.member-module .member-img .img img {
  padding: 6px;
  background-color: rgba(0, 102, 255, 0.08);
  border-radius: 100% !important;
}
.member-module .member-img .img i {
  position: absolute;
  right: 12px;
  bottom: 6px;
  width: 16px;
  height: 16px;
  /* background-image: url(../../../assets/img/ceo-member-icon.png); */
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}
.member-module .member-text {
  padding: 0 24px 24px 24px;
}
.member-module .member-text .member-name {
  display: block;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: bolder;
  color: #333;
}
.member-module .member-text .member-desc-v {
  margin: 20px 0;
  display: inline-block;
  color: #ff6a00;
  font-size: 12px;
  line-height: 1.5;
  background: rgb(255 129 0 / 12%);
  border-radius: 50px;
  padding: 5px 20px;
}
.member-module .member-text .member-desc-n {
  margin: 20px 0;
  display: inline-block;
  color: #999;
  font-size: 12px;
  line-height: 1.5;
  background: rgb(0 0 0 / 5%);
  border-radius: 50px;
  padding: 5px 20px;
}
.member-module .member-text .member-desc-v i,
.member-module .member-text .member-desc-n i {
  line-height: 1;
  margin-right: 5px;
  font-size: 16px !important;
}
.member-module .member-text .member-desc-n a {
  color: #ff6a00;
  margin-left: 5px;
}

/*用户菜单*/
.member-data {
  margin-bottom: 20px;
}
.member-nav {
  text-align: center;
  list-style: none;
}

.member-nav li a {
  font-size: 15px;
  padding: 14px 0;
  display: block;
  border-left: 2px solid transparent;
  color: #333;
}
.member-nav li a:hover {
  color: #006fff !important;
}

.member-nav .active a {
  color: #006fff !important;
  background-image: linear-gradient(
    270deg,
    rgb(213 231 255 / 16%),
    rgb(123 180 255 / 18%) 51%,
    rgb(213 231 255 / 10%)
  );
  border-color: #006fff;
}

.member-center .top .left {
  height: 100%;
}
.member-center .top .left .above {
  background-image: url("https://www.ceotheme.com/wp-content/themes/ceonova-pro/ceoshop/assets/img/ceo-member-center-above.png");
  position: relative;
  padding: 50px 30px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.member-center .top .left .above .img img {
  border-radius: 50px;
  height: 66px;
  width: 66px;
  padding: 5px;
  background: rgb(255 255 255 / 33%);
}

.member-center .top .left .above .name {
  display: inline-block;
  margin-top: 10px;
}

.member-center .top .left .above .name a {
  font-size: 18px;
  display: inline-block;
  color: #250000;
  font-weight: bold;
}

.member-center .top .left .above .name span {
  display: inline-block;
  background: rgb(244 221 186);
  border-radius: 50px;
  padding: 2px 10px 2px 6px;
  font-size: 12px;
  color: #250000;
  transform: translateY(-2px);
  margin-left: 6px;
}

.member-center .top .left .above .name span i {
  background: #e1b97b;
  color: #fff;
  font-size: 12px !important;
  border-radius: 50px;
  margin-right: 5px;
  line-height: 1;
}
.member-center .top .left .above p {
  color: #444;
  margin-top: 3px;
  font-size: 12px;
}
.member-center .top .left .below {
  padding: 0 32px;
  border-radius: 0 0 6px 6px;
  height: 58px;
  line-height: 58px;
  background: #f4ddba;
  display: flex;
  align-items: center;
}
.member-center .top .left .below span {
  font-size: 14px;
  color: #3f2114;
  flex: 1;
}
.member-center .top .left .below a {
  padding: 0 22px;
  height: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  background: #f5cc87;
  color: #473a09;
  border-radius: 50px;
}
.member-center .top .left .below a:hover {
  background: #f7d5a1;
}
.member-center .top .right {
  padding: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 15px 3px rgb(146 146 146 / 10%);
  -moz-box-shadow: 0 3px 15px 3px rgba(146, 146, 146, 0.1);
  box-shadow: 0 3px 15px 3px rgb(146 146 146 / 10%);
}
.member-center .top .right .title {
  margin-bottom: 20px;
  align-items: center;
}
.member-center .top .right .title span {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  flex: 1;
}
.member-center .top .right .title a {
  color: #aab1bd;
  font-size: 12px;
}
.member-center .top .right .title a i {
  line-height: 1;
  font-size: 16px !important;
  margin-right: 3px;
}
.member-center .top .right .title a:hover {
  color: #ff6a00;
}
.member-center .top .right .item {
  padding: 20px;
  border-radius: 4px;
}
.member-center .top .right .item1 {
  background-image: -webkit-linear-gradient(62deg, #aaf5ea 0%, #e7fffb 100%);
}

.member-center .top .right .item1 span {
  font-size: 22px;
  font-weight: 600;
  color: #333;
}
.member-center .top .right .item1 span em {
  margin-left: 5px;
  color: #529192;
  font-size: 12px;
  font-weight: 400;
}
.member-center .top .right .item1 p {
  margin: 10px 0 15px 0;
  color: #529192;
}
.member-center .top .right .item2 {
  background-image: -webkit-linear-gradient(62deg, #fcf6e5 0%, #fffaf2 100%);
}
.member-center .top .right .item2 span {
  font-size: 22px;
  font-weight: 600;
  color: #973e06;
}
.member-center .top .right .item2 span em {
  margin-left: 5px;
  color: #ba7e54;
  font-size: 12px;
  font-weight: 400;
}
.member-center .top .right .item2 p {
  margin: 10px 0 15px 0;
  color: #ba7e54;
}
.member-center .top .right .item1 .btn1 {
  padding: 3px 15px;
  border: 1px solid #33d3ca;
  background-image: -webkit-linear-gradient(62deg, #08c0c5 0%, #33d3ca 100%);
  border-radius: 4px;
  color: #fff;
  margin-right: 5px;
}
.member-center .top .right .item1 .btn2 {
  border: 1px solid #08c0c5;
  padding: 3px 15px;
  border-radius: 4px;
  color: #08c0c5;
}
.member-center .top .right .item2 .btn1 {
  padding: 3px 15px;
  border: 1px solid #ffc689;
  background-image: -webkit-linear-gradient(62deg, #ff9550 0%, #ffc689 100%);
  border-radius: 4px;
  color: #fff;
  margin-right: 5px;
}
.member-center .top .right .item2 .btn2 {
  border: 1px solid #ffc689;
  padding: 3px 15px;
  border-radius: 4px;
  color: #ff9550;
}

.member-center .middle .item {
  padding: 30px 20px;
  border-radius: 4px;
  align-items: center;
  color: #333;
}
.member-center .middle .item1 {
  background-image: url("../../../assets/user/member-center-middle-item1.png");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.member-center .middle .item2 {
  background-image: url("../../../assets/user/member-center-middle-item2.png");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.member-center .middle .item3 {
  background-image: url("../../../assets/user/member-center-middle-item3.png");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.member-center .middle .item4 {
  background-image: url("../../../assets/user/member-center-middle-item4.png");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.member-center .middle .item i {
  width: 40px;
  height: 40px;
  margin-right: 11px;
}
.member-center .middle .item1 i {
  background-image: url("../../../assets/user/member-center-middle-item1-i.png");
  background-size: cover;
}
.member-center .middle .item2 i {
  background-image: url("../../../assets/user/member-center-middle-item2-i.png");

  background-size: cover;
}
.member-center .middle .item3 i {
  background-image: url("../../../assets/user/member-center-middle-item3-i.png");

  background-size: cover;
}
.member-center .middle .item4 i {
  background-image: url("../../../assets/user/member-center-middle-item4-i.png");
  background-size: cover;
}

.member-center .middle .item p {
  font-weight: 600;
}
.member-center .bottom {
  padding: 20px;
  border-radius: 4px;
}
.member-center .bottom .item {
  min-height: 204px;
}
.member-center .bottom .item .title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  flex: 1;
}
.member-center .bottom .item .more {
  color: #c0c5cf;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 50px;
  padding: 3px 10px;
}
.member-center .bottom .item .more i {
  line-height: 1;
  font-size: 12px !important;
  transform: translateY(0px);
  margin-left: 3px;
}
.member-center .bottom .item .more:hover {
  color: #999;
  background: #eee;
}

.member-center .bottom .item ul li {
  margin-top: 15px;
  align-items: center;
}
.member-center .bottom .item ul li a {
  color: #7d8592;
}
.member-center .bottom .item ul li a:hover {
  color: var(--primary-color);
}
.member-center .bottom .item ul li span {
  padding-left: 20px;
  font-size: 12px;
  color: #aab1bd;
}
.member-center .bottom .item .empty {
  text-align: center;
}
.member-center .bottom .item .empty i {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  min-height: 150px;
  margin-right: auto !important;
  margin-left: auto !important;
}
.member-center .bottom .item .empty p {
  color: #999;
}

.member-content {
  padding: 20px;
  margin-bottom: 20px !important;
  min-height: 1000px;
}
.member-page-title {
  padding: 20px;
}
.member-page-title span {
  color: #434343;
  font-size: 18px;
  font-weight: 600;
}
/*内容为空*/
.member-empty {
  text-align: center;
  margin-bottom: 200px;
}
.member-empty i {
  /* background-image: url(../../../assets/img/ceo-member-empty.svg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  min-height: 366px;
  margin-right: auto !important;
  margin-left: auto !important;
}
.member-empty p {
  color: #999;
}

.ceo-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ceo-grid > * {
  margin: 0;
}

.ceo-grid > * > :last-child {
  margin-bottom: 0;
}

.ceo-grid {
  margin-left: -20px;
}

.ceo-grid > * {
  padding-left: 20px;
}

.ceo-grid + .ceo-grid,
.ceo-grid > .ceo-grid-margin,
* + .ceo-grid-margin {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .ceo-grid {
    margin-left: -20px;
  }

  .ceo-grid > * {
    padding-left: 20px;
  }

  .ceo-grid + .ceo-grid,
  .ceo-grid > .ceo-grid-margin,
  * + .ceo-grid-margin {
    margin-top: 0px;
  }
}

.ceo-grid-ceossss,
.ceo-grid-column-ceossss {
  margin-left: -10px;
}

.ceo-grid-ceossss > *,
.ceo-grid-column-ceossss > * {
  padding-left: 10px;
}

.ceo-grid + .ceo-grid-ceossss,
.ceo-grid + .ceo-grid-row-ceossss,
.ceo-grid-ceossss > .ceo-grid-margin,
.ceo-grid-row-ceossss > .ceo-grid-margin,
* + .ceo-grid-margin-ceossss {
  margin-top: 10px;
}

.ceo-grid-small,
.ceo-grid-column-small {
  margin-left: -15px;
}

.ceo-grid-small > *,
.ceo-grid-column-small > * {
  padding-left: 15px;
}

.ceo-grid + .ceo-grid-small,
.ceo-grid + .ceo-grid-row-small,
.ceo-grid-small > .ceo-grid-margin,
.ceo-grid-row-small > .ceo-grid-margin,
* + .ceo-grid-margin-small {
  margin-top: 15px;
}

.ceo-grid-ceosmls,
.ceo-grid-column-ceosmls {
  margin-left: -20px;
}

.ceo-grid-ceosmls > *,
.ceo-grid-column-ceosmls > * {
  padding-left: 20px;
}

.ceo-grid + .ceo-grid-ceosmls,
.ceo-grid + .ceo-grid-row-ceosmls,
.ceo-grid-ceosmls > .ceo-grid-margin,
.ceo-grid-row-ceosmls > .ceo-grid-margin,
* + .ceo-grid-margin-ceosmls {
  margin-top: 20px;
}

.ceo-grid-medium,
.ceo-grid-column-medium {
  margin-left: -30px;
}

.ceo-grid-medium > *,
.ceo-grid-column-medium > * {
  padding-left: 30px;
}

.ceo-grid + .ceo-grid-medium,
.ceo-grid + .ceo-grid-row-medium,
.ceo-grid-medium > .ceo-grid-margin,
.ceo-grid-row-medium > .ceo-grid-margin,
* + .ceo-grid-margin-medium {
  margin-top: 30px;
}

.ceo-grid-large,
.ceo-grid-column-large {
  margin-left: -40px;
}

.ceo-grid-large > *,
.ceo-grid-column-large > * {
  padding-left: 40px;
}

.ceo-grid + .ceo-grid-large,
.ceo-grid + .ceo-grid-row-large,
.ceo-grid-large > .ceo-grid-margin,
.ceo-grid-row-large > .ceo-grid-margin,
* + .ceo-grid-margin-large {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .ceo-grid-large,
  .ceo-grid-column-large {
    margin-left: -70px;
  }

  .ceo-grid-large > *,
  .ceo-grid-column-large > * {
    padding-left: 70px;
  }

  .ceo-grid + .ceo-grid-large,
  .ceo-grid + .ceo-grid-row-large,
  .ceo-grid-large > .ceo-grid-margin,
  .ceo-grid-row-large > .ceo-grid-margin,
  * + .ceo-grid-margin-large {
    margin-top: 70px;
  }
}

.ceo-grid-collapse,
.ceo-grid-column-collapse {
  margin-left: 0;
}

.ceo-grid-collapse > *,
.ceo-grid-column-collapse > * {
  padding-left: 0;
}

.ceo-grid + .ceo-grid-collapse,
.ceo-grid + .ceo-grid-row-collapse,
.ceo-grid-collapse > .ceo-grid-margin,
.ceo-grid-row-collapse > .ceo-grid-margin {
  margin-top: 0;
}

@media (min-width: 640px) {
  .ceo-countdown-number {
    font-size: 4rem;
  }
}

@media (min-width: 960px) {
  .ceo-countdown-number {
    font-size: 6rem;
  }
}

.ceo-countdown-separator {
  font-size: 1rem;
  line-height: 1.6;
}

@media (min-width: 640px) {
  .ceo-countdown-separator {
    font-size: 2rem;
  }
}

.ceo-child-width-1-2 > * {
  width: 50%;
}

.ceo-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001);
}

.ceo-child-width-1-4 > * {
  width: 25%;
}

.ceo-child-width-1-5 > * {
  width: 20%;
}

.ceo-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001);
}

.ceo-child-width-auto > * {
  width: auto;
}

.ceo-child-width-expand > :not([class*="ceo-width"]) {
  flex: 1;
  min-width: 1px;
}

@media (min-width: 640px) {
  .ceo-child-width-1-1\@s > * {
    width: 100%;
  }

  .ceo-child-width-1-2\@s > * {
    width: 50%;
  }

  .ceo-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-child-width-1-4\@s > * {
    width: 25%;
  }

  .ceo-child-width-1-5\@s > * {
    width: 20%;
  }

  .ceo-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-child-width-auto\@s > * {
    width: auto;
  }

  .ceo-child-width-expand\@s > :not([class*="ceo-width"]) {
    flex: 1;
    min-width: 1px;
  }
}

@media (min-width: 960px) {
  .ceo-child-width-1-1\@m > * {
    width: 100%;
  }

  .ceo-child-width-1-2\@m > * {
    width: 50%;
  }

  .ceo-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-child-width-1-4\@m > * {
    width: 25%;
  }

  .ceo-child-width-1-5\@m > * {
    width: 20%;
  }

  .ceo-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-child-width-auto\@m > * {
    width: auto;
  }

  .ceo-child-width-expand\@m > :not([class*="ceo-width"]) {
    flex: 1;
    min-width: 1px;
  }
}

@media (min-width: 1200px) {
  .ceo-child-width-1-1\@l > * {
    width: 100%;
  }

  .ceo-child-width-1-2\@l > * {
    width: 50%;
  }

  .ceo-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-child-width-1-4\@l > * {
    width: 25%;
  }

  .ceo-child-width-1-5\@l > * {
    width: 20%;
  }

  .ceo-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-child-width-auto\@l > * {
    width: auto;
  }

  .ceo-child-width-expand\@l > :not([class*="ceo-width"]) {
    flex: 1;
    min-width: 1px;
  }
}

@media (min-width: 1600px) {
  .ceo-child-width-1-1\@xl > * {
    width: 100%;
  }

  .ceo-child-width-1-2\@xl > * {
    width: 50%;
  }

  .ceo-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-child-width-1-4\@xl > * {
    width: 25%;
  }

  .ceo-child-width-1-5\@xl > * {
    width: 20%;
  }

  .ceo-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-child-width-auto\@xl > * {
    width: auto;
  }

  .ceo-child-width-expand\@xl > :not([class*="ceo-width"]) {
    flex: 1;
    min-width: 1px;
  }
}

[class*="ceo-width"] {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
}

.ceo-width-1-2 {
  width: 50%;
}

.ceo-width-1-3 {
  width: calc(100% * 1 / 3.001);
}

.ceo-width-2-3 {
  width: calc(100% * 2 / 3.001);
}

.ceo-width-1-4 {
  width: 25%;
}

.ceo-width-3-4 {
  width: 75%;
}

.ceo-width-1-5 {
  width: 20%;
}

.ceo-width-2-5 {
  width: 40%;
}

.ceo-width-3-5 {
  width: 60%;
}

.ceo-width-4-5 {
  width: 80%;
}

.ceo-width-1-8 {
  width: 12%;
}

.ceo-width-1-6 {
  width: calc(100% * 1 / 6.001);
}

.ceo-width-5-6 {
  width: calc(100% * 5 / 6.001);
}

.ceo-width-small {
  width: 150px;
}

.ceo-width-medium {
  width: 300px;
}

.ceo-width-large {
  width: 450px;
}

.ceo-width-xlarge {
  width: 600px;
}

.ceo-width-xxlarge {
  width: 750px;
}

.ceo-width-auto {
  width: auto;
}

.ceo-width-expand {
  flex: 1;
  min-width: 1px;
}

@media (min-width: 640px) {
  .ceo-width-1-1\@s {
    width: 100%;
  }

  .ceo-width-1-2\@s {
    width: 50%;
  }

  .ceo-width-1-3\@s {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-width-2-3\@s {
    width: calc(100% * 2 / 3.001);
  }

  .ceo-width-1-4\@s {
    width: 25%;
  }

  .ceo-width-3-4\@s {
    width: 75%;
  }

  .ceo-width-1-5\@s {
    width: 20%;
  }

  .ceo-width-2-5\@s {
    width: 40%;
  }

  .ceo-width-3-5\@s {
    width: 60%;
  }

  .ceo-width-4-5\@s {
    width: 80%;
  }

  .ceo-width-1-6\@s {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-width-5-6\@s {
    width: calc(100% * 5 / 6.001);
  }

  .ceo-width-small\@s {
    width: 150px;
  }

  .ceo-width-medium\@s {
    width: 300px;
  }

  .ceo-width-large\@s {
    width: 450px;
  }

  .ceo-width-xlarge\@s {
    width: 600px;
  }

  .ceo-width-xxlarge\@s {
    width: 750px;
  }

  .ceo-width-auto\@s {
    width: auto;
  }

  .ceo-width-expand\@s {
    flex: 1;
    min-width: 1px;
  }
}

@media (min-width: 960px) {
  .ceo-width-1-1\@m {
    width: 100%;
  }

  .ceo-width-1-2\@m {
    width: 50%;
  }

  .ceo-width-1-3\@m {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-width-2-3\@m {
    width: calc(100% * 2 / 3.001);
  }

  .ceo-width-1-4\@m {
    width: 25%;
  }

  .ceo-width-3-4\@m {
    width: 75%;
  }

  .ceo-width-1-5\@m {
    width: 20%;
  }

  .ceo-width-2-5\@m {
    width: 40%;
  }

  .ceo-width-3-5\@m {
    width: 60%;
  }

  .ceo-width-4-5\@m {
    width: 80%;
  }

  .ceo-width-1-6\@m {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-width-5-6\@m {
    width: calc(100% * 5 / 6.001);
  }

  .ceo-width-small\@m {
    width: 150px;
  }

  .ceo-width-medium\@m {
    width: 300px;
  }

  .ceo-width-large\@m {
    width: 450px;
  }

  .ceo-width-xlarge\@m {
    width: 600px;
  }

  .ceo-width-xxlarge\@m {
    width: 750px;
  }

  .ceo-width-auto\@m {
    width: auto;
  }

  .ceo-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
}

@media (min-width: 1200px) {
  .ceo-width-1-1\@l {
    width: 100%;
  }

  .ceo-width-1-2\@l {
    width: 50%;
  }

  .ceo-width-1-3\@l {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-width-2-3\@l {
    width: calc(100% * 2 / 3.001);
  }

  .ceo-width-1-4\@l {
    width: 25%;
  }

  .ceo-width-3-4\@l {
    width: 75%;
  }

  .ceo-width-1-5\@l {
    width: 20%;
  }

  .ceo-width-2-5\@l {
    width: 40%;
  }

  .ceo-width-3-5\@l {
    width: 60%;
  }

  .ceo-width-4-5\@l {
    width: 80%;
  }

  .ceo-width-1-6\@l {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-width-5-6\@l {
    width: calc(100% * 5 / 6.001);
  }

  .ceo-width-small\@l {
    width: 150px;
  }

  .ceo-width-medium\@l {
    width: 300px;
  }

  .ceo-width-large\@l {
    width: 450px;
  }

  .ceo-width-xlarge\@l {
    width: 600px;
  }

  .ceo-width-xxlarge\@l {
    width: 750px;
  }

  .ceo-width-auto\@l {
    width: auto;
  }

  .ceo-width-expand\@l {
    flex: 1;
    min-width: 1px;
  }
}

@media (min-width: 960px) {
  .ceo-width-1-1\@xl {
    width: 100%;
  }

  .ceo-width-1-2\@xl {
    width: 50%;
  }

  .ceo-width-1-3\@xl {
    width: calc(100% * 1 / 3.001);
  }

  .ceo-width-2-3\@xl {
    width: calc(100% * 2 / 3.001);
  }

  .ceo-width-1-4\@xl {
    width: 25%;
  }

  .ceo-width-3-4\@xl {
    width: 75%;
  }

  .ceo-width-1-5\@xl {
    width: 20%;
  }

  .ceo-width-2-5\@xl {
    width: 40%;
  }

  .ceo-width-3-5\@xl {
    width: 60%;
  }

  .ceo-width-4-5\@xl {
    width: 80%;
  }

  .ceo-width-1-6\@xl {
    width: calc(100% * 1 / 6.001);
  }

  .ceo-width-5-6\@xl {
    width: calc(100% * 5 / 6.001);
  }

  .ceo-width-small\@xl {
    width: 150px;
  }

  .ceo-width-medium\@xl {
    width: 300px;
  }

  .ceo-width-large\@xl {
    width: 450px;
  }

  .ceo-width-xlarge\@xl {
    width: 600px;
  }

  .ceo-width-xxlarge\@xl {
    width: 750px;
  }

  .ceo-width-auto\@xl {
    width: auto;
  }

  .ceo-width-expand\@xl {
    flex: 1;
    min-width: 1px;
  }
}

[class*="ceo-height"] {
  box-sizing: border-box;
}

.ceo-height-1-1 {
  height: 100%;
}

.ceo-height-viewport {
  min-height: 100vh;
}

.ceo-height-small {
  height: 150px;
}

.ceo-height-medium {
  height: 300px;
}

.ceo-height-large {
  height: 450px;
}

.ceo-height-max-small {
  max-height: 150px;
}

.ceo-height-max-medium {
  max-height: 300px;
}

.ceo-height-max-large {
  max-height: 450px;
}

.ceo-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333;
}

.ceo-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

.ceo-text-meta a {
  color: #999;
}

.ceo-text-meta a:hover {
  color: #666;
  text-decoration: none;
}

.ceo-text-small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.ceo-text-large {
  font-size: 1.5rem;
  line-height: 1.5;
}

.ceo-text-light {
  font-weight: 300;
}

.ceo-text-normal {
  font-weight: 400;
}

.ceo-text-bold {
  font-weight: 700;
}

.ceo-text-lighter {
  font-weight: lighter;
}

.ceo-text-bolder {
  font-weight: bolder;
}

.ceo-text-italic {
  font-style: italic;
}

.ceo-text-capitalize {
  text-transform: capitalize !important;
}

.ceo-text-uppercase {
  text-transform: uppercase !important;
}

.ceo-text-lowercase {
  text-transform: lowercase !important;
}

.ceo-text-muted {
  color: #999 !important;
}

.ceo-text-emphasis {
  color: #333 !important;
}

.ceo-text-primary {
  color: #1e87f0 !important;
}

.ceo-text-secondary {
  color: #222 !important;
}

.ceo-text-success {
  color: #32d296 !important;
}

.ceo-text-warning {
  color: #faa05a !important;
}

.ceo-text-danger {
  color: #f0506e !important;
}

.ceo-text-left {
  text-align: left !important;
}

.ceo-text-right {
  text-align: right !important;
}

.ceo-text-center {
  text-align: center !important;
}

.ceo-text-justify {
  text-align: justify !important;
}

.ceo-text-top {
  vertical-align: top !important;
}

.ceo-text-middle {
  vertical-align: middle !important;
}

.ceo-text-bottom {
  vertical-align: bottom !important;
}

.ceo-text-baseline {
  vertical-align: baseline !important;
}

.ceo-text-nowrap {
  white-space: nowrap;
}

.ceo-text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th.ceo-text-truncate,
td.ceo-text-truncate {
  max-width: 0;
}

.ceo-text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

th.ceo-text-break,
td.ceo-text-break {
  word-break: break-all;
}

[class*="ceo-column-"] {
  column-gap: 30px;
}

.ceo-column-divider {
  column-rule: 1px solid #e5e5e5;
  column-gap: 60px;
}

.ceo-column-1-2 {
  column-count: 2;
}

.ceo-column-1-3 {
  column-count: 3;
}

.ceo-column-1-4 {
  column-count: 4;
}

.ceo-column-1-5 {
  column-count: 5;
}

.ceo-column-1-6 {
  column-count: 6;
}

.ceo-column-span {
  column-span: all;
}

.ceo-cover {
  max-width: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

iframe.ceo-cover {
  pointer-events: none;
}

.ceo-cover-container {
  overflow: hidden;
  position: relative;
}

.ceo-background-default {
  background-color: #fff;
}

.ceo-background-muted {
  background-color: #f8f8f8;
}

.ceo-background-primary {
  background-color: #1e87f0;
}

.ceo-background-secondary {
  background-color: #222;
}

.ceo-background-cover,
.ceo-background-contain {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.ceo-background-cover {
  background-size: cover;
}

.ceo-background-contain {
  background-size: contain;
}

.ceo-svg {
  transform: translate(0, 0);
}

.ceo-display-block {
  display: block !important;
}

.ceo-display-inline {
  display: inline !important;
}

.ceo-display-inline-block {
  display: inline-block !important;
}

.ceo-disabled {
  pointer-events: none;
}

.ceo-flex {
  display: flex;
}

.ceo-flex-inline {
  display: inline-flex;
}

.ceo-flex::before,
.ceo-flex::after,
.ceo-flex-inline::before,
.ceo-flex-inline::after {
  display: none;
}

.ceo-flex-center {
  justify-content: center;
}

.ceo-flex-right {
  justify-content: flex-end;
}

.ceo-flex-between {
  justify-content: space-between;
}

.ceo-flex-around {
  justify-content: space-around;
}

.ceo-margin-bottom {
  margin-bottom: 20px !important;
}

.member-center .middle .item span {
  flex: 1;
}

.ceo-tag-bg {
  height: 300px;
  background-image: url("../../../assets/user/member-center-middle-item1.png");
}
</style>
