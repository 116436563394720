<template>
  <div class="module-common pc_style">

    <div class="pop">
        <div class="phone">
          <div class="top">
            <div
              class="consult-tel"
              v-for="(item, index) in module.list"
              :key="index"
            >
              <div class="consult-tel-bg">
                <img :src="item.icon" class="consult-tel-bg" />
              </div>
              <p>电话咨询</p>
              <div class="content-tel" style="display: none">
                <div class="tel">400-777-9908</div>
                <div class="desc">售前咨询电话</div>
              </div>
            </div>

            <div class="consult-tel" @click="gotop" style="cursor: pointer;">
              <div class="consult-tel-bg">
                <i class="el-icon-caret-top"></i>
              </div>
              <p>返回顶部</p>
            </div>
          </div>
        </div>
      </div>

    <!-- <div :class="tops ? 'navbar' : 'navbar-top'">
      <el-tooltip
        v-for="(item, index) in module.list"
        :key="index"
        class="item"
        effect="dark"
        :content="item.title"
        placement="left"
      >
        <img :src="item.icon" style="width:64px"/>
      </el-tooltip>

      <el-tooltip
        class="item"
        effect="dark"
        content="返回顶部"
        placement="left"
      >
        <a @click="gotop">
          <i class="el-icon-caret-top"></i>
          <br />
        </a>
      </el-tooltip>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["module"],
  data() {
    return {
      showQR: false,
      tops: false,
      heights: window.innerHeight,
      scrolltop: ""
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    showQRCode() {
      //显示二维码
      this.showQR = true;
    },
    hideQRCode() {
      //隐藏二维码
      this.showQR = false;
    },
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrolltop = scrollTop;
      if (parseInt(this.scrolltop) > parseInt(this.heights) / 3) {
        this.tops = true;
      } else {
        this.tops = false;
      }
    },
    gotop() {
      let timer = setInterval(() => {
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrolltop - 90;
        if (this.scrolltop === 0) {
          clearInterval(timer);
        }
      }, 15);
    }
  }
};
</script>

<style scoped>



/* 新的 */

.pop {
  position: fixed;
  z-index: 999;
  right: 24px;
  bottom: 48px;
}

.pop .phone .top {
  width: 80px;
  background: #fff;
  border-radius: 40px;
  padding-top: 18px;
  box-shadow: 0px 0px 40px rgba(79, 122, 253, 0.2);
  -webkit-box-shadow: 0px 0px 40px rgba(79, 122, 253, 0.2);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pop .phone-img2 {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  transition: all 0.5s;
  opacity: 1;
  margin: 2px 4px 0;
}

.online-btn {
  z-index: 999;
  position: relative;
  height: 89px;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #ededed;
}
.online-btn:hover p {
  color: #0075ff !important;
}
@keyframes inner {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.online-btn .fake-inner {
  width: 49px;
  height: 49px;
  position: absolute;
  background: transparent;
  border: 2px solid #0075ff;
  margin: auto;
  border-radius: 50%;
  left: 0;
  top: -3px;
  z-index: -1;
  animation: inner 800ms ease-out infinite;
}
@keyframes outer {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.online-btn .fake-outer {
  width: 55px;
  height: 55px;
  position: absolute;
  background: transparent;
  border: 0.5px solid #0075ff;
  margin: auto;
  border-radius: 50%;
  left: -2px;
  top: -5px;
  z-index: -1;
  animation: outer 800ms ease-out infinite;
}
.consult-box {
  position: fixed;
  z-index: 999;
  right: 0.28rem;
  bottom: 0.09rem;
  /* background: url('../images/price/consult@2x.png'); */
  background-size: cover;
  width: 3.16rem;
  height: 3.91rem;
  text-align: center;
  padding-top: 0.45rem;
  font-size: 0;
}

.consult-tel {
  height: 80px;
  text-align: center;
  border-bottom: 1px solid #ededed;
  padding-top: 10px;
  position: relative;
}
.consult-tel .content-tel {
  display: none;
  padding-top: 13px;
  color: #333;
  background: #fff;
  position: absolute;
  width: 150px;
  height: 66px;
  border-radius: 8px;
  left: -180px;
  top: 0;
  box-shadow: 0px 0px 40px rgba(79, 122, 253, 0.15);
  -webkit-box-shadow: 0px 0px 40px rgba(79, 122, 253, 0.15);
}
.consult-tel .content-tel::after {
  content: "";
  position: absolute;
  right: -21px;
  top: 24px;
  border: 15px solid transparent;
  border-left-color: #fff;
}
.consult-tel .content-tel .tel {
  font-weight: 600;
  font-size: 16px;
}
.consult-tel .content-tel .desc {
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
}
.consult-tel .consult-tel-bg {
  width: 22px;
  height: 22px;
  margin: auto;
  transform: scale(1.1);
}
.consult-tel:hover p {
  color: #0075ff !important;
}

.pop .phone .top p {
  font-weight: 400;
  font-size: 12px;
  /* line-height: 20px; */
  color: #666666;
  margin-top: 10px;
}








/* .navbar {
  text-align: center;
  background-color: white;
  position: fixed;
  top: 68%;
  right: 2%;
  width: 55px;
  height: 220px;
  z-index: 999;
  border-radius: 10px;
}
.navbar-top {
  text-align: center;
  background-color: white;
  position: fixed;
  top: 68%;
  right: 2%;
  width: 55px;
  height: 165px;
  z-index: 999;
  border-radius: 10px;
}
.navbar a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 30px;
  padding-top: 5px;
}
.navbar a:last-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 15px;
  border-radius: 0px 0px 10px 10px;
}
.navbar a:first-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 55px;
  height: 55px;
  display: block;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
}
.navbar a:hover {
  background-color: #d9534f;
  color: white;
}
.navbar-top a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 30px;
  padding-top: 5px;
}
.navbar-top a:last-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 100%;
  height: 55px;
  display: block;
  font-size: 30px;
  border-radius: 0px 0px 10px 10px;
}
.navbar-top a:first-child {
  color: rgb(0, 0, 0);
  text-decoration: none;
  width: 55px;
  height: 55px;
  display: block;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
}
.navbar-top a:hover {
  background-color: #d9534f;
  color: white;
}

.right:after {
  border-left-color: rgb(179, 179, 179);
  left: 100%;
  top: 50%;
  margin-top: -7px;
} */
</style>
