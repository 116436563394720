var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc_style module-common"},[_c('div',{staticClass:"banner_1_style"},[_c('div',{staticClass:"index-wrap-new"},[_c('div',{staticClass:"main-area01-new"},[_c('div',{staticClass:"flex-box"},[_c('div',{staticClass:"left-card",staticStyle:{"width":"20%"}},[_c('div',{staticClass:"left-box"},[_vm._m(0),_c('ul',{staticClass:"left-game-list"},[_c('a',{attrs:{"href":"#"}},[_c('li',[_vm._m(1),_c('a-icon',{staticStyle:{"color":"#EFEFEF"},attrs:{"type":"play-circle","theme":"filled"}})],1)]),_c('a',{attrs:{"href":"#"}},[_c('li',[_vm._m(2),_c('a-icon',{staticStyle:{"color":"#EFEFEF"},attrs:{"type":"play-circle","theme":"filled"}})],1)]),_c('a',{attrs:{"href":"#"}},[_c('li',[_vm._m(3),_c('a-icon',{staticStyle:{"color":"#EFEFEF"},attrs:{"type":"play-circle","theme":"filled"}})],1)]),_c('a',{attrs:{"href":"#"}},[_c('li',[_vm._m(4),_c('a-icon',{staticStyle:{"color":"#EFEFEF"},attrs:{"type":"play-circle","theme":"filled"}})],1)]),_c('a',{attrs:{"href":"#"}},[_c('li',[_vm._m(5),_c('a-icon',{staticStyle:{"color":"#EFEFEF"},attrs:{"type":"play-circle","theme":"filled"}})],1)])])])]),_c('div',{staticClass:"banner-box-bg",staticStyle:{"width":"60%"}},[_c('div',{staticClass:"banner-box-new"},[_c('el-carousel',_vm._l((_vm.module.list),function(item,index){return _c('el-carousel-item',{key:index},[_c('el-image',{staticStyle:{"border-radius":"10px","width":"100%","height":"100%"},attrs:{"src":item.imageUrl}})],1)}),1)],1)]),_c('div',{staticClass:"right-card",staticStyle:{"width":"20%"}},[_c('div',{attrs:{"id":"toolBarMemberDiv"}},[_c('a-avatar',{attrs:{"size":64,"icon":"user"}}),_vm._m(6)],1),_c('ul',{staticClass:"order-info"},[_c('li',[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" 个人中心 ")])],1),_c('li',[_c('a-button',[_vm._v("退出登录")])],1)]),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","font-weight":"700","justify-content":"space-between","align-items":"center","padding":"10px 15px 5px 15px","background-color":"#ffffff","font-size":"14px"}},[_c('span',{staticStyle:{"color":"#333"}},[_vm._v("分类")]),_c('span',{staticStyle:{"color":"#333"}},[_vm._v("更多分类")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',[_vm._v("分类一区")]),_c('span',[_c('span',[_vm._v("目录一")]),_c('span',[_vm._v("目录二")]),_c('span',[_vm._v("目录三")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',[_vm._v("分类一区")]),_c('span',[_c('span',[_vm._v("目录一")]),_c('span',[_vm._v("目录二")]),_c('span',[_vm._v("目录三")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',[_vm._v("分类一区")]),_c('span',[_c('span',[_vm._v("目录一")]),_c('span',[_vm._v("目录二")]),_c('span',[_vm._v("目录三")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',[_vm._v("分类一区")]),_c('span',[_c('span',[_vm._v("目录一")]),_c('span',[_vm._v("目录二")]),_c('span',[_vm._v("目录三")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('p',[_vm._v("分类一区")]),_c('span',[_c('span',[_vm._v("目录一")]),_c('span',[_vm._v("目录二")]),_c('span',[_vm._v("目录三")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('h2',[_vm._v("123456")]),_c('div',{staticClass:"tip-member-box member-row-box"},[_vm._v(" 普通等级 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"60px"},attrs:{"id":"rentingGoods"}},[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"order-box-no"},[_c('img',{staticStyle:{"margin-right":"20px"},attrs:{"src":"//static.uhaozu.com/zuhao/images/index-new/no-order.png","width":"61"}}),_c('div',[_c('p',[_vm._v("海量精品商品等您翻牌")]),_c('span',[_vm._v("快去浏览购买吧")])])])])])
}]

export { render, staticRenderFns }