<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
      },
      { 'border-radius': module.base.round + 'px' }
    ]"
  >
    <div>
      <div>
        <div class="group-wrapper">
          <div class="hd">
            <div class="price-left">
              <uni-text class="iconfont icon-hot3"><span></span></uni-text>
              <div class="name">近期降价</div>
            </div>
            <uni-navigator class="more">
              查看更多
              <uni-text class="iconfont icon-jinrujiantou">
                <span></span>
              </uni-text>
            </uni-navigator>
          </div>
          <div class="group-scroll">
            <uni-scroll-view class="scroll-view_H">
              <div class="uni-scroll-view">
                <div class="uni-scroll-view" style="overflow: auto hidden;">
                  <div class="uni-scroll-view-content">
                    <!---->
                    <div class="group-item">
                      <van-image  class="choice_img"/>
                      <div class="info">
                        <div class="name line1">爱奇艺-月卡</div>
                        <!-- <div class="price-box">
                          <uni-text class="tips"><span>最新</span></uni-text>
                          <uni-text class="price">
                            <span>
                              <uni-text style="font-size: 11px;">
                                <span>￥</span>
                              </uni-text>
                              1.00
                            </span>
                          </uni-text>
                        </div> -->
                      </div>
                      <div class="bom-btn">
                        <uni-text class="iconfont icon-31jiangjia">
                          <span></span>
                        </uni-text>
                        <uni-text style="font-size: 11px;">
                          <span>￥</span>
                        </uni-text>
                        1.00
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </uni-scroll-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  props: ["module"],
  components: {
    // vueSeamlessScroll
  },
  data() {
    return {
      navLists: [
        {
          categoryName: "腾讯视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1565395015.png"
        },
        {
          categoryName: "优酷视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
        },
        {
          categoryName: "PP视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        },
        {
          categoryName: "乐视视频",
          prcUrl: "http://img.huanhuanle.cn/2023-07-24-1753011500.jpg"
        }
      ]
    };
  },
  computed: {}
};
</script>

<style>
.group-wrapper {
  padding: 11px 11px 14px 11px;
  background: #fff;
}

.group-wrapper .hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-wrapper .hd .price-left {
  display: flex;
  align-items: center;
}

.group-wrapper .hd .price-left .iconfont {
  color: #e93323;
  font-size: 16px;
  margin-right: 6px;
}

.group-wrapper .hd .price-left .name {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.group-wrapper .hd .more {
  font-size: 12px;
  color: #999;
}

.group-wrapper .hd .more .iconfont {
  font-size: 16px;
}

.group-wrapper .group-scroll {
  width: 100%;
  margin-top: 14px;
}

.group-wrapper .group-scroll .scroll-view_H {
  white-space: nowrap;
  width: 100%;
}

.group-wrapper .group-scroll .group-item {
  display: inline-block;
  width: 92px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  position: relative;
  background-color: #fff;
}

.group-wrapper .group-scroll .group-item uni-image {
  width: 100%;
  height: 92px;
  border-radius: 8px 8px 0 0;
}

.group-wrapper .group-scroll .group-item .info {
  padding: 1px 0px 5px 0px;
}

.group-wrapper .group-scroll .group-item .info .name {
  font-size: 13px;
}

.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-wrapper .group-scroll .group-item .info .price-box {
  display: flex;
  align-items: center;
  margin-top: 3px;
  justify-content: space-around;
}

.group-wrapper .group-scroll .group-item .info .price-box .tips {
  background-color: rgba(233, 51, 35, 0.1);
  color: #e93323;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 16px;
  margin-right: 3px;
  border-radius: 2px;
  font-size: 9px;
}

.group-wrapper .group-scroll .group-item .info .price-box .price {
  color: #e93323;
  font-size: 13px;
  font-weight: 700;
}

.group-wrapper .group-scroll .group-item .bom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 26px;
  border-radius: 0 0 8px 8px;
  color: #fff;
  font-size: 13px;
  background-color: #38b616;
}

.group-wrapper .group-scroll .group-item .bom-btn .iconfont {
  margin-right: 5px;
}
</style>
