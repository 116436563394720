<template>
  <div>

    <div style="padding: 0px 10px">
      <div class="hot_imgs">
        <div class="list-box">
          <a-row style="padding:5px">
            <div class="item" v-for="(item, index) in attr.list" :key="index">
              <div>
                <div class="delect-btn">
                  <a-button type="link" @click="deleteLink(item)">
                    <a-icon type="close" />
                  </a-button>
                </div>

                <div class="info">
                  <div class="info-item">
                    <span class="span">名称</span>
                    <div class="input-box">
                      <a-input placeholder="请输入链接" v-model="item.name" />
                    </div>
                  </div>
                </div>
                <div class="info">
                  <div class="info-item" @click="openLink(index)">
                    <span class="span">链接</span>
                    <div class="input-box">
                      <el-input v-model="item.link" placeholder="请输入内容" readonly></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-btn">
              <button type="button" class="btn ivu-btn ivu-btn-primary ivu-btn-ghost" @click="insterLink">
                添加
              </button>
            </div>
          </a-row>
        </div>
      </div>
    </div>

    <!-- 公共图片 -->
    <HImage ref="HImage" @imageCallback="imageCallback"></HImage>

    <!-- 公共链接 -->
    <Link ref="link" @linkCallback="linkCallback">
    </Link>
  </div>
</template>

<script>

import HImage from "@/components/IMAGE/index.vue";

import Link from "@/components/Link/index.vue";

export default {
  components: {
    HImage,
    Link
  },
  props: ["attr"],
  data() {
    return {
      selectedIndex: undefined
    };
  },

  methods: {

    /**
     * 添加连接
     */
    insterLink() {
      this.attr.list.push({
        name: undefined,
        link: undefined
      });
    },

    /**
     * 删除
     */
    deleteLink(item) {
      // 确保至少保留一个元素
      if (this.attr.list.length <= 1) {
        this.$message({
          showClose: true,
          message: '至少保留一个！'
        });
        return;
      }

      var index = this.attr.list.indexOf(item);
      if (index !== -1) {
        this.attr.list.splice(index, 1);
      }
    },

    /**
     * 打开链接组件
     */
    openLink(index) {
      this.selectedIndex = index;

      this.$refs.link.handleAdd();
    },

    /**
     * 打开图片组件
     */
    openImageSelector(index) {

      this.selectedIndex = index;

      this.$refs.HImage.handleAdd();
    },

    /** 
    * 图片选择
    */
    imageCallback(row) {
      this.$set(this.attr.list, this.selectedIndex, { imageUrl: row.imgUrl });
      this.$forceUpdate()
    },

    /**
     * 链接选择
     */
    linkCallback(link) {

      // 获取要更新的行
      const updatedRow = {
        ...this.attr.list[this.selectedIndex],
        link: link,
      };

      // 使用 Vue 的 $set 方法更新数组中的对象
      this.$set(this.attr.list, this.selectedIndex, updatedRow);
      
      this.$forceUpdate()
    }

  }
};
</script>

<style></style>