import {
    queryProducCategorytList,
    queryBannerList,
    // queryNewList,
    queryProductList,
    queryProductDetail,
    getWebSetting,
    getSetting,
    getCustomerSetting,
    install,
    queryHotKeywords,
    queryRecommendList,
    queryHotList,
    queryIndexBrandList,
    queryPayConfig,
    queryRechargeConfig,
    queryNormalNewsList,
    queryProductListByBrandId,
    getBrandListByCategoryId,
} from '@/api/index'

const state = {
    producCategorytList: [],
    bannerList: [],
    // newList: [],
    productList: [],
    webSetting: [],
    setting: [],
    customerDetail: [],
    install: [],
    hotKeywords: [],
    recommendList: [],
    hotList: [],
    indexBrandList: [],
    payConfig: [],
    rechargeConfig: [],
    normalNewsList: [],
    productListByBrandId: [],
    brandListByCategoryId: [],

}


const mutations = {

    //商品列表
    SET_PRODUCT_CATEGORY_LIST: (state, data) => {
        state.producCategorytList = data
    },

    //轮播图
    SET_BANNER_LIST: (state, data) => {
        state.bannerList = data
    },


    // //首页滚动资讯
    // SET_NEW_LIST: (state, data) => {
    //     state.newList = data
    // },

    //商品分类列表
    SET_PRODUCT_LIST: (state, data) => {
        state.productList = data
    },

    //根据商品id查询商品详情
    SET_PRODUCT_DETAIL: (state, data) => {
        state.productDetail = data
    },

    //查询网站配置
    SET_WEB_SETTING: (state, data) => {
        state.webSetting = data
    },


    //查询系统配置
    SET_SETTING: (state, data) => {
        state.setting = data
    },



    //获取客服配置信息
    SET_CUSTOMER_DETAIL: (state, data) => {
        state.customerDetail = data
    },


    SET_INSTALL: (state, data) => {
        state.install = data
    },

    SET_HOTKEYWORDS: (state, data) => {
        state.hotKeywords = data
    },


    //前台获取推荐产品列表
    SET_RECOMMEND_LIST: (state, data) => {
        state.recommendList = data
    },

    //前台获取热卖产品列表
    SET_HOT_LIST: (state, data) => {
        state.hotList = data
    },


    //查询首页品牌导航
    SET_INDEX_BRAND_LIST: (state, data) => {
        state.indexBrandList = data
    },


    //查询支付通道
    SET_PAY_CONFIG: (state, data) => {
        state.payConfig = data
    },

    //查询余额充值通道
    SET_RECHARGE_CONFIG: (state, data) => {
        state.rechargeConfig = data
    },



    //查询资讯列表
    SET_NORMAL_NEWS_LIST: (state, data) => {
        state.normalNewsList = data
    },

    SET_PRODUCT_LIST_BRAND_ID: (state, data) => {
        state.productListByBrandId = data
    },

    SET_BRAND_LIST_CATEGORY_ID: (state, data) => {
        state.brandListByCategoryId = data
    },



}


const actions = {

    //查询商品信息
    queryProducCategorytList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryProducCategorytList({...data }).then((response) => {
                commit('SET_PRODUCT_CATEGORY_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    //查询轮播图信息
    queryBannerList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryBannerList({...data }).then((response) => {
                commit('SET_BANNER_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // //查询首页滚动资讯
    // queryNewList({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         queryNewList({...data }).then((response) => {
    //             commit('SET_NEW_LIST', response.result)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },


    //查询商品信息
    queryProductList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryProductList({...data }).then((response) => {
                commit('SET_PRODUCT_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    //根据商品id查询商品详情
    queryProductDetail({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryProductDetail({...data }).then((response) => {
                commit('SET_PRODUCT_DETAIL', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    //查询网站设置
    getWebSetting({ commit }, data) {
        return new Promise((resolve, reject) => {
            getWebSetting({...data }).then((response) => {
                commit('SET_WEB_SETTING', response)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    //查询系统设置
    getSetting({ commit }, data) {
        return new Promise((resolve, reject) => {
            getSetting({...data }).then((response) => {
                commit('SET_SETTING', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },




    // 获取客服配置信息
    getCustomerSetting({ commit }, data) {
        return new Promise((resolve, reject) => {
            getCustomerSetting({...data }).then((response) => {
                commit('SET_CUSTOMER_DETAIL', response)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },



    install({ commit }, data) {
        return new Promise((resolve, reject) => {
            install({...data }).then((response) => {
                commit('SET_INSTALL', response)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    queryHotKeywords({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryHotKeywords({...data }).then((response) => {
                commit('SET_HOTKEYWORDS', response)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //前台获取推荐产品列表
    queryRecommendList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryRecommendList({...data }).then((response) => {
                commit('SET_RECOMMEND_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //前台获取热卖产品列表
    queryHotList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryHotList({...data }).then((response) => {
                commit('SET_HOT_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },



    //查询首页品牌导航
    queryIndexBrandList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryIndexBrandList({...data }).then((response) => {
                commit('SET_INDEX_BRAND_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //查询支付通道
    queryPayConfig({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryPayConfig({...data }).then((response) => {
                commit('SET_PAY_CONFIG', response)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },



    //查询充值通道
    queryRechargeConfig({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryRechargeConfig({...data }).then((response) => {
                console.log(response)
                commit('SET_RECHARGE_CONFIG', response)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    //查询资讯列表
    queryNormalNewsList({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryNormalNewsList({...data }).then((response) => {
                commit('SET_NORMAL_NEWS_LIST', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //根据品牌ID查询商品列表
    queryProductListByBrandId({ commit }, data) {
        return new Promise((resolve, reject) => {
            queryProductListByBrandId({...data }).then((response) => {
                commit('SET_PRODUCT_LIST_BRAND_ID', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //根据分类ID查询品牌列表
    getBrandListByCategoryId({ commit }, data) {
        return new Promise((resolve, reject) => {
            getBrandListByCategoryId({...data }).then((response) => {
                commit('SET_BRAND_LIST_CATEGORY_ID', response.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}