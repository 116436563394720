<template>
    <div>

    </div>
</template>

<script>

import { template } from "@/api/index";

export default {
    name: "HelloWorld",
    data() {
        return {
            orderListsStast: false,
        };
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {

            new Promise((resolve, rejust) => {
                template({})
                    .then((res) => {
                        console.log(res)

                        resolve();
                    })
                    .catch((error) => {
                        rejust(error);
                    });
            });


        },
    },
};
</script>