<template>
  <div></div>
</template>
<script>
import { queryRechargeStatus } from "@/api/recharge";

export default {
  data() {
    return {
      serialNum: undefined,
    };
  },
  created() {
    if (this.$route.query.out_trade_no) {
      this.serialNum = this.$route.query.out_trade_no;
    }
    this.loopOrderState();
  },
  methods: {
    //轮询当前余额充值状态s
    loopOrderState() {
      new Promise((resolve, rejust) => {
        let data = {
          serialNum: this.serialNum,
        };
        queryRechargeStatus(data)
          .then((res) => {
            if (res.result == 1) {
              this.$store.dispatch("user/info").then();

              this.$message({
                message: "充值成功",
                type: "success",
              });

              // 付款成功处理
              if (this.isMobile()) {
                this.$router.push({ path: "mbalance" });
              } else {
                this.$router.push({ path: "credit" });
              }
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
