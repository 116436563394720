<template>
  <div class="module-common pc_style">
    <div class="nice-goods-box">
      <div class="title">
        <p>诚意推荐 品质商品</p>
      </div>
      <div class="goods_3_content">
        <el-tabs
          @tab-click="handleClick"
          class="goods-tabs4"
          v-model="activeName"
        >
          <el-tab-pane
            v-for="(value, index) in catesList"
            :label="value.categoryName"
            :key="index"
            :name="index"
            :value="index"
          ></el-tab-pane>
        </el-tabs>

        <div class="detail-list">
          <div
            class="item"
            v-for="(item, index) in catesList[selectedIndex].children"
            @click="toProductList(item)"
          >
            <div class="xunijiang_image">
              <img :src="item.prcUrl" />
            </div>
            <div class="goods-name">{{ item.categoryName }}</div>
          </div>
        </div>
        <div class="footer">
          <span>找不到想要的商品? 您可以联系客服咨询相关信息！</span
          ><span class="link" @click="toCategory()">查看更多商品</span>
        </div>
        <div class="el-loading-mask" style="display: none">
          <div class="el-loading-spinner">
            <svg viewBox="25 25 50 50" class="circular">
              <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      activeName: 0,
      loginStatus: false,
      catesList: [],
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
    }),
    moduleBgStyle() {
      return {
        background: `linear-gradient(122235deg, ${this.module.moduleBg.bgColor}, ${this.module.moduleBg.fzColor})`,
        color: this.module.moduleBg.fontColor,
      };
    },
  },
  created() {
    //查收商品分类
    this.getCateList();
  },
  methods: {
    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    querSelectedIndex(value, index) {
      this.selectedIndex = index;
      console.log(value, index);
    },

    handleClick(tab, event) {
      console.log(tab, event);
      this.selectedIndex = tab.$attrs.value;
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      this.$router.push({
        path: "pcate",
        query: { categoryId: value.id, categoryPid: value.categoryPid },
      });
    },

    /**
     * 跳转到商品分类
     */
    toCategory() {
      this.$router.push({ path: "pcate" });
    },

    /**
     * 跳转到联系客服
     */
    toCustomer() {
      this.$router.push({ path: "customer" });
    },
  },
};
</script>

<style>
.nice-goods-box {
  width: 100%;
  overflow: hidden;
  border-radius: 9px;
}

.nice-goods-box .title {
  width: 100%;
  height: 60px;
  border-radius: 9px 9px 0 0;
  background: linear-gradient(90deg, #209bff, #32baff);
  display: flex;
  justify-content: space-between;
  padding-left: 17px;
  cursor: pointer;
}

.nice-goods-box .title p {
  flex: 1;
  line-height: 52px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #fff;
}

.nice-goods-box .title:before {
  content: "";
  background-image: url(../../../../../assets/icon/tuijian.png);
  width: 96px;
  height: 23px;
  background-size: cover;
  display: block;
  margin-right: 15px;
  margin-top: 16px;
}

.nice-goods-box .title:after {
  content: "";
  background-image: url(../../../../../assets/icon/title_after.472baa5d.svg);
  width: 314px;
  height: 74px;
  background-size: cover;
  display: block;
}

.nice-goods-box .goods_3_content {
  background: #fff;
  box-shadow: 0 2px 7px 0 #dddede;
  height: auto;
  transform: translateY(-8px);
  border-radius: 9px;
}

.nice-goods-box .goods_3_content .menu {
  height: 53px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.nice-goods-box .goods_3_content .menu .item-out-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nice-goods-box .goods_3_content .menu .item-out-box:hover .item {
  background-image: linear-gradient(180deg, #59f, #1ba4ff);
  color: #fff;
  box-shadow: 0 3px 13px rgba(85, 153, 255, 0.5);
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
}

.nice-goods-box .goods_3_content .menu .item {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nice-goods-box .goods_3_content .menu .active {
  background-image: linear-gradient(180deg, #59f, #1ba4ff);
  color: #fff;
  box-shadow: 0 3px 13px rgba(85, 153, 255, 0.5);
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
}

.nice-goods-box .goods_3_content .detail-list {
  height: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: start;
  justify-items: center;
  align-content: start;
  overflow: hidden;
  padding: 0 18px;
}

.nice-goods-box .goods_3_content .detail-list .item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  flex-direction: column;
  width: 210px;
  height: 150px;
  cursor: pointer;
}

.nice-goods-box .goods_3_content .detail-list .item:hover .goods-name {
  color: #1990ff;
}

.nice-goods-box .goods_3_content .detail-list .item .goods-name {
  font-size: 13px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #252525;
}

.nice-goods-box .goods_3_content .footer {
  height: 52px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 56px;
  font-size: 13px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #5b5b5b;
}

.nice-goods-box .goods_3_content .footer .link {
  color: #1990ff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.xunijiang_image {
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.xunijiang_image img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
}

/* 美化tabs */
.goods-tabs4 {
  padding: 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.goods-tabs4 .el-tabs__header {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0px;
  /* margin-bottom: 20px; */
}

.goods-tabs4 .el-tabs__item {
  color: #606266;
  border: none;
  height: 32px;
  line-height: 32px;
  /* margin-right: 10px; */
  font-size: 16px;
  padding: 0 15px;
}

.goods-tabs4 .el-tabs__item.is-active {
  /* background-color: rgb(246, 246, 246);
  color: #ff5f00;
  border-radius: 12px 12px 0px 0px;
  font-size: 16px;
  font-weight: bold; */
  /* padding: 12px 16px; */
  color: #fff;
  background-color: #59f;
  background-image: linear-gradient(180deg, #59f, #1ba4ff);
  /* box-shadow: 0 3px 13px rgba(85, 153, 255, 0.5); */
  /* padding: 5px 10px; */
  border-radius: 50px;
  margin: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.goods-tabs4 .el-tabs__pane {
  padding: 10px;
}

/* .goods-tabs4 .el-tabs__nav {
  padding: 5px 0px;
} */

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 16px !important;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 16px !important;
}

.goods-tabs4 .el-tabs__active-bar {
  background-color: transparent !important;
}

.goods-tabs4 .el-col-5 {
  width: 20%;
}
</style>
