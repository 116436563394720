<template>
  <div>
    <div>
      <div>
        <div class="hot_imgs">
          <div class="list-box" v-for="(item, index) in attr.list" :key="index">
            <div class="list-group">
              <div class="item">
                <div class="delect-btn">
                  <span
                    class="el-icon-circle-close"
                    @click="deleteLink(index)"
                  ></span>
                </div>
                <div class="img-box">
                  <div class="uploader">
                    <div class="wrap" @click="openImageModal(index, '1')">
                      <div v-if="!item.picture" class="upload-btn">
                        <i class="el-icon-plus uploader-icon"></i>
                      </div>
                      <img v-else :src="item.picture" style="width: 52px" />
                    </div>
                  </div>
                </div>
                <div class="info">
                  <div class="info-item">
                    <span class="span">图标</span>
                    <div class="input-box">
                      <el-button
                        v-if="!item.icon"
                        type="text"
                        @click="openImageModal(index, '2')"
                      >
                        选择
                      </el-button>
                      <img v-else :src="item.icon" style="width: 52px" />
                    </div>
                  </div>
                  <div class="info-item">
                    <span class="span">标题</span>
                    <div class="input-box">
                      <el-input v-model="item.title"></el-input>
                    </div>
                  </div>
                  <div class="info-item">
                    <span class="span">描述</span>
                    <div class="input-box">
                      <el-input v-model="item.content"></el-input>
                    </div>
                  </div>
                  <div class="info-item">
                    <span class="span">链接</span>
                    <div class="input-box" @click="openLinkModal(index)">
                      <el-input v-model="item.link" readonly></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <a-badge style="width: 100%">
          <a-icon
            slot="count"
            type="close-circle"
            style="color: #000000; font-size: 20px"
            @click="deleteLink(index)"
          />

          <div
            style="background: #f9f9f9; padding: 5px 15px; margin-bottom: 10px"
          >
            <el-form label-position="left" label-width="80px">
              <el-form-item label="标题">
                <el-input v-model="item.title"></el-input>
              </el-form-item>

              <el-form-item label="链接">
                <div @click="openLinkModal(index)">
                  <el-input v-model="item.link" readonly />
                </div>
              </el-form-item>

              <el-form-item label="图标">
                <div @click="openImageModal(index)">
                  <div v-if="!item.icon">
                    <a-icon type="plus" />
                  </div>
                  <img v-else :src="item.icon" style="width: 64px" />
                </div>
              </el-form-item>

              <el-form-item label="内容">
                <div @click="dialogVisible = true">123</div>
              </el-form-item>
            </el-form>
          </div>
        </a-badge> -->
      </div>

      <div>
        <a-button type="primary" @click="addLink" block>添加</a-button>
      </div>
    </div>

    <!-- 公共链接模态框 -->
    <LinkModal ref="linkModal" @linkCallback="linkCallback" />

    <!-- 公共图片模态框 -->
    <ImageModal ref="imageModal" @imageCallback="imageCallback" />
  </div>
</template>

<script>
import LinkModal from "@/components/Link/index.vue";
import ImageModal from "@/components/IMAGE/index.vue";
import Editor from "@/components/Editor";

export default {
  components: {
    editor: Editor,
    LinkModal,
    ImageModal,
  },
  props: ["attr"],
  data() {
    return {
      dialogVisible: false,
      selectedIndex: undefined,
      selectedType: undefined,
    };
  },
  methods: {
    /**
     * 添加链接
     */
    addLink() {
      this.attr.list.push({
        title: "",
        link: "",
        icon: "",
        content: "",
        picture: "",
      });
    },

    /**
     * 删除链接
     */
    deleteLink(index) {
      if (this.attr.list.length <= 1) {
        this.$message.error("至少保留一个！");
        return;
      }
      this.attr.list.splice(index, 1);
    },

    /**
     * 打开链接模态框
     */
    openLinkModal(index) {
      this.selectedIndex = index;
      this.$refs.linkModal.handleAdd();
    },

    /**
     * 更新链接
     */
    linkCallback(aaa) {
      this.attr.list[this.selectedIndex].link = aaa;
    },

    /**
     * 打开图片组件
     */
    openImageModal(index, type) {
      this.selectedIndex = index;
      this.selectedType = type;
      this.$refs.imageModal.handleAdd();
    },

    /**
     * 图片选择
     */
    imageCallback(row) {
      if (this.selectedType === "1") {
        this.attr.list[this.selectedIndex].picture = row.imgUrl;
      } else if (this.selectedType === "2") {
        this.attr.list[this.selectedIndex].icon = row.imgUrl;
      } else {
        this.attr.list[this.selectedIndex].icon = row.imgUrl;
      }

      this.$forceUpdate();
    },
  },
};
</script>
<style>
.hot_imgs .title {
  padding-bottom: 21px;
  color: #999;
  font-size: 12px;
}

.hot_imgs .list-box .item {
  position: relative;
  display: flex;
  background: #f9f9f9;
  align-items: center;
  padding: 16px 20px 16px 0;
  margin-bottom: 16px;
  border-radius: 3px;
}

.hot_imgs .list-box .item .delect-btn {
  position: absolute;
  right: -13px;
  top: -16px;
  cursor: pointer;
}

.hot_imgs .list-box .item .delect-btn .el-icon-circle-close {
  font-size: 25px;
  color: #ccc;
}

.hot_imgs .list-box .item .move-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  cursor: move;
}

.hot_imgs .list-box .item .img-box {
  position: relative;
  width: 64px;
  height: 64px;
  margin-left: 10px;
}

.hot_imgs .list-box .item .img-box img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.hot_imgs .list-box .item .info {
  flex: 1;
  margin-left: 10px;
}

.hot_imgs .list-box .item .info .info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.hot_imgs .list-box .item .info .info-item:last-child {
  margin-bottom: 0;
}

.hot_imgs .list-box .item .info .info-item .span {
  width: 60px;
  font-size: 12px;
  color: #999;
}

.hot_imgs .list-box .item .info .info-item .input-box {
  flex: 1;
}

.hot_imgs .add-btn {
  margin-top: 10px;
}

.hot_imgs .add-btn .btn {
  width: 100%;
  height: 36px;
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #ccc;
}

.uploader .wrap .upload-btn {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  border: 1px dashed #c0ccda;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
