/**
 * 模块基本元素：
 * [s]  样式
 * [sc] 样式内容 [t样式名称 p 样式图标]
 * [m]  模块间距 [true有 false无]
 * [c]  列数 [4列 5列表]
 * [bg] 背景 [1默认 2隐藏 3自定义]
 * [bt] 背景类型 [1颜色填充 2背景图片 3透明] 
 * [bc] 背景颜色
 * [bi] 背景图片
 * [fc] 文字颜色
 */
const base = {
    s: 0,
    margins: 10,
    round: 0,
    m: true,
    c: 4,
    fc: '',
    pageBg: { bgColor: '#FFFFFF', bgImage: '', bgType: 1, },
}


/**
 * 推荐模块
 * [o] 展开 [false隐藏 true展开]
 * [img] 图片
 * [g]  跳转方式 [false不跳转 true自定义]
 * [gc] 跳转连接 [t 1商品 2文章 3页面 4自定义连接 | p 路径]
 * [m]  描述
 */

//页面数据 【type:1 导航模块 2 多功能模块 3 图文模块 4 商品模块 5 推荐模块】
const pageData = [


    /**
     * 搜索模块
     * name 名称 ｜ type 1 标识 ｜ base 基础样式 ｜ title 标题 ｜ list 商品列表 | column 表单模块 ｜ data 表单数据
     */
    {
        name: '搜索框',
        type: 101,
        base: { sc: [{ t: '样式1', p: '' }], ...base },
        style: { bgcolor: '#fff', color: '#333', text: '我是文字' },
    },

    /**
     * 轮播图模块
     * name 名称 ｜ type  标识 ｜ base 基础样式 ｜ moduleBg 背景颜色
     */
    {
        name: '轮播图',
        type: 102,
        base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }], ...base },
        style: { bgcolor: '#fff', bg2color: '#fff', color: '#333', text: '我是文字' },
        list: [],
    },
    /**
     * 分类推荐
     * name 名称 ｜ type  标识 ｜ base 基础样式 ｜ moduleBg 背景颜色
     */
    // {
    //     name: '分类推荐',
    //     type: 103,
    //     base: { sc: [{ t: '样式-1', p: '' }], ...base, },
    //     moduleBg: { bgColor: '', fzColor: '', fontColor: '', },
    //     max: 15,
    // },
    /**
     * 商品模块
     * name 名称 ｜ type 4 标识 ｜ base 基础样式 ｜ title 标题 ｜ list 商品列表 | max 最多添加4｜
     * 
     * base [t 商品标题 sth显示特惠 sjg显示价格 sgm 显示购买 sjs 显示结束时间]
     */
    {
        name: '商品列表',
        type: 104,
        base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }, { t: '样式-3', p: '' }], ...base, sth: true, sjg: true, sgm: true, sjs: true },
        max: 4,
    },

    {
        name: '商品推荐',
        type: 105,
        base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }], ...base, },
        style: { bgcolor: '#fff', color: '#333', text: '推荐商品' },
        max: 4,
    },
    {
        name: '首页公告',
        type: 106,
        base: { sc: [{ t: '样式-1', p: '' }], ...base, },
        style: { bgcolor: '#fff', color: '#333', text: '推荐商品' },
    },
    {
        name: '广告展示',
        type: 107,
        base: { sc: [{ t: '样式-1', p: '' }], ...base, },
        moduleBg: { bgColor: '#2db1ff', fzColor: '#1479ff', fontColor: '#FFFFFF', },
        max: 4,
        list: [],
    },
    /**
     * 菜单导航
     * name 导航名称 ｜ type 导航标识 ｜ base 基础样式 ｜ list 导航列表 | max 最多添加15｜
     */
    {
        name: '菜单导航',
        type: 108,
        base: { sc: [{ t: '样式-1', p: '' }], ...base, },
        max: 4,
        list: [{
            imageUrl: undefined,
            name: "导航名称",
            link: undefined
        }],
    },
    {
        name: '调价信息',
        type: 109,
        base: { sc: [{ t: '样式-1', p: '../../../template/1731323495661.jpg' }], ...base, },
        max: 15,
    },
]



const control = {
    name: '首页设置',
    type: 100,
    base: { pagebg: {...base }, ...base },
}

const footerData = {
    name: '底部设置',
    type: 1000,
    list: [],
}

export default {
    pageData: pageData,
    controlData: control,
    footerData: footerData,
}