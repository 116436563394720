import request from '@/utils/request'
// import requestForm from '@/utils/requestPic'
// import requestJSON from '@/utils/requestJSON'



/**
 * 查询卡密主信息
 * @param {*} data 
 * @returns 
 */
export function queryConvert(data) {
    return request({
        url: '/api/convert/queryConvert',
        method: 'post',
        data
    })
}


/**
 * 添加卡组
 * @param {*} data 
 * @returns 
 */
export function insertConvert(data) {
    return request({
        url: '/api/convert/insertConvert',
        method: 'post',
        data
    })
}




/**
 * 查询兑换码列表
 * @param {*} data 
 * @returns 
 */
export function queryCardList(data) {
    return request({
        url: '/api/convert/queryCard',
        method: 'post',
        data
    })
}


/**
 * 添加兑换码信息
 * @param {*} data 
 * @returns 
 */
export function insertCard(data) {
    return request({
        url: '/api/convert/insertCard',
        method: 'post',
        data
    })
}


export function delCard(id) {
    return request({
        url: '/api/convert/' + id,
        method: 'delete'
    })
}