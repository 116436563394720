var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    }
  ])},[_c('div',{staticClass:"mgoods_index3"},[_c('div',{staticClass:"more-list"},_vm._l((_vm.catesList),function(value,key,index){return _c('div',{key:index,staticClass:"more-item"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"img-box"},[_c('div',{staticClass:"xnj-image"},[_c('a-icon',{staticStyle:{"font-size":"16px"},attrs:{"type":"appstore","theme":"twoTone"}})],1),_c('uni-text',{staticClass:"title-header"},[_c('span',[_vm._v("影视会员")])])],1),_c('uni-text',{staticClass:"title-text"},[_c('span',[_vm._v("查看更多>")])])],1),_c('div',{staticClass:"more-item-content"},_vm._l((value.children),function(goods,key,index){return _c('div',{key:index,staticClass:"catalogue-box"},[_c('div',{staticClass:"xnj-image"},[_c('a-avatar',{attrs:{"size":"large","icon":"instagram"}})],1),_vm._m(0,true)])}),0)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('span',{staticClass:"detail-name"},[_c('span',[_vm._v("分类名称")])]),_c('span',{staticClass:"title-text"},[_c('span',[_vm._v("立即购买")])])])
}]

export { render, staticRenderFns }