<template>
  <div class="pc_style module-common">
    <div class="banner_1_style">
      <div class="index-wrap-new">
        <div class="main-area01-new">
          <div class="flex-box">
            <div class="left-card">
              <div class="left-box">
                <div class="fenlei_title">
                  <span style="color: #333" @click="toCategory()">
                    更多分类
                  </span>
                </div>

                <ul class="left-game-list">
                  <a v-for="(item, key, index) in catesList.slice(0, 5)" :key="index">
                    <li>
                      <div class="txt">
                        <p>{{ item.categoryName }}</p>
                        <div class="category-container">
                          <span v-for="(value, key, index) in item.children.slice(
                            0,
                            3
                          )" :key="index" @click="toProductList(value)" class="category-item">{{ value.categoryName
                            }}
                          </span>
                        </div>
                      </div>
                      <a-icon type="play-circle" theme="filled" style="color: #efefef" />
                    </li>
                  </a>
                </ul>
              </div>
            </div>
            <div class="banner-box-bg">
              <div class="banner-box-new">
                <el-carousel style="height: 400px">
                  <el-carousel-item v-for="(item, index) in module.list" :key="index" style="height: 400px">
                    <a :href="item.link" target="_blank">
                      <el-image style="border-radius: 10px; width: 100%; height: 100%" :src="item.imageUrl"></el-image>
                    </a>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>

            <template v-if="this.loginStatus">
              <div class="right-card">
                <div style="height: 240px">
                  <a-avatar :size="64" :src="avatarPicUrl" />

                  <div class="name">
                    <h2>{{ username }}</h2>
                    <div class="tip-member-box member-row-box">
                      {{ levelName }}
                    </div>
                  </div>

                  <ul class="order-info">
                    <li>
                      <a-button type="primary" @click="toUserInfo" v-preventClick>
                        个人中心
                      </a-button>
                    </li>
                    <li>
                      <a-button @click="handleLogout()" v-preventClick>退出登录</a-button>
                    </li>
                  </ul>
                </div>

                <div style="border-top: 1px solid #e8e8e8">
                  <div class="order-box-no">
                    <div>
                      <div class="title2">海量精品商品等您翻牌</div>
                      <span>快去浏览购买吧</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="right-card">
                <div style="height: 240px">
                  <div>
                    <img src="@/assets/user/avatar.png" style="width: 64px" />
                    <div style="margin-top: 20px">Hi，欢迎回来</div>
                  </div>

                  <ul class="order-info">
                    <li>
                      <a-button type="primary" @click="clickLogin()">
                        立即登录
                      </a-button>
                    </li>
                  </ul>
                </div>

                <div style="border-top: 1px solid #e8e8e8">
                  <div class="order-box-no">
                    <div>
                      <div class="title2">海量精品商品等您翻牌</div>
                      <span>快去浏览购买吧</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { mapGetters, mapState } from "vuex";
import PLogin from "@/pages/pc/common/login";
export default {
  props: ["module"],
  components: { PLogin },
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
      },
      dialogLoginVisible: false,
      dialogRegisterVisible: false,
      products: [],
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      userid: (state) => state.user.userid,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      brandListByCategoryId: (state) => state.index.brandListByCategoryId,
      levelName: (state) => state.user.levelName,
    }),
  },
  mounted() {
    //验证是否登录
    this.verifyIsLogin();

    //查收商品分类
    this.getCateList();

    //获取轮播图
    this.getBannerList();
  },
  created() { },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (hasToken) {
        this.loginStatus = true;
        this.$store.dispatch("user/info");
      }
    },

    /**
     * 跳转到商品分类
     */
    toCategory() {
      this.$router.push({ path: "pcate" });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      this.$router.push({
        path: "pcate",
        query: { categoryId: value.id, categoryPid: value.categoryPid },
      });
    },

    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    /**
     * 查询轮播图
     */
    getBannerList() {
      let data = { banType: "PC" };
      this.$store.dispatch("index/queryBannerList", data).then(() => {
        // this.$forceUpdate();
      });
    },

    /**
     * 登录
     */
    clickLogin() {
      // this.dialogLoginVisible = !this.dialogLoginVisible;
      this.$router.push("/plogin");
    },

    toUserInfo() {
      this.$router.push({ path: "/user_info" });
    },

    /**
     * 退出登录
     */
    handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          location.reload();
          this.$message({
            message: "注销成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },

    showProducts(category) {
      this.products = category;
      console.log(this.products);
    },

    hideProducts() {
      this.products = [];
    },
  },
};
</script>

<style>
.banner_1_style {
  position: relative;
  font-family: microsoft yahei, PingFang SC;
}

.banner_1_style .index-wrap-new {
  width: 1300px;
  margin: 0 auto;
  position: relative;
}

.banner_1_style .main-area01-new {
  position: relative;
  /* min-height: 540px; */
  box-sizing: border-box;
  color: #222;
}

.banner_1_style .main-area01-new .flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_1_style .main-area01-new .left-card {
  width: 260px;
  height: 400px;
  box-sizing: border-box;
  position: relative;
}

.banner_1_style .main-area01-new .left-card .top-heard {
  width: 100%;
  height: 70px;
  /* background: url(../images/index-new/left-top-bg.png) no-repeat; */
  background-size: 100% 100%;
}

.banner_1_style .main-area01-new .left-card .left-box {
  background: #fff;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  height: 400px;
}

.banner_1_style .main-area01-new .left-card .left-game-list li {
  height: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.banner_1_style .main-area01-new .left-card .left-game-list li .txt {
  width: 200px;
  color: #888888;
  font-size: 12px;
  transition: 0.2s;
}

.banner_1_style .main-area01-new .left-card .left-game-list li img {
  transition: 0.2s;
}

.banner_1_style .main-area01-new .left-card .left-game-list li .txt p {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 5px;
}

.banner_1_style .main-area01-new .left-card .left-game-list li i {
  margin-right: 5px;
}

.banner_1_style .main-area01-new .left-card .left-game-list li:hover .gameImg {
  transform: translateX(5px);
}

.banner_1_style .main-area01-new .left-card .left-gameType-list {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: #222222;
  font-size: 12px;
}

.banner_1_style .main-area01-new .left-card .left-gameType-list li {
  width: 70px;
  height: 56px;
  border-radius: 8px;
  background: #f5f6f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.banner_1_style .main-area01-new .left-card .left-gameType-list li:hover {
  background: linear-gradient(270deg, #2f2f2f 0%, #575757 100%);
  color: #ffffff;
}

.banner_1_style .main-area01-new .left-card .left-gameType-list li:hover img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(225deg) brightness(102%) contrast(101%);
}

.banner_1_style .main-area01-new .banner-box-new {
  width: 700px;
  height: 400px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.banner_1_style .main-area01-new .banner-box-new .bd li {
  width: 700px;
  height: 400px;
  overflow: hidden;
}

.banner_1_style .main-area01-new .banner-box-new .bd li img {
  width: 100%;
  height: 100%;
}

.banner_1_style .main-area01-new .banner-box-new .hd {
  padding: 0 20px;
  width: fit-content;
  height: 36px;
  position: absolute;
  bottom: 18px;
  right: 108px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_1_style .main-area01-new .banner-box-new .hd li {
  width: 10px !important;
  height: 8px;
  background: #fff;
  border-radius: 5px;
  opacity: 0.5;
  margin: 0 3px;
}

.banner_1_style .main-area01-new .banner-box-new .hd li.on {
  width: 26px !important;
  opacity: 1;
}

.banner_1_style .main-area01-new .banner-box-new .hd ul {
  display: flex;
}

.banner_1_style .main-area01-new .banner-box-new .arrow-box {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  right: -88px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_1_style .main-area01-new .banner-box-new .prev {
  width: 36px;
  height: 36px;
  /* background: url(../images/index-new/banner-left-arrow.png) no-repeat; */
  background-size: 100% 100%;
  cursor: pointer;
}

.banner_1_style .main-area01-new .banner-box-new .prev:hover {
  /* background: url(../images/index-new/banner-right-arrow-on.png) no-repeat; */
  transform: rotate(180deg);
  background-size: 100% 100%;
}

.banner_1_style .main-area01-new .banner-box-new .next {
  width: 36px;
  height: 36px;
  /* background: url(../images/index-new/banner-left-arrow.png) no-repeat; */
  transform: rotate(180deg);
  background-size: 100% 100%;
  cursor: pointer;
}

.banner_1_style .main-area01-new .banner-box-new .next:hover {
  /* background: url(../images/index-new/banner-right-arrow-on.png) no-repeat; */
  background-size: 100% 100%;
  transform: rotate(0deg);
}

.banner_1_style .main-area01-new .right-card {
  width: 312px;
  height: 400px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #ebf0f5 0%, #ffffff 30%);
  border: 2px solid #ffffff;
  border-radius: 12px;
  padding-top: 20px;
  text-align: center;
}

.banner_1_style .main-area01-new .right-card h2 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 8px;
}

.banner_1_style .main-area01-new .right-card .order-info {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 0 7px;
  margin-top: 30px;
}

.banner_1_style .main-area01-new .right-card .order-info li {
  width: 34%;
  color: #999999;
  font-size: 12px;
}

.banner_1_style .main-area01-new .right-card .order-info li p {
  font-weight: 600;
  font-size: 16px;
  color: #222;
}

.banner_1_style .main-area01-new .right-card .order-box {
  width: 280px;
  height: 84px;
  box-sizing: border-box;
  margin: 16px auto 0;
  padding: 12px 0 0 14px;
  position: relative;
  display: flex;
  /* background: url(../images/index-new/right-order-bg.png) no-repeat; */
  background-size: 100% 100%;
}

.banner_1_style .main-area01-new .right-card .order-box .txt {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.banner_1_style .main-area01-new .right-card .order-box .txt span {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
}

.banner_1_style .main-area01-new .right-card .order-box .txt p {
  width: 145px;
  overflow: hidden;
  color: #222;
}

.banner_1_style .main-area01-new .right-card .order-box .txt p em {
  color: #ff2d2c;
  font-size: 16px;
  font-weight: 600;
}

.banner_1_style .main-area01-new .right-card .order-box .txt p i {
  color: #ff2d2c;
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}

.banner_1_style .main-area01-new .right-card .order-box .arrow {
  position: absolute;
  bottom: 24px;
  right: 12px;
}

.banner_1_style .main-area01-new .right-card .order-box .status {
  position: absolute;
  top: 0;
  right: 0;
}

.banner_1_style .main-area01-new .right-card .order-box .time i {
  /* background: url(../images/index-new/time-bg.png) no-repeat; */
  display: inline-block;
  background-size: 100% 100%;
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  font-size: 14px;
  color: #fff;
}

.banner_1_style .main-area01-new .right-card .order-box .time {
  display: flex;
  align-items: center;
  margin-top: 3px;
  color: #666666;
}

.banner_1_style .main-area01-new .right-card .order-box-no {
  width: 280px;
  height: 84px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #999999;
  margin: 16px auto 0;
  padding: 0px 0 0 24px;
  display: flex;
  align-items: center;
  /* background: url(../images/index-new/right-order-no-bg.png) no-repeat; */
  background-size: 100% 100%;
}

.banner_1_style .main-area01-new .right-card .order-box-no .title2 {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 18px;
}

.banner_1_style .main-area01-new .right-card .notice-box {
  margin-top: 12px;
  padding: 0 16px;
}

.banner_1_style .main-area01-new .right-card .notice-box h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  color: #222222;
}

.banner_1_style .main-area01-new .right-card .notice-box span {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area01-new .right-card .notice-box ul {
  margin-top: 9px;
  font-size: 12px;
}

.banner_1_style .main-area01-new .right-card .notice-box ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  line-height: 26px;
  color: #666666;
}

.banner_1_style .main-area01-new .right-card .hb-tips {
  color: #ff2d2c;
  font-size: 12px;
  line-height: 16px;
  margin-top: 3px;
}

.banner_1_style .main-area01-new .right-card .login-btn-box {
  display: flex;
  align-items: center;
  padding: 0 26px;
  justify-content: space-between;
  margin: 13px 0 24px;
}

.banner_1_style .main-area01-new .right-card .login-btn {
  width: 120px;
  height: 38px;
  border-radius: 40px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 14px;
  background: linear-gradient(90deg, #fe872b 0%, #fa622f 100%);
}

.banner_1_style .main-area01-new .right-card .register-btn {
  width: 120px;
  height: 38px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;
  font-size: 14px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
}

.banner_1_style .main-area01-new .module-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.banner_1_style .main-area01-new .module-box li {
  width: 314px;
  height: 131px;
  box-sizing: border-box;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  border-radius: 12px;
  overflow: hidden;
}

.banner_1_style .main-area01-new .module-box li:hover {
  transform: translateY(-6px);
}

.banner_1_style .main-area01-new .module-box li .hero {
  position: absolute;
  bottom: -5px;
  right: 0;
}

.banner_1_style .main-area01-new .module-box li h1 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  left: 24px;
  bottom: 72px;
}

.banner_1_style .main-area01-new .module-box li h2 {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  left: 24px;
  bottom: 52px;
}

.banner_1_style .main-area01-new .module-box li .btn {
  width: 76px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  background: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
  bottom: 16px;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_1_style .main-area01-new .module-box li .btn img {
  margin-left: 2px;
  margin-top: 2px;
}

.banner_1_style .main-area01-new .module-box li.item1 {
  /* background: url(../images/index-new/module1-bg.png) no-repeat bottom; */
  background-size: 100% auto;
}

.banner_1_style .main-area01-new .module-box li.item2 {
  /* background: url(../images/index-new/module2-bg.png) no-repeat bottom; */
  background-size: 100% auto;
}

.banner_1_style .main-area01-new .module-box li.item3 {
  /* background: url(../images/index-new/module3-bg.png) no-repeat bottom; */
  background-size: 100% auto;
}

.banner_1_style .main-area01-new .module-box li.item4 {
  /* background: url(../images/index-new/module4-bg.png) no-repeat bottom; */
  background-size: 100% auto;
}

.banner_1_style .main-area01-new .module-box li:hover .hero {
  animation: heroAn 0.8s linear alternate 0.3s infinite;
}

.banner_1_style .main-area01-new .tip-member-box {
  height: 30px;
  line-height: 30px;
  margin-top: -4px;
}

.banner_1_style .main-area01-new .tip-member-box .tip-member-area {
  position: absolute;
  top: 30px;
  left: 50%;
  display: none;
  z-index: 99999;
  transform: translateX(-50%);
}

.banner_1_style .main-area01-new .left-card .game-menu-card.active {
  width: 770px;
  opacity: 1;
}

.banner_1_style .main-area01-new .left-card .game-menu-card {
  width: 0px;
  height: 400px;
  transition: 0.3s;
  opacity: 0;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 270px;
  box-sizing: border-box;
  z-index: 3;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .game-more-tips {
  color: #666;
  font-size: 12px;
  position: absolute;
  top: 34px;
  right: 24px;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .tab {
  width: 250px;
  height: 36px;
  border-radius: 10px;
  background: #f5f6f8;
  display: flex;
  align-items: center;
  padding: 0 4px;
  justify-content: space-between;
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  left: 28px;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .tab li {
  width: 76px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .tab li.on {
  background: linear-gradient(90deg, #ff2d2c 0%, #ff6600 100%);
  color: #fff;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .tab-game-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 750px;
  position: absolute;
  top: 70px;
  left: 28px;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .tab-game-list li {
  margin-bottom: 16px;
  margin-right: 22px;
}

.banner_1_style .main-area01-new .left-card .game-menu-card .tab-game-list li p {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.banner_1_style .main-area01 {
  padding-top: 400px;
}

.banner_1_style .main-area01 .banner-box-bg {
  position: absolute;
  top: 0px;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  overflow: hidden;
}

.banner_1_style .main-area01 .banner-box-new {
  width: 1300px;
  height: 400px;
  float: none;
  position: relative;
  padding-left: 355px;
  margin: 0 auto;
  box-sizing: border-box;
}

.banner_1_style .main-area01 .banner-box-new .hd {
  padding: 0 20px;
  width: fit-content;
  height: 36px;
  position: absolute;
  bottom: 18px;
  left: 370px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_1_style .main-area01 .banner-box-new .hd li {
  width: 10px !important;
  height: 8px;
  background: #fff;
  border-radius: 5px;
  opacity: 0.5;
  margin: 0 3px;
}

.banner_1_style .main-area01 .banner-box-new .hd li.on {
  width: 26px !important;
  opacity: 1;
}

.banner_1_style .main-area01 .banner-box-new .hd ul {
  display: flex;
}

.banner_1_style .main-area01 .banner-box-new .bd li {
  width: 700px;
  height: 400px;
  overflow: hidden;
}

.banner_1_style .main-area01 .banner-box-new .bd li img {
  width: 700px;
  height: 400px;
}

.banner_1_style .main-area01 .banner-box-new .bd {
  position: relative;
  height: 400px;
  z-index: 0;
}

.banner_1_style .main-area01 .banner-box-new .arrow-box {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -87px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_1_style .main-area01 .banner-box-new .prev {
  width: 36px;
  height: 36px;
  /* background: url(../images/index-new/banner-left-arrow.png) no-repeat; */
  background-size: 100% 100%;
  cursor: pointer;
}

.banner_1_style .main-area01 .banner-box-new .prev:hover {
  /* background: url(../images/index-new/banner-right-arrow-on.png) no-repeat; */
  transform: rotate(180deg);
  background-size: 100% 100%;
}

.banner_1_style .main-area01 .banner-box-new .next {
  width: 36px;
  height: 36px;
  /* background: url(../images/index-new/banner-left-arrow.png) no-repeat; */
  transform: rotate(180deg);
  background-size: 100% 100%;
  cursor: pointer;
}

.banner_1_style .main-area01 .banner-box-new .next:hover {
  /* background: url(../images/index-new/banner-right-arrow-on.png) no-repeat; */
  background-size: 100% 100%;
  transform: rotate(0deg);
}

.banner_1_style .main-area01 .right-card {
  position: absolute;
  backdrop-filter: blur(10px);
  right: 0;
  top: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 240px;
  box-sizing: border-box;
  padding: 16px 10px 12px;
}

.banner_1_style .main-area01 .right-card .box1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.banner_1_style .main-area01 .right-card .box1 a {
  color: #fff;
}

.banner_1_style .main-area01 .right-card .box1 li {
  text-align: center;
  width: 70px;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  height: 70px;
  margin-bottom: 16px;
  margin-right: 5px;
}

.banner_1_style .main-area01 .right-card .box1 li:nth-child(3n) {
  margin-right: 0px;
}

.banner_1_style .main-area01 .right-card .box1 li p {
  margin-top: 9px;
}

.banner_1_style .main-area01 .right-card .box1 li:hover img {
  transform: rotateY(360deg);
  transition: 0.5s;
}

.banner_1_style .main-area01 .right-card .line {
  height: 1px;
  background: linear-gradient(270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.12) 50%,
      rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
  margin: 3px 0 19px;
}

.banner_1_style .main-area01 .right-card .box2 {
  padding: 0 2px;
  height: 137px;
  overflow: hidden;
}

.banner_1_style .main-area01 .right-card .box2 .tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: #fff;
  margin-bottom: 8px;
  padding-left: 4px;
}

.banner_1_style .main-area01 .right-card .box2 .duo-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
}

.banner_1_style .main-area01 .right-card .box2 .duo-icon:hover {
  opacity: 1;
}

.banner_1_style .main-area01 .right-card .box2 ul li {
  color: #fff;
  font-size: 12px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.7;
}

.banner_1_style .main-area01 .right-card .box2 ul li:hover {
  opacity: 1;
}

.banner_1_style .main-area01 .right-card .box2 a {
  color: #fff;
}

.banner_1_style .main-area01 .module-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding-left: 364px;
}

.banner_1_style .main-area01 .module-box .module-item {
  width: 302px;
  transition: 0.3s;
  height: 110px;
  box-sizing: border-box;
  padding: 5px 12px;
  background: #f5f8fa;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area01 .module-box .module-item .right-txt {
  margin-left: 8px;
  font-size: 12px;
  font-family: microsoft yahei, PingFang SC;
}

.banner_1_style .main-area01 .module-box .module-item .right-txt h3 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
}

.banner_1_style .main-area01 .module-box .module-item .right-txt p {
  color: #999999;
}

.banner_1_style .main-area01 .module-box .module-item .right-txt span {
  color: #555555;
  margin-top: 14px;
  display: inline-block;
}

.banner_1_style .main-area01 .module-box .module-item:hover {
  background: #edf2f5;
}

.banner_1_style .main-area01 .module-box .module-item:hover .right-txt span {
  color: #222;
}

/* .banner_1_style .main-area01 .module-box .module-item:hover .right-txt span img {
    content: url(../images/common-new/download-a-icon-on.png);
}

.banner_1_style .main-area01 .module-box .module-item.item1:hover .item1-img {
    content: url(../images/index-new/module1-1.png);
}

.banner_1_style .main-area01 .module-box .module-item.item2:hover .item2-img {
    content: url(../images/index-new/module2-1.png);
}

.banner_1_style .main-area01 .module-box .module-item.item3:hover .item3-img {
    content: url(../images/index-new/module3-1.png);
} */

.banner_1_style .main-area01 .left-card {
  position: absolute;
  left: 0;
  top: 20px;
  width: 350px;
  box-sizing: border-box;
  padding: 26px 13px 0;
  height: 506px;
  /* background: url(../images/index-new/left-bg.png) no-repeat; */
  background-size: 100% 100%;
}

.banner_1_style .main-area01 .left-card .goods-tab-box-no {
  min-height: 124px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box {
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  margin: 0 12px;
  background: linear-gradient(267deg, #ff8241 0%, #ffb99c 100%);
}

.banner_1_style .main-area01 .left-card .goods-tab-box .hd {
  height: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 16px;
  position: relative;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .hd p {
  font-size: 14px;
  color: #653116;
  font-weight: 600;
  line-height: 22px;
  margin-right: 4px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .hd .time i {
  /* background: url(../images/index-new/time-bg.png) no-repeat; */
  display: inline-block;
  background-size: 100% 100%;
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  font-size: 14px;
  color: #fff;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .hd .time {
  color: #32343a;
  font-size: 14px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .hd .sc-icon {
  position: absolute;
  top: 6px;
  right: 14px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd {
  z-index: 2;
  position: relative;
  background: linear-gradient(180deg, #ffece3 0%, #ffffff 40%);
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .btn {
  position: absolute;
  width: 20px;
  height: 50px;
  background: #f0f0f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 21px;
  right: 12px;
  cursor: pointer;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .btn:hover {
  background: #dfdfdf;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .btn:hover img {
  /* content: url(../images/common/mainsearch-list-icon-on.png); */
  width: 12px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item {
  padding: 12px 16px 10px;
  height: 88px;
  box-sizing: border-box;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tit {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  color: #222222;
  line-height: 18px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tit p {
  margin-left: 10px;
  font-weight: 600;
  width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tag {
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tag .price {
  margin-right: 18px;
  font-size: 12px;
  color: #999999;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tag .price i {
  color: #ff4436;
  font-weight: 600;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tag .price span {
  color: #ff4436;
  font-size: 16px;
  font-weight: 600;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tag em {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .goods-item .tag em img {
  margin-right: 4px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .no-goods {
  height: 88px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 14px 10px;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .no-goods h3 {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.banner_1_style .main-area01 .left-card .goods-tab-box .bd .no-goods h4 {
  color: #999;
  font-size: 12px;
}

.banner_1_style .main-area01 .left-card .info-box {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 16px;
  position: relative;
  height: 56px;
}

.banner_1_style .main-area01 .left-card .info-box .name {
  margin-left: 12px;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

.banner_1_style .main-area01 .left-card .info-box .name i {
  color: #666;
  font-size: 12px;
  font-weight: 400;
}

.banner_1_style .main-area01 .left-card .info-box .loginBtn {
  line-height: 34px;
  width: 50px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  background: linear-gradient(90deg, #fe872b 0%, #fa622f 100%);
  border-radius: 8px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

.banner_1_style .main-area01 .left-card .info-box .registerBtn {
  line-height: 34px;
  width: 50px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  background: #feb306;
  border-radius: 8px;
}

.banner_1_style .main-area01 .left-card .order-box {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.banner_1_style .main-area01 .left-card .order-box li {
  width: 81px;
  text-align: center;
}

.banner_1_style .main-area01 .left-card .order-box li p {
  font-size: 12px;
  color: #444444;
}

.banner_1_style .main-area01 .left-card .order-box li span {
  font-size: 14px;
  color: #ff4436;
  font-weight: 600;
}

.banner_1_style .main-area01 .left-card .hotGame-box {
  padding: 0 5px;
  margin-top: 20px;
}

.banner_1_style .main-area01 .left-card .hotGame-box .tit {
  font-size: 16px;
  color: #222222;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
  margin-bottom: 2px;
}

.banner_1_style .main-area01 .left-card .hotGame-box .tit .duo-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: #888888;
  font-weight: 400;
}

.banner_1_style .main-area01 .left-card .hotGame-box .tit .duo-icon:hover {
  color: #222;
}

.banner_1_style .main-area01 .left-card .hotGame-box .tit .duo-icon:hover img {
  /* content: url(../images/common-new/download-a-icon-on.png); */
}

.banner_1_style .main-area01 .left-card .hotGame-box ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 320px;
}

.banner_1_style .main-area01 .left-card .hotGame-box ul li {
  width: 75px;
  height: 71px;
  font-size: 12px;
  color: #222222;
  margin-top: 12px;
  margin-right: 5px;
  text-align: center;
  box-sizing: border-box;
}

.banner_1_style .main-area01 .left-card .hotGame-box ul li p {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 2px;
}

.banner_1_style .main-area01 .left-card .hotGame-box ul li:hover p {
  color: #ff4436;
}

.banner_1_style .main-area01 .sub-menu-new-box {
  position: absolute;
  right: 240px;
  top: 0;
  z-index: 9;
  width: 0px;
  overflow: hidden;
  transition: 0.5s;
  opacity: 0;
}

.banner_1_style .main-area01 .sub-menu-new {
  width: 560px;
  height: 360px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: #eee 1px solid;
  background: #ffffff;
  overflow: hidden;
}

.banner_1_style .main-area01 .sub-menu-new-box.active {
  width: 560px;
  padding-right: 10px;
  opacity: 1;
}

.banner_1_style .main-area01 .sub-menu-item ul {
  overflow: hidden;
  padding: 18px 11px 0px;
}

.banner_1_style .main-area01 .sub-menu-item ul li {
  float: left;
  position: relative;
  width: 88px;
  margin-left: 2px;
  margin-bottom: 15px;
  text-align: center;
}

.banner_1_style .main-area01 .sub-menu-item ul li:nth-of-type(6n + 1) {
  margin-left: 0;
}

.banner_1_style .main-area01 .sub-menu-item ul li a img {
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px;
}

.banner_1_style .main-area01 .sub-menu-item ul li a span {
  font-size: 12px;
  color: #222;
  margin-top: 4px;
  font-family: microsoft yahei, PingFang SC;
  display: block;
  text-align: center;
  vertical-align: middle;
  max-width: 88px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
}

.banner_1_style .main-area01 .sub-menu-item ul li a i {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: -10px;
}

.banner_1_style .main-area01 .sub-menu-item ul li a i img {
  width: 20px;
  height: 20px;
}

.banner_1_style .main-area01 .sub-menu-item ul li a:hover span {
  color: #ff4436;
}

.banner_1_style .main-area01 .sub-menu-item .menu-list-area-new {
  margin: 0 20px;
  border-top: 1px solid #e8e8e8;
  padding-top: 17px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.banner_1_style .main-area01 .sub-menu-item .menu-list-area-new a {
  transition: 0.3s;
  font-size: 12px;
  color: #666;
  font-family: microsoft yahei, PingFang SC;
  width: 104px;
  display: inline-block;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  /* background: url(../images/index-new/arrow-left.png) left 8px no-repeat; */
  background-size: 4px auto;
  padding-left: 10px;
  box-sizing: border-box;
}

.banner_1_style .main-area01 .sub-menu-item .menu-list-area-new a:hover span {
  color: #ff4436;
}

.banner_1_style .main-area01 .sub-menu-item .menu-list-area-new a:hover {
  transform: translateX(5px);
  /* background: url(../images/index-new/arrow-left-on.png) left 8px no-repeat; */
  background-size: 4px auto;
}

.banner_1_style .main-area01 .sub-menu-item .menu-list-area-new span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  max-width: 104px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.banner_1_style .main-area02 {
  margin-top: 22px;
}

.banner_1_style .main-area02 .tit-tab {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e9;
  border-radius: 10px;
  background: #fff;
  width: fit-content;
  padding: 0 5px;
}

.banner_1_style .main-area02 .tit-tab .tab-item {
  width: 84px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  color: #333333;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
}

.banner_1_style .main-area02 .tit-tab .tab-item.on {
  color: #fff;
  background: linear-gradient(90deg, #ff4436 0%, #ff4436 100%);
}

.banner_1_style .main-area02 .tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_1_style .main-area02 .tit .duo-icon {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 14px;
  height: 28px;
  width: 84px;
  justify-content: center;
}

.banner_1_style .main-area02 .tit .duo-icon:hover {
  color: #222;
  border-color: #d5d5d5;
}

.banner_1_style .main-area02 .tit .duo-icon:hover img {
  /* content: url(../images/common-new/download-a-icon-on.png); */
}

.banner_1_style .main-area02 .tit a {
  color: #555555;
}

.banner_1_style .main-area02 .q-goods-list {
  position: relative;
  margin-top: 10px;
  width: 1314px;
}

.banner_1_style .main-area02 .q-goods-list .prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: -12px;
  z-index: 2;
  cursor: pointer;
  display: none;
}

.banner_1_style .main-area02 .q-goods-list .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
  z-index: 2;
  cursor: pointer;
  display: none;
}

.banner_1_style .main-area02 .q-goods-list .bd {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 330px;
}

.banner_1_style .main-area02 .q-goods-list .bd ul {
  padding: 10px 0 20px !important;
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  position: absolute;
  left: -14px;
  top: 0px;
  transition: 0.5s;
}

.banner_1_style .main-area02 .q-goods-list .bd li {
  box-sizing: border-box;
  transition: 0.3s;
  overflow: hidden;
  float: left;
  margin-right: 14px;
  width: 240px;
  height: 300px;
  border-radius: 10px;
  background: #f5f8fa;
  position: relative;
}

.banner_1_style .main-area02 .q-goods-list .bd li .see-num {
  position: absolute;
  top: 6px;
  right: 6px;
  height: 22px;
  background: #ff4436;
  font-size: 12px;
  color: #fff;
  border-radius: 2px 6px 2px 12px;
  display: flex;
  align-items: center;
  padding: 0 7px;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box {
  position: absolute;
  width: 224px;
  height: 130px;
  box-sizing: border-box;
  background: #fff;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  border-radius: 8px;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box h4 {
  font-size: 18px;
  color: #222222;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box h4 span {
  font-size: 12px;
  color: #ff540d;
  margin-left: 8px;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .press-box {
  width: 200px;
  height: 34px;
  border-radius: 8px 8px 20px 8px;
  background: #fff4f1;
  box-sizing: border-box;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .press-box .pre {
  width: 130px;
  height: 12px;
  border-radius: 12px;
  background: #f6d0d2;
  overflow: hidden;
  box-sizing: border-box;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .press-box .pre .on {
  height: 12px;
  border-radius: 12px;
  background: #ff3e20;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .info-box {
  margin: 6px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .info-box .ava-box {
  font-size: 12px;
  color: #888888;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .info-box .ava-box img {
  border: 1px solid #fff;
  border-radius: 50%;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .info-box .price-info {
  font-size: 19px;
  color: #ff4436;
  font-weight: 600;
  position: relative;
  line-height: 20px;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .info-box .price-info i {
  font-size: 12px;
  margin-left: 2px;
  font-weight: 600;
}

.banner_1_style .main-area02 .q-goods-list .bd li .txt-box .info-box .price-info .line {
  width: 100%;
  height: 10px;
  background: #ffe8d9;
  position: absolute;
  bottom: -2px;
  z-index: -1;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan {
  padding: 8px;
}

.banner_1_style .main-area02 .q-goods-list .bd li:hover {
  transform: translateY(-6px);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan h1 {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan .line2 {
  height: 1px;
  background: #eeeeee;
  position: relative;
  margin-top: 6px;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan .line2 img {
  position: absolute;
  top: 0;
  left: 0;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan .txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  color: #222222;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan .txt span {
  font-size: 18px;
  font-weight: 600;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan .txt .btn {
  line-height: 26px;
  width: 68px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  background: linear-gradient(90deg, #fe872b 0%, #fa622f 100%);
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan h2 {
  position: absolute;
  left: 8px;
  right: 8px;
  top: 174px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  border-radius: 8px;
}

.banner_1_style .main-area02 .q-goods-list .bd li.type-yan h3 {
  position: absolute;
  left: 18px;
  top: 18px;
  background: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  border-radius: 8px;
  color: #fff;
  padding-right: 9px;
  display: flex;
  align-items: center;
}

.banner_1_style .main-area02 .tj-zone {
  margin-left: -14px;
}

.banner_1_style .main-area02 .tj-zone .bd ul {
  left: 14px;
}

.banner_1_style .main-area03 {
  margin-top: 10px;
  position: relative;
}

.banner_1_style .main-area03 .tit {
  font-size: 24px;
  color: #222222;
}

.banner_1_style .main-area03 .tit h3 {
  font-size: 12px;
  color: #666666;
}

.banner_1_style .main-area03 .content-box {
  margin: 20px auto 0;
  width: 1300px;
  background: #f5f8fa;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  height: 480px;
}

.banner_1_style .main-area03 .content-box .left-box {
  width: 214px;
  padding: 30px;
  height: 480px;
  box-sizing: border-box;
  /* background: url(../images/index-new/left-box-bg.png) no-repeat; */
  background-size: 100% 100%;
}

.banner_1_style .main-area03 .content-box .left-box li {
  height: 68px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 6px;
  padding-top: 12px;
  text-align: center;
  cursor: pointer;
}

.banner_1_style .main-area03 .content-box .left-box li.on {
  background: linear-gradient(90deg, #0467ff 0%, #1fcbff 100%);
}

.banner_1_style .main-area03 .content-box .left-box li.on p {
  color: #fff;
}

.banner_1_style .main-area03 .content-box .left-box li.on span {
  color: #fff;
  opacity: 0.5;
}

.banner_1_style .main-area03 .content-box .left-box li p {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
}

.banner_1_style .main-area03 .content-box .left-box li span {
  color: #aaaaaa;
  font-size: 12px;
  margin-top: 4px;
}

.banner_1_style .main-area03 .content-box .right-box {
  padding: 25px 30px 0;
  width: 1086px;
  height: 480px;
  box-sizing: border-box;
  overflow: hidden;
}

.banner_1_style .main-area03 .content-box .right-box h1 {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}

.banner_1_style .main-area03 .content-box .right-box .word-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 12px 0 12px;
  width: 672px;
  height: 96px;
  overflow: hidden;
}

.banner_1_style .main-area03 .content-box .right-box .word-list li {
  line-height: 38px;
  cursor: pointer;
  padding: 0px 14px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #deebf5;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #003f68;
  margin: 0 10px 8px 0;
}

.banner_1_style .main-area03 .content-box .right-box .word-list li:hover {
  color: #fff;
  background: linear-gradient(90deg, #0467ff 0%, #1fcbff 100%);
  border: 1px solid #f5f8fa;
}

.banner_1_style .main-area03 .content-box .game-per {
  position: absolute;
  top: -50px;
  transition: 0.5s;
  animation: tabGameImg 1s forwards;
  display: none;
}

@keyframes tabGameImg {
  0% {
    right: -100px;
    opacity: 0;
  }

  100% {
    right: 0px;
    opacity: 1;
  }
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list {
  position: relative;
  overflow: hidden;
  width: 1038px;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .bd {
  position: relative;
  height: 216px;
  width: 100%;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .bd li {
  width: 420px !important;
  background: #fff;
  height: 184px;
  padding: 16px 16px 0;
  border-radius: 10px;
  margin-right: 12px;
  box-sizing: border-box;
  float: left;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .bd .tempWrap {
  width: 1047px !important;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .bd li:hover {
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .bd ul {
  padding: 12px 0 20px !important;
  position: absolute;
  top: 0;
  left: 12px;
  display: flex;
  align-items: center;
  width: max-content;
  transition: 0.5s;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info {
  display: flex;
  align-items: flex-start;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info span {
  color: #ff4436;
  font-size: 13px;
  margin-bottom: 2px;
  display: inline-block;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info h1 {
  color: #222222;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info img {
  margin-right: 12px;
  border-radius: 4px;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info .tag-list {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info .tag-list .qzu {
  height: 22px;
  background: #f3f6fd;
  padding: 0 6px;
  box-sizing: border-box;
  border: 1px solid #ccd2de;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333333;
  margin-right: 6px;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info .tag-list .zuso {
  display: flex;
  align-items: center;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .info .tag-list .zuso .zuso-item {
  height: 22px;
  background: #fff8f6;
  padding: 0 6px;
  box-sizing: border-box;
  border: 1px solid #ffc3ae;
  border-radius: 4px;
  font-size: 12px;
  color: #ff4436;
  line-height: 20px;
  margin-right: 6px;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .price-time {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999999;
  margin-top: 8px;
  justify-content: space-between;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .price-time dd,
.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .price-time dt {
  width: 25%;
  height: 54px;
  text-align: left;
  padding-top: 6px;
  box-sizing: border-box;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .price-time p {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .price-time i {
  font-size: 12px;
  margin-left: 2px;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list li .price-time dt p {
  color: #ff4436;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
  z-index: 2;
  cursor: pointer;
  display: none;
}

.banner_1_style .main-area03 .content-box .right-box .j-goods-list .prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: -12px;
  z-index: 2;
  cursor: pointer;
  display: none;
}

.banner_1_style .main-area03 .content-box .right-box .txtMarquee-left li {
  display: flex;
  float: left;
  margin: 0px 12px 0 0;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  width: 324px;
  background: #fff;
  border-radius: 10px;
  padding: 0 14px;
  font-size: 14px;
  color: #666666;
}

.banner_1_style .main-area03 .content-box .right-box .txtMarquee-left li span {
  color: #ff4436;
}

.banner_1_style .main-area03 .content-box .right-box .txtMarquee-left li div {
  display: flex;
  align-items: center;
}

.banner_1_style .main-area03 .content-box .right-box .txtMarquee-left .tempWrap {
  width: 1026px !important;
}

.banner_1_style .main-area03 .av-round1 {
  position: absolute;
  top: 167px;
  left: -91px;
  z-index: -1;
  animation: avRound1 4s linear alternate infinite;
}

.banner_1_style .main-area03 .av-round2 {
  position: absolute;
  top: 355px;
  right: -65px;
  z-index: -1;
  animation: avRound2 5s linear alternate infinite;
}

.banner_1_style .main-area03 .av-round3 {
  position: absolute;
  top: 283px;
  left: -87px;
  z-index: -2;
  animation: avRound3 6s linear alternate infinite;
}

.banner_1_style .main-area03 .av-round4 {
  position: absolute;
  top: 312px;
  left: -188px;
  z-index: -3;
  animation: avRound4 7s linear alternate infinite;
}

.banner_1_style .main-area03 .av-round5 {
  position: absolute;
  top: 227px;
  left: -143px;
  z-index: -3;
  animation: avRound5 8s linear alternate infinite;
}

.banner_1_style .main-area03 .av-round6 {
  position: absolute;
  top: 287px;
  right: -236px;
  z-index: -3;
  animation: avRound6 4s linear alternate infinite;
}

.banner_1_style .main-area03 .av-round7 {
  position: absolute;
  top: 320px;
  right: -100px;
  z-index: -3;
  animation: avRound7 6s linear alternate infinite;
}

.banner_1_style .main-area04 {
  padding-top: 30px;
  position: relative;
  height: 484px;
  box-sizing: border-box;
}

.banner_1_style .main-area04 .tit {
  font-size: 24px;
  color: #222222;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.banner_1_style .main-area04 .tit h3 {
  font-size: 12px;
  color: #666666;
}

.banner_1_style .main-area04::before {
  content: "";
  width: 99vw;
  height: 484px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 40%);
  z-index: -1;
}

.banner_1_style .main-area04 .tit .duo-icon {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 14px;
  height: 28px;
  width: 84px;
  justify-content: center;
  font-size: 12px;
  color: #555555;
}

.banner_1_style .main-area04 .tit .duo-icon:hover {
  color: #222;
  border-color: #d5d5d5;
}

.banner_1_style .main-area04 .tit .duo-icon:hover img {
  /* content: url(../images/common-new/download-a-icon-on.png); */
}

.banner_1_style .main-area04 .help-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.banner_1_style .main-area04 .help-box li {
  width: 424px;
  height: 164px;
  transition: 0.3s;
  background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
  border-radius: 10px;
  margin: 0 14px 14px 0;
  padding: 20px 24px 0;
  box-sizing: border-box;
}

.banner_1_style .main-area04 .help-box li:nth-child(3n) {
  margin-right: 0px;
}

.banner_1_style .main-area04 .help-box li .wt {
  display: flex;
  align-items: center;
  position: relative;
}

.banner_1_style .main-area04 .help-box li .wt .txt {
  line-height: 36px;
  background: #ff802c;
  border-radius: 2px 6px 6px 6px;
  padding: 0 12px;
  font-size: 14px;
  color: #fff;
  margin-left: 12px;
}

.banner_1_style .main-area04 .help-box li .hy {
  margin-top: 14px;
  color: #666666;
  font-size: 13px;
  line-height: 24px;
  position: relative;
}

.banner_1_style .main-area04 .help-box li .hy b {
  color: #000;
  font-weight: 600;
}

.banner_1_style .main-area04 .help-box li .hy span {
  position: relative;
  z-index: 2;
}

.banner_1_style .main-area04 .help-box li .hy img {
  left: -4px;
  top: -2px;
  position: absolute;
}

.banner_1_style .main-area04 .help-box li .hy a {
  color: #3399ff;
  text-decoration: underline;
}

.banner_1_style .main-area04 .help-box li:hover {
  background: #fff;
  transform: translateY(-6px);
}

.banner_1_style .main-area04 .help-box li .call-icon {
  position: absolute;
  top: 2px;
  left: 49px;
}

.category-container {
  display: flex;
  /* 使用 flexbox 布局 */
}

.category-item {
  display: inline-block;
  /* 设置为块级元素 */
  width: 100px;
  /* 固定宽度，可以根据需要调整 */
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
  cursor: pointer;
  /* 鼠标悬停时显示手型指针 */
}

.fenlei_title {
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 5px 15px;
  background-color: #ffffff;
  font-size: 14px;
}
</style>
