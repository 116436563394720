<template>
  <div
    class="mobile-common"
    :style="[
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]"
  >
    <div class="choice_view">
      <!-- <van-list finished-text="没有更多了"> -->
      <div class="gds-list">
        <div style="background-color: #ffffff">
          <van-tabs @click="toPCate" :ellipsis="false">
            <van-tab
              :title="item.categoryName"
              v-for="item in catesList"
              :key="item.id"
              :name="item.id"
            >
              <van-swipe :loop="true" indicator-color="#2196F3">
                <van-swipe-item
                  v-for="(subItems, index) in chunkedItems(item.children)"
                  :key="index"
                >
                  <van-grid
                    icon-size="40px"
                    :border="false"
                    :column-num="4"
                    class="index_grid"
                  >
                    <van-grid-item
                      v-for="(value, idx) in subItems"
                      :key="idx"
                      :icon="value.prcUrl"
                      :text="value.categoryName"
                      @click="toCate(value)"
                    />
                  </van-grid>
                </van-swipe-item>
              </van-swipe>
            </van-tab>
          </van-tabs>
        </div>

        <div style="margin-top: 10px; height: 300px">
          <van-list
            v-model="loadingState"
            :finished="finished"
            finished-text="没有更多了"
            @load="loadMore"
          >
            <van-row gutter="10">
              <van-col
                span="12"
                v-for="(value, key, index) in listData"
                :key="index"
              >
                <div
                  style="
                    background: #fff;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    padding: 7px;
                    border-radius: 5px;
                  "
                  @click="toProductDetails(value)"
                >
                  <span>
                    <div>
                      <el-image
                        :src="value.picUrl"
                        style="border-radius: 4px; height: 172px"
                        fit="cover"
                      >
                        <template slot="error">
                          <el-image
                            :src="$defaultImage"
                            style="border-radius: 4px"
                          ></el-image>
                        </template>
                      </el-image>
                    </div>
                  </span>

                  <div>
                    <span class="tit">
                      {{ value.productName }}
                    </span>
                  </div>

                  <!-- 标签 -->
                  <div style="margin: 10px 0px">
                    <!-- 销量 -->

                    <!-- <van-tag
                      plain
                      type="primary"
                      color="#ffffff"
                      text-color="#9B9B9B"
                    >
                      已售:{{ value.salsCount }}
                    </van-tag> -->
                  </div>

                  <div class="dx2-goods-price">
                    <div>
                      <span v-html="$decimalPrice(value.price)"></span>
                    </div>

                    <span
                      style="
                        border: 1px solid #e1c1c7;
                        background-color: #fef4f3;
                        display: inline-block;
                        height: 26px;
                        padding: 0 10px;
                        line-height: 24px;
                        font-size: 12px;
                        color: #d57081;
                        border-radius: 20px;
                      "
                    >
                      立即购买
                    </span>
                  </div>
                </div>
              </van-col>
            </van-row>
          </van-list>

          <div style="height: 60px"></div>
        </div>
      </div>
      <!-- </van-list> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      listData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryPid: undefined,
        categoryId: undefined,
      },
      catesList: [],
      finished: false,
      loadingState: false,
      isFetching: false, // 是否有正在进行中的请求
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      productList: (state) => state.index.productList,
    }),
  },
  created() {
    // 初始化商品
    this.loadData();

    this.loadCate();
  },

  methods: {
    chunkedItems(items) {
      if (!Array.isArray(items) || items.length === 0) {
        return [];
      }

      const chunkSize = 8; // 每组包含8个项目（每行4个，共2行）
      const result = [];
      for (let i = 0; i < items.length; i += chunkSize) {
        result.push(items.slice(i, i + chunkSize));
      }
      return result;
    },

    loadData() {
      this.isFetching = true;

      this.$store
        .dispatch("index/queryProductList", this.queryParam)
        .then(() => {
          this.listData = this.listData.concat(this.productList.productList);
          if (this.productList.productList.length == 0) {
            //如果后面没有了 就关闭正在加载
            this.finished = true;
          }
          this.isFetching = false;
          this.loadingState = false;
        });
    },

    /**
     * 加载更多
     */
    loadMore() {
      //打开正在加载提示
      this.finished = false;

      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.loadData();
    },

    loadCate() {
      let data = {};
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    toPCate(k) {
      this.loadingState = true;
      this.finished = false;

      this.listData = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryPid = k;
      this.queryParam.categoryId = undefined;
      this.loadData();
    },

    toCate(v) {
      this.loadingState = true;
      this.finished = false;

      this.listData = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryId = v.id;
      this.queryParam.categoryPid = undefined;

      this.loadData();
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/product_detail/${v.id}`);
    },

    toGengDuo() {
      this.$router.push({
        path: "cate",
      });
    },
  },
};
</script>

<style>
/* 加载更多数据 */
.loading {
  font-size: 15px;
  color: #a6a6a6;
  text-align: center;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* 商品列表 */
.gds-list ul {
  /* background: #fff; */
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* margin-top: 10px; */
}

.gds-list li {
  /* margin: 10px 0px 0px 0px; */
  /* margin: 10px 2.5px 0px 2.5px; */
  width: 48%;
  /* padding-bottom: 10px; */
  margin-bottom: 9px;
  border-radius: 5px;
  background: #fff;
}

.gds-list li a {
  display: block;
}

.gds-list .pic-wrap {
  display: block;
  /* height: 180px; */
  overflow: hidden;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dx2-good-img {
  background: #fff;
}

.nut-image {
  display: block;
  position: relative;
}

.gds-list .tit {
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 150px;
  font-size: 14px;
  margin-top: 5px;
}

.dx2-goods-price {
  /* padding: 0 10px; */
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nut-price {
  font-size: 16px;
  color: #fa3534;
  font-weight: 700;
}

.nut-price--symbol-normal {
  display: inline-block;
  font-size: var(--nut-price-symbol-medium-size, 14px);
}

.nut-price--decimal-normal {
  display: inline-block;
  font-size: var(--nut-price-decimal-medium-size, 14px);
}

.nut-price--decimal-normal {
  display: inline-block;
  font-size: var(--nut-price-decimal-medium-size, 14px);
}

.nut-tag {
  padding: 0 4px;
  display: inline-flex;
  align-items: center;
  font-size: var(--nut-tag-font-size, 12px);
  border-radius: var(--nut-tag-default-border-radius, 4px);
  height: var(--nut-tag-height, auto);
}

.nut-tag--default {
  border: var(--nut-tag-border-width, 1px) solid transparent;
}

/* 弹框 */
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}

/deep/ .van-dropdown-menu__bar {
  box-shadow: unset !important;
  /* 解决van-dropdownMenu下拉菜单有白边问题 */
}

.van-icon__image {
  border-radius: 10px;
}

/* 新样式 */
/* // 设置整个标签栏样式 */
.gds-list .van-tab--active {
  border-radius: 50px;
  background-color: #eb6253;
  font-size: 14px;
  color: #fff;
  margin: 7px;
}

.gds-list .van-tabs__line {
  display: none;
}

/* .gds-list .van-tabs__nav {
  background-color: transparent;
} */

.gds-list .van-tabs__nav--line.van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
}

.gds-list .van-grid-item__text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.index_grid .van-grid-item__content {
  padding: 10px 0px;
}
</style>
