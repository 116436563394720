<template>
  <div>
    <div v-if="templateData.userData && templateData.userData.base">
      <!-- 样式1 -->
      <div class="item-style1" v-if="templateData.userData.base.s === 0">
        <Index1 />
      </div>

      <!-- 样式2 -->
      <div class="item-style2" v-else-if="templateData.userData.base.s === 1">
        <Index2 />
      </div>

      <div class="item-style1" v-else>
        <Index1 />
      </div>
    </div>
    <div v-else>
      <Index1 />
    </div>
  </div>
</template>

<script>
import Index1 from "./index_1.vue";
import Index2 from "./index_2.vue";
import { mapState } from "vuex";
export default {
  components: { Index1, Index2 },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      templateData: (state) => state.template.templateList,
    }),
  },

  methods: {},
};
</script>
